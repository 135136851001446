export function parseBoolean(value: string): boolean {
  if (!value) {
    return false;
  }

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  if (+value) {
    return true;
  }

  return false;
}
