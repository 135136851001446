import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPropertiesState } from '@app/features/commons/properties/+state/properties.reducer';
import { TableauDragToSelectService } from '@app/features/commons/tableau/services/tableau-drag-to-select.service';
import { ActiveModulesStoreActions } from '@app/root-store/active-modules-store';
import { ExportService } from '@app/services/export.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ui/services/notification.service';
import { get, omit } from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  flatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { IProperty } from '../../features/commons/properties/models/property.model';
import { UserMeStoreState } from '../../root-store/user-me-store';
import { IDiscountTypesResponseSuccess } from '../models/api/generics/discount-types/discount-types-response-success.model';
import { IResponseSuccess } from '../models/response-sucess.model';
import { AfterLoginService } from '../services/after-login.service';
import { AvailableRoomService } from '../services/available-room.service';
import { CheckinOutService } from '../services/checkin-out.service';
import { CurrenciesService } from '../services/currencies.service';
import { DiscountTypesService } from '../services/discount-types.service';
import { LanguagesService } from '../services/languages.service';
import { PaymentMethodsService } from '../services/payment-methods.service';
import { PropertiesTratmentsService } from '../services/properties-treatments.service';
import { TemplateEmailService } from '../services/template-email.service';

import { PropertySelectedService } from './../services/property-selected.service';
import { ReservationService } from './../services/reservation.service';
import { VatQuoteHolderService } from './../services/vat-quote-holder.service';
import {
  CoreActionTypes,
  CreateNewReservationFailure,
  CreateNewReservationRequest,
  CreateNewReservationSuccess,
  LoadAllUserPropertiesFailure,
  LoadAllUserPropertiesRequest,
  LoadAllUserPropertiesSuccess,
  LoadAvailableRoomFailure,
  LoadAvailableRoomRequest,
  LoadAvailableRoomSuccess,
  LoadCheckinOutFailure,
  LoadCheckinOutRequest,
  LoadCheckinOutSuccess,
  LoadCurrenciesFailure,
  LoadCurrenciesRequest,
  LoadCurrenciesSuccess,
  LoadGeneralDiscountTypesFailure,
  LoadGeneralDiscountTypesRequest,
  LoadGeneralDiscountTypesSuccess,
  LoadLanguagesFailure,
  LoadLanguagesRequest,
  LoadLanguagesSuccess,
  LoadPaymentsMethodPropertyFailure,
  LoadPaymentsMethodPropertyRequest,
  LoadPaymentsMethodPropertySuccess,
  LoadPropertiesTreatmentsFailure,
  LoadPropertiesTreatmentsRequest,
  LoadPropertiesTreatmentsSuccess,
  LoadPropertyDataSelectedFailure,
  LoadReservationsRoomsCoreFailure,
  LoadReservationsRoomsCoreRequest,
  LoadReservationsRoomsCoreSuccess,
  LoadReservationsRoomsToMoveCoreFailure,
  LoadReservationsRoomsToMoveCoreRequest,
  LoadReservationsRoomsToMoveCoreSuccess,
  LoadTemplateEmailCustomFailure,
  LoadTemplateEmailCustomRequest,
  LoadTemplateEmailCustomSuccess,
  LoadUserPropertiesRequest,
  LoadUserPropertiesSuccess,
  MoveReservationFailure,
  MoveReservationRequest,
  MoveReservationSuccess,
  MoveReservationWarningSuccess,
  SetLoading,
  SplitReservationFailure,
  SplitReservationRequest,
  SplitReservationSuccess,
  SplitReservationWarningSuccess,
  VatQuoteHolderFailure,
  VatQuoteHolderRequest,
  VatQuoteHolderSuccess,
} from './core.actions';
import { ICoreState } from './core.reducer';

@Injectable()
export class CoreEffects {
  afterLoadEverything$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActionTypes.LoadUserPropertiesSuccess),
        withLatestFrom(this._store),
        map(
          ([action, store]: [
            any,
            { core: ICoreState; properties: IPropertiesState },
          ]) => {
            const { redirect, skipWorkspaceSelection } = action.payload;
            const { allProperties, allGroups } = store.core;

            if (redirect) {
              if (
                skipWorkspaceSelection !== true &&
                (allProperties.length > 1 || allGroups.length > 1)
              ) {
                this._afterLogin.askForWorkspace(redirect);
              } else {
                const redirectUrlFromQyeryParams =
                  this.activatedRoute?.snapshot?.queryParams?.redirect;
                if (!redirectUrlFromQyeryParams) {
                  this._router.navigateByUrl(redirect);
                } else {
                  window.location = redirectUrlFromQyeryParams;
                }
              }
            }
          },
        ),
      ),
    { dispatch: false },
  );

  loadUserProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadUserPropertiesRequest),
      switchMap((action: LoadUserPropertiesRequest) => {
        const { userId, redirect, selectedPropertyId } = action.payload;
        return this._propertySelectedService.loadUserProperties(userId).pipe(
          mergeMap(
            (response: IResponseSuccess<{ default: any[]; groups: any[] }>) => {
              const {
                data: { default: defaultPropertes, groups },
              } = response;
              if (!defaultPropertes.length && !groups.length) {
                return [new LoadPropertyDataSelectedFailure({ error: null })];
              }

              const allproperties = response.data.groups.reduce(
                (acc, group) => {
                  return [
                    ...acc,
                    ...group.properties.map(({ id: propertyId }) => propertyId),
                  ];
                },
                [],
              );

              return [
                new LoadUserPropertiesSuccess({
                  properties: response.data,
                  redirect,
                  selectedPropertyId,
                }),
                ActiveModulesStoreActions.loadActiveModulesRequest({
                  properties: allproperties,
                }),
              ];
            },
          ),
          catchError((error: Error) => {
            return of(new LoadPropertyDataSelectedFailure({ error }));
          }),
        );
      }),
    ),
  );

  loadAllUserProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadAllUserPropertiesRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, store]: [
          LoadAllUserPropertiesRequest,
          { userMe: UserMeStoreState.State },
        ]) => {
          const { userId } = action.payload;

          return this._propertySelectedService
            .loadAllUserProperties(userId)
            .pipe(
              mergeMap((response: IResponseSuccess<IProperty[]>) => {
                if (!response.data.length) {
                  this._router.navigate(['/properties-not-found']);
                  return [new LoadAllUserPropertiesFailure({ error: null })];
                }
                return [
                  new LoadAllUserPropertiesSuccess({
                    allUserPropeties: response.data,
                  }),
                  // ActiveModulesStoreActions.loadActiveModulesRequest({
                  //   properties: response.data.map(
                  //     ({ id: propertyId }) => propertyId,
                  //   ),
                  // }),
                ];
              }),
              catchError((error: Error) => {
                return of(new LoadAllUserPropertiesFailure({ error }));
              }),
            );
        },
      ),
    ),
  );

  loadDiscountTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadGeneralDiscountTypesRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [
          LoadGeneralDiscountTypesRequest,
          { core: ICoreState },
        ]) => {
          const { discount_types } = core.generics;
          if (discount_types.length > 0) {
            return of(
              new LoadGeneralDiscountTypesSuccess({
                discountTypesData: discount_types,
              }),
            );
          }
          return this._discountTypesService.load().pipe(
            map(
              (response: IDiscountTypesResponseSuccess) =>
                new LoadGeneralDiscountTypesSuccess({
                  discountTypesData: response.data,
                }),
            ),
            catchError((error: Error) => {
              return of(new LoadGeneralDiscountTypesFailure({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadLanguagesRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [LoadLanguagesRequest, { core: ICoreState }]) => {
          const { languages } = core.generics;
          if (languages.length > 0) {
            return of(new LoadLanguagesSuccess({ languages }));
          }
          return this._languagesService.loadLanguages().pipe(
            map(
              (response: IResponseSuccess) =>
                new LoadLanguagesSuccess({ languages: response.data }),
            ),
            catchError((error: Error) => {
              return of(new LoadLanguagesFailure({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadCurrenciesRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [LoadCurrenciesRequest, { core: ICoreState }]) => {
          const { currencies } = core.generics;
          if (currencies.length > 0) {
            return of(new LoadCurrenciesSuccess({ currencies }));
          }
          return this._currenciesService.loadCurrencies().pipe(
            map(
              (response: IResponseSuccess) =>
                new LoadCurrenciesSuccess({ currencies: response.data }),
            ),
            catchError((error: Error) => {
              return of(new LoadCurrenciesFailure({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadCheckinOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadCheckinOutRequest),
      withLatestFrom(this._store),
      debounceTime(50),
      switchMap(
        ([action, { core, userMe }]: [
          LoadCheckinOutRequest,
          { core: ICoreState; userMe: UserMeStoreState.State },
        ]) => {
          const { selectedProperty } = core;

          const { propertiesIds } = action.payload;
          const { permissions: userPermissions } = userMe.data;
          const { currentUser } = userPermissions;
          const type = get(
            currentUser.find((cu) => cu.property_id === selectedProperty?.id),
            'type',
          );

          const hasPermission = Object.values(currentUser)
            .map(({ permissions }) => {
              return get(permissions, 'reservations.read', false);
            })
            .every((value) => !!value);

          if (type === 'housekeeper' || !hasPermission) {
            return of(
              new LoadCheckinOutFailure({ error: new Error('Unauthorized') }),
            );
          }

          const date_from = moment().format('YYYY-MM-DD');

          return this._chekinOutService
            .loadCheckinOut(propertiesIds, date_from)
            .pipe(
              map(
                (response: IResponseSuccess) =>
                  new LoadCheckinOutSuccess({
                    checkinOutData: response.data,
                    date_from,
                  }),
              ),
              catchError((error: Error) => {
                this._notification.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.load_failure',
                    {
                      param: this._translate.instant('checkin_out'),
                    },
                  ),
                  type: 'error',
                });
                return of(new LoadCheckinOutFailure({ error }));
              }),
            );
        },
      ),
    ),
  );

  loadReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadReservationsRoomsCoreRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [
          LoadReservationsRoomsCoreRequest,
          { core: ICoreState },
        ]) => {
          const { propertiesIds, date, noPagination, status } = action.payload;
          let newPropertiesIds = propertiesIds;
          if (!propertiesIds) {
            newPropertiesIds = core.allProperties.map(({ id }) => id);
          }
          return this._reservationService
            .loadReservations(newPropertiesIds, date, noPagination, status)
            .pipe(
              map((response: IResponseSuccess) => {
                return new LoadReservationsRoomsCoreSuccess({
                  reservations: response.data,
                });
              }),
              catchError((error: Error) => {
                this._notification.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.load_failure',
                    {
                      param: this._translate.instant('reservation'),
                    },
                  ),
                  type: 'error',
                });
                return of(new LoadReservationsRoomsCoreFailure({ error }));
              }),
            );
        },
      ),
    ),
  );

  loadReservationsToMove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadReservationsRoomsToMoveCoreRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [
          LoadReservationsRoomsToMoveCoreRequest,
          { core: ICoreState },
        ]) => {
          const { propertiesIds, date } = action.payload;
          let newPropertiesIds = propertiesIds;
          if (!propertiesIds) {
            newPropertiesIds = core.allProperties.map(({ id }) => id);
          }
          return this._reservationService
            .loadReservations(newPropertiesIds, date)
            .pipe(
              map((response: IResponseSuccess) => {
                return new LoadReservationsRoomsToMoveCoreSuccess({
                  reservations: response.data,
                });
              }),
              catchError((error: Error) => {
                this._notification.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.load_failure',
                    {
                      param: this._translate.instant('reservation'),
                    },
                  ),
                  type: 'error',
                });
                return of(
                  new LoadReservationsRoomsToMoveCoreFailure({ error }),
                );
              }),
            );
        },
      ),
    ),
  );

  loadTemplateEmailCustom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadTemplateEmailCustomRequest),
      withLatestFrom(this._store),
      flatMap(
        ([action, { core }]: [
          LoadTemplateEmailCustomRequest,
          { core: ICoreState },
        ]) => {
          const { property_id } = action.payload;
          if (
            core.templatesEmailCustom &&
            core.templatesEmailCustom[property_id] &&
            core.templatesEmailCustom[property_id].length
          ) {
            const { templatesEmailCustom } = core;
            return of(
              new LoadTemplateEmailCustomSuccess({
                templatesEmailCustom: templatesEmailCustom[property_id],
                property_id,
              }),
            );
          }
          return this._templateEmailService
            .loadEmailTemplateCustom(property_id)
            .pipe(
              map(
                (response: IResponseSuccess) =>
                  new LoadTemplateEmailCustomSuccess({
                    templatesEmailCustom: response.data,
                    property_id,
                  }),
              ),
              catchError((error: Error) => {
                this._notification.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.load_failure',
                    {
                      param: this._translate.instant('email_template'),
                    },
                  ),
                  type: 'error',
                });
                return of(
                  new LoadTemplateEmailCustomFailure({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  loadPaymentsMethodProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadPaymentsMethodPropertyRequest),
      withLatestFrom(this._store),
      flatMap(
        ([action, { core }]: [
          LoadPaymentsMethodPropertyRequest,
          { core: ICoreState },
        ]) => {
          const { property_id } = action.payload;
          if (
            core.generics.paymentMethodsProperty &&
            core.generics.paymentMethodsProperty[property_id] &&
            core.generics.paymentMethodsProperty[property_id].length
          ) {
            const { paymentMethodsProperty } = core.generics;
            return of(
              new LoadPaymentsMethodPropertySuccess({
                paymentsMethodProperty: paymentMethodsProperty[property_id],
                property_id,
              }),
            );
          }
          return this._paymentMethodsService
            .loadPaymentMethodsProperty(property_id)
            .pipe(
              map(
                (response: IResponseSuccess) =>
                  new LoadPaymentsMethodPropertySuccess({
                    paymentsMethodProperty: response.data,
                    property_id,
                  }),
              ),
              catchError((error: Error) => {
                this._notification.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.load_failure',
                    {
                      param: this._translate.instant('payment_methods'),
                    },
                  ),
                  type: 'error',
                });
                return of(
                  new LoadPaymentsMethodPropertyFailure({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  public createNewReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.CreateNewReservationRequest),
      switchMap((action: CreateNewReservationRequest) => {
        const { reservationData, onSuccess, onFailure } = action.payload;
        return this._reservationService
          .createNewReservation(reservationData)
          .pipe(
            map((response: IResponseSuccess) => {
              this._notification.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });

              if (onSuccess) {
                onSuccess();
              }

              const payments = get(response, 'data.[0].payments');
              if (payments && payments.length) {
                payments.forEach((payment) => {
                  if (payment.id) {
                    const exportLink = `payment/${payment.id}/print?type=single_copy`;
                    this.exportService.directExport(exportLink);
                  }
                });
              }

              return new CreateNewReservationSuccess();
            }),
            catchError((error: Error) => {
              this._notification.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.create_failure',
                  {
                    param: this._translate.instant('reservation'),
                  },
                ),
                type: 'error',
              });

              if (onFailure) {
                onFailure();
              }

              return of(new CreateNewReservationFailure({ error }));
            }),
          );
      }),
    ),
  );

  public splitReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.SplitReservationRequest),
      switchMap((action: SplitReservationRequest) => {
        const { splitReservation } = action.payload;
        return this._reservationService.splitReservation(splitReservation).pipe(
          map((response: IResponseSuccess) => {
            if (response.meta.confirm_required) {
              return new SplitReservationWarningSuccess({
                splitReservation: {
                  ...response.data[0],
                  reservation_accommodation_room_id:
                    splitReservation?.reservation_accommodation_room_id,
                },
              });
            } else {
              this._notification.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
              const storageData = JSON.parse(
                localStorage.getItem('tableau_onSwapData'),
              );
              const accommodationId = Object.keys(storageData || {}).find(
                (k) =>
                  storageData[k][
                    splitReservation
                      ?.accommodation_tableau_number_id_destination
                  ],
              );
              const storageWithoutMovedReservation = omit(
                storageData,
                `${accommodationId}.${splitReservation?.accommodation_tableau_number_id_destination}.${splitReservation.arrival_date}`,
              );
              localStorage.setItem(
                'tableau_onSwapData',
                JSON.stringify(storageWithoutMovedReservation),
              );
              return new SplitReservationSuccess({
                splitReservation: {
                  ...response.data[0],
                  reservation_accommodation_room_id:
                    splitReservation?.reservation_accommodation_room_id,
                },
              });
            }
          }),
          catchError(({ error }: any) => {
            this._notification.push({
              title: this._translate.instant('error'),
              content: this._translate.instant(error.message),
              type: 'error',
            });
            this.dragService.draggedReservations = {};
            return of(
              new SplitReservationFailure({
                error: error.meta.error,
              }),
            );
          }),
        );
      }),
    ),
  );

  public moveReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.MoveReservationRequest),
      switchMap((action: MoveReservationRequest) => {
        const { moveReservation } = action.payload;
        return this._reservationService.moveReservation(moveReservation).pipe(
          map((response: IResponseSuccess) => {
            if (response.meta.confirm_required) {
              return new MoveReservationWarningSuccess({
                moveReservation: response.data[0],
              });
            } else {
              this._notification.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
              const storageData = JSON.parse(
                localStorage.getItem('tableau_onSwapData'),
              );
              const storageWithoutMovedReservation = omit(
                storageData,
                `${moveReservation.accommodation_id_to}.${moveReservation.tableau_number_id_to}.${moveReservation.checkin_date}`,
              );
              localStorage.setItem(
                'tableau_onSwapData',
                JSON.stringify(storageWithoutMovedReservation),
              );
              return new MoveReservationSuccess({
                moveReservation: response.data[0],
              });
            }
          }),
          catchError(({ error }: any) => {
            this._notification.push({
              title: this._translate.instant('error'),
              content: this._translate.instant('notifications.update_failure', {
                param: this._translate.instant(error.message),
              }),
              type: 'error',
            });
            return of(
              new MoveReservationFailure({
                error: error.meta.error,
              }),
            );
          }),
        );
      }),
    ),
  );

  loadPropertiesTreatments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadPropertiesTreatmentsRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [
          LoadPropertiesTreatmentsRequest,
          { core: ICoreState },
        ]) => {
          const { propertiesIds } = action.payload;
          return this._propertiesTreatmentsService
            .loadPropertiesTreatmentsArray(propertiesIds)
            .pipe(
              map((response: any) => {
                return new LoadPropertiesTreatmentsSuccess({
                  propertiesTreatmentsData: response.data,
                });
              }),
              catchError((error: Error) => {
                this._notification.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.load_failure',
                    {
                      param: this._translate.instant('treatments'),
                    },
                  ),
                  type: 'error',
                });
                return of(
                  new LoadPropertiesTreatmentsFailure({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  loadAvailableRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.LoadAvailableRoomRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [
          LoadAvailableRoomRequest,
          { core: ICoreState },
        ]) => {
          const { data } = action.payload;
          return this._availableRoomService.loadAvailableRoom(data).pipe(
            map((response: IResponseSuccess) => {
              return new LoadAvailableRoomSuccess({
                data: response.data,
              });
            }),
            catchError((error: Error) => {
              this._notification.push({
                title: this._translate.instant('error'),
                content: this._translate.instant('notifications.load_failure', {
                  param: this._translate.instant('available'),
                }),
                type: 'error',
              });
              return of(
                new LoadAvailableRoomFailure({
                  error,
                }),
              );
            }),
          );
        },
      ),
    ),
  );

  searchVatQuoteHolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActionTypes.VatQuoteHolderRequest),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [VatQuoteHolderRequest, { core: ICoreState }]) => {
          const { data } = action.payload;
          return this._vatQuoteHolderService.searchVatQuoteHolder(data).pipe(
            mergeMap((response: IResponseSuccess) => {
              return [
                new VatQuoteHolderSuccess({
                  data: response.data[0],
                }),
                new SetLoading({ loading: false }),
              ];
            }),
            catchError((error: Error) => {
              this._notification.push({
                title: this._translate.instant('error'),
                content: this._translate.instant('notifications.load_failure', {
                  param: this._translate.instant('search'),
                }),
                type: 'error',
              });
              return [
                new VatQuoteHolderFailure({
                  error,
                }),
                new SetLoading({ loading: false }),
              ];
            }),
          );
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private _propertySelectedService: PropertySelectedService,
    private _router: Router,
    private dragService: TableauDragToSelectService,
    private _availableRoomService: AvailableRoomService,
    private _vatQuoteHolderService: VatQuoteHolderService,
    private _reservationService: ReservationService,
    private _templateEmailService: TemplateEmailService,
    private _notification: NotificationService,
    private _translate: TranslateService,
    private _discountTypesService: DiscountTypesService,
    private _store: Store<{
      core: ICoreState;
      properties: IPropertiesState;
      userMe: UserMeStoreState.State;
    }>,
    private _languagesService: LanguagesService,
    private _currenciesService: CurrenciesService,
    private _chekinOutService: CheckinOutService,
    private _paymentMethodsService: PaymentMethodsService,
    private _propertiesTreatmentsService: PropertiesTratmentsService,
    private _afterLogin: AfterLoginService,
    private exportService: ExportService,
    private activatedRoute: ActivatedRoute,
  ) {}
}
