import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AfterIfDirectiveModule } from '../../directives/after-if/after-if.module';
import { SpinModule } from '../../ui/spin/spin.module';
import { PageHeaderModule } from '../page-header/page-header.module';

import { AttachmentViewerFindInputAreaComponent } from './attachment-viewer-find-input-area/attachment-viewer-find-input-area.component';
import { AttachmentViewerPagingAreaComponent } from './attachment-viewer-paging-area/attachment-viewer-paging-area.component';
import { AttachmentViewerToolBarComponent } from './attachment-viewer-tool-bar/attachment-viewer-tool-bar.component';
import { AttachmentViewerZoomToolbarComponent } from './attachment-viewer-zoom-toolbar/attachment-viewer-zoom-toolbar.component';
import { AttachmentViewerComponent } from './attachment-viewer.component';
import { AttachmentViewerService } from './attachment-viewer.service';

@NgModule({
  declarations: [
    AttachmentViewerComponent,
    AttachmentViewerFindInputAreaComponent,
    AttachmentViewerToolBarComponent,
    AttachmentViewerPagingAreaComponent,
    AttachmentViewerZoomToolbarComponent,
  ],
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    NzModalModule,
    NzButtonModule,
    TranslateModule,
    NgPipesModule,
    PageHeaderModule,
    SpinModule,
    NzResultModule,
    NzInputModule,
    NzToolTipModule,
    NzPopoverModule,
    NzInputNumberModule,
    NzFormModule,
    NzSelectModule,
    ReactiveFormsModule,
    AfterIfDirectiveModule,
  ],
  exports: [AttachmentViewerComponent],
  providers: [AttachmentViewerService],
})
export class AttachmentViewerModule {}
