import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { upperFirst } from 'lodash';

import { Floor, TableauRow } from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauExportRowBuilder } from '../tableau-export-builder';
import {
  DEFAULT_BORDER,
  getDefaultCellStyle,
  ROW_HEIGHT,
} from '../tableau-export.config';

@Injectable()
export class FloorRowBuilderService implements TableauExportRowBuilder {
  constructor(private translate: TranslateService) {}

  build(
    tableauRow: TableauRow<Floor>,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const { days } = state.tableau_2;

    const row = worksheet.addRow([
      tableauRow.data?.name ||
        upperFirst(this.translate.instant('tableau_numbers_without_floor')),
      ...days.map(() => null),
    ]);

    row.height = ROW_HEIGHT;

    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.style = getDefaultCellStyle({
        alignment: { vertical: 'middle', horizontal: 'right', indent: 1 },
        border: {
          bottom: DEFAULT_BORDER,
          top: DEFAULT_BORDER,
          right: { style: 'thin', color: { argb: 'f3f3f5' } },
          left: { style: 'thin', color: { argb: 'f3f3f5' } },
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f3f3f5' },
        },
      });
    });
  }
}
