import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import {
  TableauRow,
  TableauRowComponent,
  TableauRowItems,
  TableauViewOptions,
} from '../../../../models';
import { TableauConfig } from '../config';

@Component({
  selector: 'by-tableau-event-row',
  templateUrl: './tableau-event-row.component.html',
  styleUrls: ['./tableau-event-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauEventRowComponent
  implements TableauRowComponent, OnChanges
{
  @Input()
  row: TableauRow;

  @Input()
  firstColumnRowspan: number;

  @Input()
  items: TableauRowItems;

  @Input()
  days: Date[];

  @Input()
  zoom: number;

  @Input()
  viewOptions: TableauViewOptions;

  readonly TableauConfig = TableauConfig;

  constructor() {}

  ngOnChanges() {}
}
