import { INVOICE_RECEIPT_TYPE } from '@app/models/objects/invoice-receipt';
import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.UploadInvoicesReceiptsXmlRequest, (state) => ({
    ...state,
    isUploadXmlLoading: true,
    error: null,
  })),

  on(fromActions.UploadInvoicesReceiptsXmlSuccess, (state) => ({
    ...state,
    isUploadXmlLoading: false,
    error: null,
  })),

  on(fromActions.UploadInvoicesReceiptsXmlFailure, (state, { error }) => ({
    ...state,
    isUploadXmlLoading: false,
    error,
  })),

  on(fromActions.loadInvoicesReceiptsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadInvoicesReceiptsSuccess,
    (state, { invoicesReceipts, pagination }) =>
      fromState.featureAdapter.setAll(
        invoicesReceipts.map((ir) => {
          const { expenses } = ir;
          return {
            ...ir,
            expenses: expenses
              .slice()
              .sort((a, b) =>
                moment(new Date(a.registration_date)).diff(
                  new Date(b.registration_date),
                ),
              ),
            invoice_type_name: INVOICE_RECEIPT_TYPE[ir.document_type],
          };
        }),
        {
          ...state,
          pagination,
          isLoading: false,
          error: null,
        },
      ),
  ),
  on(fromActions.loadInvoicesReceiptsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadInvoiceDetailReceiptsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadInvoiceDetailReceiptsSuccess,
    (state, { invoiceDetail }) => ({
      ...state,
      invoiceDetail,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadInvoiceDetailReceiptsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetInvoiceDetailReceiptsState, (state) => ({
    ...state,
    invoiceDetail: null,
    isLoading: false,
    error: null,
  })),

  on(fromActions.exportFileSuccess, (state, { exportFileId }) => ({
    ...state,
    exportFileId,
  })),

  on(fromActions.resetInvoicesReceiptsState, () => fromState.initialState),
);

export function InvoicesReceiptsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
