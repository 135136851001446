<by-page-header [byStyle]="{ paddingTop: 0 }" [title]="title"></by-page-header>

<by-spin [nzSpinning]="loading$ | async">
  <by-maintenance-reports-list
    *ngIf="view === 'list'"
    [reports]="reports"
    [canWrite]="canWrite"
    (edit)="onEdit($event)"
    (remove)="onRemove($event)"
    (showMedia)="onShowMedia($event)"
  ></by-maintenance-reports-list>

  <by-maintenance-reports-form
    *ngIf="view === 'form'"
    [report]="reportUnderEdit"
  ></by-maintenance-reports-form>
</by-spin>

<div *nzModalFooter>
  <ng-container *ngIf="view === 'list'">
    <button *ngIf="canWrite" nz-button nzType="primary" (click)="onCreate()">
      {{ 'new_ticket' | translate | upperfirst }}
    </button>
  </ng-container>

  <ng-container *ngIf="view === 'form'">
    <button nz-button (click)="onCancel()">
      {{ 'cancel' | translate | upperfirst }}
    </button>
    <button
      nz-button
      nzType="primary"
      [nzLoading]="saving"
      (click)="onSaveReport()"
    >
      {{ 'save' | translate | upperfirst }}
    </button>
  </ng-container>
</div>
