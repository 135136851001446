import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { TableauReservation } from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';

@Component({
  selector: 'by-tableau-reservation-details-popover',
  templateUrl: './tableau-reservation-details-popover.component.html',
  styleUrls: ['./tableau-reservation-details-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauReservationDetailsPopoverComponent implements OnChanges {
  @Input()
  reservation: TableauReservation;

  @Input()
  currencySymbol: string;

  constructor(private store: Store<RootState>) {}

  ngOnChanges(changes: SimpleChanges) {
    const { reservation } = changes;

    if (
      reservation &&
      this.reservation &&
      !this.reservation.hasReservationAccommodationDetails
    ) {
      this.store.dispatch(
        TableauActions.loadReservationAccommodationDetailsRequest({
          request: {
            reservationId: this.reservation.reservation_id,
            reservationAccommodationId:
              this.reservation.reservation_accommodation_id,
          },
        }),
      );
    }
  }

  get isOptionable() {
    return this.reservation?.status === 'Optionable';
  }
}
