import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { take } from 'rxjs/operators';

import { openInNewTab } from '../../../../helpers';
import {
  TableauBoxComponent,
  TableauDraggingItem,
  TableauQuote,
  TableauRoom,
  TableauRow,
  TableauViewOptions,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import * as TableauActions from '../../../../root-store/tableau-store/actions';
import { NotificationService } from '../../../../ui/services/notification.service';
import { TableauConfig } from '../config';
import { TableauDestinationRowSelectionService } from '../services/tableau-destination-row-selection.service';
import { TableauDragService } from '../services/tableau-drag.service';
import { TableauBoxWrapperComponent } from '../tableau-box-wrapper/tableau-box-wrapper.component';

const CHILD_RESERVATION_OBLIQUE_BG = '#d9d9d9';

const CHILD_RESERVATION_BG = `
  repeating-linear-gradient(
    -45deg,
    transparent 0 5px,
    #ccc 5px 10px
  )`;

type TableauQuoteBox = TableauBoxComponent<TableauQuote>;

function needToSetBackground(changes: SimpleChanges) {
  const fieldsToWatch: Array<keyof TableauQuoteBoxComponent> = [
    'reservationsColors',
    'data',
    'boxWrapperComponent',
    'viewOptions',
  ];

  return fieldsToWatch.some((field) => !!changes[field]);
}

@Component({
  selector: 'by-tableau-quote-box',
  templateUrl: './tableau-quote-box.component.html',
  styleUrls: ['./tableau-quote-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauQuoteBoxComponent implements TableauQuoteBox, OnChanges {
  @ViewChildren(NzPopoverDirective)
  popoverDirective: QueryList<NzPopoverDirective>;

  @ViewChildren(NzTooltipDirective)
  tooltipDirective: QueryList<NzTooltipDirective>;

  @Input()
  row: TableauRow;

  @Input()
  draggingItem: TableauDraggingItem;

  @Input()
  data: TableauQuote;

  @Input()
  userCanWrite: boolean;

  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  searchValue: string;

  @Input()
  boxWrapperComponent: TableauBoxWrapperComponent;

  @Input()
  isMobile: boolean;

  @Input()
  viewOptions: TableauViewOptions;

  readonly TableauConfig = TableauConfig;

  constructor(
    private store: Store<RootState>,
    private translate: TranslateService,
    private notifications: NotificationService,
    private tableauDragService: TableauDragService,
    private nzContextMenuService: NzContextMenuService,
    private tableauDestinationRowSelectionService: TableauDestinationRowSelectionService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { boxWrapperComponent, data } = changes;

    if (needToSetBackground(changes)) {
      this.setBoxBackground();
    }

    if (data || boxWrapperComponent) {
      this.setBoxSearchValue();
    }
  }

  onDragStarted() {
    this.boxWrapperComponent.hide();
    this.tableauDragService.startDrag(this.data);
  }

  onDragDropped(event: CdkDragDrop<TableauRow<TableauRoom>>) {
    this.tableauDragService.startDrag(null);

    if (this.tableauDragService.isInvalidDragEvent(event)) {
      this.boxWrapperComponent.show();
      return;
    }

    const { container, previousContainer } = event;

    if (
      container.data?.data?.accommodation_details.id !==
      previousContainer.data.data.accommodation_details.id
    ) {
      this.notifications.push({
        type: 'warning',
        title: upperFirst(this.translate.instant('attenction')),
        content: upperFirst(this.translate.instant('message_drag_quote')),
        onClick: () => {
          openInNewTab(
            '/crm/price-quotation/' + this.data.reservation_quote_id,
          );
        },
      });

      this.boxWrapperComponent.show();

      return;
    }

    this.store.dispatch(
      TableauActions.moveQuoteRequest({
        quote: this.data,
        sourceRow: event.previousContainer.data,
        destinationRow: event.container.data,
      }),
    );
  }

  onMove() {
    this.tableauDestinationRowSelectionService
      .openSelectionForQuote(this.row, this.data)
      .pipe(take(1))
      .subscribe((destinationRow) => {
        this.store.dispatch(
          TableauActions.moveQuoteRequest({
            quote: this.data,
            sourceRow: this.row,
            destinationRow,
          }),
        );
      });
  }

  onDragScrollError() {
    this.store.dispatch(TableauActions.forceRender());
    this.tableauDragService.startDrag(null);
    this.onMove();
  }

  onOpenContextMenu(
    $event: MouseEvent,
    dropdownComponent: NzDropdownMenuComponent,
  ) {
    if (!this.userCanWrite) {
      return;
    }

    this.nzContextMenuService.create($event, dropdownComponent);
  }

  onCloseContextMenu() {
    this.nzContextMenuService.close();
  }

  onOpenTableauQuoteDetailsModal() {
    this.store.dispatch(
      TableauActions.openQuoteDetailsModal({
        quote: this.data,
        reservationsColors: this.reservationsColors,
        row: this.row,
        userCanWrite: this.userCanWrite,
      }),
    );
  }

  private setBoxBackground() {
    if (this.data?.child_reservation && !this.viewOptions?.oblique) {
      this.boxWrapperComponent.setBoxBackground(CHILD_RESERVATION_BG);
      this.boxWrapperComponent.setBoxBorder('none');
      return;
    }

    if (this.data?.child_reservation && this.viewOptions?.oblique) {
      this.boxWrapperComponent.setBoxBackground(CHILD_RESERVATION_OBLIQUE_BG);
      this.boxWrapperComponent.setBoxBorder('none');
      return;
    }

    this.boxWrapperComponent.setBoxBackground('#fff');
    this.boxWrapperComponent.setBoxBorder(
      this.reservationsColors && `2px solid ${this.reservationsColors.quotes}`,
    );
  }

  private setBoxSearchValue() {
    this.boxWrapperComponent?.setSearchLabel(this.data?.search_label);
  }

  get triggerPopover(): 'hover' | null {
    return this.isMobile || this.draggingItem ? null : 'hover';
  }

  onMouseDownPopover() {
    this.popoverDirective.forEach((tooltip) => tooltip?.hide());
  }

  onMouseDownTooltip() {
    this.tooltipDirective.forEach((tooltip) => tooltip?.hide());
  }

  get triggerTooltip(): 'hover' | null {
    return this.draggingItem ? null : 'hover';
  }
}
