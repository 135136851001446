import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { isFuture } from 'date-fns';
import * as moment from 'moment';

import { guestTypeIdsWithDocumentDisabled } from '../../components/istat-guest-list/istat-guest-list.component';

import { InsertTemplateDirective } from './insert-templatedirective';

@Directive({
  selector: '[byAgeWarning]',
})
export class AgeWarningDirective
  extends InsertTemplateDirective
  implements OnChanges
{
  @Input() byAgeWarning: TemplateRef<any>;

  @Input() byAgeWarningBirthdayDate: Date;

  @Input() byAgeWarningAge = 18;

  @Input() byAgeWarningGuestTypeId: number;

  guestTypeIdsWithDocumentDisabled = guestTypeIdsWithDocumentDisabled;

  constructor(container: ViewContainerRef, template: TemplateRef<any>) {
    super(container, template);
  }

  ngOnChanges() {
    if (
      !this.byAgeWarningBirthdayDate ||
      isFuture(this.byAgeWarningBirthdayDate)
    ) {
      this.removeTemplate();
      return;
    }

    if (this.byAgeWarning && this.byAgeWarningBirthdayDate) {
      if (
        this.guestTypeIdsWithDocumentDisabled.includes(
          this.byAgeWarningGuestTypeId,
        )
      ) {
        this.removeTemplate();
        return;
      }
      if (
        moment().diff(this.byAgeWarningBirthdayDate, 'years') <
        this.byAgeWarningAge
      ) {
        this.insertTemplate(this.byAgeWarning);
      } else {
        this.removeTemplate();
      }
    }
  }
}
