import { get } from 'lodash';
import * as moment from 'moment';

import { Invoice, InvoiceListDataTable } from '../models';
import { InvoiceConditionsService } from '../shared/invoice/services/invoice-conditions.service';

export const SDI_LABELS = {
  0: 'not_send',
  1: 'send_and_waiting',
  2: 'rejected',
  3: 'sent',
};

export const SDI_ICONS = {
  0: 'fa-info-circle',
  1: 'fa-info-circle',
  2: 'fa-info-circle',
  3: 'fa-check-circle',
};

export const SDI_COLORS = {
  0: '#c7c7c7',
  1: '#ee9200',
  2: '#f94747',
  3: '#027f07',
};

export const buildInvoiceSdiTableData = (
  colorStatus: { [statusSdi: number]: string },
  invoiceStatusSdi: number,
): {
  sdi_label: string;
  sdi_info: string;
  iconData: {
    iconColor: string;
    iconClass: string;
    show: boolean;
  };
} => {
  return {
    sdi_label: SDI_LABELS[invoiceStatusSdi],
    sdi_info: SDI_LABELS[invoiceStatusSdi],
    iconData: {
      iconColor: get(colorStatus, `[${invoiceStatusSdi}]`),
      iconClass: `fas ${SDI_ICONS[invoiceStatusSdi]} generic-list-table__sdi-icon`,
      show: true,
    },
  };
};

export const buildInvoiceDataTable = (
  invoices: Partial<Invoice>[],
  colorStatus: { [statusSdi: number]: string },
  invoiceConditionsService: InvoiceConditionsService,
): InvoiceListDataTable[] => {
  if (!invoices) {
    return;
  }
  return invoices.map((invoice) => {
    const {
      reservation_id,
      total_with_discount,
      total_tax,
      total,
      payment_date,
      status,
      status_sdi,
      xml_uri,
      send_history,
    } = invoice;

    return {
      ...invoice,
      xml_uri,
      total_with_discount: +total_with_discount,
      total_tax: +total_tax,
      total: +total,
      send_history,
      status:
        status === 'paid'
          ? status
          : moment(new Date(payment_date)).isBefore(moment(new Date()), 'days')
          ? 'expired'
          : status,
      ...buildInvoiceSdiTableData(colorStatus, status_sdi),
      tooltipData: {
        iconClass: 'far fa-hotel',
        show: !!reservation_id,
        data: ['from_reservation'],
        style: { 'margin-left': '20px', color: '#424242', 'font-size': '13px' },
      },
      canShowSendHistory:
        invoiceConditionsService.getCanShowSendHistory(invoice),
      enabledActions: {
        delete: invoiceConditionsService.getIsDeletable(invoice),
        restore: invoiceConditionsService.getCanRestore(invoice),
        changePaymentStatus:
          invoiceConditionsService.getCanChangePaymentStatus(invoice),
        changeHiddenStatus:
          invoiceConditionsService.getCanChangeHiddenStatus(invoice),
        createCreditNote:
          invoiceConditionsService.getCanCreateCreditNote(invoice),
        viewSdiFormat: invoiceConditionsService.getCanViewSdiFormat(invoice),
        canSendReminder: invoiceConditionsService.getCanSendReminder(invoice),
      },
    };
  });
};
