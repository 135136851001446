import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '../../ui/ui.module';

import { DeleteReservationModalComponent } from './delete-reservation-modal.component';
import { DeleteReservationModalService } from './delete-reservation-modal.service';

@NgModule({
    declarations: [DeleteReservationModalComponent],
    imports: [
        CommonModule,
        UiModule,
        TranslateModule,
        NgPipesModule,
        SharedModule,
    ],
    exports: [DeleteReservationModalComponent],
    providers: [DeleteReservationModalService]
})
export class DeleteReservationModalModule {}
