import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalTemplateViewerComponent } from './external-template-viewer.component';
import { HtmlSanitizerModule } from '../../pipes';

@NgModule({
  declarations: [ExternalTemplateViewerComponent],
  imports: [CommonModule, HtmlSanitizerModule],
  exports: [ExternalTemplateViewerComponent],
})
export class ExternalTemplateViewerModule {}
