<by-spin [nzSpinning]="loading">
  <form
    nz-form
    [formGroup]="form"
    nzLayout="vertical"
    id="company-details-form"
  >
    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="company_name" nzRequired>
            {{ 'company_name' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control
            [nzErrorTip]="
              'validation_form.' + (form.controls.name.errors | keys | head)
                | translate : { param: 'name' | translate }
            "
          >
            <input
              nz-input
              type="search"
              id="company_name"
              formControlName="name"
              [placeholder]="
                'placeholder.insert'
                  | translate : { param: 'company_name' | translate }
              "
              [nzAutocomplete]="companyAutocomplete"
            />
            <nz-autocomplete [nzDataSource] #companyAutocomplete>
              <nz-auto-option
                *ngFor="let company of (companies$ | async) || []"
                [nzValue]="company.name"
                (click)="onSelectCompany(company)"
              >
                {{ company.name }} - {{ company.vat_code }}
              </nz-auto-option>
            </nz-autocomplete>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="nick_name">
            {{ 'nick_name' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control
            [nzErrorTip]="
              'validation_form.required'
                | translate : { param: 'nick_name' | translate }
            "
          >
            <input
              nz-input
              type="text"
              id="nick_name"
              formControlName="nick_name"
              [placeholder]="
                'placeholder.insert'
                  | translate : { param: 'nick_name' | translate }
              "
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="vat_code">
            {{ 'vat_code' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control>
            <ng-template #addOnBeforeTemplate>
              <nz-select
                nzShowSearch
                style="width: 100%; max-width: 100px"
                [nzDropdownMatchSelectWidth]="false"
                formControlName="vat_country_id"
              >
                <ng-container *ngFor="let country of countries$ | async">
                  <nz-option
                    [nzCustomContent]="true"
                    [nzLabel]="country.country_code + ' - ' + country.name"
                    [nzValue]="country.id"
                  >
                    {{ country.country_code }} - {{ country.name }}
                  </nz-option>
                </ng-container>
              </nz-select>
            </ng-template>
            <nz-input-group
              [nzAddOnBefore]="addOnBeforeTemplate"
              [nzAddOnAfter]="addOnAfterTemplate"
            >
              <input
                nz-input
                type="text"
                id="vat_code"
                formControlName="vat_code"
                placeholder="{{
                  'placeholder.insert'
                    | translate : { param: 'vat_code' | translate | capitalize }
                    | capitalize
                }}"
              />
            </nz-input-group>
            <ng-template #addOnAfterTemplate>
              <button
                [disabled]="disabledCalculateEsterVatCode"
                (click)="onCalculateVatCode()"
                nz-button
                nzType="primary"
                class="btn-group-vat ant-btn-icon-only"
                nzSearch
              >
                <i class="fas fa-cogs"></i>
              </button>
            </ng-template>

            <span class="fake-error-tip" *ngIf="!vatCountryControl.value">
              {{ 'vat_country_id_required' | translate | upperfirst }}
            </span>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      *ngIf="form.value.vat_country_id === 1"
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="tax_code">
            {{ 'tax_code' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control
            [nzErrorTip]="
              'validation_form.required'
                | translate : { param: 'tax_code' | translate }
            "
          >
            <input
              nz-input
              type="text"
              id="taxCodeInput"
              formControlName="tax_code"
              placeholder="{{
                'placeholder.insert'
                  | translate : { param: 'tax_code' | translate | capitalize }
                  | capitalize
              }}"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label>
            {{ 'credit_institution' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control
            [nzErrorTip]="
              'validation_form.' +
                (form.controls.credit_institution.errors | keys | head)
                | translate : { param: 'credit_institution' | translate }
            "
          >
            <input
              nz-input
              id="credit_institution"
              formControlName="credit_institution"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="iban">
            {{ 'iban' | translate | capitalize }}
            <i
              class="fas fa-info-circle color--orange by-ml-5"
              nz-tooltip
              [nzTooltipTitle]="
                'iban_message_validator' | translate | upperfirst
              "
            ></i>
          </nz-form-label>
          <nz-form-control>
            <input
              nz-input
              id="iban"
              formControlName="iban"
              [placeholder]="'iban_placeholder' | translate"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label>
            {{ 'tax_system' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control>
            <nz-select formControlName="tax_system_id">
              <nz-option
                *ngFor="let tax of taxSystem"
                [nzLabel]="tax.name"
                [nzValue]="tax.id"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <by-places-form
          formControlName="places"
          [enabledInputs]="autoHideBillingPlacesFn"
          [required]="false"
          [customRequired]="requiredPlaces"
          (changePlace)="onPlacesChange($event)"
        ></by-places-form>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label>
            {{ 'address' | translate | upperfirst }}
          </nz-form-label>
          <nz-form-control
            [nzErrorTip]="
              'validation_form.required'
                | translate : { param: 'address' | translate }
            "
          >
            <input
              nz-input
              type="text"
              id="address"
              maxlength="60"
              formControlName="address"
              placeholder="{{
                'placeholder.insert'
                  | translate : { param: 'address' | translate }
              }}"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="zip_code">{{
            'zip_code' | translate | upperfirst
          }}</nz-form-label>
          <nz-form-control
            [nzErrorTip]="
              'validation_form.required'
                | translate : { param: 'zip_code' | translate }
            "
          >
            <input
              nz-input
              type="text"
              id="zip_code"
              placeholder="{{
                'placeholder.insert'
                  | translate : { param: 'zip_code' | translate }
              }}"
              [maxlength]="form.value.places?.countryId === 1 ? 5 : 30"
              formControlName="zip_code"
            />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="receiver_code">{{
            'receiver_code' | translate | upperfirst
          }}</nz-form-label>
          <nz-form-control [nzErrorTip]="receiverCodeTpl">
            <input
              nz-input
              type="text"
              id="receiver_code"
              [maxlength]="form.value.public_service ? 6 : 7"
              placeholder="{{
                'placeholder.insert'
                  | translate : { param: 'receiver_code' | translate }
              }}"
              [disableControl]="form.value.vat_country_id !== 1"
              (focusout)="setDefaultReceiverCode()"
              formControlName="receiver_code"
            />
            <ng-template #receiverCodeTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{
                  'validation_form.required'
                    | translate : { param: 'receiver_code' | translate }
                }}
              </ng-container>
              <ng-container *ngIf="control.hasError('minlength')">
                {{
                  'validation_form.minlength'
                    | translate : { param: '6' | translate }
                }}
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzFor="flag">
            {{ 'company_type' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control>
            <nz-select formControlName="public_service">
              <nz-option
                *ngFor="let companyType of companyTypes"
                [nzValue]="companyType.id"
                [nzLabel]="companyType.name | translate | upperfirst"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label>
            {{ 'email' | translate | upperfirst }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="emailTplError">
            <nz-input-group [nzPrefix]="prefixEmail">
              <input
                nz-input
                type="email"
                id="email"
                formControlName="email"
                placeholder="{{
                  'placeholder.insert'
                    | translate : { param: 'email' | translate }
                }}"
            /></nz-input-group>
            <ng-template #emailTplError let-control>
              <ng-container *ngIf="control.hasError('emailvalidator')">
                {{ 'validation_form.email' | translate }}
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="20"
    >
      <div nz-col nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label>
            {{ 'pec' | translate | upperfirst }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="pecTplError">
            <nz-input-group [nzPrefix]="prefixEmail">
              <input
                nz-input
                type="email"
                id="pec"
                [disableControl]="
                  form.value.vat_country_id !== 1 || form.value.public_service
                "
                formControlName="pec"
                placeholder="{{
                  'placeholder.insert'
                    | translate : { param: 'pec' | translate }
                }}"
            /></nz-input-group>
            <ng-template #pecTplError let-control>
              <ng-container *ngIf="control.hasError('required')">
                {{
                  'validation_form.required'
                    | translate : { param: 'email' | translate }
                }}
              </ng-container>
              <ng-container *ngIf="control.hasError('emailvalidator')">
                {{ 'validation_form.email' | translate }}
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <by-question
      formControlName="fiscalAgentShow"
      [label]="'insert_fiscal_agent' | translate | upperfirst"
    ></by-question>
  </form>

  <by-section-box
    *ngIf="isAgentEnabled"
    [title]="'fiscal_agent_data' | translate | upperfirst"
  >
    <form nz-form [formGroup]="formFiscalAgent" nzLayout="vertical">
      <div nz-row [nzGutter]="20">
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'business_name' | translate | upperfirst }}
            </nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="fiscal_agent_fullname"
                formControlName="fiscal_agent_fullname"
                placeholder="{{
                  'placeholder.insert'
                    | translate
                      : {
                          param: 'business_name' | translate | upperfirst
                        }
                    | capitalize
                }}"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'vat_code' | translate | capitalize }}
            </nz-form-label>

            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
              <nz-form-control>
                <input
                  nz-input
                  type="text"
                  [min]="0"
                  id="fiscal_agent_vat_code"
                  formControlName="fiscal_agent_vat_code"
                  placeholder="{{
                    'placeholder.insert'
                      | translate
                        : { param: 'vat_code' | translate | capitalize }
                      | capitalize
                  }}"
              /></nz-form-control>
            </nz-input-group>
            <ng-template #addOnBeforeTemplate>
              <nz-select
                nzShowSearch
                [nzDropdownMatchSelectWidth]="false"
                formControlName="fiscal_agent_vat_country_id"
                [nzCustomTemplate]="countryCodeTpl"
              >
                <ng-container *ngFor="let country of countries$ | async">
                  <nz-option
                    [nzCustomContent]="true"
                    [nzLabel]="country.country_code + ' - ' + country.name"
                    [nzValue]="country.id"
                  >
                    {{ country.country_code }} - {{ country.name }}
                  </nz-option>
                </ng-container>
              </nz-select>

              <ng-template #countryCodeTpl>
                {{
                  (
                    countries$
                    | async
                    | firstOrDefault : ['id', countrySelectedFiscalAgent]
                  )?.country_code
                }}
              </ng-template>
            </ng-template>
          </nz-form-item>
        </div>

        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
          <nz-form-item>
            <nz-form-label>
              {{ 'fiscal_agent_name' | translate | upperfirst }}
            </nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="fiscal_agent_name"
                formControlName="fiscal_agent_name"
                placeholder="{{
                  'placeholder.insert'
                    | translate
                      : {
                          param: 'fiscal_agent_name' | translate | upperfirst
                        }
                    | capitalize
                }}"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
          <nz-form-item>
            <nz-form-label>
              {{ 'fiscal_agent_surname' | translate | upperfirst }}
            </nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="fiscal_agent_surname"
                formControlName="fiscal_agent_surname"
                placeholder="{{
                  'placeholder.insert'
                    | translate
                      : {
                          param: 'fiscal_agent_surname' | translate | upperfirst
                        }
                    | capitalize
                }}"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </by-section-box>
</by-spin>

<ng-template #prefixEmail><i class="far fa-envelope"></i></ng-template>
