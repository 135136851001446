<by-estimate-interface
  [dates]="dates"
  [range]="[dates | head, dates | last]"
  [guestFullName]="guestFullName"
  [splitInformations]="splitInformations"
  [loading]="loading$ | async"
  [warnings]="estimateResponse?.warnings"
  [isClosed]="estimateResponse?.closed"
  [withoutAvail]="
    !(estimateResponse | get : ['availability'] : true) &&
    !estimateRequest?.no_avail
  "
  [newTotal]="newTotal"
  [originalTotal]="originalTotal"
  [rateplans]="rateplans"
  [noPrice]="estimateRequest?.no_price"
  [hasDoorCode]="estimateResponse?.has_door_key"
  [currencySymbol]="currencySymbol"
  [originalDays]="originalDays"
  [newDays]="newDays"
  [rateplanRequired]="rateplanRequired"
  [treatmentRequired]="!!estimateResponse?.treatment_id_required"
  [oldTreatmentId]="estimateResponse?.old_treatment_id"
  [showWarnings]="
    !estimateRequest?.accommodation_id ||
    (estimateRequest?.accommodation_id &&
      estimateRequest?.rateplan_id &&
      estimateRequest?.treatment_id)
  "
></by-estimate-interface>
