import { isEqual, omit } from 'lodash';
import * as moment from 'moment';

import { removeNullishValues } from '../../helpers';
import { showQuantityAndRateConditionMapConstant } from '../../helpers/bills-helpers';
import { calcDiscount } from '../../helpers/calc-discount';
import { handleSplit } from '../../helpers/handle-split';
import {
  AddBillModalAccommodation,
  AddBillModalAccommodationGuest,
  IBillsCategory,
  IBillsRef,
  Reservation,
} from '../../models';
import { AddBillModalAddon } from '../../models/objects/add-bill-modal-addon';
import { AddBillModalAvailableAddon } from '../../models/objects/add-bill-modal-available-addon';
import { EditBillsRequest } from '../../models/requests/edit-bills.request';
import { Booker } from '../../shared/add-bill-modal/models/reservation.model';

const sortAvailableAddons = (
  a: AddBillModalAddon,
  b: AddBillModalAddon,
): number => {
  const categoryNameA = a.category_name?.toLowerCase();
  const categoryNameB = b.category_name?.toLowerCase();

  const nameA = a.name?.toLowerCase();
  const nameB = b.name?.toLowerCase();

  if (categoryNameA < categoryNameB) {
    return -1;
  }
  if (categoryNameA > categoryNameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
};

export function getAccommodationsFromReservations(
  reservations: Reservation[],
  referenceDate: Date,
): AddBillModalAccommodation[] {
  return reservations.reduce(
    (acc, { accommodations, booker }) =>
      (acc = [
        ...acc,
        ...getAccommodationsFromReservationAccommodations(
          accommodations,
          booker,
          referenceDate,
        ),
      ]),
    [],
  );
}

export function getAccommodationsFromReservationAccommodations(
  reservationAccommodations: any[],
  reservationBooker: Booker,
  referenceDate: Date,
): AddBillModalAccommodation[] {
  return handleSplit(reservationAccommodations, referenceDate).map(
    (accommodation) => {
      // Guests factoring
      const booker = {
        id: reservationBooker.id,
        name: `${reservationBooker.name} ${reservationBooker.surname}`,
      };

      let guests: AddBillModalAccommodationGuest[] = (
        accommodation.guests || []
      ).map(({ customer, guest_type_id, main_guest: isMainGuest }) => ({
        id: customer.id,
        name: `${customer.name} ${customer.surname}`,
        type: isMainGuest
          ? 'main_guest'
          : guest_type_id === 1 || guest_type_id === 2 || guest_type_id === 3
            ? 'main_guest_by_guest_type'
            : 'guest',
      }));

      // Add the booker if there are no guests
      if (!guests.length) {
        guests = [
          {
            ...booker,
            type: 'booker',
          },
        ];
      }

      // Main guest
      const main_guest =
        guests.find(({ type }) => type === 'main_guest') ||
        guests.find(({ type }) => type === 'main_guest_by_guest_type') ||
        booker;

      // Tableau Label
      const tableau_label = accommodation.room?.tableau?.label || 'OB';

      // All Guests Name (For searches on all guests)
      const searching_field =
        guests.map(({ name }) => name).join('@') + '@' + tableau_label;

      // Children ranges
      const children_ranges = (accommodation?.children_ranges || []).map(
        ({ property_has_children_range_id }) => ({
          property_has_children_range_id,
          qty: 1,
        }),
      );

      return {
        reservation_accommodation_id: accommodation.id,
        accommodation_id: accommodation.accommodation_id,
        reservation_id: accommodation.reservation_id,
        arrival_date: accommodation.arrival_date,
        departure_date: accommodation.departure_date,
        adults: accommodation.adults_number,
        max_adults: accommodation.accommodation?.max_adults || 0,
        max_children: accommodation.accommodation?.max_children || 0,
        property_id: accommodation.property_id,
        all_reservation_accommodations_id:
          accommodation.all_reservation_accommodations_id,
        roomreservation_id: accommodation.roomreservation_id,
        tableau_label,
        children_ranges,
        guests,
        main_guest,
        searching_field,
      };
    },
  );
}

export function availableAddonsToModalAddonsFormat(
  availableAddons: AddBillModalAvailableAddon[],
  selectedAccommodation: AddBillModalAccommodation,
  selectedGuest: AddBillModalAccommodationGuest,
  referenceDate: Date,
): AddBillModalAddon[] {
  if (!selectedAccommodation || !selectedGuest) {
    return [];
  }
  const addons = availableAddons
    .map((availableAddon) => {
      const addon: AddBillModalAddon = {
        addon_id: availableAddon.id,
        customer: {
          id: availableAddon.customer.id,
          name: `${availableAddon.customer.name} ${
            availableAddon.customer.surname || ''
          }`,
        },
        name: availableAddon.name,
        total: +availableAddon.price,
        unit_price: +availableAddon.price,
        price_type: availableAddon.price_type,
        vat_quote_id: availableAddon.vat_quote_id,
        category_id: availableAddon.addon_category_id,
        category_name: availableAddon.category_name,
        adults: selectedAccommodation.adults,
        children_ranges: selectedAccommodation.children_ranges,
        periods: [
          {
            date_from: new Date(selectedAccommodation.arrival_date),
            date_to: new Date(selectedAccommodation.departure_date),
          },
        ],
        date: referenceDate,
        quantity: 1,
        discount_type_id: 5,
        discount_value: 0,
        discounted_total: 0,
        buyer: null,
      };

      return addon;
    })
    .sort(sortAvailableAddons);

  return updateAddonsBuyer(addons, selectedGuest);
}

export function getBillsRefsFromCategories(
  accommodationBillsCategories: IBillsCategory,
): IBillsRef[] {
  return Object.values(
    omit(accommodationBillsCategories.addons, 'qty', 'total_price'),
  ).reduce((allRefs, { refs }) => (allRefs = [...allRefs, ...refs]), []);
}

export function updateAddonsBuyer(
  addons: AddBillModalAddon[],
  selectedGuest: AddBillModalAccommodationGuest,
): AddBillModalAddon[] {
  return addons.map((addon) => ({
    ...addon,
    buyer: { id: selectedGuest.id, name: selectedGuest.name },
  }));
}

export function findMergableAddon(
  accommodationAddons: AddBillModalAddon[],
  addon: AddBillModalAddon,
): AddBillModalAddon {
  // Il nuovo addon non è predisposto per essere unito con uno esistente
  if (!showQuantityAndRateConditionMapConstant[addon.price_type]) {
    return null;
  }

  const keysNotToCompare = [
    'quantity',
    'total',
    'bill_id',
    'children_ranges',
    'adults',
    'periods',
    'date',
    'category_name',
    'customer',
    'buyer',
    'discounted_total',
    'override',
    'book_only',
    'invoiced_status',
  ];

  const getComparableAddon = (a: AddBillModalAddon) => {
    return {
      ...omit(a, ...keysNotToCompare),
      date_from: moment(a.periods[0].date_from).format('YYYY-MM-DD'),
      date_to: moment(a.periods[0].date_to).format('YYYY-MM-DD'),
      customer_buyer_id: a.buyer?.id,
      customer_id: a.customer?.id,
    };
  };

  const comparableAddonToAdd = getComparableAddon(addon);

  const sameAddon = (accommodationAddons || []).find((accommodationAddon) => {
    return isEqual(
      comparableAddonToAdd,
      getComparableAddon(accommodationAddon),
    );
  });

  // Non esiste un addon con cui unire il nuovo addon
  if (!sameAddon) {
    return null;
  }

  // Effettuo l'unione
  return {
    ...sameAddon,
    quantity: sameAddon.quantity + addon.quantity,
    total: sameAddon.total + addon.total,
  };
}

export function getPayloadFromAddons(
  addons: AddBillModalAddon[],
  {
    reservation_accommodation_id,
    roomreservation_id,
  }: AddBillModalAccommodation,
): EditBillsRequest {
  return addons.reduce((payload, addon, index) => {
    const discountTotal =
      addon.discount_type_id === 5
        ? calcDiscount(addon.total, +addon.discount_value)
        : +addon.discount_value;

    return (payload = {
      ...payload,
      [addon.bill_id || index]: removeNullishValues({
        addon_id: addon.addon_id,
        override: addon.override,
        base_price: addon.unit_price,
        price: addon.total,
        qty: addon.quantity,
        vat_quote_id: addon.vat_quote_id,
        bill_department_id: addon.bill_department_id,
        customer_buyer_id: addon.buyer?.id,
        customer_id: addon.customer?.id,
        discount_type_id: addon.discount_type_id,
        discount_value: addon.discount_value,
        date: moment(new Date()).format('YYYY-MM-DD'),
        reservation_accommodation_id,
        roomreservation_id,
        book_only: !!addon.book_only,
        paid: addon.deposit,
        deposit: addon.deposit ? addon.total - discountTotal : 0,
        payment_note_request: 0,
        adults: addon.adults,
        children: addon.children_ranges.map((range) => ({
          property_has_children_range_id: range.property_has_children_range_id,
          qty: range.qty,
        })),
        periods: addon.periods.map((period) => ({
          date_from: moment(period.date_from).format('YYYY-MM-DD'),
          date_to: moment(period.date_to).format('YYYY-MM-DD'),
        })),
        force_operation: true,
        all_inclusive_tax_document: +addon.all_inclusive_tax_document || null,
      }),
    });
  }, {});
}

export function getReferenceDateFromAccommodation(
  referenceDateFromState: Date,
  selectedAccommodation: AddBillModalAccommodation,
) {
  if (!selectedAccommodation) {
    return referenceDateFromState;
  }

  const today = new Date();

  // Se il referenceDate è già settato nello store deve essere validato nuovamente al cambio di accommodation
  if (referenceDateFromState) {
    const referenceDateFromStateIsValid = moment(
      referenceDateFromState,
    ).isBetween(
      moment(selectedAccommodation.arrival_date),
      moment(selectedAccommodation.departure_date),
      'days',
      '[)',
    );

    if (referenceDateFromStateIsValid) {
      return referenceDateFromState;
    }
  }

  if (
    moment(today).isBetween(
      moment(selectedAccommodation.arrival_date),
      moment(selectedAccommodation.departure_date),
      'days',
      '[)',
    )
  ) {
    return today;
  }

  if (
    moment(today).isBefore(moment(selectedAccommodation.arrival_date), 'days')
  ) {
    return selectedAccommodation.arrival_date;
  }

  return selectedAccommodation.departure_date;
}
