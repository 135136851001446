<by-estimate-interface
  [dates]="dates"
  [range]="range"
  [guestFullName]="guestFullName"
  [splitInformations]="splitInformations"
  [loading]="loading"
  [warnings]="warnings"
  [isClosed]="isClosed"
  [withoutAvail]="withoutAvail"
  [newTotal]="newTotal"
  [originalTotal]="originalTotal"
  [rateplans]="rateplans"
  [noPrice]="noPrice"
  [currencySymbol]="currencySymbol"
  [originalDays]="originalDays"
  [newDays]="newDays"
  [rateplanRequired]="rateplanRequired"
  [treatmentRequired]="treatmentRequired"
  [oldTreatmentId]="oldTreatmentId"
  [hasDoorCode]="hasDoorCode"
  [showWarnings]="showWarnings"
  [draggable]="true"
></by-estimate-interface>

<div *nzModalFooter>
  <button nz-button (click)="onCancel()">
    <i class="by-mr-2 fas fa-undo"></i>
    {{ 'cancel' | translate | upperfirst }}
  </button>

  <button nz-button nzType="primary" (click)="onConfirm()">
    {{ saveButtonLabel | upperfirst }}
  </button>
</div>
