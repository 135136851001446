<div class="by-tableau-modal-dates">
  <div class="by-tableau-modal-dates__arrival">
    <ng-container
      *ngTemplateOutlet="
        !loading ? dateTpl : skeleton;
        context: {
          style: { width: '120px' },
          nzParagraph: { rows: 2, width: '100%' },
          date: data?.arrival_date
        }
      "
    ></ng-container>
  </div>
  <div class="by-tableau-modal-dates__departure">
    <ng-container
      *ngTemplateOutlet="
        !loading ? dateTpl : skeleton;
        context: {
          style: { 'margin-left': '30px', width: '120px' },
          nzParagraph: { rows: 2, width: '100%' },
          date: data?.departure_date
        }
      "
    ></ng-container>
  </div>
</div>

<ng-template #dateTpl let-date="date">
  <div class="by-fs-16 bold month">
    {{ date | formatDate: 'DD MMM' | uppercase }}
  </div>
  <div class="by-fs-14 day">
    {{ date | formatDate: 'dddd' | capitalize }}
  </div>
</ng-template>

<ng-template #skeleton let-style="style" let-nzParagraph="nzParagraph">
  <nz-skeleton
    [nzActive]="true"
    [nzParagraph]="nzParagraph"
    [nzTitle]="false"
    [ngStyle]="style"
  ></nz-skeleton>
</ng-template>
