import * as ExcelJS from 'exceljs';
import { mergeWith } from 'lodash';

export const BLOB_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const FIRST_DAYS_COLUMN = 2;

export const FIRST_COLUMN_SIZE = 40;

export const DAYS_COLUMNS_SIZE = 10;

export const ROW_HEIGHT = 25;

export const FONT_SIZE = 10;

export const DEFAULT_BORDER: Partial<ExcelJS.Border> = {
  style: 'thin',
  color: { argb: 'e4e5e7' },
};

export const getDefaultCellStyle = (
  override?: Partial<ExcelJS.Style>,
): Partial<ExcelJS.Style> =>
  mergeWith(
    {
      border: {
        top: DEFAULT_BORDER,
        left: DEFAULT_BORDER,
        bottom: DEFAULT_BORDER,
        right: DEFAULT_BORDER,
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
      font: {
        size: FONT_SIZE,
      },
    },
    override,
    (_, value) => value,
  );
