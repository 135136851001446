<by-tableau-modal-header
  [data]="header"
  [reservationsColors]="reservationsColors"
  [loading]="loading"
></by-tableau-modal-header>

<div class="notes">
  <div *ngIf="quoteDetails?.notes?.length">
    <ng-container *ngFor="let note of quoteDetails?.notes">
      <div
        [originalText]="note.note"
        [inline]="true"
        [maxLength]="100"
        by-collapsable-text
        hideMessage="hide"
        showMessage="more"
        [label]="
          translationLabel[note?.related_subtype] | translate | upperfirst
        "
      ></div>
    </ng-container>
  </div>
  <div class="by-flex">
    <div class="by-mr-2px bold">
      {{ 'expire_date' | translate | upperfirst }}:
    </div>
    <div>{{ quoteDetails?.expire_date | toDate | date: 'dd/MM/yyyy' }}</div>
  </div>
</div>

<by-tableau-modal-prices
  [data]="quoteAccommodationPrice"
  [pricesToShow]="{ price_total: true }"
  [loading]="loading"
></by-tableau-modal-prices>

<div class="status__empty"></div>

<div *nzModalFooter nz-row nzJustify="end">
  <ng-container
    *ngTemplateOutlet="!loading ? footerTpl : skeleton; context: skeletonContex"
  ></ng-container>
</div>

<ng-template #footerTpl>
  <a
    nz-tooltip
    nzTooltipTitle="{{ 'view' | translate | capitalize }}"
    routerLink="/crm/price-quotation/{{ data?.reservation_quote_id }}"
    target="_blank"
    class="by-mr-2 pointer by-fs-15 action"
    *ngIf="userCanWrite"
  >
    <i class="fas fa-eye"> </i>
  </a>

  <a
    nz-tooltip
    nzTooltipTitle="{{ 'edit' | translate | capitalize }}"
    routerLink="/price-quotation/{{ data.reservation_quote_id }}"
    target="_blank"
    class="color--azure pointer by-fs-15"
    *ngIf="userCanWrite"
  >
    <i class="fas fa-pen"> </i>
  </a>
</ng-template>

<ng-template #skeleton let-style="style" let-nzParagraph="nzParagraph">
  <nz-skeleton
    [nzActive]="true"
    [nzParagraph]="nzParagraph"
    [nzTitle]="false"
    [ngStyle]="style"
  ></nz-skeleton>
</ng-template>
