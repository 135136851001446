import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPaymentMethods = (state: State) =>
  state.automaticPaymentMethods;

export const selectState = createFeatureSelector<State>(
  'automaticPaymentsConfiguration',
);

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (ID: number) =>
  createSelector(
    featureAdapter.getSelectors(selectState).selectEntities,
    (items) => items[ID],
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAutomaticPaymentMethods = createSelector(
  selectState,
  getPaymentMethods,
);
