import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';

import { DeleteReservationModalModule } from '../../components/delete-reservation-modal/delete-reservation-modal.module';
import { EstimateModule } from '../../features/commons/estimate/estimate.module';
import { TableauModule } from '../../features/commons/tableau-2/tableau.module';

import { TableauStoreEffects } from './effects';
import { tableauReducerFactory, TableauStoreReducer } from './reducer';
import { State } from './state';

const REDUCER_TOKEN = new InjectionToken<ActionReducer<Map<string, State>>>(
  'Tableau State Reducer',
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('tableau_2', REDUCER_TOKEN),
    EffectsModule.forFeature([TableauStoreEffects]),
    EstimateModule,
    DeleteReservationModalModule,
    TableauModule,
  ],
  providers: [
    TableauStoreReducer,
    {
      provide: REDUCER_TOKEN,
      deps: [TableauStoreReducer],
      useFactory: tableauReducerFactory,
    },
  ],
})
export class TableauStoreModule {}
