export const NO_SPACE_REGEX = new RegExp('^\\S*$');

export const LEADING_WHITESPACES = new RegExp('^\\s+');

export function hasLeadingWhitespaces(str: string) {
  return LEADING_WHITESPACES.test(str);
}

export function removeLeadingWhitespaces(str: string) {
  return str.replace(LEADING_WHITESPACES, '');
}
