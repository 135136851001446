import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableauQuote } from '../../../../models';

@Component({
  selector: 'by-tableau-quote-details-popover',
  templateUrl: './tableau-quote-details-popover.component.html',
  styleUrls: ['./tableau-quote-details-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauQuoteDetailsPopoverComponent {
  @Input()
  quote: TableauQuote;

  constructor() {}
}
