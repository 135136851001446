import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableauModalHeader } from '../../../../../models';

@Component({
  selector: 'by-tableau-modal-header-dates',
  templateUrl: './tableau-modal-header-dates.component.html',
  styleUrls: ['./tableau-modal-header-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauModalHeaderDatesComponent {
  @Input() loading = false;

  @Input() data: TableauModalHeader;

  constructor() {}
}
