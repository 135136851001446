import { Pipe, PipeTransform } from '@angular/core';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';

@Pipe({
  name: 'getByDay',
  pure: true,
})
export class GetByDayPipe implements PipeTransform {
  constructor(private dateFormatter: DateFormatterService) {}

  transform(items: Record<string, any>, date: Date): any {
    if (!items) {
      return null;
    }

    return items[this.dateFormatter.toServerFormat(date)];
  }
}
