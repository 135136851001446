import { Action, createReducer, on } from '@ngrx/store';
import { isValid } from 'date-fns';
import * as moment from 'moment';

import { ZdlakeQuestionsDatesOfSearch } from '../../models';
import { ChartLine } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
      chartData: onFormatChartData(items),
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function QuestionsDatesOfSearchReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function onFormatChartData(data: ZdlakeQuestionsDatesOfSearch[]): ChartLine[] {
  return [
    {
      name: 'demand_generation_curve',
      series: ((data || []) as ZdlakeQuestionsDatesOfSearch[]).reduce(
        (acc, { date, count }) => {
          /**
           * @description
           * name is of type Date or string because the chart must turn off x-axis rounding
           */
          const name = isValid(new Date(date)) ? new Date(date) : date;

          return [...acc, { name, value: count }];
        },
        [],
      ),
    },
  ];
}
