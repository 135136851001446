import { Injectable, Injector } from '@angular/core';
import { isNil, mapValues, sortBy } from 'lodash';
import * as moment from 'moment';

import { nightsOfPeriod } from '../core/helpers';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { TableauConfig } from '../features/commons/tableau-2/config';
import {
  TableauBoxComponents,
  TableauRowComponents,
} from '../features/commons/tableau-2/tableau-components-map';
import {
  Event,
  TableauMappingAccommodation,
  TableauMappingAccommodationDetails,
  TableauMappingAccommodationTableauNumbers,
  TableauNote,
  TableauQuote,
  TableauReservation,
  TableauRow,
  TableauRowItems,
} from '../models';
import * as tableauState from '../root-store/tableau-store/state';

import { TableauFloorsRowsService } from '.';
import { RowsService } from './rows.service';
import { TableauRowIndexService } from './tableau-row-index.service';
import { TableauSumParentAccommodationReservationsService } from './tableau-sum-parent-accommodation-reservations.service';

type FlattenTableauRowItem = any & { component: string };

const STATS_ROWS_KEYS = ['current_availability', 'percent_occupancy', 'sold'];

@Injectable({ providedIn: 'root' })
export class TableauRowsService {
  protected state: tableauState.State;

  constructor(
    protected injector: Injector,
    protected rowsService: RowsService,
    protected dateFormatter: DateFormatterService,
    protected tableauRowIndexService: TableauRowIndexService,
    protected sumParentAccommodationReservationsService: TableauSumParentAccommodationReservationsService,
  ) {}

  build(state: tableauState.State): TableauRow[] {
    if (state.viewMode === 'floors') {
      return this.injector.get(TableauFloorsRowsService).build(state);
    }

    this.state = state;

    const { mapping } = state;

    if (!mapping) {
      return [];
    }

    return [
      ...this.buildEventsRows(),
      ...this.buildPropertiesRows(),
      ...this.buildFooterRows(),
    ];
  }

  protected buildFooterRows(): TableauRow[] {
    const getFooterRow = (index: number): TableauRow => ({
      id: this.tableauRowIndexService.encode({
        label: 'footer',
        index,
        spanLength: TableauConfig.FooterRows,
      }),
      component: TableauRowComponents.Footer,
      spanIndex: index,
      data: null,
      items: null,
      firstColumnRowspan: 1,
    });

    return new Array(TableauConfig.FooterRows)
      .fill(null)
      .map((_, index) => getFooterRow(index));
  }

  protected buildEventsRows(): TableauRow[] {
    const { resources, period, viewOptions } = this.state;

    if (!viewOptions.events) {
      return [];
    }

    return this.rowsService
      .createRows<Event>(resources?.events || [], [period.from, period.to])
      .map((row, index, array) => {
        let firstColumnRowspan = array.length;

        if (index) {
          firstColumnRowspan = 0;
        }

        return {
          id: this.tableauRowIndexService.encode({
            label: 'event',
            index,
            spanLength: array.length,
          }),
          component: TableauRowComponents.Event,
          data: {},
          items: mapValues(row, (value) => {
            return {
              component: null,
              data: value,
            };
          }),
          firstColumnRowspan,
          spanIndex: index,
        };
      });
  }

  protected buildPropertiesRows(): TableauRow[] {
    const { sortedProperties, mapping } = this.state;

    const rows: TableauRow[] = [];

    sortedProperties?.forEach((propertyId) => {
      const propertyMapping = mapping[propertyId];

      if (!propertyMapping) {
        return;
      }

      const propertyDetails = propertyMapping[0]?.property_details;

      if (!propertyDetails) {
        return;
      }

      if (sortedProperties.length > 1) {
        rows.push({
          id: this.tableauRowIndexService.encode({ property_id: propertyId }),
          component: TableauRowComponents.Property,
          data: propertyDetails,
          items: {},
          firstColumnRowspan: 0,
          propertyId,
        });
      }

      rows.push(
        ...this.buildPropertyRows(propertyId),
        ...this.buildPropertyStatsRows(propertyId),
      );
    });

    return rows;
  }

  protected buildPropertyStatsRows(property_id: number): TableauRow[] {
    const { viewOptions } = this.state;
    const { availabilities } = this.state.resources;

    if (!viewOptions.stats || !availabilities) {
      return [];
    }

    const propertyStats = mapValues(availabilities, (day) => ({
      component: null,
      data: day.properties[property_id],
    }));

    return STATS_ROWS_KEYS.map((key, index) => {
      return {
        id: this.tableauRowIndexService.encode({
          property_id,
          label: 'stats',
          index,
        }),
        component: TableauRowComponents.PropertyStats,
        data: { key },
        items: propertyStats,
        firstColumnRowspan: 0,
        spanIndex: index,
        propertyId: property_id,
      };
    });
  }

  protected buildPropertyRows(propertyId: number): TableauRow[] {
    const { viewMode, collapsedAccommodations, mapping } = this.state;
    const { availabilities } = this.state.resources;

    const accommodations = mapping[propertyId];

    const rows: TableauRow[] = [];

    accommodations.forEach((accommodation) => {
      const { property_details, accommodation_details } = accommodation;

      if (this.accommodationMustBeHidden(accommodation_details)) {
        return;
      }

      if (viewMode === 'default') {
        rows.push({
          id: this.tableauRowIndexService.encode({
            property_id: property_details.property_id,
            accommodation_id: accommodation_details.id,
          }),
          component: TableauRowComponents.Accommodation,
          data: accommodation_details,
          items: mapValues(availabilities, ({ beddy_availabilities }) => {
            let availability: string;

            if (
              !beddy_availabilities ||
              isNil(beddy_availabilities[accommodation_details.id])
            ) {
              availability = '-';
            } else {
              availability =
                beddy_availabilities[accommodation_details.id] > 0
                  ? beddy_availabilities[accommodation_details.id].toString()
                  : '0';
            }

            return {
              component: null,
              data: {
                availability,
              },
            };
          }),
          firstColumnRowspan: 0,
          propertyId: property_details.property_id,
          accommodationId: accommodation_details.id,
        });
      }

      if (!collapsedAccommodations[accommodation_details.id]) {
        rows.push(
          ...this.buildRoomsRows(accommodation),
          ...this.buildObRows(accommodation),
        );
      }
    });

    return rows;
  }

  protected buildRoomsRows(
    accommodation: TableauMappingAccommodation,
  ): TableauRow[] {
    const { tableau_numbers } = accommodation;

    const rows: TableauRow[] = [];

    sortBy(tableau_numbers, ['order', 'label']).forEach((tableauNumber) => {
      rows.push(...this.buildRoomRows(accommodation, tableauNumber));
    });

    return rows;
  }

  protected buildObRows(
    accommodation: TableauMappingAccommodation,
  ): TableauRow[] {
    return [
      ...this.buildReservationsObRows(accommodation),
      ...this.buildQuotesObRows(accommodation),
    ];
  }

  protected buildReservationsObRows(
    accommodation: TableauMappingAccommodation,
  ): TableauRow[] {
    const { accommodation_details } = accommodation;

    const { reservations } = this.state.resources;

    if (
      !reservations ||
      !reservations[accommodation_details.id] ||
      !reservations[accommodation_details.id]['OB']
    ) {
      return [];
    }

    return this.buildRoomRows(accommodation, null);
  }

  protected buildQuotesObRows(
    accommodation: TableauMappingAccommodation,
  ): TableauRow[] {
    const { accommodation_details, property_details } = accommodation;

    const { quotes } = this.state.resources;
    if (
      !quotes ||
      !quotes[property_details.property_id] ||
      !quotes[property_details.property_id][accommodation_details.id] ||
      !quotes[property_details.property_id][accommodation_details.id]['OB']
    ) {
      return [];
    }

    return this.buildRoomRows(accommodation, null);
  }

  protected buildRoomRows(
    accommodation: TableauMappingAccommodation,
    tableauNumber: TableauMappingAccommodationTableauNumbers,
  ): TableauRow[] {
    const { property_details, accommodation_details } = accommodation;

    if (this.accommodationMustBeHidden(accommodation_details)) {
      return [];
    }

    const { clousures, housekeeper } = this.state.resources;

    const roomRows = this.getRoomRowsByItems(accommodation, tableauNumber?.id);

    const roomClosures =
      clousures &&
      clousures[accommodation_details.id] &&
      clousures[accommodation_details.id][tableauNumber?.id];

    const roomHousekeeper = housekeeper && housekeeper[tableauNumber?.id];

    const rows: TableauRow[] = [];

    roomRows.forEach((roomRow, index) => {
      let firstColumnRowspan = roomRows.length;

      if (index) {
        firstColumnRowspan = 0;
      }

      const items: TableauRowItems = mapValues(
        roomRow,
        ({ component, ...value }) => {
          return {
            component,
            data: value,
          };
        },
      );

      const componentClass = tableauNumber
        ? TableauRowComponents.Room
        : TableauRowComponents.Ob;

      rows.push({
        id: this.tableauRowIndexService.encode({
          property_id: property_details.property_id,
          accommodation_id: accommodation_details.id,
          room_id: tableauNumber?.id || 'OB',
          index,
          spanLength: roomRows.length,
        }),
        component: componentClass,
        data: {
          ...tableauNumber,
          closures: roomClosures || null,
          housekeeper: roomHousekeeper || null,
          accommodation_details,
        },
        items,
        firstColumnRowspan,
        spanIndex: index,
        propertyId: property_details.property_id,
        accommodationId: accommodation_details.id,
      });
    });

    return rows;
  }

  protected getRoomRowsByItems(
    accommodation: TableauMappingAccommodation,
    tableauNumberId?: number,
  ) {
    const { period } = this.state;

    const items: FlattenTableauRowItem[] = [
      ...this.getRoomReservations(accommodation, tableauNumberId),
      ...this.getRoomQuotes(accommodation, tableauNumberId),
      ...this.getRoomNotes(accommodation, tableauNumberId),
    ];

    return this.rowsService.createRows<FlattenTableauRowItem>(items, [
      period?.from,
      period?.to,
    ]);
  }

  protected getRoomReservations(
    accommodation: TableauMappingAccommodation,
    tableauNumberId?: number,
  ): TableauReservation[] {
    const { viewMode, swappedReservations, viewOptions } = this.state;
    const { reservations: reservationsResource } = this.state.resources;

    const {
      accommodation_details: { id: accommodationId, is_sum, is_sum_parent },
    } = accommodation;

    const reservations = [];

    if (reservationsResource && reservationsResource[accommodationId]) {
      Object.values(
        reservationsResource[accommodationId][tableauNumberId || 'OB'] || {},
      ).forEach((reservation: TableauReservation) => {
        let { child_reservation } = reservation;
        const { arrival_date, departure_date, tag } = reservation;

        if (viewMode === 'unified' && !is_sum && !is_sum_parent) {
          child_reservation = false;
        }

        // La prenotazione si trova nella camera d'appoggio
        const isSwapped = swappedReservations.some(
          ({ reservation: reservationInSwap }) =>
            reservationInSwap.reservation_accommodation_room_id ===
            reservation.reservation_accommodation_room_id,
        );

        // La prenotazione è stata filtrata dal filtro etichetta
        const isFilteredByTag =
          viewOptions.tag_id && tag?.id !== viewOptions.tag_id;

        if (isSwapped || isFilteredByTag) {
          return;
        }

        reservations.push({
          ...reservation,
          child_reservation,
          date_from: reservation.arrival_date,
          date_to: reservation.departure_date,
          label: this.getReservationLabel(reservation),
          search_label: this.getReservationSearchLabel(reservation),
          background_key: this.getReservationBackgroudKey(reservation),
          number_nights: nightsOfPeriod(
            new Date(arrival_date),
            new Date(departure_date),
          ),
          component: TableauBoxComponents.Reservation,
        });
      });
    }

    if (is_sum_parent) {
      return this.sumParentAccommodationReservationsService.get(reservations);
    }

    return reservations;
  }

  protected getRoomQuotes(
    accommodation: TableauMappingAccommodation,
    tableauNumberId?: number,
  ): TableauQuote[] {
    const { viewOptions } = this.state;
    const { quotes: quotesResource } = this.state.resources;

    const {
      accommodation_details: { id: accommodationId },
      property_details: { property_id },
    } = accommodation;

    if (!viewOptions.quotes) {
      return [];
    }

    let quotes: TableauQuote[] = [];

    if (
      quotesResource &&
      quotesResource[property_id] &&
      quotesResource[property_id][accommodationId]
    ) {
      quotes = (
        quotesResource[property_id][accommodationId][tableauNumberId || 'OB'] ||
        []
      ).map((quote: TableauQuote) => {
        return {
          ...quote,
          label: this.getQuoteLabel(quote),
          search_label: this.getQuoteSearchLabel(quote),
          component: TableauBoxComponents.Quote,
        };
      });
    }

    return quotes;
  }

  protected getRoomNotes(
    accommodation: TableauMappingAccommodation,
    tableauNumberId?: number,
  ): TableauNote[] {
    const { viewOptions } = this.state;
    const { notes: notesResource } = this.state.resources;

    const {
      accommodation_details: { id: accommodationId },
    } = accommodation;

    if (!viewOptions.notes) {
      return [];
    }

    let notes: TableauNote[] = [];

    if (notesResource && notesResource[accommodationId]) {
      notes = (
        notesResource[accommodationId][tableauNumberId || 'OB'] || []
      ).map((note: TableauNote) => {
        return {
          ...note,
          date_to: this.dateFormatter.toServerFormat(
            moment(note.date_to).add(1, 'day').toDate(),
          ),
          component: TableauBoxComponents.Note,
        };
      });
    }

    return notes;
  }

  protected accommodationMustBeHidden(
    accommodation: TableauMappingAccommodationDetails,
  ): boolean {
    const { viewOptions, viewMode, filteredAccommodations } = this.state;
    const {
      not_room,
      is_virtual,
      parent_id,
      is_sum,
      id: accommodationId,
    } = accommodation;

    if (!viewOptions.not_room && not_room) {
      return true;
    }

    if (!viewOptions.virtual_room && is_virtual) {
      return true;
    }

    if (viewMode === 'unified' && parent_id && !is_sum) {
      return true;
    }

    if (filteredAccommodations[accommodationId]) {
      return true;
    }

    return false;
  }

  protected getQuoteSearchLabel(quote: TableauQuote) {
    return [
      `${quote.booker_name} ${quote.booker_surname}`,
      `${quote.booker_surname} ${quote.booker_name}`,
    ].join('@');
  }

  protected getReservationSearchLabel(reservation: TableauReservation) {
    const names = [
      `${reservation.booker_name} ${reservation.booker_surname}`,
      `${reservation.booker_surname} ${reservation.booker_name}`,
    ];

    if (reservation.guest) {
      names.push(
        `${reservation.guest.name} ${reservation.guest.surname}`,
        `${reservation.guest.surname} ${reservation.guest.name}`,
      );
    }

    return names.join('@');
  }

  getQuoteLabel(
    quote: Pick<TableauQuote, 'company' | 'booker_name' | 'booker_surname'>,
  ) {
    if (quote?.company?.id) {
      return `${quote.company?.name}`;
    }

    return `${quote.booker_surname} ${quote.booker_name}`;
  }

  getReservationLabel(
    reservation: Pick<TableauReservation, 'guest' | 'company' | 'booker'>,
  ) {
    if (reservation.guest) {
      return `${reservation.guest.surname} ${reservation.guest.name}`;
    }

    if (reservation?.company?.customer?.id === reservation.booker?.id) {
      return `${reservation.booker.surname}`;
    }

    return `${reservation.booker.surname} ${reservation.booker.name}`;
  }

  getReservationBackgroudKey(
    reservation: Pick<TableauReservation, 'checkin' | 'checkout' | 'status'>,
  ) {
    const { checkin, checkout, status } = reservation;
    if (checkout) {
      return 'departure';
    }
    if (checkin) {
      return 'arrived';
    }
    return status.toLowerCase();
  }
}
