<strong>{{ 'reservation_status' | translate | capitalize }}</strong>
<by-legend-colors
  [colorsDefault]="reservationsStatusColors"
  [colorsDefaultStandard]="defaultReservationsStatusColors"
  [legendContainerStyle]="legendContainerStyle"
  [category]="TableauConfig.PreferencesKey"
  pickerPosition="left"
></by-legend-colors>

<ng-container *ngIf="channelsColors?.length">
  <strong>{{ 'channels' | translate | capitalize }}</strong>
  <by-legend-colors
    [colorsDefault]="channelsColors"
    [colorsDefaultStandard]="defaultChannelsColors"
    [legendContainerStyle]="legendContainerStyle"
    category="channelsColors"
    pickerPosition="left"
    preferenceUpdateKey="id"
  ></by-legend-colors>
</ng-container>

<ng-container *ngIf="tagsColors?.length">
  <strong>{{ 'reservation_tag' | translate | capitalize }}</strong>
  <by-legend-colors
    [colorsDefault]="tagsColors"
    [legendContainerStyle]="legendContainerStyle"
    [disableChange]="true"
  ></by-legend-colors>
</ng-container>

<strong>{{ 'payment_status' | translate | capitalize }}</strong>
<by-legend-colors
  [colorsDefault]="paymentColors"
  [colorsDefaultStandard]="paymentColorsStrandard"
  category="paymentStatusColor"
  preferenceUpdateKey="title"
  [legendContainerStyle]="legendContainerStyle"
  [mapPreferenceToSendFn]="mapPreferenceToSendFn"
></by-legend-colors>

<strong>{{ 'type_of_accommodation' | translate | capitalize }}</strong>
<by-legend-colors
  [colorsDefault]="accommodationsTypesLegend"
  [disableChange]="true"
  [legendContainerStyle]="legendContainerStyle"
></by-legend-colors>
