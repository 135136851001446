import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { GRID_BREAKPOINT } from '../../config';
import { ByClass } from '../../models';
import { ButtonMobileIconDirective } from './button-mobile-icon.directive';
import { ButtonMobileOnlyDesktopDirective } from './button-mobile-only-desktop.directive';
import { ButtonMobileOnlyMobileDirective } from './button-mobile-only-mobile.directive';

@Component({
  selector: 'by-button-mobile',
  templateUrl: './button-mobile.component.html',
  styleUrls: ['./button-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMobileComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input() byDisabled = false;

  @Input() byShape: 'circle' | 'round' | 'default' = 'default';

  @Input() byType: 'primary' | 'dashed' | 'link' | 'text' | 'default' =
    'default';

  @Input() byDesktopClass: ByClass;

  @Input() byMobileClass: ByClass;

  @Input() byResponsive = false;

  @Input() byLoading = false;

  @Input() beddyMobileTheme = true;

  defaultMobileClass: ByClass = 'by-button-mobile--zorro-mobile-default';

  beddyMobileClass: ByClass = 'by-button-mobile--beddy-mobile-default';

  isDesktop$ = this.breakpointObserver
    .observe(GRID_BREAKPOINT.small)
    .pipe(map(({ matches }) => matches));

  isDesktop = true;

  @ContentChildren(ButtonMobileOnlyDesktopDirective)
  desktopElements: QueryList<ButtonMobileOnlyDesktopDirective>;

  @ContentChildren(ButtonMobileOnlyMobileDirective)
  mobileElements: QueryList<ButtonMobileOnlyMobileDirective>;

  @ContentChild(ButtonMobileIconDirective)
  mobileIconElement: ButtonMobileIconDirective;

  private subs = new SubSink();

  ngAfterContentInit(): void {
    this.setElements();
  }

  ngOnInit(): void {
    this.subs.add(
      this.isDesktop$.pipe(debounceTime(100)).subscribe((isDesktop) => {
        this.isDesktop = isDesktop;

        this.setElements();
      }),
    );
  }

  setElements() {
    this.setDesktopElementDisplay();

    this.setMobileElementDisplay();

    this.setMobileElementDisplay();

    this.cdr.detectChanges();
  }

  setDesktopElementDisplay() {
    if (!this.desktopElements?.length) {
      return;
    }

    this.desktopElements.forEach((element) => {
      if (this.isDesktop) {
        element.show();
      } else {
        element.hide();
      }
    });
  }

  setMobileElementDisplay() {
    if (!this.mobileElements?.length) {
      return;
    }
    this.mobileElements.forEach((element) => {
      if (this.isDesktop) {
        element.hide();
      } else {
        element.show();
      }
    });
  }

  setMobileIconClass() {
    if (!this.mobileIconElement) {
      return;
    }

    if (this.isDesktop) {
      this.mobileIconElement.removeMobile();
    } else {
      this.mobileIconElement.applyMobile();
    }
  }

  get mobileTheme() {
    if (this.byMobileClass) {
      return this.byMobileClass;
    }

    if (this.beddyMobileTheme) {
      return this.beddyMobileClass;
    }

    return this.defaultMobileClass;
  }

  get shape(): 'circle' | 'round' | 'default' {
    if (!this.byResponsive) {
      return 'circle';
    }
    return this.isDesktop ? this.byShape : 'circle';
  }

  get type(): 'primary' | 'dashed' | 'link' | 'text' | 'default' {
    if (!this.byResponsive) {
      return 'default';
    }
    return this.isDesktop ? this.byType : 'default';
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
