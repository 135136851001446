import { merge } from 'lodash';
import * as moment from 'moment';

import { onLoadInvoiceDetails } from '../../helpers/on-load-invoice-details';
import { SUSPENDED_INTERVALS } from '../../helpers/suspended-intervals';
import { Invoice } from '../../models';

import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

const getExpiredIntervalLabel = (invoice: Invoice): string => {
  // Non è in sospeso
  if (invoice.status === 'paid') {
    return null;
  }

  const momentPaymentDate = moment(new Date(invoice.payment_date));

  // Non è ancora scaduta
  if (moment().isBefore(momentPaymentDate, 'days')) {
    return null;
  }

  return Object.keys(SUSPENDED_INTERVALS).find((key) => {
    const { from, to } = SUSPENDED_INTERVALS[key];

    if (!from) {
      return momentPaymentDate.isSameOrBefore(moment(to), 'days');
    }

    return momentPaymentDate.isBetween(moment(from), moment(to), 'days', '[)');
  });
};

const getExpiredDays = (invoice: Invoice): number => {
  const expiredDays = moment().diff(
    moment(new Date(invoice.payment_date)),
    'days',
  );

  return expiredDays > 0 ? expiredDays : null;
};

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_INVOICE_NEXT_NUMBER_REQUEST: {
      return { ...state, loadingNextNumber: true };
    }
    case ActionTypes.LOAD_INVOICE_NEXT_NUMBER_FAILURE: {
      return { ...state, loadingNextNumber: false };
    }
    case ActionTypes.LOAD_INVOICE_NEXT_NUMBER_SUCCESS: {
      const { invoice_layout_id, type, data } = action.payload;
      const newData = merge({}, state.invoiceNextNumber, {
        [invoice_layout_id]: {
          [type]: data,
        },
      });
      return {
        ...state,
        loadingNextNumber: false,
        invoiceNextNumber: { ...newData },
      };
    }

    case ActionTypes.LOAD_DETAIL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_DETAIL_SUCCESS: {
      const { items: originalInvoice } = action.payload;

      const [invoiceDetails, invoiceTotals] =
        onLoadInvoiceDetails(originalInvoice);

      return {
        ...state,
        isLoading: false,
        invoiceTotals,
        invoiceDetails,
      };
    }
    case ActionTypes.LOAD_DETAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      const invoices = action.payload.items.map((invoice) => {
        const { payments } = invoice;
        return {
          ...invoice,
          expired_interval_label: getExpiredIntervalLabel(invoice),
          expired_days: getExpiredDays(invoice),
          payments: payments.slice().sort((a, b) => {
            return moment(new Date(a.registration_date)).diff(
              new Date(b.registration_date),
            );
          }),
        };
      });
      return featureAdapter.setAll(invoices, {
        ...state,
        isLoading: false,
        pagination: action.payload.pagination,
        error: null,
      });
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_EMAIL_TEMPLATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        emailTemplateData: action.payload.data,
        error: null,
      };
    }
    case ActionTypes.LOAD_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_DETAIL_SHOW_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_DETAIL_SHOW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_DETAIL_SHOW_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_STATUS_SDI_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_STATUS_SDI_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        statusSdiInfo: action.payload.items.reduce((acc, curr) => {
          acc = { ...acc, [curr.id]: curr };
          return acc;
        }, {}),
        error: null,
      };
    }
    case ActionTypes.LOAD_STATUS_SDI_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.RESET_STATE: {
      return { ...initialState, statusSdiInfo: state.statusSdiInfo };
    }
    case ActionTypes.RESET_DETAIL_STATE: {
      return {
        ...state,
        invoiceDetails: null,
      };
    }
    case ActionTypes.CREATE_CREDIT_NOTE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        saving: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_CREDIT_NOTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        saving: false,
        error: null,
      };
    }
    case ActionTypes.CREATE_CREDIT_NOTE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        saving: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        saving: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      return featureAdapter.removeOne(action.payload.invoiceId, {
        ...state,
        isLoading: false,
        saving: false,
        error: null,
      });
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        saving: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: !action.payload.noReload,
        saving: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return featureAdapter.updateOne(
        {
          id: action.payload.invoice.id,
          changes: action.payload.invoice,
        },
        {
          ...state,
          isLoading: false,
          saving: false,
          error: null,
        },
      );
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        saving: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.REGISTER_INVOICE_REQUEST: {
      return {
        ...state,
        registerLoading: 'invoice',
        saving: true,
      };
    }

    case ActionTypes.REGISTER_INVOICE_SUCCESS:
    case ActionTypes.REGISTER_INVOICE_FAILURE: {
      return {
        ...state,
        registerLoading: null,
        saving: false,
      };
    }

    case ActionTypes.UPDATE_INVOICE_TOTALS: {
      return {
        ...state,
        invoiceTotals: {
          ...state.invoiceTotals,
          ...action.payload.totals,
        },
      };
    }

    case ActionTypes.SET_HOLIDAY_VOUCHER_TOTALS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ActionTypes.LOAD_WIDGETS_SUCCESS: {
      return {
        ...state,
        widgetsLoading: false,
        widgets: Object.entries(action.payload.widgets || {}).map(
          ([key, { total, count }]) => ({
            count,
            value: total,
            valueType: 'currency',
            title: { key },
          }),
        ),
      };
    }

    case ActionTypes.LOAD_WIDGETS_REQUEST: {
      return {
        ...state,
        widgetsLoading: true,
      };
    }

    case ActionTypes.LOAD_WIDGETS_FAILURE: {
      return {
        ...state,
        widgetsLoading: false,
      };
    }

    case ActionTypes.OVERRIDE_BILL_INVOICE_REQUEST: {
      return { ...state, saving: true };
    }
    case ActionTypes.OVERRIDE_BILL_INVOICE_SUCCESS:
    case ActionTypes.OVERRIDE_BILL_INVOICE_FAILURE: {
      return { ...state, saving: false };
    }

    case ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_REQUEST: {
      return { ...state, saving: true };
    }
    case ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_SUCCESS:
    case ActionTypes.DELETE_OVERRIDE_BILL_INVOICE_FAILURE: {
      return { ...state, saving: false };
    }

    case ActionTypes.SEND_EMAIL_REQUEST: {
      return { ...state, saving: true };
    }
    case ActionTypes.SEND_EMAIL_SUCCESS:
    case ActionTypes.SEND_EMAIL_FAILURE: {
      return { ...state, saving: false };
    }

    case ActionTypes.RESTORE_INVOICE_REQUEST: {
      return { ...state, saving: true };
    }
    case ActionTypes.RESTORE_INVOICE_SUCCESS:
    case ActionTypes.RESTORE_INVOICE_FAILURE: {
      return { ...state, saving: false };
    }

    case ActionTypes.EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        exportFileId: action.payload.exportFileId,
      };
    }

    default: {
      return state;
    }
  }
}
