import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {
  TableauReservationTag,
  TableauRow,
  TableauRowItem,
  TableauViewOptions,
} from '../../../../models';
import { RowExtendedItem } from '../../../../services/rows.service';
import { TableauConfig } from '../config';
import { TableauSelectionService } from '../services/tableau-selection.service';

@Component({
  selector: 'by-tableau-box-wrapper',
  templateUrl: './tableau-box-wrapper.component.html',
  styleUrls: ['./tableau-box-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauBoxWrapperComponent implements OnChanges {
  @Input()
  boxColor: string;

  @Input()
  zoom: number;

  @Input()
  searchValue: string;

  @Input()
  row: TableauRow;

  @Input()
  box: TableauRowItem<RowExtendedItem<{}>>;

  @Input()
  viewOptions: TableauViewOptions;

  wrapperClasses: Record<string, boolean> = {};

  wrapperStyles: Partial<CSSStyleDeclaration> = {};

  width: number;

  background: string;

  border: string;

  searchLabel: string;

  reservationTag: TableauReservationTag;

  private hostElement: HTMLElement;

  constructor(
    private cdr: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    private tableauSelectionService: TableauSelectionService,
  ) {
    this.hostElement = elementRef.nativeElement;
  }

  @HostListener('mouseenter')
  onMouseOver() {
    this.tableauSelectionService.checkBoxAndCompleteSelection(
      this.row,
      this.box,
    );
  }

  /**
   * @description Locks the selection of the cells below
   */
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.stopPropagation();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { box, zoom, boxColor, viewOptions } = changes;

    if (box || zoom) {
      this.setBoxWidth();
    }

    if (box && this.box) {
      this.setTruncatedEdges();
    }

    if (boxColor) {
      this.setBoxBackground(this.boxColor);
    }

    if (viewOptions) {
      this.setOblique();
    }
  }

  setBoxBackground(background: string) {
    this.background = background;
    this.cdr.detectChanges();
  }

  setReservationTag(reservationTag: TableauReservationTag) {
    this.reservationTag = reservationTag;
    this.cdr.detectChanges();
  }

  setBoxBorder(border: string) {
    this.border = border;
    this.cdr.detectChanges();
  }

  setSearchLabel(searchLabel: string) {
    this.searchLabel = searchLabel;
    this.cdr.detectChanges();
  }

  setHighlighted(highlighted: boolean) {
    const boxWidth = this.calcBoxWidth();
    const boxMarginWidth = this.calcBoxMarginWidth();

    this.setStyle(
      'transform',
      highlighted ? `scale(${(boxWidth + boxMarginWidth) / boxWidth})` : null,
    );

    this.setClass('by-tableau-box-wrapper--highlighted', highlighted);

    this.setBoxBorder(
      highlighted ? `${TableauConfig.BoxMargin}px solid #ffff00` : null,
    );
  }

  hide() {
    this.setStyle('visibility', 'collapse');
    this.hostElement.style.height = '0';
    this.cdr.detectChanges();
  }

  show() {
    this.setStyle('visibility', null);
    this.hostElement.style.height = null;
    this.cdr.detectChanges();
  }

  private calcBoxWidth() {
    return (this.box?.data?.length || 1) * TableauConfig.CellWidth * this.zoom;
  }

  private calcBoxMarginWidth() {
    return TableauConfig.BoxMargin * 2;
  }

  private setBoxWidth() {
    this.width = this.calcBoxWidth() - this.calcBoxMarginWidth();
    this.setStyle('width', this.width + 'px');
  }

  private setOblique() {
    this.setClass('by-tableau-box-wrapper--oblique', this.viewOptions?.oblique);
    this.cdr.detectChanges();
  }

  private setTruncatedEdges() {
    this.setClass(
      'by-tableau-box-wrapper--truncated-left-edge',
      this.box?.data?.leftEdgeTrucated,
    );
    this.setClass(
      'by-tableau-box-wrapper--truncated-right-edge',
      this.box?.data?.rightEdgeTruncated,
    );
  }

  private setClass(klass: string, active: boolean) {
    this.wrapperClasses = {
      ...this.wrapperClasses,
      [klass]: active,
    };
  }

  private setStyle(rule: keyof CSSStyleDeclaration, value: string) {
    this.wrapperStyles = {
      ...this.wrapperStyles,
      [rule]: value,
    };
  }
}
