import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import {
  TableauRow,
  TableauRowComponent,
  TableauRowItems,
} from '../../../../models';

@Component({
  selector: 'by-tableau-property-stats-row',
  templateUrl: './tableau-property-stats-row.component.html',
  styleUrls: ['./tableau-property-stats-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauPropertyStatsRowComponent
  implements TableauRowComponent, OnChanges {
  @Input()
  row: TableauRow;

  @Input()
  data: { key: string };

  @Input()
  items: TableauRowItems;

  @Input()
  days: Date[];

  constructor() {}

  ngOnChanges() {}
}
