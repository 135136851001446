import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { uniq, uniqBy } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { selectAllPropertiesNames } from '../../../../core/+state/core.reducer';
import {
  TableauMappingAccommodationDetails,
  TableauQuote,
  TableauReservation,
  TableauRoom,
  TableauRow,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauRowComponents } from '../tableau-components-map';
import {
  PropertyOption,
  TableauDestinationRowSelectionModalComponent,
} from '../tableau-destination-row-selection-modal/tableau-destination-row-selection-modal.component';

import { TableauDataSelectorService } from './tableau-data-selector.service';

@Injectable()
export class TableauDestinationRowSelectionService {
  rows$ = this.selector.selectRows().pipe(
    filter((rows) => !!rows),
    take(1),
  );

  propertiesNames$ = this.store.select(selectAllPropertiesNames).pipe(
    filter((names) => !!names),
    take(1),
  );

  reservationsOnSwap$ = this.selector.selectSwappedReservations().pipe(
    filter((reservationsOnSwap) => !!reservationsOnSwap),
    take(1),
    map((reservationsOnSwap) =>
      reservationsOnSwap.map(
        ({ reservation }) => reservation.reservation_accommodation_room_id,
      ),
    ),
  );

  constructor(
    private store: Store<RootState>,
    private modalService: NzModalService,
    private selector: TableauDataSelectorService,
  ) {}

  openSelectionForReservation(
    sourceRow: TableauRow<TableauRoom>,
    reservation: TableauReservation,
  ): Observable<TableauRow<TableauRoom>> {
    return combineLatest([
      this.rows$,
      this.propertiesNames$,
      this.reservationsOnSwap$,
    ]).pipe(
      switchMap(([rows, propertiesNames, reservationsOnSwap]) => {
        const propertyOptions = this.getPropertiesOptions(
          rows,
          sourceRow,
          propertiesNames,
        );

        const modal = this.openModal({
          propertyOptions,
          range: [reservation.arrival_date, reservation.departure_date],
          guestFullName: reservation.label,
          reservationsOnSwap,
        });

        return modal.afterClose.asObservable();
      }),
    );
  }

  openSelectionForQuote(
    sourceRow: TableauRow<TableauRoom>,
    quote: TableauQuote,
  ): Observable<TableauRow<TableauRoom>> {
    return combineLatest([
      this.rows$,
      this.propertiesNames$,
      this.reservationsOnSwap$,
    ]).pipe(
      switchMap(([rows, propertiesNames, reservationsOnSwap]) => {
        const propertyOptions = [
          {
            id: sourceRow.propertyId,
            name: propertiesNames[sourceRow.propertyId],
            accommodations: [
              {
                id: sourceRow.accommodationId,
                name: sourceRow.data.accommodation_details.name,
                tableauNumbers: this.getTableauNumbersRowsByAccommodation(
                  rows,
                  sourceRow.accommodationId,
                ).map((tableauNumberRow) => {
                  return {
                    id: tableauNumberRow.data.id,
                    name: tableauNumberRow.data.label,
                    row: tableauNumberRow,
                    disabled: tableauNumberRow.id === sourceRow.id,
                  };
                }),
              },
            ],
          },
        ];

        const modal = this.openModal({
          propertyOptions,
          range: [quote.date_from, quote.date_to],
          guestFullName: `${quote.booker_surname} ${quote.booker_name}`,
          reservationsOnSwap,
        });

        return modal.afterClose.asObservable();
      }),
    );
  }

  private openModal(
    nzData: Partial<TableauDestinationRowSelectionModalComponent>,
  ) {
    return this.modalService.create<
      TableauDestinationRowSelectionModalComponent,
      Partial<TableauDestinationRowSelectionModalComponent>
    >({
      nzContent: TableauDestinationRowSelectionModalComponent,
      nzData,
      nzWidth: '380px',
      nzBodyStyle: { padding: '0px' },
      nzClassName: 'multi-estimate-modal',
    });
  }

  private getPropertiesIds(rows: TableauRow[]): number[] {
    return uniq(
      rows
        .filter((row) => row.component === TableauRowComponents.Room)
        .map(({ propertyId }) => propertyId),
    );
  }

  private getAccommodationsByProperty(
    rows: TableauRow[],
    propertyId: number,
  ): TableauMappingAccommodationDetails[] {
    return uniqBy(
      rows
        .filter(
          (row) =>
            row.component === TableauRowComponents.Room &&
            row.propertyId === propertyId,
        )
        .map((row: TableauRow<TableauRoom>) => row.data.accommodation_details),
      'id',
    );
  }

  private getTableauNumbersRowsByAccommodation(
    rows: TableauRow[],
    accommodationId: number,
  ) {
    return uniqBy(
      rows.filter(
        (row) =>
          row.component === TableauRowComponents.Room &&
          row.accommodationId === accommodationId,
      ),
      (row: TableauRow<TableauRoom>) => row.data.id,
    );
  }

  private getPropertiesOptions(
    rows: TableauRow[],
    sourceRow: TableauRow<TableauRoom>,
    propertiesNames: Record<number, string>,
  ): PropertyOption[] {
    return this.getPropertiesIds(rows).map((propertyId) => {
      return {
        id: propertyId,
        name: propertiesNames[propertyId],
        accommodations: this.getAccommodationsByProperty(rows, propertyId).map(
          (accommodation) => {
            return {
              id: accommodation.id,
              name: accommodation.name,
              tableauNumbers: this.getTableauNumbersRowsByAccommodation(
                rows,
                accommodation.id,
              ).map((tableauNumberRow) => {
                return {
                  id: tableauNumberRow.data.id,
                  name: tableauNumberRow.data.label,
                  row: tableauNumberRow,
                  disabled: tableauNumberRow.id === sourceRow.id,
                };
              }),
            };
          },
        ),
      };
    });
  }
}
