import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';

import { Days } from '../models/days';
import { SplitInformations } from '../models/split-informations';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-estimate-interface',
  templateUrl: './estimate-interface.component.html',
  styleUrls: ['./estimate-interface.component.scss'],
})
export class EstimateInterfaceComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<EstimateInterfaceComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  dates: string[] = this.nzDataModal.dates || [];

  @Input()
  range: [string, string] = this.nzDataModal.range || null;

  @Input()
  oldTreatmentId: number = this.nzDataModal.oldTreatmentId || null;

  @Input()
  guestFullName: string = this.nzDataModal.guestFullName || null;

  @Input()
  splitInformations: SplitInformations =
    this.nzDataModal.splitInformations || null;

  @Input()
  loading: boolean = this.nzDataModal.loading || null;

  @Input()
  warnings: string[] = this.nzDataModal.warnings || [];

  @Input()
  isClosed: boolean = this.nzDataModal.isClosed || null;

  @Input()
  withoutAvail: boolean = this.nzDataModal.withoutAvail || null;

  @Input()
  newTotal: number = this.nzDataModal.newTotal || null;

  @Input()
  originalTotal: number = this.nzDataModal.originalTotal || null;

  @Input()
  rateplans: Array<{
    id: number;
    name: string;
    treatments: Array<{ id: number; name: string }>;
  }> = this.nzDataModal.rateplans || null;

  @Input()
  showWarnings: boolean = this.nzDataModal.showWarnings || null;

  @Input()
  rateplanRequired: boolean = this.nzDataModal.rateplanRequired || null;

  @Input()
  treatmentRequired: boolean = this.nzDataModal.treatmentRequired || null;

  @Input()
  noPrice: boolean = this.nzDataModal.noPrice || null;

  @Input()
  currencySymbol: string = this.nzDataModal.currencySymbol || '';

  @Input()
  originalDays: Days = this.nzDataModal.originalDays;

  @Input()
  newDays: Days = this.nzDataModal.newDays;

  @Input()
  draggable: boolean = this.nzDataModal.draggable;

  @Input()
  hasDoorCode: boolean = this.nzDataModal.hasDoorCode;

  splitOptionsForm = this.formBuilder.group({
    rateplan_id: [null],
    treatment_id: [null],
  });

  modelPrice: 'original' | 'moved' = 'original';

  treatments: Array<{ id: number; name: string }>;

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.subs.add(
      this.rateplanControl.valueChanges.subscribe((rateplanId) => {
        const rateplan = this.rateplans.find(({ id }) => id === rateplanId);
        this.treatments = rateplan?.treatments || [];
        this.splitOptionsForm.patchValue({
          treatment_id: this.treatmentId,
        });
      }),
    );
  }

  ngOnDestroy() {}

  get rateplanControl() {
    return this.splitOptionsForm.controls.rateplan_id;
  }

  get showRateplanAndTreatmentSelect() {
    return (
      this.rateplanRequired ||
      this.treatmentRequired ||
      this.splitOptionsForm.dirty
    );
  }

  get treatmentId() {
    const treatmentFind = this.treatments.find(
      ({ id }) => +id === this.oldTreatmentId,
    );

    return treatmentFind?.id || this.treatments[0]?.id;
  }
}
