import * as moment from 'moment';

interface SuspendedIntervals {
  [key: string]: { from: Date; to: Date };
}

export const SUSPENDED_INTERVALS: SuspendedIntervals = {
  '30_days_or_less': {
    from: moment().subtract(30, 'days').toDate(),
    to: moment().toDate(),
  },
  '60_days_to_30_days': {
    from: moment().subtract(60, 'days').toDate(),
    to: moment().subtract(30, 'days').toDate(),
  },
  '90_days_to_60_days': {
    from: moment().subtract(90, 'days').toDate(),
    to: moment().subtract(60, 'days').toDate(),
  },
  '6_months_to_90_days': {
    from: moment().subtract(6, 'months').toDate(),
    to: moment().subtract(90, 'days').toDate(),
  },
  '1_year_to_6_months': {
    from: moment().subtract(1, 'years').toDate(),
    to: moment().subtract(6, 'months').toDate(),
  },
  '1_year_or_more': {
    from: null,
    to: moment().subtract(1, 'years').toDate(),
  },
};
