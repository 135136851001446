<ng-container [ngSwitch]="row.component">
  <by-tableau-event-row
    *ngSwitchCase="Components.Event"
    [row]="row"
    [items]="row.items"
    [firstColumnRowspan]="row.firstColumnRowspan"
    [days]="days"
    [zoom]="zoom"
    [viewOptions]="viewOptions"
  ></by-tableau-event-row>

  <by-tableau-property-row
    *ngSwitchCase="Components.Property"
    [row]="row"
    [data]="row.data"
    [property]="property"
  ></by-tableau-property-row>

  <by-tableau-accommodation-row
    *ngSwitchCase="Components.Accommodation"
    [row]="row"
    [items]="row.items"
    [data]="row.data"
    [days]="days"
    [userCanWrite]="userCanWrite"
    [collapsedAccommodations]="collapsedAccommodations"
  ></by-tableau-accommodation-row>

  <by-tableau-floor-row
    *ngSwitchCase="Components.Floor"
    [row]="row"
    [data]="row.data"
  ></by-tableau-floor-row>

  <by-tableau-room-row
    *ngSwitchCase="Components.Room"
    [row]="row"
    [items]="row.items"
    [firstColumnRowspan]="row.firstColumnRowspan"
    [data]="row.data"
    [days]="days"
    [userCanWrite]="userCanWrite"
    [reservationsColors]="reservationsColors"
    [channelsColors]="channelsColors"
    [viewOptions]="viewOptions"
    [viewMode]="viewMode"
    [zoom]="zoom"
    [hoveredCell]="hoveredCell"
    [hoveredReservation]="hoveredReservation"
    [selection]="selection"
    [searchValue]="searchValue"
    [splitMode]="splitMode"
    [period]="period"
    [draggingItem]="draggingItem"
    [isMobile]="isMobile"
  ></by-tableau-room-row>

  <by-tableau-ob-row
    *ngSwitchCase="Components.Ob"
    [row]="row"
    [items]="row.items"
    [firstColumnRowspan]="row.firstColumnRowspan"
    [data]="row.data"
    [days]="days"
    [userCanWrite]="userCanWrite"
    [reservationsColors]="reservationsColors"
    [channelsColors]="channelsColors"
    [viewOptions]="viewOptions"
    [viewMode]="viewMode"
    [zoom]="zoom"
    [hoveredCell]="hoveredCell"
    [hoveredReservation]="hoveredReservation"
    [selection]="selection"
    [searchValue]="searchValue"
    [splitMode]="splitMode"
    [period]="period"
    [draggingItem]="draggingItem"
    [isMobile]="isMobile"
  ></by-tableau-ob-row>

  <by-tableau-property-stats-row
    *ngSwitchCase="Components.PropertyStats"
    [row]="row"
    [items]="row.items"
    [data]="row.data"
    [days]="days"
  ></by-tableau-property-stats-row>

  <by-tableau-footer-row
    *ngSwitchCase="Components.Footer"
    [row]="row"
    [isMobile]="isMobile"
  ></by-tableau-footer-row>
</ng-container>
