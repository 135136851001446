import { Pipe, PipeTransform } from '@angular/core';
import { FollowInvoice } from '@app/helpers';
import { FollowInvoiceConstructor } from '@app/models';

@Pipe({
  name: 'followInvoicePipe',
  standalone: true,
})
export class FollowInvoicePipe implements PipeTransform {
  transform(receipt: FollowInvoiceConstructor): FollowInvoice {
    return new FollowInvoice(receipt);
  }
}
