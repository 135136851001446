import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import {
  TableauRow,
  TableauRowItem,
  TableauSelection,
} from '../../../../models';
import { TableauSelectionableCellDirective } from '../directives/tableau-selectionable-cell.directive';
import { TableauBoxComponents } from '../tableau-components-map';

interface Interval {
  from: Date;
  to: Date;
}

@Injectable()
export class TableauSelectionService {
  private startCell: TableauSelectionableCellDirective;
  private endCell: TableauSelectionableCellDirective;

  private allCells: TableauSelectionableCellDirective[];

  private selection$ = new Subject<TableauSelection>();

  private onEndSelection: (interval: Interval) => void;

  constructor(private dateFormatter: DateFormatterService) {}

  start(
    cell: TableauSelectionableCellDirective,
    cells: TableauSelectionableCellDirective[],
    onEndSelection: (interval: Interval) => void,
  ) {
    this.end();
    this.allCells = cells;
    this.onEndSelection = onEndSelection;
    this.setSelection(cell, cell);
  }

  update(cell: TableauSelectionableCellDirective) {
    if (!this.startCell) {
      return;
    }

    if (cell.row.data.id !== this.startCell.row.data.id) {
      return;
    }

    if (moment(cell.date).isSameOrBefore(moment(this.startCell.date), 'days')) {
      this.setSelection(this.startCell, this.startCell);
      return;
    }

    this.setSelection(this.startCell, cell);
  }

  end() {
    if (this.startCell) {
      this.onEndSelection(this.getInterval());
    }

    this.startCell = null;
    this.endCell = null;
    this.allCells = null;
    this.selectedCells = null;
  }

  checkBoxAndCompleteSelection(row: TableauRow, box: TableauRowItem) {
    if (!this.startCell) {
      this.end();
      return;
    }

    if (row && this.startCell.row.id !== row.id) {
      return;
    }

    if (box && box.component === TableauBoxComponents.Note) {
      return;
    }

    this.end();
  }

  getInterval(): { from: Date; to: Date } {
    return { from: this.startCell.date, to: this.endCell.date };
  }

  getSelection() {
    return this.selection$.asObservable();
  }

  private setSelection(
    cellFrom: TableauSelectionableCellDirective,
    cellTo: TableauSelectionableCellDirective,
  ) {
    this.startCell = cellFrom;
    this.endCell = cellTo;

    this.selectedCells = this.allCells.filter((cell) => {
      return this.cellIsSelected(cell, cellFrom, cellTo);
    });
  }

  private cellIsSelected(
    cell: TableauSelectionableCellDirective,
    cellFrom: TableauSelectionableCellDirective,
    cellTo: TableauSelectionableCellDirective,
  ) {
    return moment(cell.date).isBetween(
      moment(cellFrom.date),
      moment(cellTo.date),
      'days',
      '[]',
    );
  }

  private set selectedCells(
    selectedCells: TableauSelectionableCellDirective[],
  ) {
    if (!selectedCells) {
      this.selection$.next(null);
      return;
    }

    const dates = selectedCells.reduce((_dates, cell) => {
      return {
        ..._dates,
        [this.dateFormatter.toServerFormat(cell.date)]: true,
      };
    }, {});

    const length = Object.keys(dates).length;

    this.selection$.next({
      roomId: +this.startCell.row.data.id,
      dates,
      length: Object.keys(dates).length,
      middleDate: moment(this.startCell.date)
        .add(Math.trunc(length / 2), 'days')
        .toDate(),
    });
  }
}
