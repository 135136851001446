import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';

import {
  TableauRow,
  TableauSwappedReservation,
  TableauViewOptions,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { TableauConfig } from '../config';
import { TableauDragService } from '../services/tableau-drag.service';

@Component({
  selector: 'by-tableau-swap-room',
  templateUrl: './tableau-swap-room.component.html',
  styleUrls: ['./tableau-swap-room.component.scss'],
})
export class TableauSwapRoomComponent implements OnInit {
  @ViewChild(NzPopoverDirective)
  popoverDirective: NzPopoverDirective;

  @Input()
  swappedReservations: TableauSwappedReservation[];

  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  channelsColors: Record<string, string>;

  @Input()
  viewOptions: TableauViewOptions;

  @Input()
  zoom: number;

  @Input()
  isMobile: boolean;

  readonly TableauConfig = TableauConfig;

  constructor(
    private store: Store<RootState>,
    private tableauDragService: TableauDragService,
  ) {}

  ngOnInit() {}

  onTogglePopoverVisibleStatus() {
    if (this.popoverDirective.component.nzVisible) {
      this.popoverDirective.hide();
    } else {
      this.popoverDirective.show();
    }
  }

  onRemoveAll() {
    this.store.dispatch(TableauActions.resetSwap());
  }

  onRemoveReservation(swappedReservation: TableauSwappedReservation) {
    this.store.dispatch(
      TableauActions.removeReservationFromSwap({
        swappedReservation,
      }),
    );
  }

  onDragStarted(swappedReservation: TableauSwappedReservation) {
    const { reservation } = swappedReservation;
    this.tableauDragService.startDrag(reservation);
  }

  onDragDropped(
    event: CdkDragDrop<TableauRow>,
    swappedReservation: TableauSwappedReservation,
  ) {
    this.tableauDragService.startDrag(null);

    if (this.tableauDragService.isInvalidDragEvent(event)) {
      return;
    }

    this.popoverDirective.hide();

    const { reservation, sourceRow } = swappedReservation;

    const { data: destinationRow } = event.container;

    if (sourceRow.id === destinationRow.id) {
      this.onRemoveReservation(swappedReservation);
      return;
    }

    this.store.dispatch(
      TableauActions.moveReservationFromSwapRequest({
        reservation,
        sourceRow,
        destinationRow,
      }),
    );
  }
}
