import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { InputNumberParseModule } from '../../directives/input-number-parser/input-number-parse.module';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { UiModule } from '../../ui/ui.module';

import { AddonEstimateFormComponent } from './addon-estimate-form/addon-estimate-form.component';
import { AddonEstimateModalCartComponent } from './addon-estimate-modal-cart/addon-estimate-modal-cart.component';
import { AddonEstimateModalComponent } from './addon-estimate-modal.component';

@NgModule({
  declarations: [
    AddonEstimateModalComponent,
    AddonEstimateModalCartComponent,
    AddonEstimateFormComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    InputNumberParseModule,
  ],
  exports: [AddonEstimateModalComponent],
})
export class AddonEstimateModalModule {}
