<div
  class="by-tableau-destination-row-selection-modal__header modalDraggableMove"
>
  <div class="by-flex by-flex-middle modalDraggableMove">
    <i class="by-mr-2 fas fa-exchange-alt"></i>
    <span class="ellipsis_50pr-50px">
      {{ guestFullName | capitalize }}
    </span>
    <span class="by-ml-5">
      [{{ (range | head | formatDate) + ' - ' + (range | last | formatDate) }}]
    </span>
  </div>
  <div class="by-tableau-destination-row-selection-modal__header-divider"></div>
</div>

<form
  [formGroup]="form"
  nz-form
  nzLayout="vertical"
  class="by-tableau-destination-row-selection-modal__form"
>
  <nz-alert
    class="by-mb-2"
    nzType="info"
    [nzMessage]="'where_move_reservation' | translate | upperfirst"
    nzShowIcon
  ></nz-alert>

  <nz-form-item *ngIf="propertyOptions.length > 1">
    <nz-form-label nzFor="property_id">
      {{ 'property' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control>
      <nz-select
        formControlName="property_id"
        [nzShowSearch]="true"
        class="by-w-100"
      >
        <nz-option
          *ngFor="let propertyOption of propertyOptions"
          [nzValue]="propertyOption.id"
          [nzLabel]="propertyOption.name"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="selectedProperty?.accommodations?.length > 1">
    <nz-form-label nzFor="accommodation_id">
      {{ 'housing_tipology' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control>
      <nz-select
        formControlName="accommodation_id"
        [nzShowSearch]="true"
        class="by-w-100"
      >
        <nz-option
          *ngFor="let accommodation of selectedProperty.accommodations"
          [nzValue]="accommodation.id"
          [nzLabel]="accommodation.name"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="tableau_number_id">
      {{ 'accommodation' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control>
      <nz-select
        formControlName="tableau_number_id"
        [nzShowSearch]="true"
        [nzPlaceHolder]="'select_accommodation' | translate | upperfirst"
        class="by-w-100"
      >
        <nz-option
          *ngFor="let tableauNumber of selectedAccommodation?.tableauNumbers"
          [nzValue]="tableauNumber.id"
          [nzLabel]="tableauNumber.name"
          [nzDisabled]="
            tableauNumber.disabled || !availableRooms[tableauNumber.id]
          "
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<div *nzModalFooter>
  <button nz-button (click)="onCloseModal()">
    {{ 'cancel' | translate | upperfirst }}
  </button>

  <button
    nz-button
    nzType="primary"
    [disabled]="form.invalid"
    (click)="onSubmit()"
  >
    {{ 'confirm' | translate | upperfirst }}
  </button>
</div>
