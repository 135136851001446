import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { first } from 'lodash';
import { SubSink } from 'subsink';

import { removeEmptyArrays } from '../../../../helpers';
import {
  ChildrenRange,
  FormGetter,
  ReservationFormAccommodation,
} from '../../../../models';

interface Guests {
  adults_number: number;
  children_number: number;
  total_children: GuestsChild[];
}

interface GuestsChild {
  age: number;
  quantity: number;
  id?: number;
}

type OnChange = (guests: Guests) => void;

@Component({
  selector: 'by-reservation-form-guests',
  templateUrl: './reservation-form-guests.component.html',
  styleUrls: ['./reservation-form-guests.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormGuestsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReservationFormGuestsComponent),
      multi: true,
    },
  ],
})
export class ReservationFormGuestsComponent
  implements ControlValueAccessor, OnInit, OnDestroy, FormGetter
{
  @Input()
  accommodation: ReservationFormAccommodation;

  @Input()
  childrenRanges: ChildrenRange[];

  @Input() isMobile = false;

  childrenNumberControl = this.formBuilder.control(0);

  form = this.formBuilder.group({
    adults_number: [null, [Validators.required]],
    total_children: this.formBuilder.array([]),
  });

  onTouched: () => void;

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  adultsParser = (value: string) => {
    return value.trim() || '1';
  };

  childrenParser = (value: string) => {
    return value.trim() || '0';
  };

  ngOnInit() {
    this.subs.add(
      this.childrenNumberControl.valueChanges.subscribe((childrenNumber) => {
        while (childrenNumber < this.childrenControl.length) {
          this.childrenControl.removeAt(this.childrenControl.length - 1);
        }

        while (childrenNumber > this.childrenControl.length) {
          const { combination } = first(this.childrenRanges);
          this.childrenControl.push(
            this.getGuestChildForm({ age: combination.to, quantity: 1 }),
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(guests: Guests) {
    if (!guests) {
      return;
    }

    this.form.patchValue(guests, { emitEvent: false });

    this.setGuestChildrenForm(guests.total_children || []);
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(
      this.form.valueChanges.subscribe((formValue) => {
        onChange(
          removeEmptyArrays({
            ...formValue,
            children_number: formValue.total_children.length,
            total_children: formValue.total_children.map((child) => {
              return {
                ...child,
                id: this.childrenRanges.find(
                  ({ combination }) => combination.to === child.age,
                )?.id,
              };
            }),
          }),
        );
      }),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  getForms() {
    return [this.form];
  }

  get childrenControl() {
    return this.form.get('total_children') as UntypedFormArray;
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return {
      'guests-error': true,
    };
  }

  private getGuestChildForm(child: GuestsChild) {
    return this.formBuilder.group(child);
  }

  private setGuestChildrenForm(children: GuestsChild[]) {
    this.childrenControl.clear({ emitEvent: false });

    children.forEach((child) => {
      this.childrenControl.push(this.getGuestChildForm(child), {
        emitEvent: false,
      });
    });
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
