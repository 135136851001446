<nz-badge
  [nzCount]="swappedReservations?.length"
  [nzStyle]="{ backgroundColor: '#ffa234' }"
>
  <div
    cdkDropList
    [id]="TableauConfig.SwapRoomId"
    class="by-tableau-swap-room"
    [class.by-tableau-swap-room--mobile]="isMobile"
    nz-popover
    [nzPopoverTrigger]="popoverDirective?.component?.nzVisible ? null : 'click'"
    nzPopoverPlacement="topRight"
    [nzPopoverContent]="swappedReservationsTemplate"
    [nzPopoverTitle]="null"
    (click)="onTogglePopoverVisibleStatus()"
  >
    <span>{{ 'swap' | translate }}</span>
  </div>
</nz-badge>

<ng-template #swappedReservationsTemplate>
  <div
    cdkDropList
    [id]="TableauConfig.SwapRoomId"
    [cdkDropListSortingDisabled]="true"
    class="by-tableau-swap-room__list"
  >
    <div
      *ngIf="!swappedReservations?.length; else clearSwap"
      class="text-align--center"
    >
      {{ 'empty_swap_message' | translate | capitalize }}
    </div>

    <ng-template #clearSwap>
      <div class="text-align--end">
        <a (click)="onRemoveAll()" class="color--blue">
          {{ 'clear_swap_message' | translate | capitalize }}
          <i class="fas fa-trash-alt"></i>
        </a>
      </div>
    </ng-template>

    <div class="by-tableau-swap-room__list-inner">
      <div
        *ngFor="let swappedReservation of swappedReservations"
        class="by-tableau-swap-room__list-item"
      >
        <div
          cdkDrag
          (cdkDragStarted)="onDragStarted(swappedReservation)"
          (cdkDragDropped)="onDragDropped($event, swappedReservation)"
        >
          <div *cdkDragPlaceholder></div>

          <div *cdkDragPreview>
            <by-tableau-box-wrapper
              #boxWrapperComponent
              [zoom]="zoom"
              [style.height.px]="TableauConfig.BoxHeight"
              [box]="{ data: swappedReservation.reservation }"
            >
              <by-tableau-reservation-box
                [data]="swappedReservation.reservation"
                [reservationsColors]="reservationsColors"
                [zoom]="zoom"
                [boxWrapperComponent]="boxWrapperComponent"
                [channelsColors]="channelsColors"
                [viewOptions]="viewOptions"
                [zoom]="zoom"
              ></by-tableau-reservation-box>
            </by-tableau-box-wrapper>
          </div>

          <div class="by-tableau-swap-room__list-item-container">
            <div>
              <i class="fas fa-arrows-alt"></i>
            </div>
            <div class="by-tableau-swap-room__list-item-content">
              <div>
                <strong>{{ swappedReservation.reservation.label }}</strong>
              </div>
              <div class="by-fs-11">
                <strong
                  >{{
                    'nights_label'
                      | translate
                        : {
                            nights: swappedReservation.reservation.number_nights
                          }
                      | titlecase
                  }}
                  >
                </strong>
                <span
                  >[{{
                    swappedReservation.reservation.arrival_date | formatDate
                  }}
                  -
                  {{
                    swappedReservation.reservation.departure_date | formatDate
                  }}]</span
                >
              </div>
              <div class="by-fs-11">
                <strong
                  >{{
                    swappedReservation.sourceRow.data.accommodation_details.name
                  }}
                  [{{ swappedReservation.sourceRow.data.label }}]</strong
                >
              </div>
            </div>
            <div>
              <i
                class="fas fa-times"
                (click)="onRemoveReservation(swappedReservation)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
