import { Directive, HostListener, Input } from '@angular/core';

import { TableauReservation } from '../../../../models';
import { TableauHoverService } from '../services/tableau-hover.service';

@Directive({
  selector: '[byTableauReservationHoverDispatcher]',
})
export class TableauReservationHoverDispatcherDirective {
  @Input()
  hoverDisabled: boolean;

  @Input()
  reservation: TableauReservation;

  constructor(private tableauHoverService: TableauHoverService) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.hoverDisabled) {
      return;
    }

    this.tableauHoverService.setHoveredReservation(this.reservation);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.hoverDisabled) {
      return;
    }

    this.tableauHoverService.setHoveredReservation(null);
  }
}
