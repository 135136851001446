<a
  nz-dropdown
  nzOverlayClassName="reservation-tag__dropdown"
  class="reservation-tag__dropdown-button"
  *ngIf="tags && tags.length"
  [nzDropdownMenu]="tagsDropdown"
  [nzTrigger]="'click'"
  [(nzVisible)]="tagDropdownIsVisible"
  nzTooltipClassName="reservation-tag__dropdown"
  (nzVisibleChange)="forceTooltipClose(tagSelected?.id)"
  [nzDisabled]="disabled"
>
  <ng-container>
    <div
      class="tag-div"
      nz-tooltip
      [nzTooltipTitle]="
        tagSelected
          ? tagSelected?.label
          : ('choose_a_tag' | translate | upperfirst)
      "
      [ngStyle]="{
        background: tagSelected ? tagSelected?.color : '#fff'
      }"
    >
      &nbsp;
    </div>
  </ng-container>
</a>
<nz-dropdown-menu #tagsDropdown="nzDropdownMenu">
  <ul nz-menu>
    <li [nzSelected]="!tagSelected" nz-menu-item (click)="selectTag(null)">
      <div class="tag-div tag-div__not-specified">&nbsp;</div>
    </li>
    <li
      *ngFor="let tag of tags"
      nz-tooltip
      [nzTooltipTitle]="tag?.label"
      [(nzTooltipVisible)]="tooltipVisibleMap[tag?.id]"
      [nzSelected]="tag?.id === tagSelected?.id"
      nz-menu-item
      (click)="selectTag(tag?.id)"
    >
      <div class="tag-div" [ngStyle]="{ background: tag?.color }">&nbsp;</div>
    </li>
  </ul>
</nz-dropdown-menu>
