import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { getDatesFromInterval } from '../../../../helpers';
import { TableauPeriod, TableauReservation } from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { RowsService } from '../../../../services';
import { TableauConfig } from '../config';

@Component({
  selector: 'by-tableau-reservation-split',
  templateUrl: './tableau-reservation-split.component.html',
  styleUrls: ['./tableau-reservation-split.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauReservationSplitComponent {
  @Input()
  reservation: TableauReservation;

  @Input()
  zoom: number;

  @Input()
  period: TableauPeriod;

  @Input()
  rowId: string;

  selectionRange: { from: number; to: number };

  constructor(
    private store: Store<RootState>,
    private rowsService: RowsService,
    private dateFormatter: DateFormatterService,
  ) {}

  @HostListener('mouseleave')
  onMouseLeave() {
    this.selectionRange = null;
  }

  onSegmentMouseEnter(segmentIndex: number) {
    if (segmentIndex === 0) {
      this.selectionRange = { from: 0, to: 0 };
      return;
    }

    this.selectionRange = {
      from: segmentIndex,
      to: this.reservation.length - 1,
    };
  }

  onSegmentClick(segmentIndex: number) {
    const splittedReservation = this.getReservationSegments(segmentIndex);

    this.store.dispatch(
      TableauActions.splitReservation({
        rowId: this.rowId,
        splittedReservation,
      }),
    );
  }

  getReservationSegments(segmentIndex: number): TableauReservation[] {
    const dates = getDatesFromInterval(
      this.reservation.arrival_date,
      this.reservation.departure_date,
    );

    const splitDate = this.getReservationDateFromSegmentIndex(segmentIndex);

    let splitIndex = dates.indexOf(splitDate);

    if (segmentIndex === 0) {
      splitIndex += 1;
    }

    return [
      this.getReservationSegment(0, splitIndex, dates),
      this.getReservationSegment(splitIndex, dates.length - 1, dates),
    ];
  }

  getReservationSegment(
    fromIndex: number,
    toIndex: number,
    dates: string[],
  ): TableauReservation {
    return this.rowsService.formatItem(
      {
        ...this.reservation,
        arrival_date: dates[fromIndex],
        date_from: dates[fromIndex],
        departure_date: dates[toIndex],
        date_to: dates[toIndex],
      },
      [this.period.from, this.period.to],
    );
  }

  getReservationDateFromSegmentIndex(segmentIndex: number) {
    const { dateIndex } = this.reservation;

    return this.dateFormatter.toServerFormat(
      moment(dateIndex).add(segmentIndex, 'days').toDate(),
    );
  }

  get segmentWidth() {
    return TableauConfig.CellWidth * this.zoom;
  }

  get externalSegmentWidth() {
    return this.segmentWidth - TableauConfig.BoxMargin;
  }
}
