import { add, addDays, subDays } from 'date-fns';
import {
  findLastIndex,
  flatten,
  flattenDeep,
  get,
  isEqual,
  uniq,
  uniqBy,
  uniqWith,
} from 'lodash';
import * as moment from 'moment';

import {
  IReservationDataCustom,
  IReservationDetail,
} from '../../../features/commons/reservation-details/models/reservation.model';
import { handleSplit } from '../../../helpers/handle-split';
import { ReservationTotals, RoleUser, SocialArchive } from '../../../models';

import { totalGuestFactory } from './total-guest-factory';
import { totalGuestRoomFactory } from './total-guest-room-factory';
import { userFactory } from './user-factory';
import { ByDateHelpers } from '../../../helpers';

const createCoupon = (coupon) => {
  if (!coupon) {
    return null;
  }

  let { addons } = coupon;

  addons = typeof addons === 'string' ? JSON.parse(addons) : addons;

  return { ...coupon, addons };
};

export function reservationFactory(
  reservation: any,
  loadMediaCompleted: boolean,
  propertyName: string,
  channelName: string,
  currencySymbol: string,
  currencyCode: string,
  languageName: string,
  languageIsoCode: string,
): [IReservationDataCustom, any, IReservationDetail[], ReservationTotals] {
  const {
    id,
    label_name,
    addictive_field,
    metasearch,
    channel_id,
    property_id,
    currency_id,
    total_price,
    source,
    unpaid,
    arrival_date,
    departure_date,
    total_children,
    total_adults,
    xml_property_id,
    xml_reservation_id,
    notes,
    deposit,
    reservation_reason_id,
    created_user,
    virtual_channel_name,
    virtual_channel_id,
    is_virtual_channel,
    read,
    from,
    booked_at,
    channel_checkin,
    modified_at,
    payment_status,
    status,
    expiration_date,
    sca_accepted,
    cancelled_at,
    number_nights,
    accommodations,
    booker,
    company,
    is_group,
    payment_agreement,
    total_details,
    tag,
    credit_card_data_count,
    payment_method_id,
    city_tax_calculated_date,
    generated_bills_date,
    extra,
    policy: generalPolicy,
    is_past,
    keep_availability,
    commission_amount,
    channel_reports,
    coupon,
    invoice_requested,
    availability_option,
    unread_received_messages_count,
  } = reservation;

  const {
    detail,
    id: _id,
    name,
    surname,
    privacy_check,
    privacy_ip,
    privacy_date,
    promotion_check,
    promotion_ip,
    promotion_date,
  } = booker;

  const originalBooker = {
    id: detail ? detail.customer_id : _id,
    name,
    surname,
  };
  const reservation_from_id = from && from.id;
  const allGuests = [];

  const accommodationsData = accommodations.reduce(
    (
      data,
      { accommodation_id, my_accommodation, property_id: accPropertyId },
    ) =>
      (data = {
        ...data,
        [accommodation_id]: {
          name: my_accommodation && my_accommodation.name,
          property_id: accPropertyId,
        },
      }),
    {},
  );

  const allAccommodations = handleSplit(accommodations);

  let allAccommodationRateplans = [];
  allAccommodations.forEach((accommodation: any) => {
    accommodation.rooms.forEach((r) =>
      allAccommodationRateplans.push(
        r.days.map((d) => {
          return {
            accommodation_id: r.accommodation_id,
            rateplan_id: get(
              d,
              'accommodation_rateplan.my_property_rateplan.my_rateplan.id',
            ),
            rateplan_name: get(
              d,
              'accommodation_rateplan.my_property_rateplan.my_rateplan.name',
            ),
          };
        }),
      ),
    );
    return {};
  });

  const allAccommodationRateplansFlatten = flattenDeep(
    allAccommodationRateplans,
  ).filter((acc) => acc.rateplan_id);
  allAccommodationRateplans = uniqWith(
    allAccommodationRateplansFlatten,
    isEqual,
  );

  const accommodationsProperties = accommodations.map((accommodation) => {
    return get(accommodation, 'my_accommodation.property_id');
  });

  const properties = uniq([...accommodationsProperties, property_id]);

  const generalNotes = notes.reduce((acc, { related_subtype, note }) => {
    const key = related_subtype.replace('reservation_', '');
    return {
      ...acc,
      [key]: note,
    };
  }, {});
  let socialArchive: SocialArchive;
  let unlockData = {};

  const reservationData = {
    id,
    unread_received_messages_count,
    addictive_field,
    metasearch,
    booker,
    channel_checkin,
    isGroup: is_group,
    company,
    label_name: label_name || '',
    channel: { id: channel_id, name: channelName },
    property: { id: property_id, name: propertyName },
    properties,
    allAccommodationRateplans,
    nights: +number_nights,
    read,
    booked_at: new Date(booked_at),
    modified_at,
    arrival: new Date(arrival_date),
    creditCardLastDateToView: add(new Date(departure_date), { days: 15 }),
    reservation_reason_id,
    reservation_from_id,
    departure: new Date(departure_date),
    total_details,
    tag,
    generated_bills: !!generated_bills_date,
    credit_card_data_count,
    extra: JSON.parse(extra),
    is_past,
    keep_availability,
    commission_amount,
    city_tax_calculated_date,
    payment_method_id,
    cancellation_date: cancelled_at ? new Date(cancelled_at) : null,
    sca_accepted,
    created_user,
    virtual_channel_name,
    virtual_channel_id,
    is_virtual_channel,
    payment: {
      grantTotal: +total_price,
      unpaidTotal: +unpaid,
      status: payment_status,
      deposit: +deposit,
    },
    whoPays: payment_agreement,
    payment_agreement,
    currencySymbol,
    currencyCode,
    currency_id,
    source,
    channel_reports,
    children: (total_children || []).map((data) => {
      const { age, quantity, number } = data;
      return {
        age: +age,
        number: +quantity || +number,
      };
    }),
    adults: +total_adults,
    xml: { propertyId: xml_property_id, reservationId: xml_reservation_id },
    notes: generalNotes,
    booker_privacy_policy: {
      accepted: !!privacy_check,
      date: moment(privacy_date, 'YYYY-MM-DD').toDate(),
      ip_address: privacy_ip,
    },
    booker_email_promotions_consent: {
      accepted: !!promotion_check,
      date: moment(promotion_date, 'YYYY-MM-DD').toDate(),
      ip_address: promotion_ip,
    },
    details: allAccommodations.map(
      (accommodation: any, index): IReservationDetail => {
        const {
          // tslint:disable-next-line:no-shadowed-variable
          arrival_date,
          // tslint:disable-next-line:no-shadowed-variable
          departure_date,
          price_total,
          rooms,
          room,
          // tslint:disable-next-line:no-shadowed-variable
          notes,
          accommodation_id,
          my_accommodation,
          split_references,
          original_accommodation,
          accommodation_xml_id,
          rate_xml_id,
          from_pms,
          bed_type,
          original_rate_label,
          policy,
          roomreservation_id,
          guests,
          social_archive,
          unlock_discount_type_id,
          unlock_discount_value,
          unlock_discount_free_text,
          unlock_free_addon,
          accommodation_bed_type_combination_id,
          more_fields,
          keep_accommodation,
          city_tax_report_exclusion,
        } = accommodation;

        if (index === 0) {
          socialArchive = social_archive;
          unlockData = {
            unlock_discount_type_id,
            unlock_discount_value,
            unlock_discount_free_text,
            unlock_free_addon,
          };
        }
        const propertyIdAccommodation = get(my_accommodation, 'property_id');
        const accommodationName = get(my_accommodation, 'name', '');
        const max_adults = get(my_accommodation, 'max_adults', '');
        const max_children = get(my_accommodation, 'max_children', '');
        const is_virtual = !!get(my_accommodation, 'is_virtual', false);

        let foundRoomIndex = null;
        const sortedRooms = rooms
          .map((roomData) => ({ ...roomData, guests }))
          .sort((a, b) => moment(a.days[0].date).diff(b.days[0].date));

        sortedRooms.forEach(({ guests: roomGuests }) => {
          roomGuests.forEach((guest) => {
            const isAlreadyPresent =
              allGuests.findIndex((g) => g.id === guest.id) !== -1;
            if (isAlreadyPresent) {
              return;
            }
            allGuests.push({
              ...guest.customer,
              guest_type_id: guest.guest_type_id,
            });
          });
        });

        const today = moment();

        if (today.isBefore(moment(arrival_date))) {
          foundRoomIndex = 0;
        }

        if (today.isAfter(moment(departure_date))) {
          foundRoomIndex = sortedRooms.length - 1;
        }

        if (today.isBetween(moment(arrival_date), moment(departure_date))) {
          foundRoomIndex = sortedRooms.findIndex((r) =>
            today.isBetween(r.arrival_date, r.departure_date),
          );
        }

        const foundDayIndex = findLastIndex(room.days, (day: any) =>
          moment(day.date).isSameOrBefore(new Date()),
        );

        const {
          checkin,
          checkin_hour,
          checkin_arrival_preference,
          checkout,
          checkout_hour,
          tableau,
          reservation_accommodation_id,
        } = room;

        const roomDay =
          room.days && room.days.length
            ? room.days[foundDayIndex] || room.days[0]
            : {};

        const {
          treatment,
          treatment_meal,
          accommodation_rateplan,
          reservation_accommodation_room_id,
        } = roomDay;

        const days: any = flatten(
          rooms.map((r) =>
            r.days.map((d) => ({
              ...d,
              room: { id: r.accommodation_tableau_number_id || 'OB' },
              accommodation: { id: r.accommodation_id },
              reservation_accommodation_id: r.reservation_accommodation_id,
            })),
          ),
        );
        const newDays = days
          .sort((a, b) => moment(a.date).diff(b.date))
          .reduce((acc, day) => {
            acc[day.date] = day;
            return acc;
          }, {});
        let user = { ...booker, isFake: true };
        if (guests && guests.length > 0) {
          const mainGuest = guests.find(({ main_guest }) => main_guest);

          const mainGuestByGuestType = guests.find(
            ({ guest_type_id }) => guest_type_id === 2 || guest_type_id === 3,
          );

          user = mainGuest || mainGuestByGuestType || guests[0];

          user = {
            ...user,
            ...user.customer,
            detail: user.customer.details[0],
            bookerDetail: get(booker, 'detail', {}),
          };
        }

        let customer_company_id = null;
        let companyName = '';
        if (company) {
          const { id: companyId, customer_id: companyCustomerId } = company;

          if (companyCustomerId === booker.id) {
            customer_company_id = companyId;
            companyName = company.name;
            user = { ...user, companyCustomerId };
          }
        }
        const bookerFullName = customer_company_id
          ? companyName
          : `${booker.name || ''} ${booker.surname || ''}`;
        const bookerTelephone = get(booker, 'detail.telephone');
        const bookerEmail = get(booker, 'detail.email');

        let accommodationNotes = {
          ...notes.reduce((acc, { related_subtype, note }) => {
            const key = related_subtype.replace('accommodation_', '');
            acc = { ...acc, [key]: note };
            return acc;
          }, {}),
          from_housekeeper: get(rooms, '[0].tableau.note_housekeeper', ''),
        };

        if (allAccommodations.length === 1) {
          accommodationNotes = {
            ...accommodationNotes,
            internal_general: generalNotes.internal || '',
            customer_general: generalNotes.customer || '',
          };
        }
        return {
          penalty: policy ? [policy] : [],
          user: {
            ...userFactory(user, languageName, languageIsoCode),
            bookerFullName,
            bookerEmail,
            bookerTelephone,
            customer_company_id,
          },
          summary: {
            coupon: createCoupon(coupon),
            addictive_field,
            more_fields,
            keep_accommodation,
            city_tax_report_exclusion,
            metasearch,
            invoice_requested,
            social_archive,
            unlock_discount_type_id,
            unlock_discount_value,
            unlock_discount_free_text,
            unlock_free_addon,
            roomreservation_id:
              allAccommodations.length > 1
                ? `${id}${roomreservation_id}`
                : null,
            roomreservation_id_original: roomreservation_id,
            isGroup: false,
            accommodation: {
              id: accommodation_id,
              name: accommodationName,
              split_references,
              type_id: my_accommodation.accommodation_type_id,
            },
            extra: JSON.parse(accommodation?.extra || '{}'),
            status,
            expiration_date: expiration_date
              ? new Date(expiration_date)
              : new Date(),
            availability_option,
            media: [],
            arrival: moment(days[0].date).toDate(),
            departure: moment(days[days.length - 1].date)
              .add('1', 'day')
              .toDate(),
            total_details,
            payment: { grantTotal: +price_total },
            reservation_accommodation_room_id,
            children: totalGuestRoomFactory(
              room,
              arrival_date,
              departure_date,
              'children',
            ).numChildren,
            adults: totalGuestRoomFactory(
              room,
              arrival_date,
              departure_date,
              'adults',
            ).numAdults,
            total_children: totalGuestRoomFactory(
              room,
              arrival_date,
              departure_date,
              'children',
            ).totalChildren,
            max_adults,
            max_children,
            room: {
              ...room,
              accommodation: {
                ...accommodationsData[room.accommodation_id],
                id: room.accommodation_id,
              },
              guests,
              id: (tableau && tableau.id) || 'OB',
              reservation_accommodation_room_id: room.id || 'OB',
              name: (tableau && tableau.label) || 'OB',
              days,
            },
            property_id:
              (tableau && tableau.property_id) ||
              get(
                room,
                `days[${foundDayIndex}].accommodation_rateplan.my_property_rateplan.property_id`,
              ) ||
              propertyIdAccommodation,
            rooms: rooms.map((_room) => {
              return {
                ..._room,
                max_adults,
                max_children,
                company,
                payment_agreement,
                guests: guests.filter(
                  (guest) =>
                    ByDateHelpers.isBetween(new Date(guest.arrival_date), {
                      start: subDays(new Date(_room.arrival_date), 1),
                      end: new Date(_room.departure_date),
                    }) ||
                    ByDateHelpers.isBetween(new Date(guest.departure_date), {
                      start: new Date(_room.arrival_date),
                      end: addDays(new Date(_room.departure_date), 1),
                    }),
                ),
                reservationId: reservation.id,
                currencySymbol: currencySymbol,
                currencyCode: currencyCode,
                propertyId: property_id,
                booker: {
                  ...userFactory(user, languageName, languageIsoCode),
                  bookerFullName,
                  bookerEmail,
                  bookerTelephone,
                },
                originalBooker,
              };
            }),
            clean_status: tableau?.is_clean,
            housekeeper_users_id: tableau?.housekeeper_users?.map(
              (housekeeperUser: RoleUser) => housekeeperUser.id,
            ),
            days: newDays,
            original_accommodation,
            original_rate_label,
            accommodation_xml_id,
            rate_xml_id,
            from_pms,
            bed_type,
            reservation_accommodation_id,
            checkin,
            checkinHour: checkin_hour,
            checkin_arrival_preference,
            checkout,
            checkoutHour: checkout_hour,
            treatment: {
              id: (treatment && treatment.id) || null,
              name: treatment && treatment.name,
            },
            treatment_meal,
            rateplan: {
              id: accommodation_rateplan
                ? get(
                    accommodation_rateplan,
                    'my_property_rateplan.my_rateplan.id',
                  )
                : null,
              name: accommodation_rateplan
                ? get(
                    accommodation_rateplan,
                    'my_property_rateplan.my_rateplan.name',
                    '',
                  )
                : '',
            },
            notes: accommodationNotes,
            all_reservation_accommodation_room_ids: rooms.map(
              ({ reservation_accommodation_id: resAccId, id: resRoomId }) => ({
                accommodationId: resAccId,
                roomId: resRoomId,
              }),
            ),
            all_reservation_accommodation_ids: rooms.map(
              ({ reservation_accommodation_id: resAccId }) => resAccId,
            ),

            allRoomFilter: rooms.reduce((acc, curr) => {
              acc = {
                ...acc,
                [curr.reservation_accommodation_id]: {
                  accommodation_id: curr.accommodation_id,
                  reservation_accommodation_id:
                    curr.reservation_accommodation_id,
                  room_label: (curr.tableau && curr.tableau.label) || 'OB',
                },
              };
              return acc;
            }, {}),

            all_accommodation_ids: rooms.map(
              ({ accommodation_id: accId }) => accId,
            ),
            accommodation_bed_type_combination_id,
            is_virtual,
          },
        };
      },
    ),
  };
  if (allAccommodations.length > 1) {
    const customer_company_id =
      booker.id === company && company.id ? company.id : null;

    const bookerFullName = customer_company_id
      ? company?.name || ''
      : `${booker.name || ''} ${booker.surname || ''}`;

    const bookerTelephone = get(booker, 'detail.telephone');
    const bookerEmail = get(booker, 'detail.email');

    reservationData.details.unshift({
      penalty: generalPolicy ? [generalPolicy] : null,
      user: {
        ...userFactory(
          { ...booker, name: 'Riepilogo', surname: 'Generale' },
          languageName,
          languageIsoCode,
        ),
        bookerFullName,
        bookerEmail,
        bookerTelephone,
        customer_company_id,
      },
      summary: {
        addictive_field,
        metasearch,
        coupon: createCoupon(coupon),
        invoice_requested,
        social_archive: socialArchive,
        ...unlockData,
        isGroup: true,
        status,
        property_id,
        company,
        payment_agreement,
        expiration_date: expiration_date
          ? new Date(expiration_date)
          : new Date(),
        availability_option,
        arrival: new Date(arrival_date),
        departure: new Date(departure_date),
        children: totalGuestFactory(
          allAccommodations,
          arrival_date,
          departure_date,
          'children',
        ).numChildren,
        adults: totalGuestFactory(
          allAccommodations,
          arrival_date,
          departure_date,
          'adults',
        ).numAdults,
        total_children: totalGuestFactory(
          allAccommodations,
          arrival_date,
          departure_date,
          'children',
        ).totalChildren,
        notes: reservationData.notes,
        days: [],
        totalAccommodations: (reservationData.details || []).length,
        accommodation: null,
        rooms: reservationData.details.map(({ summary, user }) => ({
          ...summary.room,
          max_adults: summary.max_adults,
          max_children: summary.max_children,
          booker: user,
          reservationId: reservation.id,
          currencySymbol: currencySymbol,
          currencyCode: currencyCode,
          propertyId: property_id,
          payment_agreement,
          company,
          originalBooker,
        })),
        allRoomFilter: reservationData.details.reduce((acc, curr) => {
          acc = { ...acc, ...curr.summary.allRoomFilter };
          return acc;
        }, {}),
        room: {
          name: `${(reservationData.details || []).length} Camere`,
        },
        treatment: null,
        treatment_meal: null,
        rateplan: null,
        total_details,
        payment: {
          grantTotal: +total_details?.total_price,
          unpaidTotal: +unpaid,
          status: payment_status,
          deposit: +deposit,
        },
      },
    });
  }

  const {
    total_details: totalDetails,
    details: detailsData,
    ...reservationDataClean
  } = reservationData;

  return [
    { ...reservationDataClean, loadMediaCompleted },
    allGuests,
    detailsData,
    totalDetails,
  ];
}
