<div
  class="by-tableau-box-wrapper"
  [ngClass]="wrapperClasses"
  [ngStyle]="wrapperStyles"
  [class.by-tableau-box-wrapper--not-searched]="
    !(searchLabel | filter: searchValue)
  "
  [style.--background]="background"
  [style.--border]="border"
>
  <div
    *ngIf="reservationTag"
    class="by-tableau-box-wrapper__tag"
    [style.--background]="reservationTag?.color"
  ></div>
  <ng-content></ng-content>
</div>
