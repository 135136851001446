<div class="summary">
  <div class="summary__header">
    <by-page-header [title]="'cart' | translate | upperfirst"></by-page-header>
  </div>
  <nz-collapse [nzBordered]="false" class="by-mb-2" [nzGhost]="true">
    <ng-container
      *ngFor="let addon of cartData; let first = first; let index = index"
    >
      <nz-collapse-panel
        #collapse
        [nzHeader]="header"
        [nzExtra]="rigthTemplate"
      >
        <div class="cart-item-content">
          <div nz-row nzAlign="middle">
            <div nz-col nzSm="24">
              <i class="far fa-calendar-alt"></i>
              <span class="by-ml-10"
                >{{
                  addon | get: ['periods', 0, 'date_from'] | date: 'dd/MM/yyyy'
                }}
                <ng-container *ngIf="showPeriodConditionMapConstant[addon.type]"
                  >&nbsp;-
                  {{
                    addon | get: ['periods', 0, 'date_to'] | date: 'dd/MM/yyyy'
                  }}</ng-container
                >
              </span>
            </div>
          </div>

          <div
            nz-row
            nzAlign="middle"
            *ngIf="showChildrenRangesConditionMapConstant[addon.type]"
          >
            <div nz-col nzSm="24">
              <i class="fas fa-user"></i>
              <span class="by-ml-10"
                >{{ addon.adults }}
                {{
                  (addon.adults > 1 ? 'adults' : 'adult')
                    | translate
                    | capitalize
                }}</span
              >
            </div>
          </div>

          <div
            nz-row
            nzAlign="middle"
            *ngIf="
              ((addon | get: 'children') || []).length > 0 &&
              showChildrenRangesConditionMapConstant[addon.type]
            "
          >
            <div nz-col nzSm="24">
              <i class="fas fa-child"></i>
              <span class="by-ml-10"
                >{{ ((addon | get: 'children') || []).length }}
                {{
                  (((addon | get: 'children') || []).length > 1
                    ? 'children'
                    : 'child'
                  )
                    | translate
                    | capitalize
                }}</span
              >
            </div>
          </div>

          <div nz-row nzAlign="middle" *ngIf="addon | get: 'discount_value'">
            <div nz-col nzSm="24">
              <i
                class="fas fa-money-bill-wave"
                style="font-size: 12px; color: #016b01"
              ></i>
              <span class="by-ml-10">
                {{ 'discount' | translate | upperfirst }}
                <strong>{{ addon.discount_value }}%</strong>
              </span>
            </div>
          </div>

          <div
            nz-row
            nzAlign="middle"
            *ngIf="addon | get: 'vat_quote_description'"
          >
            <div nz-col nzSm="24">
              <i class="fas fa-tag" style="font-size: 12px; color: #e67e22"></i>
              <span class="by-ml-10">
                {{ addon.vat_quote_description }}
              </span>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
      <ng-template #header
        ><div class="addon-header">
          <div>
            <span style="color: #1790f9">
              <strong
                *ngIf="showQuantityAndRateConditionMapConstant[addon.type]"
                >{{ addon.quantity }}</strong
              >
              {{ addon.name }}</span
            >
          </div>
        </div>
      </ng-template>
      <ng-template #rigthTemplate>
        <span style="white-space: nowrap"
          ><by-currency-format
            [currencyValue]="addon.total"
            [currencySymbol]="currencySymbol"
          ></by-currency-format>
          <span
            (click)="$event.stopPropagation()"
            nz-popconfirm
            nzPopconfirmTitle="{{
              'actions.delete' | translate: { param: 'addon' | translate }
            }}"
            (nzOnConfirm)="deleteCartItem.emit(index)"
            class="pointer"
            ><i
              class="fas fa-trash-alt"
              style="color: #e7503b; padding-left: 5px"
            ></i
          ></span>
        </span>
      </ng-template>
    </ng-container>
  </nz-collapse>
  <div class="cart-price-card by-mt-2">
    <div class="cart-price">
      <span class="text">{{ 'total' | translate | upperfirst }} </span>
      <by-currency-format
        [currencyValue]="total"
        [currencySymbol]="currencySymbol"
      ></by-currency-format>
    </div>
  </div>
</div>
