import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { LoadEventRequest } from '../../../../models/requests/load-event-request';
import { ActiveChannelsStoreActions } from '../../../../root-store/active-channels-store';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { ReservationTagStoreActions } from '../../../../root-store/reservation-tag-store';

@Injectable()
export class TableauDataLoaderService {
  constructor(private store: Store<RootState>) {}

  loadMapping(propertyIds: number[]) {
    this.store.dispatch(TableauActions.loadMappingRequest({ propertyIds }));
  }

  loadReservationTags(propertiesIDS: number[]) {
    this.store.dispatch(
      new ReservationTagStoreActions.LoadRequestAction({ propertiesIDS }),
    );
  }

  loadFloors(propertyIds: number[]) {
    this.store.dispatch(TableauActions.loadFloorsRequest({ propertyIds }));
  }

  loadEvents(propertyIds: number[], dateFrom: string, dateTo: string) {
    const request: LoadEventRequest = {
      property_id: propertyIds,
      date_from: dateFrom,
      date_to: dateTo,
      show: 1,
    };
    this.store.dispatch(TableauActions.loadEventsRequest({ request }));
  }

  loadReservations(propertyIds: number[], dateFrom: string, dateTo: string) {
    this.store.dispatch(
      TableauActions.loadReservationsRequest({ propertyIds, dateFrom, dateTo }),
    );
  }

  loadHousekeeperInformations(propertyIds: number[], referenceDate: string) {
    this.store.dispatch(
      TableauActions.loadHousekeeperInformationsRequest({
        propertyIds,
        referenceDate,
      }),
    );
  }

  loadQuotes(propertyIds: number[], dateFrom: string, dateTo: string) {
    this.store.dispatch(
      TableauActions.loadQuotesRequest({ propertyIds, dateFrom, dateTo }),
    );
  }

  loadNotes(propertyIds: number[], dateFrom: string, dateTo: string) {
    this.store.dispatch(
      TableauActions.loadNotesRequest({ propertyIds, dateFrom, dateTo }),
    );
  }

  loadAvailabilities(propertyIds: number[], dateFrom: string, dateTo: string) {
    this.store.dispatch(
      TableauActions.loadAvailabilitiesRequest({
        propertyIds,
        dateFrom,
        dateTo,
      }),
    );
  }

  loadClousures(propertyIds: number[], dateFrom: string, dateTo: string) {
    // this.store.dispatch(
    //   TableauActions.loadClousuresRequest({
    //     propertyIds,
    //     dateFrom,
    //     dateTo,
    //   }),
    // );
  }

  loadActiveChannels(propertyIds: number[]) {
    this.store.dispatch(
      ActiveChannelsStoreActions.loadRequest({
        properties: propertyIds,
        withoutAccommodations: true,
      }),
    );
  }
}
