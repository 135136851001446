import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SubSink } from 'subsink';

import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { FormGetter, IInvoiceLayout } from '../../../../models';

interface Deposit {
  deposit: number;
  invoice_layout_id: number;
  deposit_number: number;
}

type OnChange = (deposit: Deposit) => void;

@Component({
  selector: 'by-reservation-form-deposit',
  templateUrl: './reservation-form-deposit.component.html',
  styleUrls: ['./reservation-form-deposit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormDepositComponent),
      multi: true,
    },
  ],
})
export class ReservationFormDepositComponent
  implements OnChanges, OnDestroy, FormGetter
{
  @Input()
  currency: ICurrency;

  @Input()
  invoiceLayouts: IInvoiceLayout[];

  @Input()
  nextDepositNumber: number;

  @Input()
  nextDepositNumberLoading: number;

  @Input()
  defaultInvoiceLayoutId: number;

  @Input()
  totalPrice: number;

  @Input() isMobile = false;

  @Output()
  loadDepositNextNumber = new EventEmitter<number>();

  form = this.formBuilder.group({
    deposit: [0],
    invoice_layout_id: [null],
    deposit_number: [null],
  });

  onTouched: () => void;

  private subs = new SubSink();

  private depositMaxVAlidator: ValidatorFn;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.subs.add(
      this.form
        .get('invoice_layout_id')
        .valueChanges.subscribe((invoiceLayoutId) => {
          this.loadDepositNextNumber.emit(invoiceLayoutId);
        }),
    );

    this.subs.add(
      this.form.get('deposit').valueChanges.subscribe((deposit) => {
        const control = this.form.get('deposit_number');

        control.clearValidators();

        if (deposit) {
          control.setValidators([Validators.required]);
        }

        control.updateValueAndValidity({ emitEvent: false });
      }),
    );
  }

  currencySymbolFormatter = (value: string) =>
    value && `${this.currency?.symbol || ''} ${value}`;

  currencyParser = (value: string) =>
    value.replace(`${this.currency?.symbol} `, '') || '0';

  ngOnChanges(changes: SimpleChanges) {
    const { defaultInvoiceLayoutId, nextDepositNumber, totalPrice } = changes;

    if (defaultInvoiceLayoutId && this.defaultInvoiceLayoutId) {
      this.setDefaultInvoiceLayout();
    }

    if (nextDepositNumber && this.nextDepositNumber) {
      this.setNextDepositNumber();
    }

    if (totalPrice) {
      this.setTotalPrice();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(deposit: Deposit) {
    this.form.patchValue(deposit, { emitEvent: false });
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(
      this.form.valueChanges.subscribe((value: Deposit) => {
        if (!value.deposit) {
          value = null;
        }

        onChange(value);
      }),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
      return;
    }

    this.form.enable({ emitEvent: false });
  }

  getForms() {
    return [this.form];
  }

  private setDefaultInvoiceLayout() {
    this.form.patchValue({ invoice_layout_id: this.defaultInvoiceLayoutId });
  }

  private setNextDepositNumber() {
    this.form.patchValue({ deposit_number: this.nextDepositNumber });
  }

  private setTotalPrice() {
    const control = this.form.get('deposit');

    if (this.depositMaxVAlidator) {
      control.removeValidators([this.depositMaxVAlidator]);
    }

    this.depositMaxVAlidator = Validators.max(this.totalPrice || 0);

    control.addValidators([this.depositMaxVAlidator]);

    control.updateValueAndValidity({ emitEvent: false });
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
