<ng-container [formGroup]="form">
  <nz-form-item class="by-reservation-form__item">
    <nz-form-label>
      {{ 'status' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
    >
      <nz-select
        [nzDropdownMatchSelectWidth]="false"
        [nzSize]="inputSize"
        [nzPlaceHolder]="
          'placeholder.select' | translate: { param: 'status' | translate }
        "
        formControlName="status"
        (nzBlur)="onTouched()"
      >
        <nz-option
          [nzLabel]="'confirmed' | translate | upperfirst"
          [nzValue]="ReservationStatus.Confirmed"
        ></nz-option>
        <nz-option
          [nzLabel]="'pending' | translate | upperfirst"
          [nzValue]="ReservationStatus.Pending"
        ></nz-option>
        <nz-option
          [nzDisabled]="!showOptionable"
          [nzLabel]="'optionable' | translate | upperfirst"
          [nzValue]="ReservationStatus.Optionable"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="form.value.status === ReservationStatus.Optionable">
    <nz-form-item
      class="by-reservation-form__item by-reservation-form__item--gray"
    >
      <nz-form-label>
        {{ 'expiry' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
      >
        <nz-date-picker
          byDisabledMobileKeyboard
          byDisabledDateFixer
          [nzSize]="inputSize"
          [nzShowToday]="false"
          [nzAllowClear]="false"
          nzFormat="dd/MM/yyyy"
          formControlName="expiration_date"
          [nzDisabledDate]="disableExpirationDate"
          nzPlaceHolder="Start"
        >
        </nz-date-picker>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="by-reservation-form__item">
      <nz-form-label>
        {{ 'availability_optionable' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
      >
        <label nz-checkbox formControlName="availability_option"> </label>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</ng-container>
