<div
  class="modalDraggableMove"
  [style.background-color]="reservationsColors[data?.background_key]"
  style="height: 20px"
></div>
<div
  class="header-container"
  [style.background-color]="reservationsColors[data?.background_key]"
>
  <div class="by-flex">
    <nz-avatar
      *ngIf="data?.user?.facebook; else avatarTemplate"
      class="avatar"
      nzSize="large"
      [nzSrc]="'https://unavatar.vercel.app/facebook/' + data?.user?.facebook"
    ></nz-avatar>

    <by-tableau-modal-header-details
      style="flex: 1"
      [loading]="loading"
      [data]="data"
    ></by-tableau-modal-header-details>

    <ng-container *ngIf="data?.logo">
      <img
        [ngClass]="{
          logo: !data?.logo?.radius,
          'logo--radius': data?.logo?.radius
        }"
        *ngIf="data?.logo?.url; else labelTemplate"
        [src]="data?.logo?.url"
        nz-tooltip
        [nzTooltipTitle]="data?.logo?.label"
      />

      <ng-template #labelTemplate>
        <div
          *ngIf="data?.logo?.is_virtual_channel; else companyLogo"
          class="
            logo--radius
            by-fs-10
            company-name-first-char
            color--white
          "
          nz-tooltip
          [nzTooltipTitle]="data?.logo?.label"
        >
          <span>{{ data?.logo?.label | slice: 0:3 }}</span>
        </div>

        <ng-template #companyLogo>
          <div
            class="logo--radius company-name-first-char color--white"
            nz-tooltip
            [nzTooltipTitle]="data?.logo?.label"
          >
            <span>{{ data?.logo?.label | slice: 0:1 }}</span>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</div>

<by-tableau-modal-header-dates
  [data]="data"
  [loading]="loading"
></by-tableau-modal-header-dates>

<ng-template #avatarTemplate>
  <nz-avatar nzSize="large" class="fal fa-user by-fs-18 avatar"> </nz-avatar>
</ng-template>
