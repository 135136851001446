import {
  Directive,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[byTableauScrollDragErrorCatcher]',
})
export class TableauScrollDragErrorCatcherDirective implements OnDestroy {
  @Output()
  dragScrollError = new EventEmitter();

  private dragging = false;

  @HostListener('cdkDragStarted')
  onDragStarted() {
    this.dragging = true;
  }

  @HostListener('cdkDragDropped')
  onDragDropped() {
    this.dragging = false;
  }

  ngOnDestroy() {
    if (this.dragging) {
      this.dragScrollError.emit();
    }
  }
}
