<form nz-form [formGroup]="form" nzLayout="vertical">
  <by-image-uploader
    [media]="report?.media"
    (deleteImage)="onDeleteMedia($event)"
    (upload)="onUploadMedia($event)"
  ></by-image-uploader>

  <div nz-row nzAlign="middle" nzGutter="20" class="by-mt-2">
    <div nz-col nzXs="24" nzSm="12">
      <nz-form-item>
        <nz-form-label nzFor="status" nzRequired>
          {{ 'status' | translate | upperfirst }}
        </nz-form-label>

        <nz-form-control
          [nzErrorTip]="
            'validation_form.required'
              | translate: { param: 'guest_type_id' | translate }
          "
        >
          <nz-select
            formControlName="status"
            [nzDropdownMatchSelectWidth]="false"
          >
            <nz-option
              *ngFor="let status of states"
              [nzValue]="status"
              [nzLabel]="status | translate | upperfirst"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzXs="24" nzSm="12">
      <nz-form-item>
        <nz-form-label nzFor="title" nzRequired>
          {{ 'title' | translate | upperfirst }}
        </nz-form-label>

        <nz-form-control
          [nzErrorTip]="'validation_form.required' | translate | upperfirst"
        >
          <input
            type="text"
            nz-input
            formControlName="title"
            [placeholder]="'title' | translate | upperfirst"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row nzAlign="middle" nzGutter="20">
    <div nz-col nzXs="24" nzSm="24">
      <nz-form-item>
        <nz-form-label nzFor="description" nzRequired>
          {{ 'description' | translate | upperfirst }}
        </nz-form-label>

        <nz-form-control
          [nzErrorTip]="'validation_form.required' | translate | upperfirst"
        >
          <textarea nz-input formControlName="description" rows="5"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
