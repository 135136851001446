import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { TokenService } from '@app/core/services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { get, trim } from 'lodash';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AttachmentViewerComponent } from '../components/attachment-viewer/attachment-viewer.component';
import { AttachmentViewerService } from '../components/attachment-viewer/attachment-viewer.service';
import { getBase64 } from '../core/helpers/get-base-64';
import { generateSearchQuery } from '../core/helpers/params-generator';
import {
  emitMobileAppEvent,
  handleBlobResponseError,
  openInNewTab,
  removeNullishValues,
  StringUrl,
} from '../helpers';
import {
  ExportActionType,
  ExportFormat,
  HttpExportData,
  InvoiceCashOut,
  InvoiceSuffixPrint,
  PrintInvoiceParameters,
} from '../models';
import { FileSaverService } from './file-saver.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  invoiceSuffixPrintMap: InvoiceSuffixPrint = {
    printA5: '/view_pdf?type=double_copy',
    printA4: '/view_pdf?type=single_copy',
    download_pdfA5: '/download_pdf?type=double_copy',
    download_pdfA4: '/download_pdf?type=single_copy',
  };

  attachemntViewerComponent: AttachmentViewerComponent;

  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private attachmentViewer: AttachmentViewerService,
    private fileSaver: FileSaverService,
  ) {}

  export(
    link: string,
    exportFormat: ExportFormat,
    action: ExportActionType,
    fileName?: string,
  ) {
    this.open({
      url: this.createlink(link, exportFormat, action),
      fileName,
    });
  }

  private createlink(
    link: string,
    format: ExportFormat,
    action: ExportActionType,
  ) {
    link = environment.apiUrl + link;

    const stringUrl = new StringUrl(link);

    const today = moment(new Date()).format('YYYY-MM-DD');

    stringUrl.setParam('export', '1');
    stringUrl.setParam('export_format', format);
    stringUrl.setParam('current_date', today);

    if (action === 'print') {
      stringUrl.setParam('export_preview', '1');
    }

    if (action === 'export') {
      stringUrl.setParam('download_pdf', '1');
    }

    return stringUrl.toString();
  }

  printInvoice({
    invoice_id,
    download,
    paper_format,
    invoiceCashOut,
    temporary,
    ...params
  }: PrintInvoiceParameters) {
    const invoiceCashOutParams = this.getInvoiceCashOutParams(invoiceCashOut);

    const otherParams = generateSearchQuery(
      removeNullishValues({ ...params, paper_format }),
    );

    const prefix = temporary ? 'invoice/temporary' : 'invoices';

    const action = download ? 'download_pdf' : 'view_pdf';

    const query = `${
      otherParams ? otherParams + '&' : ''
    }show_groups=0${invoiceCashOutParams}`;

    const url = `${prefix}/${invoice_id}/${action}?${query}`;

    this.directExport(url, params?.title);
  }

  directExport(link: string, fileName: string = null) {
    if (!link.includes(environment.apiUrl) && !this.isStorageLink(link)) {
      link = environment.apiUrl + link;
    }

    const stringUrl = new StringUrl(link);

    if (!this.isStorageLink(link)) {
      stringUrl.setParam(
        'current_date',
        moment(new Date()).format('YYYY-MM-DD'),
      );

      stringUrl.setParam(
        'reference_date',
        moment(new Date()).format('YYYY-MM-DD'),
      );
    }
 
    this.open({ url: stringUrl.toString(), fileName });
  }

  httpExport(data: HttpExportData): Observable<any> {
    if (!this.attachmentViewer.getComponentInstance) {
      this.attachmentViewer.open({
        loading: true,
      });
    }
    const { errorHandlerService, link, payload } = data;

    return this.http.post(link, payload).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (!errorHandlerService) {
          this.errorHandler.handle(error);
        } else {
          errorHandlerService.handle(error);
        }
        this.attachmentViewer.getComponentInstance.error = error;
        this.attachmentViewer.getComponentInstance.loading = false;
        return of(error);
      }),
    );
  }

  blobExport(
    data: HttpExportData,
    fileName?: string,
    open = true,
  ): Observable<any> {
    const { errorHandlerService, link, payload } = data;

    return this.http.post(link, payload, { responseType: 'blob' }).pipe(
      map((response: any) => {
        if (open) {
          const url = window.URL.createObjectURL(response);
          this.open({ url, fileName });
        }
        return response;
      }),
      catchError((error: any) => {
        if (!errorHandlerService) {
          this.errorHandler.handle(error);
        } else {
          errorHandlerService.handle(error);
        }
        return of(error);
      }),
    );
  }

  httpGetExport(
    link: string,
    format: ExportFormat,
    action: ExportActionType,
  ): Observable<any> {
    return this.http
      .get(
        this.createlink(link, format, action).replace(environment.apiUrl, ''),
      )
      .pipe(
        catchError((error: any) => {
          this.errorHandler.handle(error);
          return of(error);
        }),
      );
  }

  private isStorageLink(link: string): boolean {
    return link.includes('amazonaws.com');
  }

  private isBlobLink(link: string): boolean {
    return link.includes('blob');
  }

  private open(data: { url: string; fileName?: string }) {
    const { url, fileName } = data;
    this.tokenService
      .getOrRefreshToken()
      .toPromise()
      .then((token) => {
        if (this.isStorageLink(url) || this.isBlobLink(url)) {
          this.openingMethods({ url, fileName });
          return;
        }

        const stringUrl = new StringUrl(url);

        if (window.location.host.includes('localhost')) {
          stringUrl.setParam('token', token);
        }

        stringUrl.setParam('lang', this.translate.currentLang);

        this.openingMethods({
          url: stringUrl.toString(),
          fileName,
          token,
        });
      });
  }

  private getInvoiceCashOutParams(invoiceCashOut: InvoiceCashOut): string {
    if (!invoiceCashOut) {
      return '';
    }

    const reservationPayments = JSON.stringify(
      invoiceCashOut.reservation_payments || [],
    );
    const paymentMethods = JSON.stringify(invoiceCashOut.payment_methods || []);
    const reservationAdvances = JSON.stringify(
      invoiceCashOut.reservation_advances || [],
    );

    return `&reservation_payments=${reservationPayments}&payment_methods=${paymentMethods}&reservation_advances=${reservationAdvances}`;
  }

  private openingMethods(data: {
    url: string;
    fileName: string;
    token?: string;
  }): void {
    let attachemntViewerComponent = this.attachmentViewer.getComponentInstance;

    if (this.attachmentViewer) {
      this.attachmentViewer.printData = {
        src: data.url,
        fileName: data.fileName,
      };
    }

    if (!attachemntViewerComponent) {
      this.attachmentViewer.open({
        loading: true,
      });
      attachemntViewerComponent = this.attachmentViewer.getComponentInstance;
    }

    let options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe: 'response';
      responseType: 'blob';
    } = {
      observe: 'response',
      responseType: 'blob',
    };
    if (data.token) {
      options = {
        ...options,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      };
    }

    this.http
      .get(data.url, options)
      .pipe(
        handleBlobResponseError<HttpResponse<Blob>>(),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handle(error);

          attachemntViewerComponent.loading = false;

          attachemntViewerComponent.error = error;

          return throwError(error);
        }),
      )
      .subscribe((response) => {
        const contentType = response.headers.get('Content-Type');
        const contentDisposition =
          response.headers.get('Content-Disposition') || '';

        const fileName =
          data.fileName ||
          trim(
            get(contentDisposition.split('filename='), [1], '').split(';')[0],
            '"',
          );

        if (contentType?.includes('pdf') && !contentDisposition) {
          const file = new File([response.body], fileName, {
            type: contentType,
          });
          this.attachmentViewer.view(file);
        } else {
          const blob = new Blob([response.body], { type: contentType });

          const urlObject = window.URL.createObjectURL(blob);

          getBase64(blob, (base64) => {
            const payload = JSON.stringify({ base64, name: fileName });

            if (!emitMobileAppEvent('saveAndOpenFile', payload)) {
              openInNewTab(urlObject, fileName);
            }

            this.attachmentViewer.close();
          });
        }
      });
  }
}
