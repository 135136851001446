import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'sameDay',
})
export class SameDayPipe implements PipeTransform {
  constructor() {}

  transform(date1: string | Date, date2: string | Date): boolean {
    if (!date1 || !date2) {
      return false;
    }

    return moment(date1).isSame(moment(date2), 'days');
  }
}
