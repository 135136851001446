import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import { StatsWidgetResponse } from '../../models';
import { ChartLineItem } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

const getSeries = (data: StatsWidgetResponse): ChartLineItem[] => {
  return data.map((value) => ({
    name: moment(new Date(value.key)).format('DD.MM'),
    value: value.collected.total_price,
    extra: { date: new Date(value.key) },
  }));
};

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    lastYear: null,
  })),
  on(fromActions.loadSuccess, (state, { revenueData }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      currentYear: getSeries(revenueData),
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadLastYearRequest, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadLastYearSuccess, (state, { revenueData }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      lastYear: [
        { name: 'last_year', visible: true, series: getSeries(revenueData) },
      ],
    };
  }),
  on(fromActions.loadLastYearFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.toggleLastYear, (state, { visible }) => ({
    ...state,
    lastYear: state.lastYear?.map((line) => {
      return { ...line, visible };
    }),
  })),

  on(fromActions.setLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function revenueChartWidgetReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
