import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class RateplansService {
  constructor(private http: HttpClient) {}

  load(properties: number[], withTrashed = false) {
    return this.http.get(
      `rateplans?${generateParamArray(
        'property_id',
        properties,
      )}&with_trashed=${+withTrashed}`,
    );
  }

  loadRateplansAccommodation(
    data: {
      accommodation_id: number;
      reservation_accommodation_room_id?: number;
    },
    without_expired = 1,
  ) {
    return this.http.get(
      `accommodation/${
        data.accommodation_id
      }/rateplans?without_expired=${without_expired}&${generateSearchQuery({
        reservation_accommodation_room_id:
          data?.reservation_accommodation_room_id,
      })}`,
    );
  }

  loadRateplansLookup(propertiesIds: number[]) {
    return this.http.get(
      `rateplans/lookup?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  loadAllAccommodationsRateplans(properties: number[]) {
    return this.http.get(
      `properties/accommodations_rateplans?${generateParamArray(
        'property_id',
        properties,
      )}`,
    );
  }

  delete(rateplanId: number) {
    return this.http.delete(`rateplans/${rateplanId}`);
  }
}
