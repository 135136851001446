import { Directive, HostListener, Input, QueryList } from '@angular/core';
import { Store } from '@ngrx/store';

import { TableauRoom, TableauRow } from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { TableauSelectionService } from '../services/tableau-selection.service';

@Directive({
  selector: '[byTableauSelectionableCell]',
})
export class TableauSelectionableCellDirective {
  @Input()
  row: TableauRow<TableauRoom>;

  @Input()
  date: Date;

  @Input()
  cellDirectives: QueryList<TableauSelectionableCellDirective>;

  @Input()
  selectionDisabled: boolean;

  constructor(
    private store: Store<RootState>,
    private tableauSelectionService: TableauSelectionService,
  ) {}

  onEndSelection = ({ from, to }) => {
    this.store.dispatch(
      TableauActions.openActionsModal({
        row: this.row,
        dateFrom: from,
        dateTo: to,
      }),
    );
  };

  @HostListener('mousedown')
  onMouseDown() {
    if (this.selectionDisabled) {
      return;
    }

    this.tableauSelectionService.start(
      this,
      this.cellDirectives.toArray(),
      this.onEndSelection,
    );
  }

  @HostListener('mouseenter')
  onMouseOver() {
    this.tableauSelectionService.update(this);
  }
}
