import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[byToggleButton]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonDirective),
      multi: true,
    },
  ],
})
export class ToggleButtonDirective implements ControlValueAccessor {
  @Input()
  activeClass = 'by-toggle-button--active';

  private active: boolean;

  private hostNativeElement: HTMLElement;

  private onChange: (active: boolean) => void;
  private onTouch: () => void;

  constructor(hostElementRef: ElementRef) {
    this.hostNativeElement = hostElementRef.nativeElement;
  }

  @HostListener('click')
  onClick() {
    this.onTouch();
    this.onToggleActive(!this.active);

    this.onChange(this.active);
  }

  writeValue(active: boolean) {
    this.onToggleActive(active);
  }

  registerOnChange(onChange: (active: boolean) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: () => void) {
    this.onTouch = onTouch;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.hostNativeElement.setAttribute('disabled', 'true');
    } else {
      this.hostNativeElement.removeAttribute('disabled');
    }
  }

  private onToggleActive(active: boolean) {
    this.active = active;

    if (active) {
      this.hostNativeElement.classList.add(this.activeClass);
    } else {
      this.hostNativeElement.classList.remove(this.activeClass);
    }
  }
}
