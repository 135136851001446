import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { first } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { AvailableRoom, Dictionary, TableauRow } from '../../../../models';
import {
  AvailableRoomsStoreActions,
  AvailableRoomsStoreSelectors,
} from '../../../../root-store/available-rooms-store';
import { RootState } from '../../../../root-store/root-state';
import { ModalDragService } from '../../../../services/modal-drag.service';

export interface PropertyOption {
  id: number;
  name: string;
  accommodations: AccommodationOption[];
}

interface AccommodationOption {
  id: number;
  name: string;
  tableauNumbers: TableauNumberOption[];
}

interface TableauNumberOption {
  id: number;
  name: string;
  row: TableauRow;
  disabled: boolean;
}

@Component({
  selector: 'by-tableau-destination-row-selection-modal',
  templateUrl: './tableau-destination-row-selection-modal.component.html',
  styleUrls: ['./tableau-destination-row-selection-modal.component.scss'],
  providers: [ModalDragService],
})
export class TableauDestinationRowSelectionModalComponent
  implements OnInit, OnDestroy
{
  readonly nzModalData: Partial<TableauDestinationRowSelectionModalComponent> =
    inject(NZ_MODAL_DATA);

  propertyOptions: PropertyOption[] = this.nzModalData.propertyOptions || [];

  range: [string, string] = this.nzModalData.range;

  guestFullName: string = this.nzModalData.guestFullName;

  reservationsOnSwap: number[] = this.nzModalData.reservationsOnSwap || [];

  availableRooms: Dictionary<AvailableRoom> = {};

  form = this.formBuilder.group({
    property_id: [null],
    accommodation_id: [null],
    tableau_number_id: [null, [Validators.required]],
  });

  selectedProperty: PropertyOption;

  selectedAccommodation: AccommodationOption;

  selectedTableauNumber: TableauNumberOption;

  private subs = new SubSink();

  constructor(
    private modalRef: NzModalRef,
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private modalDragService: ModalDragService,
  ) {
    this.modalDragService.handle(this.modalRef);
  }

  ngOnInit() {
    this.subs.add(
      this.form.valueChanges.subscribe(
        ({ property_id, accommodation_id, tableau_number_id }) => {
          this.selectedProperty = this.propertyOptions.find(
            ({ id }) => id === property_id,
          );

          const selectedAccommodation =
            this.selectedProperty?.accommodations?.find(
              ({ id }) => id === accommodation_id,
            ) || first(this.selectedProperty?.accommodations);

          const accommodationChanged =
            selectedAccommodation?.id !== this.selectedAccommodation?.id;

          this.selectedAccommodation = selectedAccommodation;

          if (accommodationChanged) {
            this.loadAvailableRooms();
          }

          this.selectedTableauNumber =
            this.selectedAccommodation?.tableauNumbers?.find(
              ({ id }) => id === tableau_number_id,
            );

          this.form.patchValue(
            {
              property_id: this.selectedProperty?.id,
              accommodation_id: this.selectedAccommodation?.id,
              tableau_number_id: this.selectedTableauNumber?.id,
            },
            { emitEvent: false },
          );
        },
      ),
    );

    this.subs.add(
      this.store
        .pipe(select(AvailableRoomsStoreSelectors.selectEntities))
        .subscribe((availableRooms) => {
          this.availableRooms = availableRooms || {};
        }),
    );

    this.form.patchValue({
      property_id: this.propertyOptions[0].id,
      accommodation_id: this.propertyOptions[0].accommodations[0].id,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(AvailableRoomsStoreActions.resetState());
  }

  onCloseModal() {
    this.modalRef.close();
  }

  onSubmit() {
    this.modalRef.close(this.selectedTableauNumber.row);
  }

  private loadAvailableRooms() {
    const [date_from, date_to] = this.range;

    this.store.dispatch(
      AvailableRoomsStoreActions.loadRequest({
        accommodation_id: this.selectedAccommodation.id,
        property_id: this.selectedProperty.id,
        date_from,
        date_to,
        reservations_on_swap: this.reservationsOnSwap,
      }),
    );
  }
}
