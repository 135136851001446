<td nzLeft="0px" class="by-tableau-cell--first by-tableau-cell--{{ data.key }}">
  {{ data.key | translate | upperfirst }}
</td>

<td
  *ngFor="let day of days"
  class="by-tableau-cell by-tableau-cell--{{ data.key }}"
  byTableauCellHoverDispatcher
  [date]="day"
  [row]="row"
>
  {{ items | get: [day | formatDate: 'YYYY-MM-DD', 'data', data.key] }}
</td>
