import { Injectable } from '@angular/core';
import { createReducer, on } from '@ngrx/store';
import {
  get,
  keyBy,
  mapKeys,
  mapValues,
  mergeWith,
  min,
  pick,
  uniq,
} from 'lodash';
import * as moment from 'moment';

import { TableauConfig } from '../../features/commons/tableau-2/config';
import {
  TableauBoxComponents,
  TableauRowComponents,
} from '../../features/commons/tableau-2/tableau-components-map';
import {
  LoadReservationAccommodationDetailsRequest,
  SetReservationCheckinAction,
  SetReservationTagActionRequest,
  SetTableauNumber,
  TableauAvailabilities,
  TableauAvailability,
  TableauHousekeeperInformation,
  TableauMappingAccommodationDetails,
  TableauMappingAccommodationTableauNumbers,
  TableauNote,
  TableauReservation,
  TableauReservationAccommodationDetails,
  TableauReservationsMappingAccommodation,
  TableauRoom,
  TableauRow,
  TableauSwappedReservation,
} from '../../models';
import { TableauRowsService } from '../../services/tableau-rows.service';

import * as fromActions from './actions';
import { featureAdapter, initialState, State, StateResources } from './state';

enum LoadingSlugs {
  Build = 'build',
  Mapping = 'mapping',
  Floors = 'floors',
  Events = 'events',
  Reservations = 'reservations',
  Housekeeper = 'housekeeper',
  Quotes = 'quotes',
  Notes = 'notes',
  Availabilities = 'availabilities',
  Clousures = 'clousures',
  Moving = 'moving',
}

@Injectable()
export class TableauStoreReducer {
  constructor(private tableauRowsService: TableauRowsService) {}

  createReducer() {
    return createReducer(
      initialState,

      /**
       * Build
       */

      on(fromActions.buildRowsRequest, (state, { skipLoading }) => ({
        ...state,
        loadings: skipLoading
          ? state.loadings
          : this.addLoading(state.loadings, LoadingSlugs.Build),
      })),
      on(fromActions.buildRowsSuccess, (state, { rows }) =>
        featureAdapter.setAll(rows, {
          ...state,
          loadings: this.removeLoading(state.loadings, LoadingSlugs.Build),
        }),
      ),

      /**
       * Mapping
       */

      on(fromActions.loadMappingRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Mapping),
        error: null,
      })),
      on(fromActions.loadMappingFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Mapping),
        error,
      })),
      on(fromActions.loadMappingSuccess, (state, { mapping }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Mapping),
        mapping,
      })),

      /**
       * Floors
       */

      on(fromActions.loadFloorsRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Floors),
        error: null,
      })),
      on(fromActions.loadFloorsFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Floors),
        error,
      })),
      on(fromActions.loadFloorsSuccess, (state, { floors }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Floors),
        floors,
      })),

      /**
       * Events
       */

      on(fromActions.loadEventsRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Events),
        error: null,
      })),
      on(fromActions.loadEventsFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Events),
        error,
      })),
      on(fromActions.loadEventsSuccess, (state, { events }) => ({
        ...this.setResources(state, { events }),
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Events),
      })),

      /**
       * Reservations
       */

      on(fromActions.loadReservationsRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Reservations),
        error: null,
      })),
      on(fromActions.loadReservationsFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Reservations),
        error,
      })),
      on(fromActions.loadReservationsSuccess, (state, { reservations }) => {
        return {
          ...this.setResources(state, {
            reservations: mergeWith(
              {},
              state.resources.reservations,
              reservations,
              (
                a: TableauReservationsMappingAccommodation,
                b: TableauReservationsMappingAccommodation,
              ) => {
                return {
                  ...a,
                  ...mapValues(b, (res, tableauNumberId) => ({
                    ...a?.[tableauNumberId],
                    ...keyBy(res, 'reservation_accommodation_room_id'),
                  })),
                };
              },
            ),
          }),
          loadings: this.removeLoading(
            state.loadings,
            LoadingSlugs.Reservations,
          ),
        };
      }),

      /**
       * Housekeeper Informations
       */

      on(fromActions.loadHousekeeperInformationsRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Housekeeper),
        error: null,
      })),
      on(
        fromActions.loadHousekeeperInformationsFailure,
        (state, { error }) => ({
          ...state,
          loadings: this.removeLoading(
            state.loadings,
            LoadingSlugs.Housekeeper,
          ),
          error,
        }),
      ),
      on(
        fromActions.loadHousekeeperInformationsSuccess,
        (state, { housekeeperInformations }) => ({
          ...this.setResources(state, {
            housekeeper: {
              ...state.resources.housekeeper,
              ...housekeeperInformations,
            },
          }),
          loadings: this.removeLoading(
            state.loadings,
            LoadingSlugs.Housekeeper,
          ),
        }),
      ),

      /**
       * Quotes
       */

      on(fromActions.loadQuotesRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Quotes),
        error: null,
      })),
      on(fromActions.loadQuotesFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Quotes),
        error,
      })),
      on(fromActions.loadQuotesSuccess, (state, { quotes }) => ({
        ...this.setResources(state, {
          quotes: { ...state.resources.quotes, ...quotes },
        }),
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Quotes),
      })),

      /**
       * Notes
       */

      on(fromActions.loadNotesRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Notes),
        error: null,
      })),
      on(fromActions.loadNotesFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Notes),
        error,
      })),
      on(fromActions.loadNotesSuccess, (state, { notes }) => ({
        ...this.setResources(state, {
          notes: { ...state.resources.notes, ...notes },
        }),
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Notes),
      })),

      on(fromActions.createNoteSuccess, (state, { note }) => {
        return this.buildAndGetState(this.upsertNote(state, note));
      }),
      on(fromActions.createNoteFailure, (state, { error }) => ({
        ...state,
        error,
      })),

      on(fromActions.updateNoteSuccess, (state, { note }) => {
        return this.buildAndGetState(this.upsertNote(state, note));
      }),
      on(fromActions.updateNoteFailure, (state, { error }) => ({
        ...state,
        error,
      })),

      on(fromActions.deleteNoteSuccess, (state, { noteId }) => {
        return this.buildAndGetState(
          this.notesResourceMapper(state, (roomNotes) =>
            roomNotes.filter(({ id }) => id !== noteId),
          ),
        );
      }),
      on(fromActions.deleteNoteFailure, (state, { error }) => ({
        ...state,
        error,
      })),

      /**
       * Availabilities
       */

      on(fromActions.loadAvailabilitiesRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Availabilities),
        error: null,
      })),
      on(fromActions.loadAvailabilitiesFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(
          state.loadings,
          LoadingSlugs.Availabilities,
        ),
        error,
      })),
      on(
        fromActions.loadAvailabilitiesSuccess,
        (state, { availabilities }) => ({
          ...this.setResources(state, {
            availabilities: this.mergeAvailabilities(
              state.resources.availabilities,
              availabilities,
            ),
          }),
          loadings: this.removeLoading(
            state.loadings,
            LoadingSlugs.Availabilities,
          ),
        }),
      ),

      /**
       * Clousures
       */

      on(fromActions.loadClousuresRequest, (state) => ({
        ...state,
        loadings: this.addLoading(state.loadings, LoadingSlugs.Clousures),
        error: null,
      })),
      on(fromActions.loadClousuresFailure, (state, { error }) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Clousures),
        error,
      })),
      on(fromActions.loadClousuresSuccess, (state, { clousures }) => ({
        ...this.setResources(state, {
          clousures: { ...state.resources.clousures, ...clousures },
        }),
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Clousures),
      })),

      /**
       * Collapse/Expand Accommodation
       */
      on(
        fromActions.toggleAccommodationExpand,
        (state, { accommodationId, expand }) =>
          this.buildAndGetState({
            ...state,
            collapsedAccommodations: {
              ...state.collapsedAccommodations,
              [accommodationId]: !expand,
            },
          }),
      ),

      /**
       * Collapse/Expand All Accommodations
       */
      on(fromActions.toggleAllAccommodationsExpand, (state, { expand }) => {
        const allAccommodationsIds = Object.values(state.entities)
          .filter(
            ({ component }) => component === TableauRowComponents.Accommodation,
          )
          .map(({ data }: { data: TableauMappingAccommodationDetails }) => {
            return data.id;
          });

        return this.buildAndGetState({
          ...state,
          collapsedAccommodations: allAccommodationsIds.reduce(
            (collapsedAccommodations, accommodationId) => ({
              ...collapsedAccommodations,
              [accommodationId]: !expand,
            }),
            {},
          ),
        });
      }),

      /**
       * Move Reservation
       */
      on(
        fromActions.moveReservationRequest,
        (state, { reservation, destinationRow, sourceRow }) => {
          return featureAdapter.updateMany(
            [
              {
                id: sourceRow.id,
                changes: {
                  items: {
                    ...sourceRow.items,
                    [reservation.dateIndex]: null,
                  },
                },
              },
              {
                id: destinationRow.id,
                changes: {
                  items: {
                    ...destinationRow.items,
                    [reservation.dateIndex]: {
                      component: TableauBoxComponents.Reservation,
                      data: reservation,
                    },
                  },
                },
              },
            ],
            {
              ...state,
              loadings: this.addLoading(state.loadings, LoadingSlugs.Moving),
            },
          );
        },
      ),
      on(fromActions.moveReservationSuccess, (state, { reservation }) => ({
        ...state,
        swappedReservations: this.removeSwappedReservation(reservation, state),
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Moving),
      })),
      on(fromActions.moveReservationWarning, (state) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Moving),
      })),
      on(fromActions.moveReservationFailure, (state, { error }) =>
        this.buildAndGetState({
          ...state,
          loadings: this.removeLoading(state.loadings, LoadingSlugs.Moving),
          error,
        }),
      ),

      /**
       * Move Quote
       */
      on(
        fromActions.moveQuoteRequest,
        (state, { quote, destinationRow, sourceRow }) => {
          return featureAdapter.updateMany(
            [
              {
                id: sourceRow.id,
                changes: {
                  items: {
                    ...sourceRow.items,
                    [quote.dateIndex]: null,
                  },
                },
              },
              {
                id: destinationRow.id,
                changes: {
                  items: {
                    ...destinationRow.items,
                    [quote.dateIndex]: {
                      component: TableauBoxComponents.Quote,
                      data: quote,
                    },
                  },
                },
              },
            ],
            {
              ...state,
              loadings: this.addLoading(state.loadings, LoadingSlugs.Moving),
            },
          );
        },
      ),
      on(fromActions.moveQuoteSuccess, (state) => ({
        ...state,
        loadings: this.removeLoading(state.loadings, LoadingSlugs.Moving),
      })),
      on(fromActions.moveQuoteFailure, (state, { error }) =>
        this.buildAndGetState({
          ...state,
          loadings: this.removeLoading(state.loadings, LoadingSlugs.Moving),
          error,
        }),
      ),

      /**
       * Load Reservation Accommodation Details
       */
      on(fromActions.loadReservationAccommodationDetailsRequest, (state) => {
        return {
          ...state,
        };
      }),
      on(
        fromActions.loadReservationAccommodationDetailsSuccess,
        (state, { request, details }) =>
          this.buildAndGetState(
            this.reservationsResourceMapper(
              {
                ...state,
              },
              this.setReservationAccommodationDetails(request, details),
            ),
          ),
      ),

      /**
       * Move Reservation from/to swap
       */
      on(fromActions.addReservationToSwap, (state, { swappedReservation }) => {
        const { reservation, sourceRow } = swappedReservation;
        return featureAdapter.updateOne(
          {
            id: sourceRow.id,
            changes: {
              items: {
                ...sourceRow.items,
                [reservation.dateIndex]: null,
              },
            },
          },
          {
            ...state,
            swappedReservations: [
              ...state.swappedReservations,
              swappedReservation,
            ],
          },
        );
      }),
      on(
        fromActions.removeReservationFromSwap,
        (state, { swappedReservation }) => {
          const { reservation } = swappedReservation;

          const swappedReservations = this.removeSwappedReservation(
            reservation,
            state,
          );

          return this.buildAndGetState({
            ...state,
            swappedReservations,
          });
        },
      ),
      on(fromActions.resetSwap, (state) => {
        return this.buildAndGetState({
          ...state,
          swappedReservations: initialState.swappedReservations,
        });
      }),
      on(
        fromActions.moveReservationFromSwapRequest,
        (state, { reservation, destinationRow }) => {
          return featureAdapter.updateOne(
            {
              id: destinationRow.id,
              changes: {
                items: {
                  ...destinationRow.items,
                  [reservation.dateIndex]: {
                    component: TableauBoxComponents.Reservation,
                    data: reservation,
                  },
                },
              },
            },
            {
              ...state,
              loadings: this.addLoading(state.loadings, LoadingSlugs.Moving),
            },
          );
        },
      ),

      on(fromActions.setPeriod, (state, { start }) => {
        const period = this.getPeriod(start);

        return {
          ...state,
          period,
          swappedReservations: initialState.swappedReservations,
          days: this.getDaysFromPeriod(period),
        };
      }),

      on(fromActions.setPreferences, (state, { preferences, tags }) => {
        const { viewOptions, viewMode, zoom, ...colors } = preferences;

        const reservationsColors = {
          ...state.reservationsColors,
          ...mapKeys(colors, (_, key) => {
            return key.replace('-color', '');
          }),
        };

        let tag_id = viewOptions?.tag_id;

        if (tags) {
          tag_id = tags.some((tag) => tag.id === tag_id) ? tag_id : 0;
        }

        return this.buildAndGetState({
          ...state,
          reservationsColors,
          viewMode: viewMode || state.viewMode,
          zoom: zoom || state.zoom,
          viewOptions: {
            ...state.viewOptions,
            ...viewOptions,
            tag_id,
          },
        });
      }),

      on(fromActions.setPropertiesOrder, (state, { sortedProperties }) => ({
        ...state,
        sortedProperties,
      })),

      on(fromActions.setLoadedProperties, (state, { loadedProperties }) => ({
        ...state,
        loadedProperties: uniq([
          ...state.loadedProperties,
          ...loadedProperties,
        ]),
      })),

      on(
        fromActions.splitReservation,
        (state, { rowId, splittedReservation }) =>
          featureAdapter.updateOne(
            {
              id: rowId,
              changes: {
                items: {
                  ...state.entities[rowId].items,
                  ...splittedReservation.reduce(
                    (items, reservation) => ({
                      ...items,
                      [reservation.dateIndex]: {
                        component: TableauBoxComponents.Reservation,
                        data: reservation,
                      },
                    }),
                    {},
                  ),
                },
              },
            },
            { ...state, splitMode: false },
          ),
      ),

      on(fromActions.resetLoadedProperties, (state) => ({
        ...state,
        loadedProperties: initialState.loadedProperties,
        filteredAccommodations: initialState.filteredAccommodations,
      })),

      on(fromActions.resetLoadedResources, (state, { resources }) => ({
        ...state,
        resources: {
          ...state.resources,
          ...(resources?.length
            ? pick(initialState.resources, resources)
            : initialState.resources),
        },
      })),

      on(fromActions.setViewMode, (state) => ({
        ...state,
        collapsedAccommodations: initialState.collapsedAccommodations,
      })),

      on(fromActions.toggleSplitMode, (state, { splitMode }) => ({
        ...state,
        splitMode,
      })),
      on(fromActions.setReservationTagSuccess, (state, params) => {
        return this.buildAndGetState(
          this.reservationsResourceMapper(
            state,
            this.setReservationTag(params),
          ),
        );
      }),
      on(fromActions.setCheckinStatus, (state, params) => {
        return this.buildAndGetState(
          this.reservationsResourceMapper(state, this.setCheckinStatus(params)),
        );
      }),
      on(fromActions.setRoomCleanStatusSuccess, (state, { affected }) => {
        const { housekeeper } = state.resources;

        return this.buildAndGetState({
          ...state,
          resources: {
            ...state.resources,
            housekeeper: {
              ...housekeeper,
              ...mapValues(affected, (value, tableauNumberId) => {
                return {
                  ...housekeeper[tableauNumberId],
                  ...value,
                };
              }),
            },
          },
        });
      }),

      on(fromActions.setRoomDetailsSuccess, (state, params) => {
        return this.buildAndGetState(
          this.commonsTableauNumbersFinder(state, params, (tableauNumber) => ({
            ...tableauNumber,
            label: params.label,
          })),
        );
      }),

      on(
        fromActions.setRoomMaintenanceStatus,
        (state, { row, under_maintenance }) => {
          const changes: Partial<TableauRow<Partial<TableauRoom>>> = {
            data: {
              ...row.data,
              housekeeper: {
                ...row.data.housekeeper,
                pending_maintainer_issues_number: +under_maintenance,
              },
            },
          };

          return featureAdapter.updateOne(
            {
              id: row.id,
              changes,
            },
            this.updateHousekeeperTableauNumber(state, row.data.id, {
              pending_maintainer_issues_number: +under_maintenance,
            }),
          );
        },
      ),

      on(
        fromActions.setFilteredAccommodations,
        (state, { filteredAccommodations }) => {
          return this.buildAndGetState({
            ...state,
            filteredAccommodations: filteredAccommodations.reduce(
              (map, accommodationId) => {
                map = { ...map, [accommodationId]: true };
                return map;
              },
              {},
            ),
          });
        },
      ),

      on(fromActions.exportRequest, (state) => {
        return {
          ...state,
          loadedProperties: state.sortedProperties,
          exportLoading: true,
        };
      }),
      on(fromActions.exportSuccess, (state) => {
        return {
          ...state,
          exportLoading: false,
        };
      }),

      on(fromActions.resetState, () => ({
        ...initialState,
      })),
    );
  }

  private updateHousekeeperTableauNumber(
    state: State,
    tableauNumberId: number,
    information: Partial<TableauHousekeeperInformation>,
  ): State {
    return {
      ...state,
      resources: {
        ...state.resources,
        housekeeper: {
          ...state.resources.housekeeper,
          [tableauNumberId]: {
            ...state.resources.housekeeper[tableauNumberId],
            ...information,
          },
        },
      },
    };
  }

  private commonsTableauNumbersFinder = (
    state: State,
    data: SetTableauNumber,
    parser: (
      tableauNumber: TableauMappingAccommodationTableauNumbers,
    ) => TableauMappingAccommodationTableauNumbers,
  ): State => {
    const { propertyId, rowId } = data;

    const {
      data: { common_id },
    } = state.entities[rowId] as TableauRow<TableauRoom>;

    return {
      ...state,
      mapping: {
        ...state.mapping,
        [propertyId]: state.mapping[propertyId].map((mappingAccommodation) => {
          const { accommodation_details, tableau_numbers } =
            mappingAccommodation;

          let isSum =
            accommodation_details.is_sum || accommodation_details.is_sum_parent;

          return {
            ...mappingAccommodation,
            tableau_numbers: tableau_numbers.map((tableauNumber) => {
              if (
                common_id !== tableauNumber.common_id ||
                (isSum && accommodation_details.id !== data.accommodationId)
              ) {
                return tableauNumber;
              }

              return parser(tableauNumber);
            }),
          };
        }),
      },
    };
  };

  private setReservationTag =
    (params: SetReservationTagActionRequest) =>
    (reservations: TableauReservation[]): TableauReservation[] => {
      const { reservation_id, tag } = params;

      return reservations.map((reservation) => {
        if (reservation.reservation_id !== reservation_id) {
          return reservation;
        }
        return {
          ...reservation,
          tag,
        };
      });
    };

  private setCheckinStatus =
    (params: SetReservationCheckinAction) =>
    (reservations: TableauReservation[]): TableauReservation[] => {
      return reservations.map((reservation) => {
        if (reservation.roomreservation_id === params.roomreservation_id) {
          return {
            ...reservation,
            checkin: params.checkinStatus.checkin,
            checkout: params.checkinStatus.checkout,
          };
        }

        return reservation;
      });
    };

  private setReservationAccommodationDetails =
    (
      request: LoadReservationAccommodationDetailsRequest,
      details: TableauReservationAccommodationDetails,
    ) =>
    (reservations: TableauReservation[]): TableauReservation[] => {
      return reservations.map((reservation) => {
        if (
          reservation.reservation_accommodation_id ===
          request.reservationAccommodationId
        ) {
          return {
            ...reservation,
            ...details,
            hasReservationAccommodationDetails: true,
          };
        }

        return reservation;
      });
    };

  private addLoading(loadings: string[], slug: string) {
    return [...loadings, slug];
  }

  private removeLoading(loadings: string[], slug: string) {
    return loadings.filter((loading) => loading !== slug);
  }

  private setResources(
    state: State,
    resources: Partial<StateResources>,
  ): State {
    return {
      ...state,
      resources: { ...state.resources, ...resources },
    };
  }

  private getPeriod(from: Date) {
    const cellsInView = this.calculateCellsInView();

    return {
      from,
      to: moment(from).add(cellsInView, 'days').toDate(),
    };
  }

  private getDaysFromPeriod(period: { from: Date; to: Date }): Date[] {
    const dateFrom = moment(period.from);
    const dateTo = moment(period.to);

    const dates = [];

    while (dateFrom.isSameOrBefore(dateTo, 'days')) {
      dates.push(dateFrom.toDate());
      dateFrom.add(1, 'day');
    }

    return dates;
  }

  private calculateCellsInView(): number {
    const { innerWidth, innerHeight } = window;

    const tableWidth = min([
      innerWidth >= innerHeight ? innerWidth : innerHeight,
      TableauConfig.MaxWidth,
    ]);

    const cellWidth = TableauConfig.CellWidth;

    let cellsInView = Math.floor(tableWidth / cellWidth);

    cellsInView = cellsInView <= 15 ? 20 : cellsInView + 5;

    return cellsInView;
  }

  private mergeAvailabilities(
    oldAvailabilities: TableauAvailabilities,
    newAvailabilities: TableauAvailabilities,
  ): TableauAvailabilities {
    return mergeWith(
      {},
      oldAvailabilities,
      newAvailabilities,
      (a, b): TableauAvailability => {
        return {
          all_closed: a?.all_closed && b?.all_closed,
          sold: a?.sold + b?.sold,
          percent_occupancy: null,
          reservations: a?.reservations + b?.reservations,
          current_availability:
            a?.current_availability + b?.current_availability,
          beddy_availabilities: {
            ...a?.beddy_availabilities,
            ...b?.beddy_availabilities,
          },
          properties: {
            ...a?.properties,
            ...b?.properties,
          },
        };
      },
    );
  }

  private buildAndGetState(state: State): State {
    const build = this.tableauRowsService.build(state);
    return featureAdapter.setAll(build, state);
  }

  private upsertNote(state: State, note: TableauNote): State {
    const {
      resources: { notes },
    } = state;

    const { accommodation_id, accommodation_tableau_number_id } = note;

    let roomNotes: TableauNote[] = get(
      notes,
      [accommodation_id, accommodation_tableau_number_id],
      [],
    );

    let updated = false;

    roomNotes = roomNotes.map((roomNote) => {
      if (roomNote.id === note.id) {
        updated = true;
        return note;
      }

      return roomNote;
    });

    if (!updated) {
      roomNotes = [...roomNotes, note];
    }

    return {
      ...state,
      resources: {
        ...state.resources,
        notes: {
          ...notes,
          [accommodation_id]: {
            ...get(notes, accommodation_id, null),
            [accommodation_tableau_number_id]: roomNotes,
          },
        },
      },
    };
  }

  private removeSwappedReservation(
    reservation: TableauReservation,
    state: State,
  ): TableauSwappedReservation[] {
    return state.swappedReservations.filter(
      ({ reservation: reservationInSwap }) =>
        reservationInSwap.reservation_accommodation_room_id !==
        reservation.reservation_accommodation_room_id,
    );
  }

  private notesResourceMapper(
    state: State,
    mapper: (notes: TableauNote[]) => TableauNote[],
  ) {
    return {
      ...state,
      resources: {
        ...state.resources,
        notes: mapValues(state.resources.notes, (accommodation) =>
          mapValues(accommodation, (roomNotes) => mapper(roomNotes)),
        ),
      },
    };
  }

  private reservationsResourceMapper(
    state: State,
    mapper: (reservations: TableauReservation[]) => TableauReservation[],
  ) {
    return {
      ...state,
      resources: {
        ...state.resources,
        reservations: mapValues(state.resources.reservations, (property) =>
          mapValues(property, (reservations) =>
            mapper(Object.values(reservations)),
          ),
        ),
      },
    };
  }
}

export function tableauReducerFactory(reducer: TableauStoreReducer) {
  return reducer.createReducer();
}
