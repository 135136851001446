import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PropertiesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PropertiesStoreEffects {
  constructor(
    private dataService: PropertiesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private translate: TranslateService,
    private notification: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ page, searchParams }) =>
        this.dataService.load(page, searchParams).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLookupRequest),
      switchMap(({ value, channelId, active }) =>
        this.dataService.loadLookup(value, channelId, active).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadLookupSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadLookupFailure(error));
          }),
        ),
      ),
    ),
  );

  loadGroupProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadGroupPropertiesRequest),
      switchMap(({ property_id }) =>
        this.dataService.loadGroupProperties(property_id).pipe(
          map((response) =>
            fromActions.loadGroupPropertiesSuccess({
              items: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadGroupPropertiesFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ itemId }) =>
        this.dataService.loadDetails(itemId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadDetailsSuccess({
              item: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ itemId }) =>
        this.dataService.delete(itemId).pipe(
          map((response: IResponseSuccess) => {
            this.notification.updateSuccess('properties');
            return fromActions.deleteSuccess({
              itemId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  craete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request }) =>
        this.dataService.create(request).pipe(
          map((response: IResponseSuccess) => {
            this.notification.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('property'),
              }),
              type: 'success',
            });
            this.router.navigate(['/admin/properties']);
            return fromActions.createSuccess({
              item: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map((response: IResponseSuccess) => {
            this.notification.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('property'),
              }),
              type: 'success',
            });
            return fromActions.updateSuccess({
              item: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  updateAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAttributesRequest),
      switchMap(({ request }) =>
        this.dataService.updateAttributes(request).pipe(
          map((response: IResponseSuccess) => {
            this.notification.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('property'),
              }),
              type: 'success',
            });
            return fromActions.updateAttributesSuccess({ request });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateAttributesFailure(error));
          }),
        ),
      ),
    ),
  );

  enable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.enableRequest),
      switchMap(({ itemId }) =>
        this.dataService.enable(itemId).pipe(
          map((response: IResponseSuccess) => {
            this.notification.updateSuccess('properties');
            return fromActions.enableSuccess({
              itemId: itemId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.enableFailure(error));
          }),
        ),
      ),
    ),
  );

  clone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cloneRequest),
      switchMap(({ sourcePropertyId, destinationPropertyId, optionsToClone }) =>
        this.dataService
          .clone(sourcePropertyId, destinationPropertyId, optionsToClone)
          .pipe(
            map((response) => {
              if (!this.errorHandler.handleWarnings(response)) {
                this.notification.done('clone_success');
              }

              return fromActions.cloneSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.cloneFailure(error));
            }),
          ),
      ),
    ),
  );
}
