import * as WelcomeAutomaticEmailsTemplateStoreActions from './actions';
import * as WelcomeAutomaticEmailsTemplateStoreSelectors from './selectors';
import * as WelcomeAutomaticEmailsTemplateStoreState from './state';

export { WelcomeAutomaticEmailsTemplateStoreModule } from './welcome-automatic-emails-template-store.module';

export {
  WelcomeAutomaticEmailsTemplateStoreActions,
  WelcomeAutomaticEmailsTemplateStoreSelectors,
  WelcomeAutomaticEmailsTemplateStoreState,
};
