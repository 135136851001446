<div class="by-tableau-modal-header-details noselectText">
  <ng-container
    *ngTemplateOutlet="
      !loading ? detailTpl : skeleton;
      context: {
        nzParagraph: { rows: 2 }
      }
    "
  ></ng-container>
  <ng-template #detailTpl>
    <by-tableau-modal-header-details-row
      [label]="data?.user?.fullName"
      iconClass="fas fa-address-book by-mr-5"
    >
      <div
        #byTableauModalHeadeDetailsRowContent
        class="by-flex by-flex-direction--column"
      >
        <by-tableau-modal-header-details-row
          *ngIf="data?.user?.telephone"
          [label]="data?.user?.telephone"
          iconClass="by-mr-5 fas fa-phone"
        ></by-tableau-modal-header-details-row>

        <by-tableau-modal-header-details-row
          *ngIf="data?.user?.email"
          [label]="data?.user?.email"
          iconClass="by-mr-5 fas fa-envelope"
        ></by-tableau-modal-header-details-row>

        <by-tableau-modal-header-details-row
          [label]="guestLabelTpl"
          iconClass="fas fa-user by-mr-5"
        >
          <ng-template #guestLabelTpl>
            <span *ngIf="data?.total_adults">
              {{
                'adults_label'
                  | translate: { adults: data?.total_adults }
                  | titlecase
              }}
            </span>
            <span *ngIf="data?.total_children" class="by-ml-5">
              {{
                'children_label'
                  | translate: { children: data?.total_children }
                  | titlecase
              }}
            </span>
          </ng-template>
        </by-tableau-modal-header-details-row>
      </div>
    </by-tableau-modal-header-details-row>

    <by-tableau-modal-header-details-row
      [label]="
        'nights_label'
          | translate
            : {
                nights: data?.number_nights
              }
          | titlecase
      "
      iconClass="fas fa-moon by-mr-5"
    >
      <div
        #byTableauModalHeadeDetailsRowContent
        class="by-flex by-flex-direction--column"
      >
        <by-tableau-modal-header-details-row
          [label]="data?.tableau_number?.label"
          iconClass="fas fa-bed by-mr-5"
        ></by-tableau-modal-header-details-row>

        <by-tableau-modal-header-details-row
          [label]="data?.treatments_description?.title"
          iconClass="fas fa-coffee by-mr-5"
        >
          <div
            #byTableauModalHeadeDetailsRowContent
            class="by-tableau-modal-header-details__treatments"
            nz-tooltip
            [nzTooltipTitle]="treatmentsTpl"
          >
            <ng-container *ngTemplateOutlet="treatmentsTpl"></ng-container>
          </div>
          <ng-template #treatmentsTpl>
            <pre class="by-fs-10 color--white" style="font-family: inherit">{{
              data?.treatments_description?.description
            }}</pre>
          </ng-template>
        </by-tableau-modal-header-details-row>

        <by-tableau-modal-header-details-row
          *ngIf="data?.policy_description"
          [label]="data?.policy_description"
          iconClass="fas fa-credit-card by-mr-5"
        ></by-tableau-modal-header-details-row>

        <by-tableau-modal-header-details-row
          *ngIf="data?.checkin_hour"
          [label]="checkinHourTpl"
          iconClass="fas fa-clock by-mr-5"
        >
          <ng-template #checkinHourTpl>
            <span class="by-mr-3">{{ 'checkin_expected' | translate }}: </span
            ><span>{{ data.checkin_hour }}</span>
          </ng-template>
        </by-tableau-modal-header-details-row>

        <by-tableau-modal-header-details-row
          *ngIf="data?.checkout_hour"
          [label]="checkoutHourTpl"
          iconClass="fas fa-clock by-mr-5"
        >
          <ng-template #checkoutHourTpl>
            <span class="by-mr-3">{{ 'checkout_expected' | translate }}: </span
            ><span>{{ data.checkout_hour }}</span>
          </ng-template>
        </by-tableau-modal-header-details-row>
      </div>
    </by-tableau-modal-header-details-row>

    <by-tableau-modal-header-details-row
      [label]="'ID: ' + data?.resource_id"
    ></by-tableau-modal-header-details-row>
  </ng-template>
</div>

<ng-template #skeleton let-style="style" let-nzParagraph="nzParagraph">
  <nz-skeleton
    [nzActive]="true"
    [nzParagraph]="nzParagraph"
    [nzTitle]="false"
    [ngStyle]="style"
  ></nz-skeleton>
</ng-template>
