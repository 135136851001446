import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[by-collapsable-box]',
  templateUrl: './collapsable-box.component.html',
  animations: [
    trigger('slideInOut', [
      state('expanded', style({ height: '*' })),
      state('collapsed', style({ height: 0, overflow: 'hidden' })),
      transition('expanded => collapsed', [
        animate('150ms cubic-bezier(0.645, 0.045, 0.355, 1)'),
      ]),
      transition('collapsed => expanded', [
        animate('150ms cubic-bezier(0.645, 0.045, 0.355, 1)'),
      ]),
    ]),
  ],
})
export class CollapsableBoxComponent {
  constructor() {}

  @Input() set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
    this.expandChanges.emit(isExpanded);
  }

  @Input() byClass: string | string[] | Set<string> | { [klass: string]: any };

  @Output() expandChanges = new EventEmitter<boolean>();

  private _isExpanded = false;

  get isExpanded() {
    return this._isExpanded;
  }

  get collapsedStatus() {
    return this._isExpanded ? 'expanded' : 'collapsed';
  }

  onToggle() {
    this._isExpanded = !this._isExpanded;
    this.expandChanges.emit(this._isExpanded);
  }
}
