import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  private originalServerDate: Date;
  private originalLocalDate: Date;

  private getting$: Subject<Date>;

  constructor(private http: HttpClient) {}

  get(): Observable<Date> {
    if (!this.originalServerDate && !this.getting$) {
      return this.getServerDate();
    }

    if (this.getting$) {
      return this.getting$.asObservable();
    }

    return of(this.calculateServerDate());
  }

  private getServerDate(): Observable<Date> {
    this.getting$ = new Subject();

    return this.http.get(`${environment.apiUrl}public/time`).pipe(
      retry(20),
      catchError(() => {
        return of({ now: new Date().toISOString() });
      }),
      map(({ now }: { now: string }) => {
        const date = new Date(now);

        this.originalServerDate = date;
        this.originalLocalDate = new Date();

        this.getting$.next(date);
        this.getting$.unsubscribe();
        this.getting$ = null;

        return date;
      }),
    );
  }

  private calculateServerDate(): Date {
    const millisecondsDifference = moment(this.originalLocalDate).diff(
      moment(this.originalServerDate),
      'milliseconds',
    );

    return moment().subtract(millisecondsDifference, 'milliseconds').toDate();
  }
}
