import { Component, forwardRef, Input } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  Price,
  ReservationFormPriceComponent,
} from '../reservation-form-price/reservation-form-price.component';

type OnChange = (price: Price) => void;

@Component({
  selector: 'by-reservation-form-summary-discount-input',
  templateUrl: './reservation-form-summary-discount-input.component.html',
  styleUrls: ['./reservation-form-summary-discount-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => ReservationFormSummaryDiscountInputComponent,
      ),
      multi: true,
    },
  ],
})
export class ReservationFormSummaryDiscountInputComponent extends ReservationFormPriceComponent {
  @Input() label = '';

  private originalPriceConfig: Price;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super(formBuilder);
  }

  /**
   * @override
   */
  registerOnChange(onChange: OnChange) {
    this.onChange = onChange;
  }

  /**
   * @override
   */
  writeValue(priceConfig: Price) {
    this.originalPriceConfig = priceConfig;
    super.writeValue(priceConfig);
  }

  onConfirm() {
    this.showDiscount = false;
    this.onChange(this.getPriceConfig());
  }

  onCancel() {
    this.showDiscount = false;

    this.onChange(this.originalPriceConfig);

    super.writeValue(this.originalPriceConfig);

    this.discountChanged.emit();
  }

  get paddingLeft() {
    return this.isMobile ? 5 : 0;
  }
}
