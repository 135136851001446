import { first, groupBy, last } from 'lodash';
import * as moment from 'moment';

import { datePositionInRange } from './date-position-in-range';

/**
 *
 * @author Sebastiano Maesano
 *
 * @param reservationAccommodations il nodo accommodations che arriva dalla get del dettaglio prenotazione, o della lista prenotazioni
 *
 * @param referenceDate [OPTIONAL] data di riferimento, per sceglire le camere che occupano un determinato giorno. Se non viene passato
 * non verrà filtrata nessuna camera, ma di ogni reservation_accommodation verrà presa una sola room con la seguente logica:
 * Se OGGI è contenuto nei days di una room allora quella sarà la room della reservation_accommodation
 * Se OGGI è prima dell'arrival_date della prima room, la room corretta sarà quella con arrival_date minore
 * Se OGGI è dopo il departure_date dell'ultima room, la room corretta sarà quella con departure_date maggiore
 *
 * @returns reservation accommodations with split handled
 */
export function handleSplit(
  reservationAccommodations: any[],
  referenceDate?: Date,
): any[] {
  const resAccommodationsGrouped = groupBy(
    reservationAccommodations,
    'roomreservation_id',
  );

  // Ciclo ogni array di reservation accommodations
  // - ogni elemento è un array di reservation accommodations appartenenti alla stessa room reservation
  // - con questo ciclo verranno rimosse le reservation accommodation che non ci interessano
  return (
    Object.values(resAccommodationsGrouped)
      .map((reservationAccommodationsWithSameRoomReservation) => {
        // Ciclo ogni reservation accommodation
        // - "room" conterrà solo la room occupata nel giorno di riferimento,
        // - se room è null significa che l'accommodation non ha room che mi servono (capita se splitto cambiando accommodation)
        // - inserisco arrival_date e departure_date nell'accommodation
        const filteredReservationAccommodations =
          reservationAccommodationsWithSameRoomReservation
            .map((reservationAccommodation) => {
              // Ordino le rooms
              const sortedRooms = reservationAccommodation.rooms
                .slice()
                .sort((a, b) =>
                  moment(new Date(a.arrival_date)).diff(
                    new Date(b.arrival_date),
                  ),
                );

              // Prendo le rooms con renferenceDate dentro i days con arrival_date e departure_date inclusi
              const roomsWithReferenceDateBetweenDays = sortedRooms
                .map((room) => {
                  const today_is = datePositionInRange(
                    new Date(),
                    new Date(room.arrival_date),
                    new Date(room.departure_date),
                  );

                  return { ...room, today_is };
                })
                .filter((room, index) => {
                  if (referenceDate) {
                    return moment(referenceDate).isBetween(
                      moment(new Date(room.arrival_date)),
                      moment(new Date(room.departure_date)),
                      'days',
                      '[]',
                    );
                  }

                  const lastIndex = sortedRooms.length - 1;

                  // Applico la logica prevista per quando referenceDate non è definito (vedi desrizione funzione)
                  return (
                    room.today_is === 'between' ||
                    (room.today_is === 'before' && index === 0) ||
                    (room.today_is === 'after' && index === lastIndex)
                  );
                });

              // Se le rooms sono 2 significa che il referenceDate corrisponde con il departure_date di una e l'arrival_date dell'altra,
              // in questo caso prendo quella in ingresso, cioè l'ultima visto che l'array è ordinato
              let currentRoom = last<any>(roomsWithReferenceDateBetweenDays);

              // Inserisco nella currentRoom il day, cioè il day corrente in base al reference date o la data odierna.
              if (currentRoom && currentRoom.days?.length) {
                let day = first(currentRoom.days);

                if (currentRoom.today_is === 'after') {
                  day = last(currentRoom.days);
                }

                if (currentRoom.today_is === 'between') {
                  day = currentRoom.days.find(({ date }) =>
                    moment(new Date(date)).isSame(
                      referenceDate || new Date(),
                      'days',
                    ),
                  );
                }

                currentRoom = { ...currentRoom, day };
              }

              const full_label = `${
                reservationAccommodation.name ||
                reservationAccommodation.original_accommodation?.name
              } - ${currentRoom?.tableau?.label || 'OB'}`;

              return {
                ...reservationAccommodation,
                room: currentRoom || null,
                full_label,
                all_reservation_accommodations_id:
                  reservationAccommodationsWithSameRoomReservation.map(
                    ({ id }) => id,
                  ),
              };
            })
            // Se le reservation accommodations non hanno rooms che mi interessano per la data di riferimento le elimino
            .filter(({ room }) => room)
            // Ordino le reservation accommodations per arrival_date
            .slice()
            .sort((a, b) =>
              moment(new Date(a.arrival_date)).diff(new Date(b.arrival_date)),
            );

        // Nel caso in cui refernceDate è definito ritorno sempre l'ultima res_acc per soddisfare i seguenti casi:
        // - Se l'array ha un solo elemento last() mi becca l'unico elemento che c'è
        // - Nel caso in cui siano 2 prendo l'ultima che è quella entrante (referenceDate === arrival_date), dato che l'array è ordinato
        if (referenceDate) {
          return last(filteredReservationAccommodations);
        }

        // Nel caso refernceDate non sia definito devo invece capire quale reservation_accommodation scegliere,
        // perché se vi è uno split per accommodation_id (quindi non solo di numero tableau) mi troverei con N reservation_accommodations
        // - In questo punto della funzione le reservation_accommodations avranno arrival_date e departure_date ricavato dalle room
        // procediamo quindi con l'analisi delle reservationAccommodation applicando la stessa logica precdentemente applicata sul filter delle rooms
        // Anche qui, come nelle rooms, le reservation_accommodations sono ordinate per data di checkin
        return filteredReservationAccommodations.find((resAcc, index) => {
          const lastIndex = filteredReservationAccommodations.length - 1;

          const todayIs = datePositionInRange(
            new Date(),
            new Date(resAcc.arrival_date),
            new Date(resAcc.departure_date),
          );

          return (
            todayIs === 'between' ||
            (todayIs === 'before' && index === 0) ||
            (todayIs === 'after' && index === lastIndex)
          );
        });
      })
      .filter((resAcc) => !!resAcc)
      // Faccio il merge con le rooms delle accommodation che ho filtrato appartenenti allo stesso roomreservation_id
      .map((resAcc) => {
        const allSortedRoomsByRoomReservationId = resAccommodationsGrouped[
          resAcc.roomreservation_id
        ]
          .reduce(
            (rooms, { rooms: resAccRooms }) =>
              (rooms = [...rooms, ...resAccRooms]),
            [],
          )
          .sort((a, b) =>
            moment(new Date(a.arrival_date)).diff(new Date(b.arrival_date)),
          );

        const { arrival_date } = first(
          allSortedRoomsByRoomReservationId,
        ) as any;
        const { departure_date } = last(
          allSortedRoomsByRoomReservationId,
        ) as any;

        return {
          ...resAcc,
          arrival_date,
          departure_date,
          rooms: allSortedRoomsByRoomReservationId,
          is_splitted: allSortedRoomsByRoomReservationId?.length > 1,
          room: {
            ...resAcc.room,
            days: allSortedRoomsByRoomReservationId.reduce(
              (days, room) => (days = [...days, ...room.days]),
              [],
            ),
          },
        };
      })
  );
}
