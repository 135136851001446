import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Directive({
  selector: '[byContinuousClick]',
})
export class ContinuousClickDirective implements OnChanges {
  @Input()
  intervalPeriod = 100;

  @Input()
  disabled: boolean;

  @Output()
  continuousClick = new EventEmitter();

  private clickSubscription: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    const { disabled } = changes;

    if (disabled) {
      this.onDisabledChange();
    }
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.clickSubscription = interval(this.intervalPeriod).subscribe(() => {
      this.continuousClick.emit();
    });
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.clickSubscription?.unsubscribe();
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.clickSubscription?.unsubscribe();
  }

  private onDisabledChange() {
    if (this.disabled) {
      this.clickSubscription?.unsubscribe();
    }
  }
}
