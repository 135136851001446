import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { get, intersection, isNil, omit } from 'lodash';
import * as moment from 'moment';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { objectDiff } from '../../../helpers';
import {
  showChildrenRangesConditionMapConstant,
  showPeriodConditionMapConstant,
  showQuantityAndRateConditionMapConstant,
} from '../../../helpers/bills-helpers';
import { calcDiscount, removeDiscount } from '../../../helpers/calc-discount';
import {
  ChildrenRange,
  EstimateAddonPriceRequest,
  PlaceVatQuote,
} from '../../../models';

@Component({
  selector: 'by-addon-estimate-form',
  templateUrl: './addon-estimate-form.component.html',
  styleUrls: ['./addon-estimate-form.component.scss'],
})
export class AddonEstimateFormComponent implements OnInit, OnDestroy {
  @Input() set addonForm(form: UntypedFormGroup) {
    if (!form) {
      return;
    }

    this.form = form;

    this.formSubscription();

    const { date_range, addon_id } = form.value;
    const from = this.dateFormatter.toServerFormat(date_range[0]);
    const to = this.dateFormatter.toServerFormat(date_range[1]);

    const addon = {
      ...form.value,
      date_ranges: [{ from, to }],
    };

    this.addonId = addon_id;

    const addonEstimatePayload = this.getEstimateRequestPayload(addon);
    this.estimate.emit(addonEstimatePayload);
  }

  @Input() currencySymbol = '€';

  @Input() accommodationData: {
    max_adults: number;
    max_children: number;
    arrival_date: Date;
    departure_date: Date;
  };

  @Input() set estimateData(data) {
    if (!data) {
      return;
    }

    const total = get(data, `[${this.addonId}].total`);
    const price = get(data, `[${this.addonId}].unit_price`);
    const quantity = get(data, `[${this.addonId}].quantity`);

    if (!total || !price) {
      return;
    }

    this.form.patchValue({
      total,
      price,
      discount_value: 0,
      quantity,
    });
  }

  @Input() childrenRange: ChildrenRange[] = [];

  @Input() vatQuotes: PlaceVatQuote[] = [];

  @Input() priceLoading = false;

  @Output() estimate = new EventEmitter<EstimateAddonPriceRequest>();

  @Output() addToCart = new EventEmitter<void>();

  form: UntypedFormGroup;
  addonId: number;

  showPeriodConditionMapConstant = showPeriodConditionMapConstant;
  showChildrenRangesConditionMapConstant = showChildrenRangesConditionMapConstant;
  showQuantityAndRateConditionMapConstant = showQuantityAndRateConditionMapConstant;

  private subs = new SubSink();
  private valuesCache = { price: 0, total: 0 };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateFormatter: DateFormatterService,
  ) {}

  // START UTILITY

  currencyFormatter = (value: string) =>
    `${this.currencySymbol || ''} ${(+value || 0).toFixed(2)}`;

  currencyParser = (value: string) => {
    return value.replace(`${this.currencySymbol} `, '').trim() || '0';
  };

  percentageFormatter = (value: string) =>
    `${value ? (+value).toFixed(2) : ''} %`;

  accommodationRangeCheck = (date: Date) => {
    return (
      moment(date).isBefore(
        moment(get(this.accommodationData, 'arrival_date')),
      ) ||
      moment(date).isAfter(
        moment(get(this.accommodationData, 'departure_date')),
      )
    );
  };

  // END UTILITY

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private formSubscription() {
    this.subs.add(
      this.form.valueChanges
        .pipe(startWith({}, this.form.value), debounceTime(300), pairwise())
        .subscribe(([oldValue, newValue]) => {
          const changes = objectDiff(newValue, oldValue);

          const estimateFields: string[] = [
            'date_range',
            'single_date',
            'quantity',
            'price',
            'children_number',
            'adults_number',
            'total_children',
            'discount_value',
            'total',
          ];

          const estimateFieldsChanged = intersection(
            Object.keys(changes),
            estimateFields,
          );

          const changesToEvaluate = estimateFieldsChanged.reduce(
            (_changes, key) =>
              (_changes = { ..._changes, [key]: newValue[key] }),
            {},
          );

          if (!Object.keys(changesToEvaluate || {}).length) {
            return;
          }

          const { addon_id } = this.form.value;
          this.onFormValuesChange(changesToEvaluate, addon_id);
        }),
    );
  }

  private onFormValuesChange(changes: any, addon_id: number) {
    if (!changes) {
      return;
    }

    if (!isNil(changes['quantity'])) {
      this.onChangeQuantity(changes['quantity'], addon_id);
    }

    if (!isNil(changes['price'])) {
      this.onChangeUnitPrice(changes['price'], addon_id);
    }

    if (!isNil(changes['date_range'])) {
      this.onChangeDate(changes['date_range'], addon_id);
    }

    if (!isNil(changes['adults_number'])) {
      this.onChangeAdults(changes['adults_number'], addon_id);
    }

    if (!isNil(changes['single_date'])) {
      this.onChangeSingleDate(changes['single_date'], addon_id);
    }

    if (!isNil(changes['children_number'])) {
      this.onChangeChildrenNumber(changes['children_number'], addon_id);
    }

    if (!isNil(changes['total_children'])) {
      this.onChangeChildrenRange(changes['total_children'], addon_id);
    }

    if (!isNil(changes['discount_value'])) {
      this.onDiscountValueChange(changes['discount_value'], addon_id);
    }

    if (!isNil(changes['total'])) {
      this.onTotalChange(changes['total']);
    }
  }

  // Quantity
  private onChangeQuantity(quantity: number, addon_id: number) {
    const { price, discount_value } = this.form.value;

    const totalPrice = price * quantity;

    const total = discount_value
      ? totalPrice - calcDiscount(totalPrice, discount_value)
      : totalPrice;

    this.form.patchValue({ total });
  }

  // Price
  private onChangeUnitPrice(price: number, addon_id: number) {
    const { quantity, discount_value } = this.form.value;

    const totalPrice = price * quantity;

    const newTotal = discount_value
      ? totalPrice - calcDiscount(totalPrice, discount_value)
      : totalPrice;

    if (this.valuesCache.price === price) {
      return;
    }

    this.valuesCache = { total: newTotal, price };
    this.form.patchValue({ total: newTotal });
  }

  // Adults
  private onChangeAdults(adults: number, addon_id: number) {
    let addon = this.form.value;

    if (!addon) {
      return;
    }

    const { date_range } = addon;

    addon = {
      ...addon,
      addon_id,
      adults_number: adults,
      date_ranges: this.getDateRangeServerFormatFromControl(date_range),
    };

    const payload = this.getEstimateRequestPayload(addon);
    this.estimate.emit(payload);
  }

  // Period
  private onChangeDate(period: Array<Date>, addon_id: number) {
    let addon = this.form.value;

    if (!addon) {
      return;
    }

    const from = this.dateFormatter.toServerFormat(period[0]);
    let to = this.dateFormatter.toServerFormat(period[1]);

    if (
      from === to &&
      this.showPeriodConditionMapConstant[this.form.get('type').value]
    ) {
      const fromDate = moment(from).toDate();
      const toDate = moment(from).add(1, 'days').toDate();
      this.form.patchValue({
        date_range: [fromDate, toDate],
      });
      to = this.dateFormatter.toServerFormat(toDate);
    }

    addon = {
      ...addon,
      addon_id,
      date_ranges: [{ from, to }],
    };

    const addonEstimatePayload = this.getEstimateRequestPayload(addon);
    this.estimate.emit(addonEstimatePayload);
  }

  // Single Date
  private onChangeSingleDate(date: Date, addon_id: number) {
    let addon = this.form.value;

    if (!addon) {
      return;
    }

    const formattedDate = this.dateFormatter.toServerFormat(date);

    addon = {
      ...addon,
      addon_id,
      date_ranges: [{ from: formattedDate, to: formattedDate }],
    };

    const addonEstimatePayload = this.getEstimateRequestPayload(addon);
    this.estimate.emit(addonEstimatePayload);
  }

  // Children number
  private onChangeChildrenNumber(childrenNumber: number, addon_id: number) {
    const childrenFormArray: UntypedFormArray = this.form.get(
      'total_children',
    ) as UntypedFormArray;
    const oldChildrenNumber = childrenFormArray.controls.length;

    if (oldChildrenNumber > childrenNumber) {
      // rimuovo il bambino dall'array
      childrenFormArray.removeAt(oldChildrenNumber - 1);

      // recupero i dati per l'estimate
      let addon = this.form.value;
      const { date_range } = addon;
      addon = {
        ...addon,
        addon_id,
        date_ranges: this.getDateRangeServerFormatFromControl(date_range),
      };

      // costruisco il payload e invio l'estimate
      const payload = this.getEstimateRequestPayload(addon);
      this.estimate.emit(payload);
    }

    if (oldChildrenNumber < childrenNumber) {
      childrenFormArray.push(this.formBuilder.group({ id: null, quantity: 1 }));
      // non è necessario fare estimate, verrà fatto automaticamente quando si sceglie la fascia di età per il nuovo bambino
    }
  }

  // Children age
  private onChangeChildrenRange(
    total_children: Array<{ id: number; quantity: number }>,
    addonId: number,
  ) {
    let addon = this.form.value;

    const { date_range } = addon;

    addon = {
      ...addon,
      addon_id: addonId,
      total_children,
      date_ranges: this.getDateRangeServerFormatFromControl(date_range),
    };

    const payload = this.getEstimateRequestPayload(addon);
    this.estimate.emit(payload);
  }

  // Discount
  private onDiscountValueChange(discountValue: number, addonId: number) {
    const { quantity, price } = this.form.value;

    const totalPrice = price * quantity;

    const total = discountValue
      ? totalPrice - calcDiscount(totalPrice, discountValue)
      : totalPrice;

    this.valuesCache = { price, total };

    this.form.patchValue({ total });
  }

  // Total
  private onTotalChange(total: number) {
    const { quantity, discount_value } = this.form.value;

    const newPrice = discount_value
      ? removeDiscount(total, discount_value) / quantity
      : total / quantity;

    if (this.valuesCache.total === total) {
      return;
    }

    this.valuesCache = { total, price: newPrice };
    this.form.patchValue({ price: newPrice });
  }

  private getDateRangeServerFormatFromControl(
    dateRange: Array<Date>,
  ): Array<{ from: string; to: string }> {
    const from = this.dateFormatter.toServerFormat(dateRange[0]);
    const to = this.dateFormatter.toServerFormat(dateRange[1]);

    return [{ from, to }];
  }

  private getEstimateRequestPayload(addon): EstimateAddonPriceRequest {
    const {
      addon_id,
      quantity,
      date_ranges,
      adults_number: adults,
      total_children,
      accommodation_id,
    } = addon;

    const payload = {
      addon_id,
      accommodation_id,
      adults,
      children_ranges: (total_children || [])
        .map(({ id, quantity: childrenQuantity }) => ({
          property_has_children_range_id: id,
          quantity: childrenQuantity,
        }))
        .filter(
          ({ property_has_children_range_id }) =>
            !isNil(property_has_children_range_id),
        ),
      quantity,
      date_ranges,
    };

    return this.cleanEstimateRequest(payload);
  }

  private cleanEstimateRequest(
    request: EstimateAddonPriceRequest,
  ): EstimateAddonPriceRequest {
    const { addon_id } = request;
    const { type } = this.form.value;

    let newRequest = { ...request };

    if (!this.showChildrenRangesConditionMapConstant[type]) {
      newRequest = omit(newRequest, 'adults', 'children_ranges');
    }

    if (!this.showQuantityAndRateConditionMapConstant[type]) {
      newRequest = {
        ...newRequest,
        quantity: 1,
      };
    }

    return newRequest;
  }
}
