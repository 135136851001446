<div class="relative">
  <div
    #bookmarks
    class="by-tableau-reservation-details__bookmarks"
    [class.by-tableau-reservation-details__bookmarks--mobile]="isMobile"
    [class.by-tableau-reservation-details__bookmarks--scrollable]="
      bookmarksHaveScroll
    "
  >
    <ng-container *ngIf="rooms$ | async as rooms">
      <ng-container *ngIf="rooms.length > 1">
        <div
          *ngIf="bookmarksHaveScroll"
          class="by-tableau-reservation-details__bookmark-arrow by-tableau-reservation-details__bookmark-arrow--top"
        >
          <button
            (click)="isMobile ? onHorizontalScroll(-1) : onVerticalScroll(-1)"
          >
            <i class="fas fa-chevron-{{ isMobile ? 'left' : 'up' }}"></i>
          </button>
        </div>
        <div class="by-tableau-reservation-details__bookmarks-container">
          <div
            *ngFor="let room of rooms; trackBy: trackByFn"
            (click)="onSelectRoom(room)"
            class="by-tableau-reservation-details__bookmark"
            [class.by-tableau-reservation-details__bookmark--selected]="
              (currentReservationRoom$ | async)?.roomreservation_id ===
              room.roomreservation_id
            "
            [style.background-color]="reservationsColors[room.background_key]"
          >
            <ng-container
              *ngIf="viewOptions.reservation_details_group_label === 'room'"
            >
              {{ room.tableau_number?.label || 'OB' | truncate: 3:'..' }}
            </ng-container>

            <ng-container
              *ngIf="viewOptions.reservation_details_group_label === 'guest'"
            >
              {{ room.label | initials: 2 | uppercase }}
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="bookmarksHaveScroll"
          class="by-tableau-reservation-details__bookmark-arrow by-tableau-reservation-details__bookmark-arrow--bottom"
        >
          <button
            (click)="isMobile ? onHorizontalScroll(1) : onVerticalScroll(1)"
          >
            <i class="fas fa-chevron-{{ isMobile ? 'right' : 'down' }}"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div>
    <by-tableau-reservation-details-room
      [reservationRoom]="currentReservationRoom$ | async"
      [reservationsColors]="reservationsColors"
      [loading]="loading$ | async"
      [tags]="tags$ | async"
      [userCanWrite]="userCanWrite"
      [showDeleteRoom]="(rooms$ | async)?.length > 1"
      (closeModal)="onCloseModal()"
      (setKeepAccommodation)="onSetKeepAccommodation($event)"
    ></by-tableau-reservation-details-room>
  </div>
</div>
