<td nzLeft="0px" class="by-tableau-cell by-tableau-cell--first">
  <div>
    <div class="icons">
      <i
        class="far fa-chevron-{{ isCollapsed ? 'down' : 'up' }} by-mr-3"
        (click)="onToggleCollapse()"
      ></i>

      <i
        *ngIf="userCanWrite"
        nz-tooltip
        [nzTooltipTitle]="'beds_types' | translate | capitalize"
        (click)="onOpenBedTypesModal()"
        class="fas fa-bed pointer by-mr-3"
      ></i>

      <i
        *ngIf="data.is_virtual"
        class="by-icon by-virtual-room-icon color--red"
        [nzTooltipTitle]="'virtual_room' | translate | capitalize"
        nz-tooltip
      ></i>

      <i
        *ngIf="data.is_parent && !data.is_sum_parent"
        class="by-icon by-master-room-icon color--orange"
        [nzTooltipTitle]="'parent_virtual_room' | translate | upperfirst"
        nz-tooltip
      ></i>

      <i
        *ngIf="data.is_sum_parent"
        class="by-icon by-sum-room-icon color--green"
        [nzTooltipTitle]="'parent_virtual_sum' | translate | upperfirst"
        nz-tooltip
      ></i>

      <i
        *ngIf="!data.is_sum && data.parent_id"
        [nzTooltipTitle]="
          'linked_room_tip'
            | translate: { linkedRooms: data.parent_name }
            | upperfirst
        "
        nz-tooltip
        class="by-icon by-child-room-icon color--azure"
      ></i>

      <i
        *ngIf="data.is_sum"
        [nzTooltipTitle]="
          'linked_room_tip'
            | translate: { linkedRooms: data.parent_name }
            | upperfirst
        "
        nz-tooltip
        class="by-icon by-sum-room-child-icon color--azure"
      ></i>

      <i
        *ngIf="data.not_room"
        class="by-icon by-no-room-icon color--ligth-blue"
        [nzTooltipTitle]="data.type_name"
        nz-tooltip
      ></i>
    </div>
    <div>
      {{ data.name }}
    </div>
  </div>
</td>

<ng-container *ngFor="let day of days">
  <td
    *ngIf="(items | getByDay: day)?.data?.availability || '-' as availability"
    class="by-tableau-cell"
    [ngClass]="{
      'by-tableau-cell--we': day | isWeekend,
      'by-tableau-cell--no-avail': !+availability
    }"
    byTableauCellHoverDispatcher
    [date]="day"
    [row]="row"
  >
    {{ availability }}
  </td>
</ng-container>
