import { Pipe, PipeTransform } from '@angular/core';

import { InvoiceDetails, InvoiceType } from '../../models';

@Pipe({
  name: 'invoiceTypeLabel',
})
export class InvoiceTypeLabelPipe implements PipeTransform {
  transform(
    documentTypeName: string,
    invoice: InvoiceDetails,
    invoiceTypes: Record<number, InvoiceType>,
    withNumberComplete = true,
  ): string {
    //  const { document_type_code_id } = invoice;

    // if (!document_type_code_id) {
    //   return documentTypeName;
    // }

    // const invoiceType = invoiceTypes?.[invoice.document_type_id];

    // const code = invoiceType?.document_type_codes?.find(
    //   ({ id }) => id === document_type_code_id,
    // )?.code;

    let label = documentTypeName;

    if (withNumberComplete && invoice?.number_complete) {
      label = `${label} ${invoice?.number_complete}`;
    }

    // if (code) {
    //   label = `${label} ${code}`;
    // }

    return label;
  }
}
