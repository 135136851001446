<div class="by-reservation-form-summary">
  <div class="by-reservation-form-summary__header">
    {{ 'reservation' | translate | upperfirst }}:
    {{ accommodationRequest?.arrival_date | formatDate }}
    <i class="far fa-angle-double-right"></i>
    {{ accommodationRequest?.departure_date | formatDate }} -
    {{ 'nights' | translate | upperfirst }}: {{ nights }}
  </div>

  <div class="by-reservation-form-summary__content">
    <!-- <div
      class="
        by-reservation-form-summary__content-row
        by-reservation-form-summary__content-row--flex
      "
    >
      <div>
        <i class="fas fa-bed by-reservation-form-summary__content-row-icon"></i
        ><strong>
          {{ accommodation?.tableau_label }} - {{ accommodation?.name }}
        </strong>
      </div>
    </div> -->
    <div>
      <by-reservation-form-summary-discount-input
        label="{{ accommodation?.tableau_label }} - {{ accommodation?.name }}"
        [(ngModel)]="price"
        (ngModelChange)="onPriceChanged($event)"
        [estimateResponse]="estimateResponse"
        [proportionalDayPriceResponse]="proportionalDayPriceResponse"
        [currency]="currency"
        [priceLoading]="priceLoading"
        [isMobile]="isMobile"
        (proportionalDayPrice)="proportionalDayPrice.emit($event)"
        (discountChanged)="onDiscountChanged()"
      ></by-reservation-form-summary-discount-input>
    </div>

    <div class="by-reservation-form-summary__content-row">
      <i class="fas fa-user by-reservation-form-summary__content-row-icon"></i
      ><span>
        {{
          'adults_label'
            | translate: { adults: accommodationRequest?.adults_number }
        }}
      </span>
    </div>

    <div
      *ngIf="accommodationRequest?.total_children?.length as childrenNumber"
      class="by-reservation-form-summary__content-row"
    >
      <i class="fas fa-child by-reservation-form-summary__content-row-icon"></i
      ><span>
        {{ 'children_label' | translate: { children: childrenNumber } }}
      </span>
    </div>

    <div class="by-reservation-form-summary__content-row">
      <i
        class="fas fa-calculator by-reservation-form-summary__content-row-icon"
      ></i
      ><span>
        {{ selectedRateplanName }}
      </span>
    </div>

    <div class="by-reservation-form-summary__content-row">
      <i class="fas fa-coffee by-reservation-form-summary__content-row-icon"></i
      ><span>
        {{ selectedTratemantName }}
      </span>
    </div>

    <div class="by-mt-2">
      <a (click)="addAddons.emit()">
        <span *ngIf="!addons.length">
          <i
            class="far fa-plus by-reservation-form-summary__content-row-icon"
          ></i>
          {{ 'add_bill' | translate | upperfirst }}
        </span>

        <span *ngIf="addons.length">
          <i
            class="far fa-pencil-alt by-reservation-form-summary__content-row-icon"
          ></i>
          {{ 'edit_bills' | translate | upperfirst }}
        </span>
      </a>

      <div class="by-reservation-form-summary__addons" *ngIf="addons.length">
        <ng-container *ngFor="let addon of addons">
          <div class="by-reservation-form-summary__addon">
            <span>
              <strong
                *ngIf="showQuantityAndRateConditionMapConstant[addon.type]"
              >
                {{ addon.quantity }}
              </strong>
              {{ addon.name }}
            </span>
            <strong>
              {{ addon.total | toCurrency: currency?.symbol }}
            </strong>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="by-reservation-form-summary__footer">
    <div>
      {{ 'total' | translate | upperfirst }} ({{ 'vat_included' | translate }}):
    </div>
    <div>
      <by-reservation-form-summary-price-editor
        #formGetter
        #priceEditor
        [ngModel]="priceWithAddons"
        (ngModelChange)="onPriceWithAddonsChanged($event)"
        [estimateResponse]="estimateResponse"
        [proportionalDayPriceResponse]="proportionalDayPriceResponse"
        [currency]="currency"
        [priceLoading]="priceLoading"
        [addonsTotalPrice]="addonsTotalPrice"
        [depositValue]="firstStepRequest?.deposit"
        [isMobile]="isMobile"
        (proportionalDayPrice)="onProportionalDayPrice($event)"
      ></by-reservation-form-summary-price-editor>
    </div>
  </div>
</div>

<div
  class="by-mt-10 color--red"
  *ngIf="priceEditor?.form?.invalid && priceEditor?.form?.touched"
>
  <i class="fas fa-exclamation-circle"></i>
  {{ 'deposit_greater_than_total' | translate | upperfirst }}
</div>
