import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers/params-generator';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { observableSequence, removeNullishValues } from '../helpers';
import { replaceAll } from '../helpers/replace-all';
import { Reservation, ReservationsStatsWidgetRequest } from '../models';
import { SearchParams } from '../models/objects/search-params';
import {
  ExportFileRequestSuccess,
  ExportFileRequestType,
} from '../models/types/export-file-request';
import * as moment from 'moment';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(payload: SearchParams) {
    const url = replaceAll(
      `reservations?${generateSearchQuery({
        ...payload,
        now: this.dateFormatter.toServerFormat(new Date()),
      })}`,
      '#',
      '',
    );
    return this.http.get<IResponseSuccess<Reservation[]>>(url);
  }

  delete(
    reservationId: number,
    force_operation: number = 0,
    recalculate?: boolean,
  ) {
    return this.http.delete(
      `reservation/${reservationId}?force_operation=${force_operation}${
        recalculate ? '&recalculate=1' : ''
      }`,
    );
  }

  deleteAccommodation(
    reservationId: number,
    reservationAccommodationIds: number[],
  ) {
    return observableSequence(
      reservationAccommodationIds.map((reservationAccommodationId) =>
        this.http.delete(
          `reservation/${reservationId}/accommodation/${reservationAccommodationId}`,
        ),
      ),
    );
  }

  setRead(reservationId: number, read: boolean) {
    return this.http.put(`reservation/${reservationId}/set_read`, {
      read,
    });
  }

  updateChannel(reservationId: number, channel_id: number) {
    return this.http.put(`reservation/${reservationId}/channel`, {
      channel_id,
    });
  }

  resendEmail(
    reservationId: number,
    roomReservationIDS: string[],
    additionalEmails: string[],
    sendEmailToCompany: boolean,
    sendEmailToCustomer: boolean,
    sendEmailToProperty: boolean,
    templateCustomId: number,
    templateSystemId: number,
  ) {
    return this.http.post(
      `reservation/${reservationId}/resend_email`,
      removeNullishValues({
        additional_emails: additionalEmails,
        send_email_to_company: sendEmailToCompany,
        send_email_to_customer: sendEmailToCustomer,
        send_email_to_property: sendEmailToProperty,
        template_custom_id: templateCustomId,
        template_system_id: templateSystemId || null,
        roomreservation_id: roomReservationIDS?.length
          ? roomReservationIDS
          : null,
      }),
    );
  }

  noShow(reservationId: number) {
    return this.http.post(`reservation/${reservationId}/no_show`, {
      reservationId,
    });
  }

  markCreditCardAsInvalid(reservationId: number) {
    return this.http.post(`reservation/${reservationId}/invalid_cc`, {
      reservationId,
    });
  }

  deleteReservationDueInvalidCreditCard(reservationId: number) {
    return this.http.post(
      `reservation/${reservationId}/cancellation_due_invalid_cc`,
      {
        reservationId,
      },
    );
  }

  synchronizesRoomReservation(data: {
    keep_availability: number;
    reservation_accommodation_id: number;
  }) {
    return this.http.put(
      `reservation/accommodation/${data.reservation_accommodation_id}/sync_accommodation`,
      { keep_availability: data.keep_availability },
    );
  }

  loadWidget(data: ReservationsStatsWidgetRequest) {
    return this.http.get(
      `statistics/reservations_totals?${generateSearchQuery(data)}`,
    );
  }

  exportFile(params: ExportFileRequestType) {
    const {
      format,
      referenceDate,
      exportColumns,
      queryString,
      selectedGroupId,
      propertyIds,
    } = params;

    const queryParams = {
      property_id: propertyIds,
      current_date: moment(new Date()).format('YYYY-MM-DD'),
      download_pdf: '1',
      export: 1,
      export_format: format,
      reference_date: referenceDate,
      export_columns: exportColumns,
      property_group_id: selectedGroupId,
      ...queryString,
    };

    return this.http.get<IResponseSuccess<ExportFileRequestSuccess>>(
      `reservations?${generateSearchQuery(removeNullishValues(queryParams))}`,
    );
  }
}
