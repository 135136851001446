import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'formatToDate' })
export class FormatToDatePipe implements PipeTransform {
  constructor() {}

  public transform(date: moment.Moment) {
    return new Date(date.format('YYYY-MM-DD'));
  }
}
