<div
  class="
    by-flex by-flex-middle
    color--white
    by-fs-12 by-tableau-modal-header-details-row
  "
  [class.pointer]="contentElements?.length"
  (click)="onToggle()"
>
  <i *ngIf="iconClass" [ngClass]="iconClass"></i>

  <pre
    nz-tooltip
    [nzTooltipTitle]="label"
    class="by-tableau-modal-header-details-row__label"
  ><ng-container *nzStringTemplateOutlet="label">{{label}}</ng-container></pre>

  <i
    [ngClass]="{ rotate: isExpanded }"
    class="by-ml-5 by-tableau-modal-header-details-row__arrow fas fa-caret-up"
    *ngIf="contentElements?.length"
  ></i>
</div>
<div by-collapsable-box>
  <ng-content></ng-content>
</div>
