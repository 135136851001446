import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubSink } from 'subsink';

import { EmailValidator } from '../../../../core/validators/email.validators';
import {
  CompanyLookup,
  FormGetter,
  IEmailTemplateCustom,
} from '../../../../models';
import { Customer } from '../reservation-form-customer/reservation-form-customer.component';

export interface NotificationConfig {
  send_email_to_customer: boolean;
  send_email_to_company: boolean;
  template_email_send_copy: boolean;
  template_email_id: number;
  template_email_type: 'system' | 'custom';
}

type OnChange = (config: NotificationConfig) => void;

const SYSTEM_TEMPLATE_ID = 'system';

@Component({
  selector: 'by-reservation-form-notification',
  templateUrl: './reservation-form-notification.component.html',
  styleUrls: ['./reservation-form-notification.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormNotificationComponent),
      multi: true,
    },
  ],
})
export class ReservationFormNotificationComponent
  implements OnChanges, OnDestroy, FormGetter
{
  @Input()
  selectedCompany: CompanyLookup;

  @Input()
  selectedCustomer: Customer;

  @Input()
  customEmailTeplates: IEmailTemplateCustom[];

  @Output()
  showEmailPreview = new EventEmitter();

  form = this.formBuilder.group({
    send_email_to_customer: [false],
    send_email_to_company: [false],
    template_email_id: [SYSTEM_TEMPLATE_ID],
    template_email_send_copy: [false],
  });

  onTouched: () => void;

  canSendEmailToCustomer = false;

  canSendEmailToCompany = false;

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    const { selectedCompany, selectedCustomer } = changes;

    if (selectedCompany) {
      this.setSelectedCompany();
    }

    if (selectedCustomer) {
      this.setSelectedCustomer();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(config: NotificationConfig) {
    this.form.patchValue(config, { emitEvent: false });
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(
      this.form.valueChanges.subscribe((formValue) => {
        const { send_email_to_customer, send_email_to_company } = formValue;

        if (!send_email_to_customer && !send_email_to_company) {
          onChange(null);
          return;
        }

        onChange({
          ...formValue,
          template_email_type:
            formValue.template_email_id === SYSTEM_TEMPLATE_ID
              ? 'system'
              : 'custom',
        });
      }),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  getForms() {
    return [this.form];
  }

  private setSelectedCustomer() {
    this.canSendEmailToCustomer =
      this.selectedCustomer?.email &&
      this.selectedCustomer?.name &&
      this.selectedCustomer?.surname &&
      EmailValidator.validate(this.selectedCustomer.email);

    if (!this.canSendEmailToCustomer) {
      this.form.patchValue({
        send_email_to_customer: false,
      });
    }
  }

  private setSelectedCompany() {
    this.canSendEmailToCompany =
      this.selectedCompany?.email &&
      EmailValidator.validate(this.selectedCompany.email);

    if (!this.canSendEmailToCompany) {
      this.form.patchValue({
        send_email_to_company: false,
      });
    }
  }
}
