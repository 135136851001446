import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SubSink } from 'subsink';

import { AttachmentViewerService } from '../../../../components/attachment-viewer/attachment-viewer.service';
import { handleFormSubmit } from '../../../../core/helpers/handleFormSubmit';
import { removeNullishValues } from '../../../../helpers';
import {
  LoadMaintenanceReportsRequest,
  MaintenanceReport,
  SaveMaintenanceReportRequest,
} from '../../../../models';
import {
  MaintenanceReportsStoreActions,
  MaintenanceReportsStoreSelectors,
} from '../../../../root-store/maintenance-reports-store';
import { RootState } from '../../../../root-store/root-state';
import { MaintenanceReportsFormComponent } from '../maintenance-reports-form/maintenance-reports-form.component';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-maintenance-reports-container',
  templateUrl: './maintenance-reports-container.component.html',
  styleUrls: ['./maintenance-reports-container.component.scss'],
})
export class MaintenanceReportsContainerComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<MaintenanceReportsContainerComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  params: LoadMaintenanceReportsRequest = this.nzDataModal.params;

  @Input()
  title: string = this.nzDataModal.title;

  @Input()
  canWrite: boolean = this.nzDataModal.canWrite || true;

  @ViewChild(MaintenanceReportsFormComponent)
  formComponent: MaintenanceReportsFormComponent;

  loading$ = this.store.pipe(
    select(MaintenanceReportsStoreSelectors.selectIsLoading),
  );

  reports: MaintenanceReport[];

  saving = false;

  view: 'list' | 'form' = 'list';

  reportUnderEdit: MaintenanceReport;

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private attachmentViewerService: AttachmentViewerService,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.store
        .pipe(select(MaintenanceReportsStoreSelectors.selectReports))
        .subscribe((reports) => {
          this.reports = reports;
        }),
    );

    this.store.dispatch(
      MaintenanceReportsStoreActions.loadRequest({ request: this.params }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(MaintenanceReportsStoreActions.resetState());
  }

  onEdit(report: MaintenanceReport) {
    this.reportUnderEdit = report;
    this.view = 'form';
  }

  onCreate() {
    this.reportUnderEdit = null;
    this.view = 'form';
  }

  onRemove(report: MaintenanceReport) {
    this.store.dispatch(
      MaintenanceReportsStoreActions.deleteRequest({
        request: { report_id: report.id, property_id: this.params.property_id },
      }),
    );
  }

  onCancel() {
    this.reportUnderEdit = null;
    this.view = 'list';
  }

  onShowMedia(report: MaintenanceReport) {
    this.attachmentViewerService.view(report.media);
  }

  get unsolvedReportsNumber() {
    return this.reports.filter((report) => report.status === 'unsolved').length;
  }

  onSaveReport() {
    const { form, report, medias } = this.formComponent;

    const request: SaveMaintenanceReportRequest = removeNullishValues({
      medias,
      ...form.value,
      ...this.params,
    });

    const onSuccess = () => {
      this.saving = false;
      this.onCancel();
    };

    handleFormSubmit([form], () => {
      this.saving = true;

      if (report) {
        this.store.dispatch(
          MaintenanceReportsStoreActions.updateRequest({
            request,
            onSuccess,
          }),
        );
      } else {
        this.store.dispatch(
          MaintenanceReportsStoreActions.createRequest({
            request,
            onSuccess,
          }),
        );
      }
    });
  }
}
