import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { difference } from 'lodash';
import { SubSink } from 'subsink';

import {
  Channel,
  TableauMappingAccommodation,
  TableauViewMode,
  TableauViewOptions,
} from '../../../../models';
import { IReservationTag } from '../../../../models/objects/reservation-tag';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { NotificationService } from '../../../../ui/services/notification.service';
import { TableauConfig } from '../config';

@Component({
  selector: 'by-tableau-toolbar',
  templateUrl: './tableau-toolbar.component.html',
  styleUrls: ['./tableau-toolbar.component.scss'],
})
export class TableauToolbarComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  isMobile: boolean;

  @Input()
  viewOptions: TableauViewOptions;

  @Input()
  viewMode: TableauViewMode;

  @Input()
  reservationTags: any[];

  @Input()
  zoom: number;

  @Input()
  tags: IReservationTag[];

  @Input()
  fullscreen: boolean;

  @Input()
  splitMode: boolean;

  @Input()
  activeChannels: Channel[];

  @Input()
  propertiesSelectTemplate: TemplateRef<void>;

  @Input()
  accommodations: TableauMappingAccommodation[];

  @Input()
  propertiesNames: Record<number, string>;

  @Input()
  filteredAccommodations: number[];

  @Input()
  exportLoading: boolean;

  @Output()
  fullscreenChange = new EventEmitter<boolean>();

  @Output()
  startDateChange = new EventEmitter<Date>();

  viewOptionsForm = this.formBuilder.group({
    events: [null],
    oblique: [null],
    notes: [null],
    quotes: [null],
    not_room: [null],
    virtual_room: [null],
    stats: [null],
    ota_source: [null],
    tags: [null],
    reservations_palette: [null],
    reservation_details_group_label: [null],
    bed_type: [null],
    housekeeper_notes: [null],
    tag_id: [0],
  });

  visibleAccommodationsControl = this.formBuilder.control([]);

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private notifications: NotificationService,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.viewOptionsForm.valueChanges.subscribe((viewOptions) => {
        this.store.dispatch(
          TableauActions.setViewOptions({
            viewOptions,
          }),
        );
      }),
    );

    this.subs.add(
      this.visibleAccommodationsControl.valueChanges.subscribe(
        (visibleAccommodations) => {
          this.store.dispatch(
            TableauActions.setFilteredAccommodations({
              filteredAccommodations: difference(
                this.allAccommodationsIds,
                visibleAccommodations,
              ),
            }),
          );
        },
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { viewOptions, accommodations, filteredAccommodations } = changes;

    if (viewOptions && this.viewOptions) {
      this.setViewOptions();
    }

    if (accommodations && this.accommodations) {
      this.setVisibleAccommodations();
    }

    if (filteredAccommodations && this.filteredAccommodations) {
      this.setVisibleAccommodations();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onClickToday() {
    this.startDateChange.emit(new Date());
  }

  onChangeViewMode(viewMode: TableauViewMode) {
    this.pushSuccessNotification();
    this.store.dispatch(TableauActions.setViewMode({ viewMode }));
  }

  onExpandChange(expand: boolean) {
    this.store.dispatch(
      TableauActions.toggleAllAccommodationsExpand({ expand }),
    );
  }

  onSplitModeChange(splitMode: boolean) {
    this.store.dispatch(TableauActions.toggleSplitMode({ splitMode }));
  }

  onZoomIn() {
    this.store.dispatch(TableauActions.zoomIn());
  }

  onZoomOut() {
    this.store.dispatch(TableauActions.zoomOut());
  }

  onExport() {
    this.store.dispatch(TableauActions.exportRequest());
  }

  setViewOptions() {
    this.viewOptionsForm.reset(
      { ...this.viewOptions, tag_id: this.viewOptions.tag_id || 0 },
      { emitEvent: false },
    );
  }

  get zoomInDisabled() {
    return this.zoom >= TableauConfig.MaxZoom;
  }

  get zoomOutDisabled() {
    return this.zoom <= TableauConfig.MinZoom;
  }

  get expandDisabled() {
    return this.viewMode === 'unified';
  }

  private get allAccommodationsIds() {
    return this.accommodations.map(
      ({ accommodation_details }) => accommodation_details.id,
    );
  }

  private pushSuccessNotification() {
    this.notifications.success('notifications.generic_success');
  }

  private setVisibleAccommodations() {
    this.visibleAccommodationsControl.setValue(
      difference(this.allAccommodationsIds, this.filteredAccommodations),
      {
        emitEvent: false,
      },
    );
  }
}
