import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import { CollectedType, StatsWidgetResponse } from '../../models';
import { ChartLineItem } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

const getSeries = (
  data: StatsWidgetResponse,
  key: CollectedType,
): ChartLineItem[] => {
  return data.map((value) => ({
    name: moment(new Date(value.key)).format('DD.MM'),
    value: value.collected[key],
    date: new Date(value.key),
  }));
};

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
    chartLines: [],
  })),
  on(fromActions.loadSuccess, (state, { data, timing }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      chartLines: [
        ...state.chartLines,
        {
          name: 'adr_' + timing,
          visible: true,
          series: getSeries(data, 'adr'),
        },
        {
          name: 'rev_par_' + timing,
          visible: true,
          series: getSeries(data, 'revpar'),
        },
      ],
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadLastYearRequest, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.toggleLastYear, (state, { visible }) => ({
    ...state,
    chartLines: state.chartLines?.map((line) => {
      if (line.name.includes('last_year')) {
        return { ...line, visible };
      }
      return line;
    }),
  })),

  on(fromActions.setLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function adrRevparChartWidgetReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
