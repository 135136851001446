import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableauModalPrices } from '../../../../models';

interface Prices {
  currency_symbol: string;
  price_total: number;
  total_discount: number;
  price_paid: number;
  price_unpaid: number;
}

type PriceToShow = {
  [Property in keyof Omit<Prices, 'currency_symbol'>]: boolean;
};

@Component({
  selector: 'by-tableau-modal-prices',
  templateUrl: './tableau-modal-prices.component.html',
  styleUrls: ['./tableau-modal-prices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauModalPricesComponent {
  @Input() data: Prices;

  @Input() pricesToShow: Partial<PriceToShow> = {
    price_paid: true,
    price_total: true,
    price_unpaid: true,
    total_discount: true,
  };

  @Input() loading = false;

  constructor() {}

  nzParagraph = { rows: 2, width: '100%' };

  get currencySymbol() {
    return this.data?.currency_symbol;
  }
}
