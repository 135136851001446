import { Pipe, PipeTransform } from '@angular/core';
import { RootState } from '../root-store/root-state';
import { Store, select } from '@ngrx/store';
import { UserPreferencesStoreSelectors } from '../root-store/user-preferences-store';
import { DEFAULT_PAYMENTS_COLORS } from '../core/helpers/default-colors';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'paymentStatusColor',
  standalone: true,
})
export class PaymentStatusColorPipe implements PipeTransform {
  paymentStatusColorPreference$ = this.store.pipe(
    select(
      UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
        'paymentStatusColor',
      ),
    ),
  );

  defaultColors = DEFAULT_PAYMENTS_COLORS;

  constructor(private store: Store<RootState>) {}

  transform(key: string): Observable<string> {
    return this.paymentStatusColorPreference$.pipe(
      map(
        (userPreferences) =>
          userPreferences?.[key] || this.defaultColors?.[key],
      ),
    );
  }
}
