import * as moment from 'moment';

export function datesFromRange(
  from: Date,
  to: Date,
  comprendEnd = true,
): Date[] {
  if (!from || !to) {
    return [];
  }
  const method = comprendEnd ? 'isSameOrBefore' : 'isBefore';
  const selectedDates = [];
  let currentDate = from;
  while (moment(currentDate)[method](moment(to), 'days')) {
    currentDate.setHours(0, 0, 0, 0);
    selectedDates.push(currentDate);
    currentDate = moment(currentDate).add('1', 'day').toDate();
  }
  return selectedDates;
}
