import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

import {
  TableauBoxComponent,
  TableauDraggingItem,
  TableauNote,
  TableauRoom,
  TableauRow,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { TableauBoxWrapperComponent } from '../tableau-box-wrapper/tableau-box-wrapper.component';

type TableauNoteBox = TableauBoxComponent<TableauNote>;

@Component({
  selector: 'by-tableau-note-box',
  templateUrl: './tableau-note-box.component.html',
  styleUrls: ['./tableau-note-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauNoteBoxComponent implements TableauNoteBox, OnChanges {
  @ViewChildren(NzTooltipDirective)
  tooltipDirective: NzTooltipDirective[];

  @Input()
  data: TableauNote;

  @Input()
  draggingItem: TableauDraggingItem;

  @Input()
  row: TableauRow<TableauRoom>;

  @Input()
  userCanWrite: boolean;

  @Input()
  searchValue: string;

  @Input()
  boxWrapperComponent: TableauBoxWrapperComponent;

  constructor(
    private store: Store<RootState>,
    private nzContextMenuService: NzContextMenuService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { boxWrapperComponent, data } = changes;

    if (boxWrapperComponent && this.boxWrapperComponent) {
      this.setBoxColors();
    }

    if (data || boxWrapperComponent) {
      this.setBoxSearchValue();
    }
  }

  onOpenContextMenu(
    $event: MouseEvent,
    dropdownComponent: NzDropdownMenuComponent,
  ) {
    this.nzContextMenuService.create($event, dropdownComponent);
  }

  onCloseContextMenu() {
    this.nzContextMenuService.close();
  }

  onOpenNote() {
    this.store.dispatch(
      TableauActions.openNoteModal({ note: this.data, row: this.row }),
    );
  }

  onDeleteNote() {
    this.store.dispatch(
      TableauActions.deleteNoteRequest({
        noteId: this.data.id,
      }),
    );
  }

  private setBoxColors() {
    this.boxWrapperComponent.setBoxBorder('2px solid #ffa234');
    this.boxWrapperComponent.setBoxBackground('#fff');
  }

  private setBoxSearchValue() {
    this.boxWrapperComponent?.setSearchLabel(this.data?.title);
  }

  get triggerTooltip(): 'hover' | null {
    return this.draggingItem ? null : 'hover';
  }

  onMouseDownTooltip() {
    this.tooltipDirective.forEach((tooltip) => tooltip?.hide());
  }
}
