import { AfterViewInit, Directive, Input } from '@angular/core';
import { isNil } from 'lodash';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';

@Directive({
  selector: '[byInputNumberParse]',
})
export class InputNumberParseDirective implements AfterViewInit {
  @Input('currencySymbol') set _currencySymbol(currencySymbol: string) {
    this.currencySymbol = currencySymbol;
    this.ngAfterViewInit();
  }

  currencySymbol = '';

  nzMin: number;

  constructor(private nzInputNumber: NzInputNumberComponent) {}

  ngAfterViewInit() {
    this.nzMin = isFinite(this.nzInputNumber.nzMin)
      ? this.nzInputNumber.nzMin
      : null;

    this.nzInputNumber.nzParser = this.currencyParser;
    this.nzInputNumber.nzFormatter = this.currencyFormatter;
  }

  currencyFormatter = (value: number) => {
    return `${this.currencySymbol} ${
      !isNil(this.nzMin) && value < this.nzMin ? this.nzMin : value
    }`;
  };
  currencyParser = (value: string) => {
    const valueParsed = value.replace(`${this.currencySymbol} `, '');
    return !isNil(this.nzMin) && +valueParsed < this.nzMin
      ? `${this.nzMin}`
      : valueParsed;
  };
}
