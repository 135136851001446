import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormItemComponentBase } from '../form-items/form-item-component-base';

@Component({
  selector: 'by-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuestionComponent,
      multi: true,
    },
  ],
})
export class QuestionComponent
  extends FormItemComponentBase
  implements OnInit, ControlValueAccessor
{
  @Input() label: string;

  @Input() labelTemplate: TemplateRef<any>;

  @Input() isDisabled = false;

  @Input() yesValue = true;

  @Input() noValue = false;

  @Input() byStyle = {};

  @Input() typeLayout: 'small' | 'default' = 'default';

  @Input() defaultClass = 'by-flex by-flex-between by-flex-middle';

  value = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  onTouched: any = () => {};
  onChange: any = () => {};

  writeValue(value: boolean) {
    if (this.value === value) {
      return;
    }

    this.value = value;
  }

  updateValue(value: boolean) {
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  override validate() {}

  override updateView(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {}
}
