<div
  *ngIf="!data.child_reservation"
  class="by-tableau-quote by-tableau-box"
  [style.width]="boxWrapperComponent?.width + 'px'"
  [style.border-color]="reservationsColors.quotes"
  nz-popover
  [nzPopoverContent]="triggerPopover ? quoteDetailsTpl : null"
  [nzPopoverMouseEnterDelay]="TableauConfig.BoxPopoverDelay"
  nzPopoverOverlayClassName="by-tableau-reservation-details-popover"
  cdkDrag
  [cdkDragPreviewClass]="'by-tableau-box--dragging'"
  [cdkDragStartDelay]="isMobile && TableauConfig.DraggingDelayTime"
  [cdkDragDisabled]="!userCanWrite"
  (cdkDragStarted)="onDragStarted()"
  (cdkDragDropped)="onDragDropped($event)"
  byTableauScrollDragErrorCatcher
  (dragScrollError)="onDragScrollError()"
  (contextmenu)="userCanWrite && onOpenContextMenu($event, contextMenu)"
  (click)="onOpenTableauQuoteDetailsModal()"
  (mousedown)="onMouseDownPopover()"
>
  <ng-template #quoteDetailsTpl>
    <by-tableau-quote-details-popover
      [quote]="data"
    ></by-tableau-quote-details-popover>
  </ng-template>
  <div *cdkDragPlaceholder></div>

  <span>{{ data.label | upperfirst }}</span>
</div>

<div
  *ngIf="data.child_reservation"
  class="by-tableau-quote by-tableau-quote--child"
  nz-tooltip
  [nzTooltipTitle]="'child_quote' | translate | upperfirst"
  nzTooltipPlacement="right"
  (mousedown)="onMouseDownTooltip()"
></div>

<nz-dropdown-menu #contextMenu="nzDropdownMenu">
  <ul nz-menu nzInDropDown (nzClick)="onCloseContextMenu()">
    <li (click)="onMove()" nz-menu-item>
      <i class="fas fa-arrows-alt"></i>
      {{ 'move' | translate | capitalize }}
    </li>
  </ul>
</nz-dropdown-menu>
