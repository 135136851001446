<ng-container [formGroup]="form">
  <by-spin
    class="text-align--end"
    [nzSize]="inputSize"
    [nzSpinning]="priceLoading"
  >
    <div class="by-flex by-flex-middle">
      <i
        *ngIf="!showEditor"
        class="fal fa-pencil-alt by-mr-10 pointer"
        nz-tooltip
        [nzTooltipTitle]="'edit_total_price' | translate | upperfirst"
        (click)="showEditor = true"
      ></i>

      <ng-container *ngIf="showEditor; else priceLabel">
        <nz-input-number
          byDecimalOnly
          [nzSize]="inputSize"
          formControlName="price_total"
          [nzMin]="addonsTotalPrice"
          [nzStep]="1"
          [nzFormatter]="currencySymbolFormatter"
          [nzParser]="currencyParser"
          (nzBlur)="onTouched()"
        ></nz-input-number>

        <div class="by-flex by-flex-middle">
          <button
            (click)="onCancel()"
            nz-button
            nzDanger
            nzType="primary"
            nzShape="circle"
            [nzSize]="inputSize"
            nz-tooltip
            [nzTooltipTitle]="'cancel' | translate | upperfirst"
            class="by-ml-5 ant-btn-icon-only"
          >
            <i class="far fa-times"></i>
          </button>

          <button
            (click)="onConfirm()"
            nz-button
            nzType="primary"
            nzShape="circle"
            [nzSize]="inputSize"
            nz-tooltip
            [nzTooltipTitle]="'confirm' | translate | upperfirst"
            class="by-ml-5 ant-btn-icon-only"
          >
            <i class="far fa-check"></i>
          </button>
        </div>
      </ng-container>

      <ng-template #priceLabel>
        <span>{{
          totalPriceControl.value | toCurrency : currency?.symbol
        }}</span>
      </ng-template>
    </div>
  </by-spin>
</ng-container>
