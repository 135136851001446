import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { last } from 'lodash';

import { formatDate, isWeekend } from '../../../../../helpers';
import { RootState } from '../../../../../root-store/root-state';
import { getMonthsFromDays } from '../../helpers/get-months-from-days';
import { TableauExportBuilder } from '../tableau-export-builder';
import {
  FIRST_DAYS_COLUMN,
  getDefaultCellStyle,
  ROW_HEIGHT,
} from '../tableau-export.config';

@Injectable()
export class HeaderBuilderService implements TableauExportBuilder {
  build(state: RootState, worksheet: ExcelJS.Worksheet) {
    this.buildMonths(state, worksheet);
    this.buildDays(state, worksheet);
  }

  private buildMonths(state: RootState, worksheet: ExcelJS.Worksheet) {
    const { days } = state.tableau_2;

    const months = getMonthsFromDays(days);

    const merges: Array<{ from: number; to: number }> = [];

    // Building row
    const row = worksheet.addRow([
      ...new Array(FIRST_DAYS_COLUMN - 1).fill(null),
      ...months.reduce((cells, month, index) => {
        const previousMerge = last(merges);

        const mergeFrom = previousMerge
          ? previousMerge?.to + 1
          : FIRST_DAYS_COLUMN;

        merges.push({ from: mergeFrom, to: mergeFrom + month.colspan - 1 });

        const monthCellText =
          month.colspan === 1
            ? formatDate(month.day, 'MMM')
            : formatDate(month.day, 'MMMM YYYY');

        cells.push(
          monthCellText.toUpperCase(),
          ...new Array(month.colspan - 1).fill(null),
        );

        return cells;
      }, []),
    ]);

    // Merge cells by month
    merges.forEach((merge) => {
      worksheet.mergeCells(row.number, merge.from, row.number, merge.to);
    });

    // Set cells styles
    row.eachCell((cell) => {
      cell.style = getDefaultCellStyle();
    });

    row.height = ROW_HEIGHT;
  }

  private buildDays(state: RootState, worksheet: ExcelJS.Worksheet) {
    const { days } = state.tableau_2;

    const daysCells = new Map<number, Date>();

    // Building row
    const row = worksheet.addRow([
      ...new Array(FIRST_DAYS_COLUMN - 1).fill(null),
      ...days.map((day, index) => {
        daysCells.set(FIRST_DAYS_COLUMN + index, day);
        return formatDate(day, 'D');
      }),
    ]);

    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const date = daysCells.get(colNumber);

      let cellColor = 'f3f3f5';

      if (date && isWeekend(date)) {
        cellColor = 'fdecf6';
      }

      cell.style = getDefaultCellStyle({
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor },
        },
      });
    });

    row.height = ROW_HEIGHT;
  }
}
