<div class="by-flex by-flex-between by-tableau-modal-prices">
  <div class="box" *ngIf="pricesToShow['price_total']">
    <ng-container
      *ngTemplateOutlet="
        !loading ? priceTpl : skeleton;
        context: {
          nzParagraph: nzParagraph,
          price: data?.price_total || 0,
          label: 'total'
        }
      "
    ></ng-container>
  </div>
  <div
    *ngIf="pricesToShow['total_discount'] && data?.total_discount"
    class="box"
  >
    <ng-container
      *ngTemplateOutlet="
        !loading ? priceTpl : skeleton;
        context: {
          nzParagraph: nzParagraph,
          price: data?.total_discount || 0,
          label: 'discount'
        }
      "
    ></ng-container>
  </div>
  <div class="box" *ngIf="pricesToShow['price_paid']">
    <ng-container
      *ngTemplateOutlet="
        !loading ? priceTpl : skeleton;
        context: {
          nzParagraph: nzParagraph,
          price: data?.price_paid || 0,
          label: 'paid'
        }
      "
    ></ng-container>
  </div>

  <div class="box" *ngIf="pricesToShow['price_unpaid']">
    <ng-container
      *ngTemplateOutlet="
        !loading ? priceTpl : skeleton;
        context: {
          nzParagraph: nzParagraph,
          price: data?.price_unpaid || 0,
          label: 'to_pay'
        }
      "
    ></ng-container>
  </div>
</div>

<ng-template #priceTpl let-price="price" let-label="label">
  <span>{{ label | translate | upperfirst }}</span>
  <span>
    <b>
      <by-currency-format
        [currencyValue]="price"
        [currencySymbol]="currencySymbol"
      ></by-currency-format>
    </b>
  </span>
</ng-template>

<ng-template #skeleton let-style="style" let-nzParagraph="nzParagraph">
  <nz-skeleton
    [nzActive]="true"
    [nzParagraph]="nzParagraph"
    [nzTitle]="false"
    [ngStyle]="style"
  ></nz-skeleton>
</ng-template>
