<div class="header" *ngIf="dates" [class.modalDraggableMove]="draggable">
  <div class="by-flex by-flex-middle" [class.modalDraggableMove]="draggable">
    <i class="by-mr-2 fas fa-exchange-alt" style="line-height: unset"></i>
    <span class="ellipsis_50pr-50px">
      {{ guestFullName | capitalize }}
    </span>
    <span class="by-fs-11 by-ml-5">
      [{{ (range | head | formatDate) + ' - ' + (range | last | formatDate) }}]
    </span>
  </div>
  <div class="header-divider"></div>
</div>

<div class="accommodation-info" *ngIf="splitInformations">
  <div class="accommodation-box--border">
    <div class="text-align--center">
      <div class="accommodation-box__item">
        <span>{{ splitInformations.original.accommodation }}</span>
      </div>
      <div class="accommodation-box__item" style="color: #fff">
        <span>{{ splitInformations.original.tableau_label }}</span>
      </div>
    </div>
  </div>
  <div class="rounded-icon">
    <i class="icon-change far fa-sync"></i>
  </div>
  <div class="accommodation-box">
    <div class="text-align--center">
      <div class="accommodation-box__item">
        <span>{{ splitInformations.new.accommodation }}</span>
      </div>
      <div class="accommodation-box__item" style="color: #fff">
        <span>{{ splitInformations.new.tableau_label }}</span>
      </div>
    </div>
  </div>
</div>

<by-spin [nzSpinning]="loading">
  <div class="content-move">
    <ng-container *ngIf="showWarnings">
      <div class="alert-box__list">
        <div class="alert-box__item" *ngIf="warnings?.length">
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{ warnings | join: ', ' }}</span>
        </div>

        <div class="alert-box__item" *ngIf="isClosed">
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{ 'message_move_closed' | translate | upperfirst }}</span>
        </div>

        <div class="alert-box__item" *ngIf="withoutAvail">
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{
            'message_move_availability' | translate | upperfirst
          }}</span>
        </div>

        <div class="alert-box__item" *ngIf="hasDoorCode">
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{
            'accommodation_has_door_code_remeber_to_generate_new_code'
              | translate
              | upperfirst
          }}</span>
        </div>

        <div
          class="alert-box__item"
          *ngIf="newTotal !== originalTotal && newTotal > 0 && !noPrice"
        >
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{ 'message_move_price' | translate | upperfirst }}</span>
        </div>

        <div class="alert-box__item" *ngIf="showRateplanAndTreatmentSelect">
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{
            'message_move_new_configuration' | translate | upperfirst
          }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="showRateplanAndTreatmentSelect"
      [formGroup]="splitOptionsForm"
    >
      <div nz-row nzJustify="space-around">
        <div nz-col>
          <div nz-row nzAlign="middle" nzJustify="between">
            <div nz-col>
              <nz-form-label class="required-input-red">
                {{ 'rateplans' | translate | capitalize }}
              </nz-form-label>
            </div>
          </div>
        </div>

        <div nz-col>
          <nz-form-control>
            <nz-select
              nzSize="small"
              style="min-width: 200px"
              [nzDropdownMatchSelectWidth]="false"
              nzPlaceHolder="{{
                'placeholder.select'
                  | translate: { param: 'rateplans' | translate }
              }}"
              formControlName="rateplan_id"
            >
              <nz-option
                *ngFor="let rateplan of rateplans"
                [nzLabel]="rateplan.name"
                [nzValue]="rateplan.id"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </div>
      </div>

      <div>
        <div nz-row nzJustify="space-around">
          <div nz-col>
            <div nz-row nzAlign="middle" nzJustify="between">
              <div nz-col>
                <nz-form-label class="required-input-red">
                  {{ 'treatments' | translate | capitalize }}
                </nz-form-label>
              </div>
            </div>
          </div>

          <div nz-col>
            <nz-form-control>
              <nz-select
                nzSize="small"
                style="min-width: 200px"
                [nzDropdownMatchSelectWidth]="false"
                nzPlaceHolder="{{
                  'placeholder.select'
                    | translate: { param: 'treatments' | translate }
                }}"
                formControlName="treatment_id"
              >
                <nz-option
                  *ngFor="let treatment of treatments"
                  [nzLabel]="treatment.name"
                  [nzValue]="treatment.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="newTotal !== originalTotal && newTotal > 0 && !noPrice"
    >
      <div class="radio_group_move_reservation_modal">
        <nz-radio-group [(ngModel)]="modelPrice" class="flexRadioGroup">
          <label
            nz-radio
            nzValue="original"
            class="radio_group_move_reservation_modal--old-price"
          >
            <div class="radio_group_move_reservation_modal__span-container">
              <span class="radio_group_move_reservation_modal__span--label">
                {{ 'old_price' | translate | capitalize }}
              </span>
              <span class="radio_group_move_reservation_modal__span--price">
                <by-currency-format
                  [currencyValue]="originalTotal"
                  [currencySymbol]="currencySymbol"
                ></by-currency-format>
              </span>
            </div>
            <i
              class="by-ml-2px fas fa-info-circle pointer"
              nz-popover
              [nzPopoverContent]="dayPriceOldTemplate"
              [nzPopoverTitle]="dayPriceTitle"
              nzPopoverPlacement="topRight"
            ></i>
          </label>
          <div class="light-grey-vertical-divisor">&nbsp;</div>
          <label
            nz-radio
            nzValue="moved"
            class="radio_group_move_reservation_modal--new-price"
          >
            <div class="radio_group_move_reservation_modal__span-container">
              <span class="radio_group_move_reservation_modal__span--label">
                {{ 'new_price' | translate | capitalize }}
              </span>
              <span class="radio_group_move_reservation_modal__span--price">
                <by-currency-format
                  [currencyValue]="newTotal"
                  [currencySymbol]="currencySymbol"
                ></by-currency-format>
              </span>
            </div>
            <i
              class="by-ml-2px fas fa-info-circle pointer"
              nz-popover
              [nzPopoverContent]="dayPriceNewTemplate"
              [nzPopoverTitle]="dayPriceTitle"
              nzPopoverPlacement="topRight"
            ></i>
          </label>
        </nz-radio-group>
        <ng-template #dayPriceTitle>
          <div class="day-price-title">
            <span>{{ 'price_day' | translate }}</span>
          </div>
        </ng-template>
        <ng-template #dayPriceOldTemplate>
          <div class="day-price-content">
            <ng-container *ngFor="let date of dates">
              <p *ngIf="originalDays[date] as day">
                {{ date | formatDate }}:
                {{
                  day.total - (day.discountValue || 0)
                    | toCurrency: currencySymbol
                }}
              </p>
            </ng-container>
          </div>
        </ng-template>
        <ng-template #dayPriceNewTemplate>
          <div class="day-price-content">
            <ng-container *ngFor="let date of dates">
              <p *ngIf="newDays[date] as day">
                {{ date | formatDate }}:
                {{
                  day.total - (day.discountValue || 0)
                    | toCurrency: currencySymbol
                }}
              </p>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</by-spin>
