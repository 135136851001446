import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import {
  Property,
  TableauMappingAccommodationProperty,
  TableauRow,
  TableauRowComponent,
} from '../../../../models';

type TableauPropertyRow = TableauRowComponent<
  TableauMappingAccommodationProperty
>;

@Component({
  selector: 'by-tableau-property-row',
  templateUrl: './tableau-property-row.component.html',
  styleUrls: ['./tableau-property-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauPropertyRowComponent
  implements TableauPropertyRow, OnChanges {
  @Input()
  row: TableauRow;

  @Input()
  data: TableauMappingAccommodationProperty;

  @Input()
  property: Property;

  constructor() {}

  ngOnChanges() {}
}
