import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import * as moment from 'moment';
import { SubSink } from 'subsink';

import { nightsOfPeriod } from '../../../../core/helpers';

interface Period {
  from: Date;
  to: Date;
}

type Dates = [Date, Date];

@Component({
  selector: 'by-reservation-form-dates',
  templateUrl: './reservation-form-dates.component.html',
  styleUrls: ['./reservation-form-dates.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormDatesComponent),
      multi: true,
    },
  ],
})
export class ReservationFormDatesComponent
  implements ControlValueAccessor, OnDestroy
{
  @Input()
  dateFromLabel: string;

  @Input()
  dateToLabel: string;

  @Input()
  showNights = true;

  form = this.formBuilder.group({
    from: [null],
    to: [null],
  });

  nights: number;

  onTouched: () => void;

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  disableDateTo = (date: Date) => {
    const { from } = this.form.value;
    return moment(date).isSameOrBefore(moment(from), 'days');
  };

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(dates: Dates) {
    const [from, to] = dates || [];
    this.setNights(from, to);
    this.form.patchValue({ from, to }, { emitEvent: false });
  }

  registerOnChange(fn: (dates: Dates) => void) {
    this.subs.add(
      this.form.valueChanges.subscribe((period: Period) => {
        const { from, to } = this.fixAndGetPeriod(period);
        this.setNights(from, to);
        fn([from, to]);
      }),
    );
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  private setNights(from: Date, to: Date) {
    if (!from || !to) {
      return;
    }

    this.nights = nightsOfPeriod(from, to);
  }

  private fixAndGetPeriod(period: Period): Period {
    const { from } = period;
    let { to } = period;

    if (moment(to).isSameOrBefore(moment(from), 'days')) {
      to = moment(from).add(1, 'day').toDate();
    }

    const newPeriod = { from, to };

    this.form.patchValue(newPeriod, { emitEvent: false });

    return newPeriod;
  }
}
