import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import * as moment from 'moment';
import { SubSink } from 'subsink';

import { ReservationStatus } from '../../../../config';
import { FormGetter } from '../../../../models';

interface Status {
  status: string;
  expiration_date: Date;
  availability_option: boolean;
}

type OnChange = (config: Status) => void;

@Component({
  selector: 'by-reservation-form-status',
  templateUrl: './reservation-form-status.component.html',
  styleUrls: ['./reservation-form-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormStatusComponent),
      multi: true,
    },
  ],
})
export class ReservationFormStatusComponent
  implements OnChanges, OnDestroy, ControlValueAccessor, FormGetter
{
  @Input()
  dates: Date[];

  @Input() isMobile = false;

  form = this.formBuilder.group({
    status: [null],
    expiration_date: [null],
    availability_option: [false],
  });

  showOptionable: boolean;

  onTouched: () => void;

  readonly ReservationStatus = ReservationStatus;

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  disableExpirationDate = (current: Date): boolean => {
    const [dateFrom] = this.dates || [];

    return (
      moment(current).isAfter(moment(dateFrom)) ||
      moment(current).isBefore(moment())
    );
  };

  ngOnChanges(changes: SimpleChanges) {
    const { dates } = changes;

    if (dates && this.dates) {
      this.setDates();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(status: Status) {
    this.form.patchValue(status, { emitEvent: false });
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(
      this.form.valueChanges.subscribe((statusConfig: Status) => {
        if (statusConfig.status !== ReservationStatus.Optionable) {
          statusConfig = {
            ...statusConfig,
            expiration_date: null,
            availability_option: null,
          };
        }

        onChange(statusConfig);
      }),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  getForms() {
    return [this.form];
  }

  private setDates() {
    const [dateFrom] = this.dates;

    let status: Partial<Status> = { expiration_date: dateFrom };

    if (moment(dateFrom).isBefore(moment(), 'days')) {
      this.showOptionable = false;
      status = { ...status, status: ReservationStatus.Confirmed };
    } else {
      this.showOptionable = true;
    }

    this.form.patchValue(status);
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
