import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  showChildrenRangesConditionMapConstant,
  showPeriodConditionMapConstant,
  showQuantityAndRateConditionMapConstant,
} from '../../../helpers/bills-helpers';
import { EstimateAddonModalData } from '../../../models/objects/estimate-addon-modal';

@Component({
  selector: 'by-addon-estimate-modal-cart',
  templateUrl: './addon-estimate-modal-cart.component.html',
  styleUrls: ['./addon-estimate-modal-cart.component.scss'],
})
export class AddonEstimateModalCartComponent implements OnInit {
  @Input() set cart(cart: EstimateAddonModalData[]) {
    if (!cart) {
      return;
    }

    this.cartData = cart;
    this.total = cart.reduce((cartTotal, { total }) => (cartTotal += total), 0);
  }

  @Input()
  public currencySymbol = '€';

  @Output()
  public deleteCartItem = new EventEmitter<number>();

  total = 0;
  cartData: EstimateAddonModalData[] = [];

  showPeriodConditionMapConstant = showPeriodConditionMapConstant;
  showChildrenRangesConditionMapConstant = showChildrenRangesConditionMapConstant;
  showQuantityAndRateConditionMapConstant = showQuantityAndRateConditionMapConstant;

  constructor() {}

  ngOnInit() {}
}
