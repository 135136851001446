import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MaintenanceReport } from '../../../../models';

@Component({
  selector: 'by-maintenance-reports-list',
  templateUrl: './maintenance-reports-list.component.html',
  styleUrls: ['./maintenance-reports-list.component.scss'],
})
export class MaintenanceReportsListComponent {
  @Input()
  reports: MaintenanceReport[];

  @Input()
  canWrite: boolean;

  @Output()
  edit = new EventEmitter<MaintenanceReport>();

  @Output()
  remove = new EventEmitter<MaintenanceReport>();

  @Output()
  showMedia = new EventEmitter<MaintenanceReport>();
}
