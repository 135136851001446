import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';

import { TableauRow } from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauRowComponents } from '../../tableau-components-map';
import {
  TableauExportBuilder,
  TableauExportRowBuilder,
} from '../tableau-export-builder';

import { AccommodationRowBuilderService } from './accommodation-row-builder.service';
import { EventRowBuilderService } from './event-row-builder.service';
import { FloorRowBuilderService } from './floor-row-builder.service';
import { PropertyRowBuilderService } from './property-row-builder.service';
import { PropertyStatsRowBuilderService } from './property-stats-row-builder.service';
import { RoomRowBuilderService } from './room-row-builder.service';

@Injectable()
export class RowsBuilderService implements TableauExportBuilder {
  private rowsBuilders: { [component: string]: TableauExportRowBuilder } = {
    [TableauRowComponents.Event]: this.eventRowBuilder,
    [TableauRowComponents.Property]: this.propertyRowBuilder,
    [TableauRowComponents.Accommodation]: this.accommodationRowBuilder,
    [TableauRowComponents.Room]: this.roomRowBuilder,
    [TableauRowComponents.Ob]: this.roomRowBuilder,
    [TableauRowComponents.Floor]: this.floorRowBuilder,
    [TableauRowComponents.PropertyStats]: this.propertyStatsRowBuilder,
  };

  constructor(
    private roomRowBuilder: RoomRowBuilderService,
    private floorRowBuilder: FloorRowBuilderService,
    private eventRowBuilder: EventRowBuilderService,
    private propertyRowBuilder: PropertyRowBuilderService,
    private accommodationRowBuilder: AccommodationRowBuilderService,
    private propertyStatsRowBuilder: PropertyStatsRowBuilderService,
  ) {}

  build(state: RootState, worksheet: ExcelJS.Worksheet) {
    const {
      tableau_2: { ids, entities },
    } = state;

    const rows = ids.map((id) => entities[id]);

    rows.forEach((row) => {
      this.buildRow(row, state, worksheet);
    });
  }

  private buildRow(
    row: TableauRow,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const builder = this.rowsBuilders[row.component];
    builder?.build(row, state, worksheet);
  }
}
