import { minBy } from 'lodash';
import * as moment from 'moment';

import { StatsPreset } from '../../../../models';
import { IProperty } from '../../properties/models/property.model';
import {
  eachQuarterOfInterval,
  endOfQuarter,
  isAfter,
  isBefore,
  startOfQuarter,
  startOfYear,
  subYears,
} from 'date-fns';
import { removeTime } from '../../../../helpers';

export const PERIOD_PRESET: StatsPreset[] = getPastYearsQuartersPresets(2);

export function getPastYearsQuartersPresets(years = 1): StatsPreset[] {
  return [
    ...eachQuarterOfInterval({
      start: startOfYear(subYears(new Date(), years - 1)),
      end: new Date(),
    }).map((quarterFirstMonth) => ({
      value: [
        startOfQuarter(quarterFirstMonth),
        endOfQuarter(quarterFirstMonth),
      ],
      formatStartDate: 'MMM',
      formatEndDate: 'MMM y',
    })),
    {
      key: 'current_year',
      value: [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
      order: 10,
    },
    {
      key: 'last_two_years',
      value: [
        moment().endOf('year').subtract(1, 'year').startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
      order: 11,
    },
    {
      key: 'last_three_years',
      value: [
        moment().endOf('year').subtract(2, 'year').startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
      order: 12,
    },
    {
      key: 'next_year',
      value: [
        moment().startOf('year').add(1, 'years').toDate(),
        moment().endOf('year').add(1, 'years').toDate(),
      ],
      order: 13,
    },
    {
      key: 'beginning',
      value: [
        moment().endOf('year').subtract(10, 'years').toDate(),
        moment().endOf('year').toDate(),
      ],
      order: 14,
    },
  ];
}

export function getMinPropertyActivationDate(properties: IProperty[]): Date {
  return minBy(
    properties.map(({ activated_date }) => new Date(activated_date)),
    (date) => date.getTime(),
  );
}

export function getPeriodPresetsByPropertiesActivation(
  properties: IProperty[],
): StatsPreset[] {
  const minPropertyActivationDate = getMinPropertyActivationDate(properties);

  let presets = PERIOD_PRESET;

  if (!minPropertyActivationDate) {
    return presets;
  }

  presets = presets.map((preset) => {
    if (preset.key !== 'beginning') {
      return preset;
    }

    return {
      ...preset,
      value: [minPropertyActivationDate, preset.value[1]],
    };
  });

  return presets.filter(({ value }) => {
    return moment(value[0]).isSameOrAfter(
      moment(minPropertyActivationDate),
      'days',
    );
  });
}

export function maxPresetsDate(
  presets: StatsPreset[],
  date: Date,
): StatsPreset[] {
  return presets
    .filter((preset) => {
      return moment(preset.value[0]).isSameOrBefore(moment(date), 'days');
    })
    .map((preset) => {
      if (moment(preset.value[1]).isAfter(moment(date), 'days')) {
        return { ...preset, value: [preset.value[0], date] };
      }

      return preset;
    });
}

export function getPastYearsQuartersPropertiesPresets(
  properties: IProperty[],
  years = 1,
): StatsPreset[] {
  const minPropertyActivationDate = getMinPropertyActivationDate(properties);

  const presets: StatsPreset[] = getPastYearsQuartersPresets(years);

  if (!minPropertyActivationDate) {
    return presets;
  }

  return presets.filter(({ value }) => {
    return !isBefore(
      removeTime(value[1]),
      removeTime(minPropertyActivationDate),
    );
  });
}
