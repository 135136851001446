import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { formatDate, parseFileName } from '../../../../helpers';
import { RootState } from '../../../../root-store/root-state';

import { HeaderBuilderService } from './builders/header-builder.service';
import { LegendBuilderService } from './builders/legend-builder.service';
import { RowsBuilderService } from './builders/rows-builder.service';
import {
  BLOB_TYPE,
  DAYS_COLUMNS_SIZE,
  FIRST_COLUMN_SIZE,
} from './tableau-export.config';

@Injectable()
export class TableauExportService {
  constructor(
    private translate: TranslateService,
    private rowsBuilder: RowsBuilderService,
    private dateFormatter: DateFormatterService,
    private headerBuilder: HeaderBuilderService,
    private legendBuilder: LegendBuilderService,
  ) {}

  export(state: RootState): Observable<File> {
    const workbook = new ExcelJS.Workbook();

    const fileName = this.getFileName(state);

    workbook.creator = 'Beddy';
    workbook.created = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet(fileName);

    this.setColumns(state, worksheet);

    this.headerBuilder.build(state, worksheet);
    this.rowsBuilder.build(state, worksheet);
    this.legendBuilder.build(state, worksheet);

    this.setViews(worksheet);

    return from(workbook.xlsx.writeBuffer()).pipe(
      map((buffer) => new File([buffer], fileName, { type: BLOB_TYPE })),
    );
  }

  private getFileName(state: RootState): string {
    const {
      tableau_2: {
        period: { from: dateFrom, to: dateTo },
      },
    } = state;

    return parseFileName(
      `${this.translate.instant('tableau')} ${formatDate(
        dateFrom,
      )} ${formatDate(dateTo)}.xlsx`,
    );
  }

  private setColumns(state: RootState, worksheet: ExcelJS.Worksheet) {
    const {
      tableau_2: { days },
    } = state;

    worksheet.columns = [
      { key: 'accommodation', width: FIRST_COLUMN_SIZE },
      ...days.map(
        (day): Partial<ExcelJS.Column> => ({
          key: this.dateFormatter.toServerFormat(day),
          width: DAYS_COLUMNS_SIZE,
        }),
      ),
    ];
  }

  private setViews(worksheet: ExcelJS.Worksheet) {
    worksheet.views = [
      {
        state: 'frozen',
        ySplit: 2,
        xSplit: 1,
      },
    ];
  }
}
