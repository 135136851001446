import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccommodationRowBuilderService } from './builders/accommodation-row-builder.service';
import { EventRowBuilderService } from './builders/event-row-builder.service';
import { FloorRowBuilderService } from './builders/floor-row-builder.service';
import { HeaderBuilderService } from './builders/header-builder.service';
import { LegendBuilderService } from './builders/legend-builder.service';
import { PropertyRowBuilderService } from './builders/property-row-builder.service';
import { PropertyStatsRowBuilderService } from './builders/property-stats-row-builder.service';
import { RoomRowBuilderService } from './builders/room-row-builder.service';
import { RowsBuilderService } from './builders/rows-builder.service';
import { TableauExportService } from './tableau-export.service';

const SERVICES = [
  TableauExportService,
  HeaderBuilderService,
  RowsBuilderService,
  PropertyRowBuilderService,
  EventRowBuilderService,
  AccommodationRowBuilderService,
  RoomRowBuilderService,
  PropertyStatsRowBuilderService,
  FloorRowBuilderService,
  LegendBuilderService,
];

@NgModule({
  imports: [CommonModule],
  providers: SERVICES,
})
export class TableauExportModule {}
