import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import clip from 'text-clipper';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[by-collapsable-text]',
  templateUrl: './collapsable-text.component.html',
  styleUrls: ['./collapsable-text.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'expand',
        style({
          overflow: 'hidden',
          height: '*',
        }),
      ),
      state(
        'close',
        style({
          overflow: 'hidden',
          height: 'auto',
        }),
      ),
      transition(
        'close => expand',
        animate(
          '200ms ease-in-out',
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })]),
        ),
      ),
      transition(
        'expand => close',
        animate(
          '200ms ease-in-out',
          keyframes([style({ opacity: 0 }), style({ opacity: 1 })]),
        ),
      ),
    ]),
  ],
})
export class CollapsableTextComponent implements OnInit, OnChanges {
  @Input() originalText: string;
  @Input() maxLength = 1000;
  @Input() maxLines = 2;
  @Input() label: string;
  @Input() inline = false;
  @Input() display = 'inline';
  @Input() showMessage = 'show_everything';
  @Input() hideMessage = 'show_less';
  @Input() clipConfig: any = {
    html: true,
    maxLines: this.maxLines,
    breakWords: true,
    indicator: '...',
  };
  @Input() bypassSanitizer = false;

  collapsedStatus: 'close' | 'expand' = 'close';
  clippedText: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['originalText'].currentValue) {
      this.clipText();
    }
  }

  ngOnInit(): void {}

  toggleCollapse() {
    this.collapsedStatus === 'close'
      ? (this.collapsedStatus = 'expand')
      : (this.collapsedStatus = 'close');
  }

  clipText() {
    this.clippedText = clip(this.originalText, this.maxLength, this.clipConfig);
  }

  get isHtmlString() {
    return this.clipConfig?.html;
  }

  get showReadMore() {
    return this.originalText?.length > this.clippedText?.length;
  }
}
