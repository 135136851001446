import { Directive, HostListener, Input } from '@angular/core';

import { TableauRow } from '../../../../models';
import { TableauHoverService } from '../services/tableau-hover.service';

@Directive({
  selector: '[byTableauCellHoverDispatcher]',
})
export class TableauCellHoverDispatcherDirective {
  @Input()
  date: Date;

  @Input()
  row: TableauRow;

  constructor(private tableauHoverService: TableauHoverService) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    this.tableauHoverService.setHoveredCell({
      date: this.date,
      rowId: this.row.id,
    });
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.tableauHoverService.setHoveredCell(null);
  }
}
