<button
  [disabled]="byDisabled"
  nz-button
  [nzShape]="shape"
  [nzType]="type"
  [ngClass]="isDesktop ? byDesktopClass : mobileTheme"
  [nzLoading]="byLoading"
  class="custom-button by-button-mobile"
>
  <ng-content></ng-content>
</button>
