import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { first, flatten } from 'lodash';

import { nightsOfPeriod } from '../../../../core/helpers';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { INewReservation } from '../../../../core/models/api/reservation/reservaion.model';
import { showQuantityAndRateConditionMapConstant } from '../../../../helpers/bills-helpers';
import {
  AutomaticPaymentSummary,
  EstimateRateplan,
  FormGetter,
  ProportionalDayPrice,
  ReservationFormAccommodation,
} from '../../../../models';
import { EstimateResponse } from '../../../../models/responses/estimate.response';
import { Price } from '../reservation-form-price/reservation-form-price.component';

@Component({
  selector: 'by-reservation-form-summary',
  templateUrl: './reservation-form-summary.component.html',
  styleUrls: ['./reservation-form-summary.component.scss'],
})
export class ReservationFormSummaryComponent implements OnChanges, FormGetter {
  @ViewChildren('formGetter')
  formGetters: QueryList<FormGetter>;

  @Input()
  firstStepRequest: Partial<INewReservation>;

  @Input()
  accommodation: ReservationFormAccommodation;

  @Input()
  estimateResponse: EstimateResponse;

  @Input()
  estimateRateplans: EstimateRateplan[];

  @Input()
  proportionalDayPriceResponse: ProportionalDayPrice;

  @Input()
  priceLoading: boolean;

  @Input()
  currency: ICurrency;

  @Input()
  isMobile = false;

  @Output()
  proportionalDayPrice = new EventEmitter<ProportionalDayPrice>();

  @Output()
  priceChanged = new EventEmitter<Price>();

  @Output()
  addAddons = new EventEmitter();

  @Output()
  discountChanged = new EventEmitter();

  price: Price;

  priceWithAddons: Price;

  nights: number;

  selectedRateplanName: string;

  selectedTratemantName: string;

  addonsTotalPrice = 0;

  readonly showQuantityAndRateConditionMapConstant =
    showQuantityAndRateConditionMapConstant;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const { firstStepRequest } = changes;

    if (firstStepRequest && this.firstStepRequest) {
      this.setPrice();
      this.setNights();
      this.setRateplan();
      this.setAddonsTotalPrice();
    }
  }

  onPriceChanged(price: Price) {
    this.setPriceWithAddons();
    this.priceChanged.emit(price);
  }

  onPriceWithAddonsChanged(price: Price) {
    this.price = {
      ...price,
      price_total: price.price_total - this.addonsTotalPrice,
    };

    this.priceChanged.emit(this.price);
  }

  onProportionalDayPrice(proportionalDayPrice: ProportionalDayPrice) {
    this.proportionalDayPrice.emit({
      ...proportionalDayPrice,
      new_price: proportionalDayPrice.new_price - this.addonsTotalPrice,
      old_price: proportionalDayPrice.old_price - this.addonsTotalPrice,
    });
  }

  onDiscountChanged() {
    this.discountChanged.emit(this.price);
  }

  getForms() {
    return flatten(this.formGetters.map((formGetter) => formGetter.getForms()));
  }

  get accommodationRequest() {
    return first(this.firstStepRequest?.accommodations);
  }

  get addons() {
    return this.accommodationRequest?.addons || [];
  }

  private setPrice() {
    const {
      daily_rates,
      discount_type_id,
      discount_value,
      price,
      price_without_discount,
    } = this.accommodationRequest;

    this.price = {
      daily_rates,
      discount_type_id,
      discount_value,
      price_total: price,
      price_without_discount,
    };
  }

  private setPriceWithAddons() {
    this.priceWithAddons = {
      ...this.price,
      price_total: this.price.price_total + this.addonsTotalPrice,
    };
  }

  private setNights() {
    this.nights = nightsOfPeriod(
      new Date(this.accommodationRequest.arrival_date),
      new Date(this.accommodationRequest.departure_date),
    );
  }

  private setRateplan() {
    const rateplan = this.estimateRateplans.find(
      ({ id }) => id === this.accommodationRequest.rateplan_id,
    );

    const treatment = rateplan.treatments.find(
      ({ id }) => id === this.accommodationRequest.treatment_id,
    );

    this.selectedRateplanName = rateplan.name;
    this.selectedTratemantName = treatment.name;
  }

  private setAddonsTotalPrice() {
    this.addonsTotalPrice = (this.accommodationRequest.addons || []).reduce(
      (addonsTotalPrice, addon) => {
        return (addonsTotalPrice += addon.total);
      },
      0,
    );

    this.setPriceWithAddons();
  }
}
