import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';

import { ILanguage } from '../../core/models/api/generics/languages/language.model';
import {
  AddGuestForm,
  AddGuestFormControls,
  Company,
  GuestTypeOption,
  ICityTaxExemptionItem,
} from '../../models';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { IstatGuestModalData } from '../../models/objects/istat-guest-list-modal';

@Component({
  selector: 'by-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
})
export class AddCustomerComponent implements OnInit {
  @Input() options: Array<{
    name: string;
    id: number;
    type?: 'customer' | 'guest' | 'company';
    company?: Company;
  }> = [];

  @Input() customerTypes: GuestTypeOption[] = [];

  @Input() languages: ILanguage[] = [];

  @Input()
  cityTaxExemptionData: ICityTaxExemptionItem[];

  @Input() set defaultData(defaultData: Partial<AddGuestForm>) {
    if (!defaultData) {
      return;
    }

    this.guestDataForm.patchValue(defaultData);
  }

  @Input() dateForValidation: {
    arrival_date: Date;
    departure_date: Date;
  };

  @Output() customerSelected = new EventEmitter<number>();

  createGuest = false;

  guestDataForm: UntypedFormGroup;

  readonly nzModalData: Pick<
    IstatGuestModalData,
    | 'customerTypes'
    | 'languages'
    | 'cityTaxExemptionData'
    | 'defaultData'
    | 'dateForValidation'
  > = inject(NZ_MODAL_DATA);

  constructor(private formBuilder: UntypedFormBuilder) {
    const formData: AddGuestFormControls = {
      city_tax_exemption_id: [0, [Validators.required]],
      guest_type_id: [4, [Validators.required]],
      arrival_date: [null, [Validators.required]],
      departure_date: [null, [Validators.required]],
      customer_id: [null, [Validators.required]],
      language_id: [1, []],
    };

    this.guestDataForm = this.formBuilder.group(formData);
  }

  ngOnInit() {
    const {
      customerTypes,
      languages,
      cityTaxExemptionData,
      defaultData,
      dateForValidation,
    } = this.nzModalData;

    this.customerTypes = customerTypes;
    this.languages = languages;
    this.cityTaxExemptionData = cityTaxExemptionData;
    this.defaultData = defaultData;
    this.dateForValidation = dateForValidation;

    if (
      !this.customerTypes.some(
        ({ id }) => this.guestDataForm.value.guest_type_id == id,
      )
    ) {
      this.guestDataForm.controls.guest_type_id.patchValue(
        this.customerTypes[0].id,
      );
    }
  }

  get controlCustomerId() {
    return this.guestDataForm.get('customer_id');
  }

  get languageId() {
    return this.guestDataForm.get('language_id');
  }

  onResetCustomer(createGuest: boolean) {
    if (createGuest) {
      this.controlCustomerId.clearValidators();

      this.languageId.setValidators([Validators.required]);
    } else {
      this.controlCustomerId.setValidators([Validators.required]);

      this.languageId.clearValidators();
    }

    this.controlCustomerId.updateValueAndValidity();

    this.languageId.updateValueAndValidity();

    const { customer_id, ...oldValues } = this.guestDataForm.value;

    this.guestDataForm.reset(oldValues);
  }

  disabledStartDate = (current: Date): boolean => {
    if (!this.dateForValidation) {
      return false;
    }
    const departure = this.dateForValidation.departure_date;
    const arrival = this.dateForValidation.arrival_date;

    return (
      moment(current).isBefore(arrival, 'days') ||
      moment(current).isSameOrAfter(departure, 'days')
    );
  };

  disabledEndDate = (current: Date): boolean => {
    if (!this.dateForValidation) {
      return false;
    }
    const departure = this.dateForValidation.departure_date;
    const arrival = this.dateForValidation.arrival_date;

    return (
      moment(current).isSameOrBefore(arrival, 'days') ||
      moment(current).isAfter(departure, 'days')
    );
  };
}
