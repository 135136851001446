import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { invoiceArchives }) =>
    fromState.featureAdapter.setAll(invoiceArchives, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { id }) =>
    fromState.featureAdapter.removeOne(id, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.cancelCreationRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.cancelCreationSuccess, (state, { id }) =>
    fromState.featureAdapter.removeOne(id, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.cancelCreationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateProgressPercentagesRequest, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(
    fromActions.updateProgressPercentagesSuccess,
    (state, { invoiceArchives }) => {
      const data = invoiceArchives.map((archive) => {
        const { estimated_time, created_at } = archive;

        return {
          ...archive,
          estimated_time: createEstimatedTime(created_at, estimated_time),
        };
      });

      return fromState.featureAdapter.upsertMany(data, {
        ...state,
        isLoading: false,
        error: null,
      });
    },
  ),
  on(fromActions.updateProgressPercentagesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function invoiceArchivesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function createEstimatedTime(created_at, estimated_time) {
  const dateEndProcess = moment(created_at).add(estimated_time);

  const d = moment.duration(
    moment(dateEndProcess, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(new Date(), 'DD/MM/YYYY HH:mm:ss'),
    ),
  );

  const m = `${
    d.minutes() > 10 ? d.minutes() : `0${d.minutes() < 0 ? 0 : d.minutes()}`
  }`;
  const s = `${
    d.seconds() > 10 ? d.seconds() : `0${d.seconds() < 0 ? 0 : d.seconds()}`
  }`;
  return `${m}:${s}`;
}
