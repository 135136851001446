import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkVirtualScrollFixerDirective } from './cdk-virtual-scroll-fixer.directive';

@NgModule({
  declarations: [CdkVirtualScrollFixerDirective],
  imports: [CommonModule],
  exports: [CdkVirtualScrollFixerDirective],
})
export class CdkVirtualScrollFixerModule {}
