import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonMobileComponent } from './button-mobile.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ButtonMobileOnlyDesktopDirective } from './button-mobile-only-desktop.directive';
import { ButtonMobileOnlyMobileDirective } from './button-mobile-only-mobile.directive';
import { ButtonMobileIconDirective } from './button-mobile-icon.directive';

@NgModule({
  declarations: [
    ButtonMobileComponent,
    ButtonMobileOnlyDesktopDirective,
    ButtonMobileOnlyMobileDirective,
    ButtonMobileIconDirective,
  ],
  imports: [CommonModule, NzButtonModule],
  exports: [
    ButtonMobileComponent,
    ButtonMobileOnlyDesktopDirective,
    ButtonMobileOnlyMobileDirective,
    ButtonMobileIconDirective,
  ],
})
export class ButtonMobileModule {}
