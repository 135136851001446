<td
  nzLeft="0px"
  class="by-tableau-cell--first"
  [ngClass]="{
    'by-tableau-cell--hover': rowIsHovered
  }"
  byTableauCellHoverDispatcher
  [row]="row"
  nz-tooltip
  [nzTooltipTitle]="viewMode !== 'default' && data?.accommodation_details?.name"
  nzTooltipPlacement="right"
>
  OB
</td>

<td
  *ngFor="let day of days"
  class="by-tableau-cell"
  [ngClass]="{
    'by-tableau-cell--we': day | isWeekend,
    'by-tableau-cell--hover': rowIsHovered,
    'by-tableau-cell--super-hover':
      rowIsHovered && (day | sameDay: hoveredCell?.date)
  }"
  byTableauCellHoverDispatcher
  [date]="day"
  [row]="row"
>
  <by-tableau-box-wrapper
    *ngIf="items | getByDay: day as item"
    [zoom]="zoom"
    [box]="item"
    [row]="row"
    [viewOptions]="viewOptions"
    [searchValue]="searchValue"
  >
    <div
      by-tableau-box-resolver
      [draggingItem]="draggingItem"
      [zoom]="zoom"
      [item]="item"
      [reservationsColors]="reservationsColors"
      [channelsColors]="channelsColors"
      [viewOptions]="viewOptions"
      [userCanWrite]="userCanWrite"
      [splitMode]="splitMode"
      [row]="row"
      [period]="period"
      [hoveredReservation]="hoveredReservation"
      [isMobile]="isMobile"
    ></div>
  </by-tableau-box-wrapper>
</td>
