import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { upperCase } from 'lodash';

import { DateFormatterService } from '../../../../../core/services/date-formatter.service';
import { isWeekend } from '../../../../../helpers';
import { Event, TableauRow, TableauRowItem } from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauRowIndexService } from '../../../../../services';
import { TableauExportRowBuilder } from '../tableau-export-builder';
import {
  FIRST_DAYS_COLUMN,
  getDefaultCellStyle,
  ROW_HEIGHT,
} from '../tableau-export.config';

@Injectable()
export class EventRowBuilderService implements TableauExportRowBuilder {
  constructor(
    private translate: TranslateService,
    private dateFormatter: DateFormatterService,
    private tableauRowIndexService: TableauRowIndexService,
  ) {}

  build(
    tableauRow: TableauRow,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const { days } = state.tableau_2;
    const { firstColumnRowspan, items, spanIndex } = tableauRow;

    const daysCells = new Map<number, Date>();

    const eventsCells = new Map<number, Event>();

    const firstColumnText = firstColumnRowspan
      ? upperCase(this.translate.instant('events'))
      : null;

    // Building row
    const row = worksheet.addRow([
      firstColumnText,
      ...days.map((day, index) => {
        daysCells.set(FIRST_DAYS_COLUMN + index, day);

        const eventRowItem: TableauRowItem<Event> =
          items[this.dateFormatter.toServerFormat(day)];

        if (eventRowItem) {
          eventsCells.set(FIRST_DAYS_COLUMN + index, eventRowItem.data);
        }

        return eventRowItem?.data.title || null;
      }),
    ]);

    // Cell formatting
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const date = daysCells.get(colNumber);

      cell.style = getDefaultCellStyle();

      if (date && isWeekend(date)) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'fdecf6' },
        };
      }
    });

    // Add Events
    eventsCells.forEach((event, cellNumber) => {
      const cell = row.getCell(cellNumber);

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: event.color.replace('#', '') },
      };

      worksheet.mergeCells(
        row.number,
        cellNumber,
        row.number,
        cellNumber + event.length - 1,
      );
    });

    // Merging first column
    if (!firstColumnRowspan) {
      const { spanLength } = this.tableauRowIndexService.decode(tableauRow.id);
      if (spanLength === spanIndex + 1) {
        worksheet.mergeCells(row.number - spanIndex, 1, row.number, 1);
      }
    }

    row.height = ROW_HEIGHT;
  }
}
