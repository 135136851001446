import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  selectCurrencies,
  selectCurrencyProperty,
} from '../../core/+state/core.reducer';
import { generateSearchQuery } from '../../core/helpers/params-generator';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { DateFormatterService } from '../../core/services/date-formatter.service';
import { MaintenanceReportsContainerComponent } from '../../features/commons/maintenance-reports/maintenance-reports-container/maintenance-reports-container.component';
import { TableauConfig } from '../../features/commons/tableau-2/config';
import { TableauExportService } from '../../features/commons/tableau-2/export/tableau-export.service';
import { TableauQuoteDetailsComponent } from '../../features/commons/tableau-2/tableau-quote-details/tableau-quote-details.component';
import { TableauReservationDetailsComponent } from '../../features/commons/tableau-2/tableau-reservation-details/tableau-reservation-details.component';
import {
  Event,
  Floors,
  ISplitReservation,
  SplitReservationAccommodationResponse,
  TableauClousures,
  TableauHousekeeperInformation,
  TableauHousekeeperInformations,
  TableauMapping,
  TableauMappingAccommodationTableauNumbers,
  TableauMoveReservationRequest,
  TableauNote,
  TableauNotes,
  TableauQuotes,
  TableauReservationAccommodationDetails,
  TableauReservations,
  TableauSwappedReservation,
} from '../../models';
import {
  AccommodationsService,
  EventsService,
  FileSaverService,
  FloorsService,
  HousekeeperDashboardService,
  PriceQuotationService,
  TableauActionsService,
  TableauService,
} from '../../services';
import { ReservationTagService } from '../../services/reservation-tag.service';
import { TableauRowsService } from '../../services/tableau-rows.service';
import { TableauSplitEstimateService } from '../../services/tableau-split-estimate.service';
import { BedTypesFormComponent } from '../../shared/bed-types/bed-types-form/bed-types-form.component';
import { NotificationService } from '../../ui/services/notification.service';
import { RootState } from '../root-state';
import {
  UserPreferencesStoreActions,
  UserPreferencesStoreSelectors,
} from '../user-preferences-store';

import { TableauActions } from '.';
import * as fromActions from './actions';
import * as fromSelectors from './selectors';
import { TableauDataSelectorService } from '../../features/commons/tableau-2/services/tableau-data-selector.service';

@Injectable()
export class TableauStoreEffects {
  private tableauUserPreferences$ = this.store.pipe(
    select(
      UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
        TableauConfig.PreferencesKey,
      ),
    ),
  );

  private tableauSwappedReservations$ = this.store.pipe(
    select(fromSelectors.selectSwappedReservations),
  );

  private tableauState$ = this.store.pipe(select(fromSelectors.selectState));

  private tableauMapping$ = this.store.pipe(select(fromSelectors.selectMpping));

  private zoom$ = this.store.pipe(select(fromSelectors.selectZoom));

  private loading$ = this.store.pipe(select(fromSelectors.selectLoading));

  private currencies$ = this.store.pipe(select(selectCurrencies));

  private currency$ = this.store.pipe(select(selectCurrencyProperty));

  private buildRowSuccess$ = this.actions$.pipe(
    ofType(fromActions.buildRowsSuccess),
    withLatestFrom(this.loading$),
    skipWhile(([_, loading]) => loading),
    take(1),
  );

  buildRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.buildRowsRequest),
      withLatestFrom(this.tableauState$),
      debounceTime(200),
      map(([_, state]) => {
        return fromActions.buildRowsSuccess({
          rows: this.tableauRowsService.build(state),
        });
      }),
    ),
  );

  loadMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMappingRequest),
      switchMap(({ propertyIds }) =>
        this.tableauService
          .loadMapping(
            propertyIds,
            this.dateFormatter.toServerFormat(new Date()),
          )
          .pipe(
            mergeMap(({ data: mapping }: IResponseSuccess<TableauMapping>) => {
              return [
                fromActions.loadMappingSuccess({ mapping }),
                fromActions.buildRowsRequest({}),
              ];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadMappingFailure(error));
            }),
          ),
      ),
    ),
  );

  loadFloors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFloorsRequest),
      switchMap(({ propertyIds }) =>
        this.floorsService.load(propertyIds).pipe(
          mergeMap(({ data: floors }: IResponseSuccess<Floors>) => {
            return [
              fromActions.loadFloorsSuccess({ floors }),
              fromActions.buildRowsRequest({}),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFloorsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadEventsRequest),
      switchMap(({ request }) => {
        return this.eventsService.load(request).pipe(
          mergeMap(({ data: events }: IResponseSuccess<Event[]>) => {
            return [
              fromActions.loadEventsSuccess({ events }),
              fromActions.buildRowsRequest({}),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadEventsFailure(error));
          }),
        );
      }),
    ),
  );

  loadReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadReservationsRequest),
      switchMap(({ propertyIds, dateFrom, dateTo }) =>
        this.tableauService
          .loadReservations(propertyIds, dateFrom, dateTo)
          .pipe(
            mergeMap(
              ({
                data: reservations,
              }: IResponseSuccess<TableauReservations>) => {
                return [
                  fromActions.loadReservationsSuccess({ reservations }),
                  fromActions.buildRowsRequest({}),
                ];
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadReservationsFailure(error));
            }),
          ),
      ),
    ),
  );

  loadHousekeeperInformations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadHousekeeperInformationsRequest),
      switchMap(({ propertyIds, referenceDate }) =>
        this.tableauService
          .loadHousekeeperInformations(propertyIds, referenceDate)
          .pipe(
            mergeMap(
              ({
                data: housekeeperInformations,
              }: IResponseSuccess<TableauHousekeeperInformations>) => {
                return [
                  fromActions.loadHousekeeperInformationsSuccess({
                    housekeeperInformations,
                  }),
                  fromActions.buildRowsRequest({ skipLoading: true }),
                ];
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadHousekeeperInformationsFailure(error));
            }),
          ),
      ),
    ),
  );

  loadQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadQuotesRequest),
      switchMap(({ propertyIds, dateFrom, dateTo }) =>
        this.tableauService.loadQuotes(propertyIds, dateFrom, dateTo).pipe(
          mergeMap(({ data: quotes }: IResponseSuccess<TableauQuotes>) => {
            return [
              fromActions.loadQuotesSuccess({ quotes }),
              fromActions.buildRowsRequest({}),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadQuotesFailure(error));
          }),
        ),
      ),
    ),
  );

  loadNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadNotesRequest),
      switchMap(({ propertyIds, dateFrom, dateTo }) =>
        this.tableauService.loadNotes(propertyIds, dateFrom, dateTo).pipe(
          mergeMap(({ data: notes }: IResponseSuccess<TableauNotes>) => {
            return [
              fromActions.loadNotesSuccess({ notes }),
              fromActions.buildRowsRequest({}),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadNotesFailure(error));
          }),
        ),
      ),
    ),
  );

  createNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createNoteRequest),
      switchMap(({ note, onSuccess, onFailure }) =>
        this.tableauService.createNote(note).pipe(
          map(({ data: [newNote] }: IResponseSuccess<TableauNote[]>) => {
            this.pushSuccessNotification();

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.createNoteSuccess({ note: newNote });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.createNoteFailure(error));
          }),
        ),
      ),
    ),
  );

  updateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateNoteRequest),
      switchMap(({ note, onSuccess, onFailure }) =>
        this.tableauService.updateNote(note).pipe(
          map(({ data: [updatedNote] }: IResponseSuccess<TableauNote[]>) => {
            this.pushSuccessNotification();

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.updateNoteSuccess({ note: updatedNote });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.updateNoteFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteNoteRequest),
      switchMap(({ noteId, onSuccess, onFailure }) =>
        this.tableauService.deleteNote(noteId).pipe(
          map(() => {
            this.pushSuccessNotification();

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.deleteNoteSuccess({ noteId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.deleteNoteFailure(error));
          }),
        ),
      ),
    ),
  );

  loadAvailabilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAvailabilitiesRequest),
      switchMap(({ propertyIds, dateFrom, dateTo }) =>
        this.tableauService
          .loadAvailabilities(propertyIds, dateFrom, dateTo)
          .pipe(
            mergeMap(({ availabilities, clousures }) => {
              return [
                fromActions.loadAvailabilitiesSuccess({
                  availabilities,
                }),
                fromActions.loadClousuresSuccess({ clousures }),
                fromActions.buildRowsRequest({}),
              ];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadAvailabilitiesFailure(error));
            }),
          ),
      ),
    ),
  );

  loadClousures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadClousuresRequest),
      switchMap(({ propertyIds, dateFrom, dateTo }) =>
        this.tableauService.loadClousures(propertyIds, dateFrom, dateTo).pipe(
          mergeMap(
            ({ data: clousures }: IResponseSuccess<TableauClousures>) => {
              return [
                fromActions.loadClousuresSuccess({ clousures }),
                fromActions.buildRowsRequest({}),
              ];
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadClousuresFailure(error));
          }),
        ),
      ),
    ),
  );

  moveReservationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.moveReservationRequest,
        fromActions.moveReservationFromSwapRequest,
      ),
      withLatestFrom(this.tableauSwappedReservations$),
      switchMap(([action, swappedReservations]) => {
        const { reservation } = action;

        const splitRequest = this.getSplitRequest(action, swappedReservations);

        return this.tableauService.moveReservation(splitRequest).pipe(
          map(
            (
              response: IResponseSuccess<
                SplitReservationAccommodationResponse[]
              >,
            ) => {
              const {
                data: [splitData],
                meta,
              } = response;

              if (!meta.confirm_required) {
                // Gestisco i warnings, se non ce ne sono genero la notifica di success
                if (!this.errorHandler.handleWarnings(response)) {
                  this.pushSuccessNotification();
                }

                return fromActions.moveReservationSuccess({ reservation });
              }

              return fromActions.moveReservationWarning({
                splitData,
                splitRequest,
                warnings: meta.warnings,
                sourceAction: action,
              });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.moveReservationFailure(error));
          }),
        );
      }),
    ),
  );

  moveReservationWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveReservationWarning),
      withLatestFrom(this.currencies$),
      switchMap(([{ sourceAction, ...action }, currencies]) => {
        return this.tableauSplitEstimateService
          .estimate({
            ...sourceAction,
            ...action,
            currencies,
          })
          .pipe(
            map((extraPayload) => {
              if (!extraPayload) {
                return fromActions.moveReservationFailure(null);
              }

              return { ...sourceAction, extraPayload };
            }),
          );
      }),
    ),
  );

  moveQuoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveQuoteRequest),
      switchMap(({ quote, destinationRow }) => {
        return this.priceQuotationService
          .updateCartItem(
            quote.reservation_quote_id,
            quote.reservation_quote_option_id,
            quote.reservation_quote_option_accommodation_id,
            {
              accommodation_id: destinationRow.data.accommodation_details.id,
              accommodation_tableau_number_id: destinationRow.data.id,
              change_only_modified: 1,
            },
          )
          .pipe(
            map(() => {
              this.pushSuccessNotification();
              return fromActions.moveQuoteSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.moveQuoteFailure(error));
            }),
          );
      }),
    ),
  );

  setClosure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setClosureRequest),
      switchMap(({ request, onSuccess, onFailure }) => {
        return this.tableauService.setClosure(request).pipe(
          map(() => {
            this.pushSuccessNotification();

            if (onSuccess) {
              onSuccess();
            }

            return fromActions.setClosureSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            if (onFailure) {
              onFailure();
            }

            return of(fromActions.setClosureFailure(error));
          }),
        );
      }),
    ),
  );

  splitReservation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.splitReservation),
        tap(() =>
          this.notifications.push({
            type: 'warning',
            title: upperFirst(this.translate.instant('linked_split')),
            content: upperFirst(
              this.translate.instant('notifications.warning_split_reservation'),
            ),
          }),
        ),
      ),
    {
      dispatch: false,
    },
  );

  setPeriod$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.setPeriod),
        tap(({ start }) => {
          this.location.replaceState(
            `${window.location.pathname}?${generateSearchQuery(
              this.dateFormatter.formatObjectDates({ start }),
            )}`,
          );
        }),
      ),
    { dispatch: false },
  );

  setViewMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setViewMode),
      withLatestFrom(this.tableauUserPreferences$),
      map(([{ viewMode }, tableauUserPreferences]) => {
        return this.getUpdateTableauUserPreferencesAction({
          ...tableauUserPreferences,
          viewMode,
        });
      }),
    ),
  );

  setZoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setZoom),
      withLatestFrom(this.tableauUserPreferences$),
      map(([{ zoom }, tableauUserPreferences]) => {
        return this.getUpdateTableauUserPreferencesAction({
          ...tableauUserPreferences,
          zoom,
        });
      }),
    ),
  );

  setViewOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setViewOptions),
      withLatestFrom(this.tableauUserPreferences$),
      map(([{ viewOptions }, tableauUserPreferences]) => {
        return this.getUpdateTableauUserPreferencesAction({
          ...tableauUserPreferences,
          viewOptions: {
            ...tableauUserPreferences?.viewOptions,
            ...viewOptions,
          },
        });
      }),
    ),
  );

  openQuoteDetailsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openQuoteDetailsModal),
        map(({ quote, reservationsColors, row, userCanWrite }) => {
          const {
            reservation_quote_id,
            reservation_quote_option_accommodation_id,
            reservation_quote_option_id,
          } = quote;
          const {
            data: { id: tableau_room_id, label: tableau_room_label },
          } = row;
          const data = {
            reservation_quote_option_accommodation_id,
            reservation_quote_id,
            reservation_quote_option_id,
            tableau_room_id,
            tableau_room_label,
          };
          this.modalService.create<
            TableauQuoteDetailsComponent,
            Partial<TableauQuoteDetailsComponent>
          >({
            nzClassName: 'tableau-reservation-details',
            nzContent: TableauQuoteDetailsComponent,
            nzData: {
              reservationsColors,
              data,
              userCanWrite,
            },
            nzBodyStyle: { padding: '0' },
            nzWidth: 345,
          });
        }),
      ),
    { dispatch: false },
  );

  openReservationDetailsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openReservationDetailsModal),
        withLatestFrom(this.tableauState$),
        map(
          ([
            {
              property_id,
              reservation_accommodation_room_id,
              reservation_id,
              roomreservation_id,
              reservationsColors,
              userCanWrite,
            },
            { viewOptions },
          ]) => {
            this.modalService.create({
              nzClassName: 'tableau-reservation-details',
              nzBodyStyle: { padding: '0' },
              nzWidth: 345,
              nzContent: TableauReservationDetailsComponent,
              nzData: {
                reservation_accommodation_room_id,
                reservation_id,
                roomreservation_id: roomreservation_id.replace(
                  reservation_id.toString(),
                  '',
                ),
                property_id,
                reservationsColors,
                userCanWrite,
                viewOptions,
              },
            });
          },
        ),
      ),
    { dispatch: false },
  );

  successNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromActions.resetSwap,
          fromActions.addReservationToSwap,
          fromActions.removeReservationFromSwap,
        ),
        tap(() => this.pushSuccessNotification()),
      ),
    { dispatch: false },
  );

  openActionsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openActionsModal),
        withLatestFrom(this.currency$),
        tap(([action, currency]) =>
          this.tableauActionsService.chooseAction({ ...action, currency }),
        ),
      ),
    { dispatch: false },
  );

  openNoteModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openNoteModal),
        tap(({ row, note }) =>
          this.tableauActionsService.openNoteForm({ row }, note),
        ),
      ),
    { dispatch: false },
  );

  openMaintenanceModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openMaintenanceModal),
      switchMap(({ row, canWrite }) => {
        const modal = this.modalService.create({
          nzWidth: 600,
          nzContent: MaintenanceReportsContainerComponent,
          nzData: {
            title: upperFirst(
              this.translate.instant('tickets_for', {
                cleanable: `${row.data.label} (${row.data.accommodation_details.name})`,
              }),
            ),
            params: {
              property_id: row.propertyId,
              cleanable_related_id: row.data.id,
              cleanable_related_type: 'tableau_number',
            },
            canWrite,
          },
        });

        return modal.afterClose.pipe(
          map(() => {
            const { unsolvedReportsNumber } = modal.getContentComponent();
            return TableauActions.setRoomMaintenanceStatus({
              row,
              under_maintenance: !!unsolvedReportsNumber,
            });
          }),
        );
      }),
    ),
  );

  openBedTypesModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openBedTypesModal),
        withLatestFrom(this.tableauMapping$),
        tap(([{ accommodationId, property_id }, mapping]) => {
          let tableauRooms: TableauMappingAccommodationTableauNumbers[] = [];
          let accommodationName: string;
          (mapping[property_id] || []).forEach(
            ({ accommodation_details: { id, name }, tableau_numbers }) => {
              if (id === accommodationId) {
                tableauRooms = tableau_numbers;
                accommodationName = name;
              }
            },
          );
          this.modalService.create({
            nzTitle: accommodationName,
            nzContent: BedTypesFormComponent,
            nzData: {
              tableauRooms,
              accommodationId,
              propertyId: property_id,
            },
            nzFooter: null,
          });
        }),
      ),
    { dispatch: false },
  );

  attachTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setReservationTagRequest),
      switchMap(({ reservation_id, tag }) =>
        this.tagService.updateReservationTag(tag?.id, reservation_id).pipe(
          map(() => {
            return fromActions.setReservationTagSuccess({
              tag,
              reservation_id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadAvailabilitiesFailure(error));
          }),
        ),
      ),
    ),
  );

  readPreferences$ = createEffect(() =>
    combineLatest([
      this.tableauUserPreferences$,
      this.actions$.pipe(ofType(fromActions.readPreferences)),
      this.tableauDataSelectorService.selectTags(),
    ]).pipe(
      filter(([preferences]) => !!preferences),
      map(([preferences, _, tags]) =>
        fromActions.setPreferences({ preferences, tags }),
      ),
    ),
  );

  setRoomCleanStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setRoomCleanStatusRequest),
      switchMap(({ row, isClean }) => {
        const cleanableType = 'tableau_number';

        return this.housekeeperDashboardService
          .setRecord({
            date: this.dateFormatter.toServerFormat(new Date()),
            property_id: row.propertyId,
            record: { clean: isClean },
            related_type: cleanableType,
            related_id: row.data.id,
          })
          .pipe(
            map(
              ({
                data,
              }: IResponseSuccess<{
                [cleanableType: string]: {
                  [cleanableId: number]: Partial<TableauHousekeeperInformation>;
                };
              }>) => {
                this.pushSuccessNotification();
                return TableauActions.setRoomCleanStatusSuccess({
                  affected: data[cleanableType],
                });
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setRoomCleanStatusFailure(error));
            }),
          );
      }),
    ),
  );

  setRoomDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setRoomDetailsRequest),
      switchMap((params) => {
        return this.accommodationService
          .setRoomDetails({
            accommodation_id: params.accommodationId,
            accommodation_tableau_number_id: params.tableauNumberId,
            label: params.label,
            door_key_codes: params?.door_key_codes,
          })
          .pipe(
            map(() => {
              return TableauActions.setRoomDetailsSuccess(params);
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setRoomDetailsFailure(error));
            }),
          );
      }),
    ),
  );

  loadReservationAccommodationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadReservationAccommodationDetailsRequest),
      switchMap(({ request, onSuccess }) => {
        const { reservationId, reservationAccommodationId, referenceDate } =
          request;

        return this.tableauService
          .loadReservationAccommodationDetails(
            reservationId,
            reservationAccommodationId,
            referenceDate || this.dateFormatter.toServerFormat(new Date()),
          )
          .pipe(
            map(
              ({
                data: [details],
              }: IResponseSuccess<
                TableauReservationAccommodationDetails[]
              >) => {
                if (onSuccess) {
                  onSuccess(details);
                }

                return TableauActions.loadReservationAccommodationDetailsSuccess(
                  { request, details },
                );
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.loadReservationAccommodationDetailsFailure(error),
              );
            }),
          );
      }),
    ),
  );

  zoomIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.zoomIn),
      withLatestFrom(this.zoom$),
      filter(([_, zoom]) => zoom <= TableauConfig.MaxZoom),
      map(([_, zoom]) => {
        return fromActions.setZoom({ zoom: zoom + 1 });
      }),
    ),
  );

  zoomOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.zoomOut),
      withLatestFrom(this.zoom$),
      filter(([_, zoom]) => zoom > TableauConfig.MinZoom),
      map(([_, zoom]) => {
        return fromActions.setZoom({ zoom: zoom - 1 });
      }),
    ),
  );

  exportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportRequest),
      tap(() =>
        this.store.dispatch(
          fromActions.buildRowsRequest({ skipLoading: true }),
        ),
      ),
      switchMap(() => this.buildRowSuccess$),
      withLatestFrom(this.store),
      switchMap(([_, state]) => this.tableauExportService.export(state)),
      tap((file) => this.fileSaverService.saveAs(file, file.name)),
      map(() => fromActions.exportSuccess()),
    ),
  );

  constructor(
    private actions$: Actions,
    private location: Location,
    private store: Store<RootState>,
    private translate: TranslateService,
    private eventsService: EventsService,
    private modalService: NzModalService,
    private floorsService: FloorsService,
    private tableauService: TableauService,
    private errorHandler: ErrorHandlerService,
    private tagService: ReservationTagService,
    private notifications: NotificationService,
    private fileSaverService: FileSaverService,
    private dateFormatter: DateFormatterService,
    private tableauRowsService: TableauRowsService,
    private tableauExportService: TableauExportService,
    private accommodationService: AccommodationsService,
    private priceQuotationService: PriceQuotationService,
    private tableauActionsService: TableauActionsService,
    private housekeeperDashboardService: HousekeeperDashboardService,
    private tableauSplitEstimateService: TableauSplitEstimateService,
    private tableauDataSelectorService: TableauDataSelectorService,
  ) {}

  private getUpdateTableauUserPreferencesAction(preferences: any) {
    return new UserPreferencesStoreActions.UpdateRequestAction({
      preferences: {
        options: {
          [TableauConfig.PreferencesKey]: preferences,
        },
      },
    });
  }

  private pushSuccessNotification() {
    this.notifications.success('notifications.generic_success');
  }

  private getSplitRequest(
    actionRequest: TableauMoveReservationRequest,
    swappedReservations: TableauSwappedReservation[],
  ): ISplitReservation {
    const { reservation, destinationRow, extraPayload } = actionRequest;

    const destinationRoomId = destinationRow.data.id;
    const { arrival_date, departure_date, reservation_accommodation_room_id } =
      reservation;

    const reservations_on_swap = swappedReservations.map(
      ({
        reservation: {
          reservation_accommodation_room_id: reservationAccommodationRoomId,
        },
      }) => reservationAccommodationRoomId,
    );

    return {
      accommodation_tableau_number_id_destination: destinationRoomId,
      reservation_accommodation_room_id,
      arrival_date,
      departure_date,
      reservations_on_swap,
      ...extraPayload,
    };
  }
}
