<div class="by-reservation-form-notification" [formGroup]="form">
  <div
    *ngIf="canSendEmailToCustomer"
    class="by-reservation-form-notification__row"
  >
    <span>{{ 'send_email_new_reservation' | translate | upperfirst }}</span>
    <nz-switch
      formControlName="send_email_to_customer"
      nzSize="small"
    ></nz-switch>
  </div>

  <div *ngIf="selectedCompany" class="by-reservation-form-notification__row">
    <span>{{ 'send_email_to_company' | translate | upperfirst }}</span>

    <span class="by-flex by-flex-middle">
      <i
        class="fas fa-envelope pointer"
        [class.color--azure]="canSendEmailToCompany"
        [class.color--orange]="!canSendEmailToCompany"
        nz-tooltip
        [nzTooltipTitle]="
          canSendEmailToCompany
            ? selectedCompany.email
            : ('email_not_setted_or_invalid' | translate | upperfirst)
        "
      ></i>

      <nz-switch
        *ngIf="canSendEmailToCompany"
        class="by-ml-10"
        formControlName="send_email_to_company"
        nzSize="small"
      ></nz-switch>
    </span>
  </div>

  <ng-container
    *ngIf="
      form.value.send_email_to_customer || form.value.send_email_to_company
    "
  >
    <div class="by-reservation-form-notification__row">
      <span>{{ 'template_email_type' | translate | upperfirst }}</span>

      <div class="by-flex by-flex-middle">
        <i
          class="fal fa-eye by-mr-10 pointer"
          nz-tooltip
          [nzTooltipTitle]="'email_preview' | translate | upperfirst"
          (click)="showEmailPreview.emit()"
        ></i>

        <nz-select
          formControlName="template_email_id"
          style="width: 100px"
          [nzShowSearch]="true"
          [nzDropdownMatchSelectWidth]="false"
        >
          <nz-option
            nzValue="system"
            [nzLabel]="'system' | translate | upperfirst"
          ></nz-option>

          <nz-option
            *ngFor="let template of customEmailTeplates"
            [nzValue]="template.id"
            [nzLabel]="template.label"
          ></nz-option>
        </nz-select>

        <nz-switch
          *ngIf="canSendEmailToCompany"
          class="by-ml-10"
          formControlName="send_email_to_company"
          nzSize="small"
        ></nz-switch>
      </div>
    </div>

    <div class="by-reservation-form-notification__row">
      <span>{{ 'template_email_send_copy' | translate | upperfirst }}</span>
      <nz-switch
        formControlName="template_email_send_copy"
        nzSize="small"
      ></nz-switch>
    </div>
  </ng-container>
</div>
