import { Component, Input } from '@angular/core';
import { cloneDeep, isNil, mapValues, omit, omitBy, pick } from 'lodash';

import {
  DEFAULT_COLORS,
  RESERVATION_PAYMENTS_COLRS,
} from '../../../../core/helpers/default-colors';
import { DEFAULT_ACCOMMODATIONS_LEGEND_TYPE_COLORS } from '../../../../core/helpers/default-legend-color';
import { Channel } from '../../../../models';
import { IReservationTag } from '../../../../models/objects/reservation-tag';
import { TableauConfig } from '../config';
import { IPreferenceUpdate } from '../../../../core/models/api/generics/preferences/preference.model';

const EDITABLE_RESERVATION_STATUS_COLORS = [
  'confirmed',
  'quotes',
  'optionable',
  'pending',
  'arrived',
  'departure',
];

const toLegend = (colors: Record<string, string>): Colors => {
  return Object.entries(colors || {}).map(([title, color]) => ({
    title,
    color,
  }));
};

const channelsToLegend = (
  channels: Channel[],
  key: keyof Pick<Channel, 'color' | 'default_color'>,
): Colors => {
  return channels?.map((channel) => {
    return { id: channel.id, title: channel.name, color: channel[key] };
  });
};

type Colors = Array<{
  title: string;
  color: string;
  id?: number;
}>;

@Component({
  selector: 'by-tableau-legend',
  templateUrl: './tableau-legend.component.html',
  styleUrls: ['./tableau-legend.component.scss'],
})
export class TableauLegendComponent {
  @Input()
  set reservationsColors(reservationsColors: Record<string, string>) {
    this.reservationsStatusColors = toLegend(
      pick(reservationsColors, EDITABLE_RESERVATION_STATUS_COLORS),
    );
  }

  @Input()
  set tags(tags: IReservationTag[]) {
    this.tagsColors = tags?.map(({ color, label }) => ({
      color,
      title: label,
    }));
  }

  @Input()
  set activeChannels(channels: Channel[]) {
    this.channelsColors = channelsToLegend(channels, 'color');
    this.defaultChannelsColors = channelsToLegend(channels, 'default_color');
  }

  @Input() set paymentStatusColors(newColorStatus: Record<string, string>) {
    this.paymentColors = toLegend({
      ...RESERVATION_PAYMENTS_COLRS,
      ...omit(newColorStatus, ['partially_paid', 'unpaid']),
    });

    this.paymentColorsStrandard = cloneDeep(this.paymentColors);
  }

  defaultReservationsStatusColors = toLegend(
    pick(DEFAULT_COLORS, EDITABLE_RESERVATION_STATUS_COLORS),
  );

  reservationsStatusColors: Colors;

  paymentColors: Colors;

  paymentColorsStrandard: Colors;

  tagsColors: Colors;

  accommodationsTypesLegend = DEFAULT_ACCOMMODATIONS_LEGEND_TYPE_COLORS;

  channelsColors: Colors;

  defaultChannelsColors: Colors;

  paymentStyle = {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    textAlign: 'center',
  };

  legendContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '15px 0',
    marginBottom: '6px',
  };

  readonly TableauConfig = TableauConfig;

  constructor() {}

  mapPreferenceToSendFn(preferences: IPreferenceUpdate): IPreferenceUpdate {
    if (!preferences) {
      return preferences;
    }

    return mapValues(
      preferences,
      (preferenceValue, preeferencekey: keyof IPreferenceUpdate) => {
        if (
          typeof preferenceValue === 'number' ||
          preeferencekey !== 'options'
        ) {
          return preferenceValue;
        }

        return mapValues(preferenceValue, (optionVal, optionskey) => {
          if (optionskey !== 'paymentStatusColor') {
            return optionVal;
          }

          return omitBy(
            {
              ...optionVal,
              unpaid: optionVal.to_pay,
              partially_paid: optionVal.suspended,
            },
            isNil,
          );
        });
      },
    ) as IPreferenceUpdate;
  }
}
