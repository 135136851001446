import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { LookupModule } from '../lookup/lookup.module';

import { CompaniesLookupComponent } from './companies-lookup.component';

@NgModule({
  declarations: [CompaniesLookupComponent],
  imports: [CommonModule, LookupModule, ReactiveFormsModule],
  exports: [CompaniesLookupComponent],
})
export class CompaniesLookupModule {}
