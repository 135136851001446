<ng-container [formGroup]="form">
  <nz-form-item class="by-reservation-form__item">
    <nz-form-label>
      {{ 'deposit' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      class="by-reservation-form__control"
      [class.by-full-width-control]="isMobile"
      [nzErrorTip]="'deposit_greater_than_total' | translate | upperfirst"
    >
      <nz-input-number
        byDecimalOnly
        [nzSize]="inputSize"
        formControlName="deposit"
        [nzMin]="0"
        [nzMax]="totalPrice || 0"
        [nzStep]="1"
        [nzFormatter]="currencySymbolFormatter"
        [nzParser]="currencyParser"
        (nzBlur)="onTouched()"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="form.value.deposit">
    <nz-form-item
      class="by-reservation-form__item by-reservation-form__item--gray"
    >
      <nz-form-label>
        {{ 'invoice_layout' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
      >
        <nz-select
          [nzDropdownMatchSelectWidth]="false"
          [nzSize]="inputSize"
          formControlName="invoice_layout_id"
          (nzBlur)="onTouched()"
        >
          <nz-option
            *ngFor="let invoiceLayout of invoiceLayouts"
            [nzLabel]="invoiceLayout.name"
            [nzValue]="invoiceLayout.id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="by-reservation-form__item">
      <nz-form-label>
        {{ 'deposit_number' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
        [nzErrorTip]="
          'validation_form.' +
            (form.controls.deposit_number.errors | keys | head) | translate
        "
      >
        <by-spin [nzSpinning]="nextDepositNumberLoading" [nzSize]="inputSize">
          <nz-input-number
            byDecimalOnly
            [nzSize]="inputSize"
            formControlName="deposit_number"
            [nzMin]="0"
            [nzStep]="1"
            (nzBlur)="onTouched()"
          ></nz-input-number>
        </by-spin>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</ng-container>
