<ng-container [formGroup]="form">
  <by-spin
    [class.text-align--end]="!isMobile"
    [nzSize]="inputSize"
    [nzSpinning]="priceLoading"
  >
    <div class="by-flex by-flex-between" [style.padding-left.px]="paddingLeft">
      <strong style="text-align: start">
        {{ label }}
      </strong>
      <div class="by-flex by-flex-middle">
        <i
          *ngIf="totalPriceControl.value > 0"
          class="fas fa-percent color--azure by-mr-10 pointer"
          nz-tooltip
          [nzTooltipTitle]="'apply_discount' | translate | upperfirst"
          (click)="showDiscount = true"
        ></i>

        <del
          *ngIf="priceWithoutDiscount > totalPriceControl.value"
          class="color--grey-dark by-mr-10 nowrap"
          >{{ priceWithoutDiscount | toCurrency: currency?.symbol }}</del
        >

        <strong class="nowrap">{{
          form.value.price_total | toCurrency: currency?.symbol
        }}</strong>
      </div>
    </div>
  </by-spin>

  <div *ngIf="showDiscount" class="by-flex by-flex-middle by-mt-5">
    <nz-input-group nzCompact class="text-align--end by-flex">
      <nz-select
        [nzSize]="inputSize"
        formControlName="discount_type_id"
        [nzShowArrow]="false"
        [nzDropdownMatchSelectWidth]="false"
      >
        <nz-option [nzValue]="4" [nzLabel]="currency?.symbol"></nz-option>
        <nz-option [nzValue]="5" nzLabel="%"></nz-option>
      </nz-select>

      <nz-input-number
        byDecimalOnly
        [nzSize]="inputSize"
        formControlName="discount_value"
        [nzMin]="0"
        [nzMax]="
          form.value.discount_type_id === 5
            ? 100
            : priceWithoutDiscount || 99999
        "
        [nzStep]="1"
      ></nz-input-number>
    </nz-input-group>

    <div class="by-flex by-flex-middle">
      <button
        (click)="onCancel()"
        nz-button
        nzDanger
        nzType="primary"
        nzShape="circle"
        [nzSize]="inputSize"
        nz-tooltip
        [nzTooltipTitle]="'cancel' | translate | upperfirst"
        class="by-ml-5 ant-btn-icon-only"
      >
        <i class="far fa-times"></i>
      </button>

      <button
        (click)="onConfirm()"
        nz-button
        nzType="primary"
        nzShape="circle"
        [nzSize]="inputSize"
        nz-tooltip
        [nzTooltipTitle]="'confirm' | translate | upperfirst"
        class="by-ml-5 ant-btn-icon-only"
      >
        <i class="far fa-check"></i>
      </button>
    </div>
  </div>
</ng-container>
