import { Component, Input, OnChanges } from '@angular/core';

import { Floor, TableauRow, TableauRowComponent } from '../../../../models';

type TableauFloorRow = TableauRowComponent<Floor>;

@Component({
  selector: 'by-tableau-floor-row',
  templateUrl: './tableau-floor-row.component.html',
  styleUrls: ['./tableau-floor-row.component.scss'],
})
export class TableauFloorRowComponent implements OnChanges, TableauFloorRow {
  constructor() {}
  @Input()
  row: TableauRow;

  @Input()
  data: Floor;

  ngOnChanges() {}
}
