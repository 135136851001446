export function arrayWalk<T>(
  array: T[],
  forEach: (item: T, next: () => void) => void,
  index = 0,
): void {
  if (!array[index]) {
    return;
  }

  forEach(array[index], () => arrayWalk(array, forEach, index + 1));
}
