import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
  inject,
} from '@angular/core';
import { AutomaticPaymentSummary } from '../../../../models';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'by-reservation-form-automatic-payment',
  templateUrl: './reservation-form-automatic-payment.component.html',
  styleUrl: './reservation-form-automatic-payment.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormAutomaticPaymentComponent),
      multi: true,
    },
  ],
})
export class ReservationFormAutomaticPaymentComponent
  implements ControlValueAccessor, OnDestroy
{
  @Input({ required: true }) isMobile: boolean;

  @Input({ required: true }) inputSize: 'small' | 'default';

  @Input({ required: true }) currency: ICurrency;

  @Input({ required: true }) automaticPaymentSummary: AutomaticPaymentSummary[];

  private fb = inject(FormBuilder);

  private subs = new Subscription();

  private onChange: (value: number) => void;
  private onTouched: () => void;

  paymentPolicyControl = this.fb.control<number>(null);

  public writeValue(value: number): void {
    this.paymentPolicyControl.patchValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;

    this.subs.add(
      this.paymentPolicyControl.valueChanges.subscribe((value) =>
        this.onChange(value),
      ),
    );
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.paymentPolicyControl.disable()
      : this.paymentPolicyControl.enable();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
