<div
  *ngIf="!data.child_reservation"
  class="by-tableau-reservation by-tableau-box"
  [style.width]="boxWrapperComponent?.width + 'px'"
  [style.background-color]="backgroundColor"
  nz-popover
  [nzPopoverContent]="triggerPopover ? reservationDetailsTpl : null"
  [nzPopoverMouseEnterDelay]="TableauConfig.BoxPopoverDelay"
  nzPopoverOverlayClassName="by-tableau-reservation-details-popover"
  (contextmenu)="userCanWrite && onOpenContextMenu($event, contextMenu)"
  cdkDrag
  [cdkDragStartDelay]="isMobile && TableauConfig.DraggingDelayTime"
  [cdkDragDisabled]="!userCanWrite"
  [cdkDragPreviewClass]="'by-tableau-box--dragging'"
  (cdkDragStarted)="onDragStarted()"
  (cdkDragDropped)="onDragDropped($event)"
  byTableauScrollDragErrorCatcher
  (dragScrollError)="onDragScrollError()"
  byTableauReservationHoverDispatcher
  [reservation]="data"
  (click)="onOpenReservationDetailsModal()"
  (mousedown)="onMouseDownPopover()"
>
  <ng-template #reservationDetailsTpl>
    <by-tableau-reservation-details-popover
      [reservation]="data"
    ></by-tableau-reservation-details-popover>
  </ng-template>
  <div *cdkDragPlaceholder></div>

  <ng-container [ngTemplateOutlet]="reservationContent"></ng-container>

  <ng-template #reservationContent>
    <div class="by-tableau-reservation__head">
      <i
        class="fas fa-circle by-fs-7"
        nz-tooltip
        [nzTooltipTitle]="
          triggerTooltip ? (data.payment_status | translate | capitalize) : null
        "
        nzTooltipPlacement="top"
        [style.color]="data.payment_status | paymentStatusColor | async"
        (mousedown)="onMouseDownTooltip()"
      ></i>

      <span *ngIf="viewOptions?.ota_source" class="by-ml-3">
        <ng-container [ngSwitch]="true">
          <img
            *ngSwitchCase="!!data.metasearch"
            src="{{ CDN_URL }}images/metasearch/{{
              data.metasearch.id
            }}/svg/logo.svg"
            width="15"
            nz-tooltip
            [nzTooltipTitle]="triggerTooltip ? data.metasearch.name : null"
            (mousedown)="onMouseDownTooltip()"
          />

          <img
            *ngSwitchCase="!!(data.company_id && data.company_logo?.length)"
            [src]="STORAGE_URL + data.company_logo[0]?.path"
            class="by-tableau-reservation__company-logo"
            nz-tooltip
            [nzTooltipTitle]="triggerTooltip ? data.company_name : null"
            (mousedown)="onMouseDownTooltip()"
          />

          <span
            *ngSwitchCase="!!(data.company_id && !data.company_logo?.length)"
            class="by-tableau-reservation__company-logo by-tableau-reservation__company-logo--no-image"
            nz-tooltip
            [nzTooltipTitle]="triggerTooltip ? data.company_name : null"
            (mousedown)="onMouseDownTooltip()"
          >
            {{ data.company_name | slice: 0 : 1 | uppercase }}
          </span>

          <span
            *ngSwitchCase="data.channel_id >= 1000"
            class="by-tableau-reservation__channel-name-logo by-tableau-reservation__channel-name-logo--no-image"
            nz-tooltip
            [nzTooltipTitle]="triggerTooltip ? data.channel_name : null"
            (mousedown)="onMouseDownTooltip()"
          >
            {{ data.channel_name | slice: 0 : 3 | uppercase }}
          </span>

          <img
            *ngSwitchDefault
            src="{{ CDN_URL }}images/channels/{{
              data.channel_id
            }}/svg/logo.svg"
            width="15"
            nz-tooltip
            [nzTooltipTitle]="triggerTooltip ? data.channel_name : null"
            (mousedown)="onMouseDownTooltip()"
          />
        </ng-container>
      </span>
    </div>

    <div class="by-tableau-reservation__content">
      {{ data.label | uppercase }}
    </div>
  </ng-template>

  <by-tableau-reservation-split
    *ngIf="showReservationSplit"
    [reservation]="data"
    [zoom]="zoom"
    [period]="period"
    [rowId]="row?.id"
  ></by-tableau-reservation-split>
</div>

<div
  *ngIf="data.child_reservation"
  class="by-tableau-reservation by-tableau-reservation--child"
  nz-tooltip
  [nzTooltipTitle]="
    triggerTooltip ? ('child_reservation' | translate | upperfirst) : null
  "
  nzTooltipPlacement="right"
  byTableauReservationHoverDispatcher
  [hoverDisabled]="isSumParentRow"
  [reservation]="data"
  (mousedown)="onMouseDownTooltip()"
></div>

<nz-dropdown-menu #contextMenu="nzDropdownMenu">
  <ul nz-menu nzInDropDown (nzClick)="onCloseContextMenu()">
    <li [nzDisabled]="data.keep_accommodation" (click)="onMove()" nz-menu-item>
      <i class="fas fa-arrows-alt"></i>
      {{ 'move' | translate | capitalize }}
    </li>

    <li [nzDisabled]="data.keep_accommodation" (click)="onSwap()" nz-menu-item>
      <i class="fas fa-sync-alt color--azure"></i>
      {{ 'swap' | translate | capitalize }}
    </li>

    <li [nzDisabled]="data.keep_accommodation" (click)="onSplit()" nz-menu-item>
      <i class="fas fa-cut color--green"></i>
      {{ 'split' | translate | capitalize }}
    </li>

    <li nz-menu-item>
      <a
        target="_blank"
        href="/reservation/{{ data.reservation_id }}?roomReservationId={{
          data.roomreservation_id
        }}&subTab=1"
      >
        <i class="fas fa-users color--orange"></i>
        {{ 'guests' | translate | capitalize }}
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
