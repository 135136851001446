import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { CollapsableBoxComponent } from './collapsable-box.component';

@NgModule({
  declarations: [CollapsableBoxComponent],
  imports: [CommonModule, TranslateModule, NgPipesModule],
  exports: [CollapsableBoxComponent],
})
export class CollapsableBoxModule {}
