import { ChangeDetectorRef, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[byOnlyMobile]',
})
export class ButtonMobileOnlyMobileDirective {
  private _isVisible = true;

  constructor(private cdr: ChangeDetectorRef) {}

  @HostBinding('class.hidden') get isHidden() {
    return !this._isVisible;
  }

  @HostBinding('class.show') get isVisible() {
    return this._isVisible;
  }

  show() {
    this._isVisible = true;
    this.cdr.detectChanges();
  }

  hide() {
    this._isVisible = false;
    this.cdr.detectChanges();
  }
}
