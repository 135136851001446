import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { difference, flatMap } from 'lodash';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
} from 'rxjs/operators';

import { ActiveChannelsStoreSelectors } from '../../../../root-store/active-channels-store';
import { ReservationTagStoreSelectors } from '../../../../root-store/reservation-tag-store';
import { RootState } from '../../../../root-store/root-state';
import * as TableauSelectors from '../../../../root-store/tableau-store/selectors';
import { UserMePermissionsStoreSelectors } from '../../../../root-store/user-me-permissions-store';
import { UserPreferencesStoreSelectors } from '../../../../root-store/user-preferences-store';

@Injectable()
export class TableauDataSelectorService {
  constructor(
    private store: Store<RootState>,
    private breakpointObserver: BreakpointObserver,
  ) {}

  selectLoadedProperties() {
    return this.store.pipe(select(TableauSelectors.selectLoadedProperties));
  }

  selectPropertiesToLoad() {
    return this.selectLoadedProperties().pipe(
      pairwise(),
      map(([oldLoadedProperties, newLoadedProperties]) => {
        return difference(newLoadedProperties, oldLoadedProperties);
      }),
      filter((loadedProperties) => !!loadedProperties?.length),
    );
  }

  selectMappingAccommodations() {
    return this.store.pipe(
      select(TableauSelectors.selectMpping),
      map((mapping) => flatMap(mapping)),
    );
  }

  selectPeriod() {
    return this.store.pipe(
      select(TableauSelectors.selectPeriod),
      filter((period) => !!period),
    );
  }

  selectLoading() {
    return this.store.pipe(select(TableauSelectors.selectLoading));
  }

  selectUserCanWrite() {
    return this.store.pipe(
      select(
        UserMePermissionsStoreSelectors.selectUserMeCanWriteInFeature(
          'tableau',
        ),
      ),
    );
  }

  selectRows() {
    return this.store.pipe(select(TableauSelectors.selectRows));
  }

  selectDays() {
    return this.store.pipe(select(TableauSelectors.selectDays));
  }

  selectReservationsColors() {
    return this.store.pipe(select(TableauSelectors.selectReservationsColors));
  }

  selectZoom() {
    return this.store.pipe(select(TableauSelectors.selectZoom));
  }

  selectIsMobile() {
    return this.breakpointObserver
      .observe(['(max-width: 900px)'])
      .pipe(map(({ matches }) => matches));
  }

  selectWindowResize() {
    return fromEvent(window, 'resize').pipe(debounceTime(50));
  }

  selectWindowMouseUp() {
    return fromEvent(window, 'mouseup');
  }

  selectViewOptions() {
    return this.store.pipe(select(TableauSelectors.selectViewOptions));
  }

  selectViewMode() {
    return this.store.pipe(
      select(TableauSelectors.selectViewMode),
      distinctUntilChanged(),
    );
  }

  selectSplitMode() {
    return this.store.pipe(select(TableauSelectors.selectSplitMode));
  }

  selectCollapsedAccommodations() {
    return this.store.pipe(
      select(TableauSelectors.selectCollapsedAccommodations),
    );
  }

  selectSwappedReservations() {
    return this.store.pipe(select(TableauSelectors.selectSwappedReservations));
  }

  selectTags() {
    return this.store.pipe(
      select(ReservationTagStoreSelectors.selectAllReservationTagItems),
    );
  }

  selectActiveChannels() {
    return this.store.pipe(select(ActiveChannelsStoreSelectors.selectAllItems));
  }

  selectPaymentStatusColor() {
    return this.store.select(
      UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
        'paymentStatusColor',
      ),
    );
  }

  selectChannelsColors() {
    return this.store.pipe(
      select(ActiveChannelsStoreSelectors.selectChannelsColors),
    );
  }

  selectFilteredAccommodations() {
    return this.store.pipe(
      select(TableauSelectors.selectFilteredAccommodations),
    );
  }

  selectExportLoading() {
    return this.store.pipe(select(TableauSelectors.selectExportLoading));
  }

  selectIsTagFilterApplied() {
    return this.store.pipe(select(TableauSelectors.selectIsTagFilterApplied));
  }
}
