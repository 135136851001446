import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>('tableau_2');

export const selectRows = featureAdapter.getSelectors(selectState).selectAll;

export const selectPeriod = createSelector(
  selectState,
  (state: State) => state.period,
);

export const selectDays = createSelector(
  selectState,
  (state: State) => state.days,
);

export const selectZoom = createSelector(
  selectState,
  (state: State) => state.zoom,
);

export const selectReservationsColors = createSelector(
  selectState,
  (state: State) => state.reservationsColors,
);

export const selectCollapsedAccommodations = createSelector(
  selectState,
  (state: State) => state.collapsedAccommodations,
);

export const selectViewOptions = createSelector(
  selectState,
  (state: State) => state.viewOptions,
);

export const selectIsTagFilterApplied = createSelector(
  selectState,
  (state: State) => !state.viewOptions.tag_id,
);

export const selectViewMode = createSelector(
  selectState,
  (state: State) => state.viewMode,
);

export const selectSplitMode = createSelector(
  selectState,
  (state: State) => state.splitMode,
);

export const selectLoadedProperties = createSelector(
  selectState,
  (state: State) => state.loadedProperties,
);

export const selectSwappedReservations = createSelector(
  selectState,
  (state: State) => state.swappedReservations,
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => !!state.loadings?.length,
);

export const selectMpping = createSelector(
  selectState,
  (state: State) => state?.mapping,
);

export const selectFilteredAccommodations = createSelector(
  selectState,
  (state: State) =>
    Object.entries(state.filteredAccommodations)
      .filter(([_, value]) => value)
      .map(([key]) => +key),
);

export const selectExportLoading = createSelector(
  selectState,
  (state: State) => state.exportLoading,
);
