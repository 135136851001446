import * as moment from 'moment';

/**
 * @param from Data di inzio intervallo (nel formato YYYY-MM-DD)
 * @param to Data di fine intervallo (nel formato YYYY-MM-DD)
 * @returns Array delle date nel formato YYYY-MM-DD
 */
export function getDatesFromInterval(from: string, to: string): string[] {
  const dateFrom = moment(from);
  const dateTo = moment(to);

  const dates = [];

  while (dateFrom.isSameOrBefore(dateTo, 'days')) {
    dates.push(dateFrom.format('YYYY-MM-DD'));
    dateFrom.add(1, 'day');
  }

  return dates;
}
