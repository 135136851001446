import { Component, Input, OnInit } from '@angular/core';

import { TableauRow } from '../../../../models';

@Component({
  selector: 'by-tableau-footer-row',
  templateUrl: './tableau-footer-row.component.html',
  styleUrls: ['./tableau-footer-row.component.scss'],
})
export class TableauFooterRowComponent implements OnInit {
  @Input()
  row: TableauRow;

  @Input()
  isMobile: boolean;

  constructor() {}

  ngOnInit(): void {}
}
