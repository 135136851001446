<thead>
  <tr class="by-remove-override" mat-row>
    <th nzLeft="0px" class="by-tableau-cell by-tableau-cell--search">
      <div class="by-flex by-flex-middle">
        <button
          class="{{
            isMobile
              ? 'by-w-50 by-flex-grow by-flex-shrink by-mr-5'
              : 'by-tableau-reload-button'
          }} ant-btn-icon-only"
          nz-tooltip
          [nzTooltipTitle]="'reload' | translate | upperfirst"
          nzTooltipPlacement="rightTop"
          nz-button
          nzType="primary"
          (click)="reload.emit(today)"
        >
          <i class="far fa-sync-alt"></i>
        </button>

        <nz-badge
          *ngIf="isMobile"
          class="by-w-50 by-flex-grow by-flex-shrink"
          [nzDot]="!!searchControl.value"
        >
          <button
            class="by-w-100 ant-btn-icon-only"
            nz-button
            nzType="primary"
            (click)="mobileSearchDrawer.open()"
          >
            <i class="far fa-search"></i>
          </button>
        </nz-badge>

        <nz-drawer
          #mobileSearchDrawer
          nzPlacement="top"
          [nzHeight]="85"
          [nzClosable]="false"
          (nzOnClose)="mobileSearchDrawer.close()"
        >
          <ng-container
            *nzDrawerContent
            [ngTemplateOutlet]="searchInputTemplate"
          ></ng-container>
        </nz-drawer>

        <ng-container
          *ngIf="!isMobile"
          [ngTemplateOutlet]="searchInputTemplate"
        ></ng-container>

        <ng-template #searchInputTemplate>
          <nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="searchIcon">
            <input
              [formControl]="searchControl"
              nz-input
              placeholder="{{ 'search' | translate | capitalize }}..."
            />
          </nz-input-group>
          <ng-template #searchIcon>
            <i class="fal fa-search"></i>
          </ng-template>
          <ng-template #suffixTemplate>
            <i
              *ngIf="searchControl.value"
              (click)="onSearchReset()"
              class="fal fa-times-circle"
            ></i>
          </ng-template>
        </ng-template>
      </div>
    </th>
    <th
      *ngFor="let month of months; let lastMonth = last"
      [attr.colspan]="month.colspan"
      class="by-tableau-cell by-tableau-cell--month"
    >
      <span *ngIf="month.colspan > 1">
        {{ month.day | formatDate : 'MMMM YYYY' | uppercase }}
      </span>

      <span *ngIf="month.colspan === 1">
        {{ month.day | formatDate : 'MMM' | uppercase }}
      </span>

      <div
        *ngIf="lastMonth"
        class="by-tableau-next-period"
        (click)="movePeriod.emit(1)"
      >
        <span>
          {{ 'next' | translate | upperfirst }}
          <i class="heartBeatAnimation far fa-chevron-double-right by-ml-5"></i
        ></span>
      </div>
    </th>
  </tr>

  <tr class="by-remove-override">
    <th nzLeft="0px" class="by-tableau-cell">
      <div *ngIf="!isMobile" class="by-tableau-calendar">
        <button
          class="by-mr-3 ant-btn-icon-only"
          nz-tooltip
          [nzTooltipTitle]="'today' | translate | upperfirst"
          nzTooltipPlacement="rightTop"
          nz-button
          (click)="onStartDateChange(yesterday)"
        >
          <i class="fas fa-map-pin"></i>
        </button>

        <button
          byContinuousClick
          (continuousClick)="movePeriod.emit(-1)"
          nz-button
          class="ant-btn-icon-only"
          nzType="default"
          (click)="movePeriod.emit(-1)"
        >
          <i class="far fa-chevron-left"></i>
        </button>

        <ng-container *ngTemplateOutlet="datepicker"></ng-container>

        <button
          byContinuousClick
          (continuousClick)="movePeriod.emit(1)"
          nz-button
          class="ant-btn-icon-only"
          nzType="default"
          (click)="movePeriod.emit(1)"
        >
          <i class="far fa-chevron-right"></i>
        </button>
      </div>

      <button
        #mobileCalendarButton
        *ngIf="isMobile"
        class="by-tableau-mobile-calendar-button ant-btn-icon-only"
        nz-button
        nzType="primary"
        nz-popover
        [nzPopoverContent]="datepicker"
        nzPopoverOverlayClassName="popover-without-padding"
        nzPopoverTrigger="click"
        nzPopoverPlacement="bottomRight"
      >
        <i class="far fa-calendar-alt"></i>
      </button>
    </th>

    <th
      *ngFor="let day of days"
      class="by-tableau-cell by-tableau-cell--day"
      [ngClass]="{
        'by-tableau-cell--we': day | isWeekend,
        'by-tableau-cell--hover': day | sameDay : hoveredCell?.date,
        'by-tableau-cell--today': day | sameDay : today
      }"
    >
      <div class="by-tableau-cell--day-number">
        <strong>{{ day | formatDate : 'D' | uppercase }}</strong>
      </div>
      <div class="by-tableau-cell--day-name">
        <strong>{{ day | formatDate : 'dd' | uppercase }}</strong>
      </div>
    </th>
  </tr>
</thead>

<ng-template #datepicker>
  <nz-date-picker
    class="date-picker-border-width-none"
    byDisabledMobileKeyboard
    [style.padding]="isMobile ? 0 : null"
    [style.width]="isMobile ? null : '122px'"
    [nzInline]="isMobile"
    byDisabledDateFixer
    [nzAllowClear]="false"
    nzFormat="dd/MM/yyyy"
    [formControl]="startPeriodDate"
    [nzShowToday]="false"
  ></nz-date-picker>
</ng-template>
