import { isUndefined, mapValues, omitBy } from 'lodash';

export const isEmptyValue = (value) =>
  value === null || value === undefined || value === '';

export function removeNullishValues<T extends Object>(data: T): T {
  return { ...(omitBy(data, (i) => i === null || i === undefined) as T) };
}

export function removeUndefinedValues<T extends Object>(data: T): T {
  return { ...(omitBy(data, isUndefined) as T) };
}

export function removeAllNullishValues<T extends Object>(data: T): T {
  return omitBy(
    data,
    (i: any) => i === null || i === undefined || i === '',
  ) as T;
}

export function removeEmptyArrays<T extends Object>(data: T): T {
  return {
    ...(omitBy(
      data,
      (value) => Array.isArray(value) && value.length === 0,
    ) as T),
  };
}

export function emptyValuesToNull(data) {
  return mapValues(data, (value) => {
    if (isEmptyValue(value)) {
      value = null;
    }

    return value;
  });
}
