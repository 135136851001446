import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import {
  SetTableauNumber,
  TableauDraggingItem,
  TableauHoveredCell,
  TableauPeriod,
  TableauReservation,
  TableauRoom,
  TableauRow,
  TableauRowComponent,
  TableauRowIndexData,
  TableauRowItems,
  TableauSelection,
  TableauViewMode,
  TableauViewOptions,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import { TableauActions } from '../../../../root-store/tableau-store';
import { TableauRowIndexService } from '../../../../services';
import { TableauSelectionableCellDirective } from '../directives/tableau-selectionable-cell.directive';

type TableauRoomRow = TableauRowComponent<TableauRoom>;

@Component({
  selector: 'by-tableau-room-row',
  templateUrl: './tableau-room-row.component.html',
  styleUrls: ['./tableau-room-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauRoomRowComponent implements TableauRoomRow, OnChanges {
  @ViewChildren(TableauSelectionableCellDirective)
  cellDirectives: QueryList<TableauSelectionableCellDirective>;

  @Input()
  row: TableauRow;

  @Input()
  data: TableauRoom;

  @Input()
  firstColumnRowspan: number;

  @Input()
  items: TableauRowItems;

  @Input()
  days: Date[];

  @Input()
  userCanWrite: boolean;

  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  channelsColors: Record<number, string>;

  @Input()
  viewOptions: TableauViewOptions;

  @Input()
  viewMode: TableauViewMode;

  @Input()
  zoom: number;

  @Input()
  hoveredCell: TableauHoveredCell;

  @Input()
  hoveredReservation: TableauReservation;

  @Input()
  selection: TableauSelection;

  @Input()
  searchValue: string;

  @Input()
  splitMode: boolean;

  @Input()
  period: TableauPeriod;

  @Input()
  draggingItem: TableauDraggingItem;

  @Input()
  isMobile: boolean;

  rowIsHovered: boolean;

  rowIsHoveredWithSameIndex: boolean;

  rowIsSelected: boolean;

  rowData: TableauRowIndexData;

  labelControl = new UntypedFormControl(null);

  @ViewChild(NzPopoverDirective) popoverComponent: NzPopoverDirective;

  constructor(
    protected tableauRowIndexService: TableauRowIndexService,
    protected store: Store<RootState>,
    protected dateFormatter: DateFormatterService,
  ) {}

  dayTrackBy = (_, day: Date) => {
    return this.dateFormatter.toServerFormat(day);
  };

  ngOnChanges(changes: SimpleChanges) {
    const { hoveredCell, selection, row, data } = changes;

    if (hoveredCell) {
      this.setRowIsHovered();
    }

    if (selection) {
      this.setRowIsSelected();
    }

    if (row) {
      this.setRowData();
    }

    if (data) {
      this.setRoomLabelControl();
    }
  }

  onChangeCleanStatus() {
    this.store.dispatch(
      TableauActions.setRoomCleanStatusRequest({
        row: this.row,
        isClean: !this.data.housekeeper?.clean,
      }),
    );
  }

  onOpenMaintenanceModal() {
    this.store.dispatch(
      TableauActions.openMaintenanceModal({
        row: this.row,
        canWrite: this.userCanWrite,
      }),
    );
  }

  onSaveDetails() {
    this.store.dispatch(
      TableauActions.setRoomDetailsRequest({
        ...this.setTableauNumberData,
        label: this.labelControl.value,
        door_key_codes: this.data?.door_key_codes,
      }),
    );
    this.popoverComponent.hide();
  }

  onCancelLabelChanges() {
    this.labelControl.setValue(this.data.label);
    this.popoverComponent.hide();
  }

  setRoomLabelControl() {
    this.labelControl.setValue(this.data.label);
  }

  get setTableauNumberData(): SetTableauNumber {
    return {
      propertyId: this.row.propertyId,
      tableauNumberId: this.data.id,
      accommodationId: this.data.accommodation_details.id,
      rowId: this.row.id,
    };
  }

  protected setRowIsHovered() {
    this.rowIsHovered = this.hoveredCell?.row?.room_id === this.data.id;

    this.rowIsHoveredWithSameIndex =
      this.rowIsHovered && this.hoveredCell?.row?.index === this.row.spanIndex;
  }

  protected setRowIsSelected() {
    this.rowIsSelected = this.selection?.roomId === this.data.id;
  }

  protected setRowData() {
    this.rowData = this.tableauRowIndexService.decode(this.row?.id);
  }
}
