import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { RootState } from '../../../../root-store/root-state';
import { TableauRowIndexService } from '../../../../services';
import { TableauRoomRowComponent } from '../tableau-room-row/tableau-room-row.component';

@Component({
  selector: 'by-tableau-ob-row',
  templateUrl: './tableau-ob-row.component.html',
  styleUrls: ['./tableau-ob-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauObRowComponent extends TableauRoomRowComponent {
  constructor(
    protected tableauRowIndexService: TableauRowIndexService,
    protected store: Store<RootState>,
    protected dateFormatter: DateFormatterService,
  ) {
    super(tableauRowIndexService, store, dateFormatter);
  }

  /**
   * @override
   */
  protected setRowIsHovered() {
    if (this.hoveredCell?.row?.room_id !== 'OB') {
      this.rowIsHovered = false;
      return;
    }

    const rowData = this.tableauRowIndexService.decode(this.row?.id);

    this.rowIsHovered =
      this.hoveredCell?.row?.accommodation_id === rowData?.accommodation_id &&
      this.hoveredCell?.row?.index === rowData?.index;
  }
}
