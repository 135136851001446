import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';

import { ButtonMobileModule } from '../../../components/button-mobile/button-mobile.module';
import { CollapsableBoxModule } from '../../../components/collapsable-box/collapsable-box.module';
import { CollapsableTextModule } from '../../../components/collapsable-text/collapsable-text.module';
import { SimpleCompanyFormModule } from '../../../components/simple-company-form/simple-company-form.module';
import { TagSelectModule } from '../../../components/tag-select/tag-select.module';
import { CdkVirtualScrollFixerModule } from '../../../directives/cdk-virtual-scroll-fixer/cdk-virtual-scroll-fixer.module';
import { ContinuousClickModule } from '../../../directives/continuous-click/continuous-click.module';
import { ToggleButtonModule } from '../../../directives/toggle-button/toggle-button.module';
import { SharedModule } from '../../../shared/shared.module';
import { UiModule } from '../../../ui/ui.module';
import { EstimateModule } from '../estimate/estimate.module';
import { MaintenanceReportsModule } from '../maintenance-reports/maintenance-reports.module';
import { ReservationFormModule } from '../reservation-form/reservation-form.module';

import { TableauCellHoverDispatcherDirective } from './directives/tableau-cell-hover-dispatcher.directive';
import { TableauReservationHoverDispatcherDirective } from './directives/tableau-reservation-hover-dispatcher.directive';
import { TableauScrollDragErrorCatcherDirective } from './directives/tableau-scroll-drag-error-catcher.directive';
import { TableauSelectionableCellDirective } from './directives/tableau-selectionable-cell.directive';
import { TableauExportModule } from './export/tableau-export.module';
import { GetByDayPipe } from './pipes/get-by-day.pipe';
import { IsWeekendPipe } from './pipes/is-weekend.pipe';
import { TableauDataLoaderService } from './services/tableau-data-loader.service';
import { TableauDataSelectorService } from './services/tableau-data-selector.service';
import { TableauDestinationRowSelectionService } from './services/tableau-destination-row-selection.service';
import { TableauDragService } from './services/tableau-drag.service';
import { TableauHoverService } from './services/tableau-hover.service';
import { TableauSearchService } from './services/tableau-search.service';
import { TableauSelectionService } from './services/tableau-selection.service';
import { TableauAccommodationRowComponent } from './tableau-accommodation-row/tableau-accommodation-row.component';
import { TableauBoxResolverComponent } from './tableau-box-resolver/tableau-box-resolver.component';
import { TableauBoxWrapperComponent } from './tableau-box-wrapper/tableau-box-wrapper.component';
import { TableauChooseActionModalComponent } from './tableau-choose-action-modal/tableau-choose-action-modal.component';
import { TableauClosureModalComponent } from './tableau-closure-modal/tableau-closure-modal.component';
import { TableauDestinationRowSelectionModalComponent } from './tableau-destination-row-selection-modal/tableau-destination-row-selection-modal.component';
import { TableauEstimateModalComponent } from './tableau-estimate-modal/tableau-estimate-modal.component';
import { TableauEventRowComponent } from './tableau-event-row/tableau-event-row.component';
import { TableauFloorRowComponent } from './tableau-floor-row/tableau-floor-row.component';
import { TableauFooterRowComponent } from './tableau-footer-row/tableau-footer-row.component';
import { TableauHeaderComponent } from './tableau-header/tableau-header.component';
import { TableauLegendComponent } from './tableau-legend/tableau-legend.component';
import { TableauModalHeaderDatesComponent } from './tableau-modal-header/tableau-modal-header-dates/tableau-modal-header-dates.component';
import { TableauModalHeaderDetailsRowComponent } from './tableau-modal-header/tableau-modal-header-details-row/tableau-modal-header-details-row.component';
import { TableauModalHeaderDetailsComponent } from './tableau-modal-header/tableau-modal-header-details/tableau-modal-header-details.component';
import { TableauModalHeaderComponent } from './tableau-modal-header/tableau-modal-header.component';
import { TableauModalPricesComponent } from './tableau-modal-prices/tableau-modal-prices.component';
import { TableauNoteBoxComponent } from './tableau-note-box/tableau-note-box.component';
import { TableauNoteFormModalComponent } from './tableau-note-form-modal/tableau-note-form-modal.component';
import { TableauObRowComponent } from './tableau-ob-row/tableau-ob-row.component';
import { TableauPageComponent } from './tableau-page/tableau-page.component';
import { TableauPropertyRowComponent } from './tableau-property-row/tableau-property-row.component';
import { TableauPropertyStatsRowComponent } from './tableau-property-stats-row/tableau-property-stats-row.component';
import { TableauQuoteBoxComponent } from './tableau-quote-box/tableau-quote-box.component';
import { TableauQuoteDetailsPopoverComponent } from './tableau-quote-details-popover/tableau-quote-details-popover.component';
import { TableauQuoteDetailsComponent } from './tableau-quote-details/tableau-quote-details.component';
import { TableauReservationBoxComponent } from './tableau-reservation-box/tableau-reservation-box.component';
import { TableauReservationDetailsPopoverComponent } from './tableau-reservation-details-popover/tableau-reservation-details-popover.component';
import { TableauReservationDetailsRoomComponent } from './tableau-reservation-details-room/tableau-reservation-details-room.component';
import { TableauReservationDetailsComponent } from './tableau-reservation-details/tableau-reservation-details.component';
import { TableauReservationFormModalComponent } from './tableau-reservation-form-modal/tableau-reservation-form-modal.component';
import { TableauReservationSplitComponent } from './tableau-reservation-split/tableau-reservation-split.component';
import { TableauRoomRowComponent } from './tableau-room-row/tableau-room-row.component';
import { TableauRoutingModule } from './tableau-routing.module';
import { TableauRowResolverComponent } from './tableau-row-resolver/tableau-row-resolver.component';
import { TableauSwapRoomComponent } from './tableau-swap-room/tableau-swap-room.component';
import { TableauToolbarComponent } from './tableau-toolbar/tableau-toolbar.component';
import { PaymentStatusColorPipe } from '../../../pipes/payment-status-color.pipe';
import { AutomaticPaymentsConfigurationStoreModule } from '../automatic-payments-configuration/+state';
import { AutomaticPaymentsSummaryStoreModule } from '../../../components/automatic-payment-summary/+state';

const PIPES = [IsWeekendPipe, GetByDayPipe];

const DIRECTIVES = [
  TableauCellHoverDispatcherDirective,
  TableauSelectionableCellDirective,
  TableauReservationHoverDispatcherDirective,
  TableauScrollDragErrorCatcherDirective,
];

const SERVICES = [
  TableauHoverService,
  TableauSelectionService,
  TableauDataLoaderService,
  TableauDataSelectorService,
  TableauSearchService,
  TableauDragService,
  TableauDestinationRowSelectionService,
];

@NgModule({
  declarations: [
    ...PIPES,
    ...DIRECTIVES,
    TableauPageComponent,
    TableauRowResolverComponent,
    TableauBoxResolverComponent,
    TableauBoxWrapperComponent,
    TableauPropertyRowComponent,
    TableauAccommodationRowComponent,
    TableauEventRowComponent,
    TableauPropertyStatsRowComponent,
    TableauRoomRowComponent,
    TableauObRowComponent,
    TableauHeaderComponent,
    TableauReservationBoxComponent,
    TableauQuoteBoxComponent,
    TableauNoteBoxComponent,
    TableauToolbarComponent,
    TableauReservationSplitComponent,
    TableauDestinationRowSelectionModalComponent,
    TableauEstimateModalComponent,
    TableauReservationDetailsComponent,
    TableauModalHeaderComponent,
    TableauSwapRoomComponent,
    TableauChooseActionModalComponent,
    TableauNoteFormModalComponent,
    TableauClosureModalComponent,
    TableauReservationFormModalComponent,
    TableauModalHeaderDetailsComponent,
    TableauModalHeaderDetailsRowComponent,
    TableauModalHeaderDatesComponent,
    TableauModalPricesComponent,
    TableauQuoteDetailsComponent,
    TableauFooterRowComponent,
    TableauLegendComponent,
    TableauReservationDetailsPopoverComponent,
    TableauQuoteDetailsPopoverComponent,
    TableauFloorRowComponent,
    TableauReservationDetailsRoomComponent,
  ],
  imports: [
    CommonModule,
    TableauRoutingModule,
    UiModule,
    SharedModule,
    TranslateModule,
    NgPipesModule,
    ReactiveFormsModule,
    FormsModule,
    CdkVirtualScrollFixerModule,
    ContinuousClickModule,
    ButtonMobileModule,
    ToggleButtonModule,
    DragDropModule,
    EstimateModule,
    CollapsableBoxModule,
    CollapsableTextModule,
    TagSelectModule,
    ReservationFormModule,
    SimpleCompanyFormModule,
    NzOutletModule,
    TableauExportModule,
    MaintenanceReportsModule,
    PaymentStatusColorPipe,
    AutomaticPaymentsSummaryStoreModule,
    AutomaticPaymentsConfigurationStoreModule,
  ],
  providers: SERVICES,
})
export class TableauModule {}
