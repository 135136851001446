<div *ngIf="forms" class="table-responsive">
  <nz-tabset
    [nzType]="'card'"
    [(nzSelectedIndex)]="tabSelectedIndex"
    [nzTabBarExtraContent]="extraTemplate"
  >
    <nz-tab
      *ngFor="let tab of tabs; let indexTab = index"
      [nzTitle]="titleTemplate"
    >
      <ng-template #titleTemplate>
        <div>
          <i
            *ngIf="tab.default"
            class="fas fa-star color--orange"
            nz-tooltip
            [nzTooltipTitle]="
              'default_bed_combination' | translate | upperfirst
            "
          ></i>
          {{ tab.label }}
          <i
            nz-popconfirm
            nzPopconfirmTitle="{{
              'actions.delete'
                | translate : { param: 'combination' | translate }
            }}"
            (nzOnConfirm)="closeTab(tab.combination_id, indexTab)"
            class="ant-tabs-close-x far fa-times"
          ></i>
        </div>
      </ng-template>

      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label nzFor="titleTab">{{
            'title' | translate | upperfirst
          }}</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <input
                nz-input
                name="propertyName"
                id="titleTab"
                placeholder="{{
                  'placeholder.insert'
                    | translate : { param: 'title' | translate }
                }}"
                type="text"
                [(ngModel)]="tab.label"
                [ngModelOptions]="{ standalone: true }"
                class="titlePropertyName"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="titleTab"
            >{{ 'ideal_for' | translate | upperfirst }}
            {{ tab.ideal_for || null }}
            {{
              (tab.ideal_for > 1 ? 'guests' : 'guest') | translate
            }}</nz-form-label
          >
          <nz-form-control>
            <nz-input-group>
              <nz-input-number
                form
                [(ngModel)]="tab.ideal_for"
                [ngModelOptions]="{ standalone: true }"
                id="titleTab"
                byNumberOnly
                class="by-w-100"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="titleTab">{{
            'change_days_sheets' | translate | upperfirst
          }}</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <nz-input-number
                form
                [(ngModel)]="tab.change_days_sheets"
                [ngModelOptions]="{ standalone: true }"
                id="titleTab"
                byNumberOnly
                class="by-w-100"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="titleTab">{{
            'change_days_linen' | translate | upperfirst
          }}</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <nz-input-number
                form
                [(ngModel)]="tab.change_days_linen"
                [ngModelOptions]="{ standalone: true }"
                id="titleTab"
                byNumberOnly
                class="by-w-100"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="titleTab">{{
            'clean_every_days' | translate | upperfirst
          }}</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <nz-input-number
                form
                [(ngModel)]="tab.clean_every_days"
                [ngModelOptions]="{ standalone: true }"
                id="titleTab"
                byNumberOnly
                class="by-w-100"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <by-question
          [labelTemplate]="labelTemplate"
          [(ngModel)]="tab.default"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onDefaultChange(indexTab)"
        >
        </by-question>
        <ng-template #labelTemplate>
          <div>
            {{ 'default_combination_label' | translate | upperfirst }}
          </div>
          <span class="font-small">{{
            'default_combination_description' | translate | upperfirst
          }}</span>
        </ng-template>

        <nz-form-item *ngIf="tab.default">
          <nz-form-label nzRequired>
            <i
              nz-tooltip
              [nzTooltipTitle]="
                'visibility_days_before_next_reservation_description'
                  | translate
                  | upperfirst
              "
              class="fas fa-info-circle pointer color--orange by-mr-5"
            ></i>
            {{
              'visibility_days_before_next_reservation_label'
                | translate
                  : { days: tab.visibility_days_before_next_reservation || 'X' }
                | upperfirst
            }}
          </nz-form-label>
          <nz-form-control
            [nzValidateStatus]="
              clickedOnSave && !checkTabValidity(tab) ? 'error' : 'success'
            "
            [nzErrorTip]="'validation_form.required' | translate | upperfirst"
          >
            <nz-input-group>
              <nz-input-number
                form
                [(ngModel)]="tab.visibility_days_before_next_reservation"
                [ngModelOptions]="{ standalone: true }"
                id="titleTab"
                byNumberOnly
                class="by-w-100"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-tab>
  </nz-tabset>
  <ng-template #extraTemplate>
    <i class="ant-tabs-new-tab far fa-plus square" (click)="newTab(true)"></i>
  </ng-template>
</div>
<div class="by-mt-4 by-mr-10" nz-row nzJustify="end" nzAlign="middle">
  <button (click)="onSave()" nz-button nzType="primary">
    {{ 'save' | translate }}
  </button>
</div>
