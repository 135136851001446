<td
  *ngIf="firstColumnRowspan"
  nzLeft="0px"
  [attr.rowspan]="firstColumnRowspan"
  class="by-tableau-cell--first"
  [ngClass]="{
    'by-tableau-cell--hover': rowIsHovered
  }"
  byTableauCellHoverDispatcher
  [row]="row"
  nz-tooltip
  [nzTooltipTitle]="viewMode !== 'default' && data?.accommodation_details?.name"
  nzTooltipPlacement="right"
>
  <div class="by-flex by-flex-middle by-flex-between by-flex-wrap">
    <div class="by-flex by-flex-middle by-flex-grow">
      <i
        class="fas fa-broom pointer"
        [ngClass]="{
          'color--green': data.housekeeper?.clean,
          'color--red': !data.housekeeper?.clean
        }"
        nz-tooltip
        [nzTooltipTitle]="'change_status' | translate | upperfirst"
        (click)="userCanWrite && onChangeCleanStatus()"
      ></i>

      <i
        *ngIf="data.housekeeper?.notes && viewOptions?.housekeeper_notes"
        class="fas fa-person-sign pointer by-ml-10 color--azure by-fs-13"
        nz-tooltip
        [nzTooltipTitle]="housekeeperNotes"
      ></i>
      <ng-template #housekeeperNotes>
        <div class="by-mb-5">
          <strong>{{ 'housekeeper_notes' | translate | upperfirst }}</strong>
        </div>
        <div>{{ data.housekeeper?.notes }}</div>
      </ng-template>

      <i
        *ngIf="data.housekeeper?.pending_maintainer_issues_number"
        class="fas fa-exclamation-triangle pointer by-ml-10 color--orange"
        (click)="onOpenMaintenanceModal()"
      ></i>

      <span
        *ngIf="viewOptions?.bed_type"
        class="by-ml-5 color--grey-dark"
        nz-tooltip
        nzTooltipTitle="{{ 'preparation' | translate | upperfirst }}: {{
          data.housekeeper?.bed_type?.current?.label
        }}"
      >
        {{ data.housekeeper?.bed_type?.current?.label | truncate: 3 }}
      </span>
    </div>
    <div
      class="by-ml-5 pointer by-flex-grow by-tableau-label text-align--end"
      nz-popover
      nzPopoverTrigger="click"
      [nzPopoverTitle]="'edit_label' | translate | upperfirst"
      [nzPopoverContent]="editLabelTpl"
    >
      {{ data.label }}
    </div>
  </div>
  <ng-template #editLabelTpl>
    <div class="by-flex by-flex-middle">
      <input
        type="text"
        class="by-mr-5"
        nz-input
        [formControl]="labelControl"
      />
      <button
        nz-button
        nzSize="small"
        class="by-mr-5 ant-btn-icon-only"
        nzType="primary"
        (click)="onSaveDetails()"
      >
        <i class="far fa-check"></i>
      </button>
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        style="width: 35px"
        class="ant-btn-icon-only"
        nzDanger
        (click)="onCancelLabelChanges()"
      >
        <i class="far fa-times"></i>
      </button>
    </div>
  </ng-template>
</td>

<td
  *ngFor="let day of days; trackBy: dayTrackBy"
  class="by-tableau-cell"
  [ngClass]="{
    'by-tableau-cell--we': day | isWeekend,
    'by-tableau-cell--barred': data.closures | getByDay: day,
    'by-tableau-cell--hover': rowIsHovered,
    'by-tableau-cell--super-hover':
      rowIsHovered && (day | sameDay: hoveredCell?.date),
    'by-tableau-cell--selection':
      rowIsSelected && (selection.dates | getByDay: day)
  }"
  byTableauCellHoverDispatcher
  byTableauSelectionableCell
  [date]="day"
  [row]="row"
  [cellDirectives]="cellDirectives"
  [selectionDisabled]="!userCanWrite"
>
  <by-tableau-box-wrapper
    *ngIf="
      rowIsHoveredWithSameIndex &&
      draggingItem &&
      (day | sameDay: draggingItem.dateIndex)
    "
    [zoom]="zoom"
    [box]="{ data: draggingItem }"
    [viewOptions]="viewOptions"
  >
    <div
      class="by-tableau-drag-preview"
      [class.by-tableau-drag-preview--oblique]="viewOptions?.oblique"
    ></div>
  </by-tableau-box-wrapper>

  <span
    *ngIf="
      rowIsSelected &&
      selection?.length > 1 &&
      !rowData.index &&
      (day | sameDay: selection.middleDate)
    "
    class="by-tableau-cell--selection-label"
    [ngClass]="{
      'by-tableau-cell--selection-label-even': selection.length | isEven
    }"
  >
    {{ selection.length - 1 }}
    {{ (selection.length > 2 ? 'nights' : 'night') | translate | upperfirst }}
  </span>

  <by-tableau-box-wrapper
    *ngIf="items | getByDay: day as item"
    [box]="item"
    [row]="row"
    [zoom]="zoom"
    [viewOptions]="viewOptions"
    [searchValue]="searchValue"
  >
    <div
      by-tableau-box-resolver
      [zoom]="zoom"
      [item]="item"
      [draggingItem]="draggingItem"
      [reservationsColors]="reservationsColors"
      [channelsColors]="channelsColors"
      [viewOptions]="viewOptions"
      [userCanWrite]="userCanWrite"
      [splitMode]="splitMode"
      [row]="row"
      [period]="period"
      [hoveredReservation]="hoveredReservation"
      [isMobile]="isMobile"
    ></div>
  </by-tableau-box-wrapper>
</td>
