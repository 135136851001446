import { groupBy } from 'lodash';
import * as moment from 'moment';

import { Month } from '../models/month';

export function getMonthsFromDays(days: Date[]): Month[] {
  const dates = days.map((day) => {
    const year = moment(day).format('YYYY');
    const month = moment(day).format('MM');

    return {
      day,
      year,
      yearMonth: `${year}-${month}`,
    };
  });

  const groupedDatesByMonth = groupBy(dates, 'yearMonth');

  return Object.keys(groupedDatesByMonth)
    .sort()
    .map((yearMonth) => {
      const { year, day } = groupedDatesByMonth[yearMonth][0];
      return { year, day, colspan: groupedDatesByMonth[yearMonth].length };
    });
}
