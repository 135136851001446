import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { TableauDraggingItem, TableauRow } from '../../../../models';
import { TableauConfig } from '../config';
import { TableauRowComponents } from '../tableau-components-map';

@Injectable()
export class TableauDragService {
  private draggingItem$ = new Subject<{
    dateIndex: string;
    length: number;
  }>();

  startDrag(draggingItem: TableauDraggingItem) {
    this.draggingItem$.next(draggingItem);
  }

  getDraggingItem() {
    return this.draggingItem$.asObservable();
  }

  isInvalidDragEvent(event: CdkDragDrop<TableauRow>) {
    const { isPointerOverContainer, container, previousContainer } = event;

    if (!isPointerOverContainer) {
      return true;
    }

    if (container.id === previousContainer.id) {
      return true;
    }

    if (
      container.id !== TableauConfig.SwapRoomId &&
      container.data?.component !== TableauRowComponents.Room
    ) {
      return true;
    }

    return false;
  }
}
