import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { flatten, mapKeys } from 'lodash';
import * as moment from 'moment';
import { SubSink } from 'subsink';

import { ReservationStatus } from '../../../../config';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { IPaymentMethod } from '../../../../core/models/api/generics/payment-methods/payment-method.model';
import {
  ChildrenRange,
  EstimateRateplan,
  FormGetter,
  IInvoiceLayout,
  ProportionalDayPrice,
  ReservationFormAccommodation,
} from '../../../../models';
import { EstimateRequest } from '../../../../models/requests/estimate-request';
import { EstimateResponse } from '../../../../models/responses/estimate.response';
import { ReservationFormPaymentMethodComponent } from '../reservation-form-payment-method/reservation-form-payment-method.component';

@Component({
  selector: 'by-reservation-form-first-step',
  templateUrl: './reservation-form-first-step.component.html',
  styleUrls: ['./reservation-form-first-step.component.scss'],
})
export class ReservationFormFirstStepComponent
  implements OnInit, OnDestroy, FormGetter
{
  @ViewChildren('formGetter')
  formGetters: QueryList<FormGetter>;

  @ViewChild(ReservationFormPaymentMethodComponent)
  paymentMethodComponent: ReservationFormPaymentMethodComponent;

  @Input()
  accommodation: ReservationFormAccommodation;

  @Input()
  childrenRanges: ChildrenRange[];

  @Input()
  rateplans: EstimateRateplan[];

  @Input()
  dates: Date[];

  @Input()
  estimateResponse: EstimateResponse;

  @Input()
  proportionalDayPriceResponse: ProportionalDayPrice;

  @Input()
  priceLoading: boolean;

  @Input()
  currency: ICurrency;

  @Input()
  invoiceLayouts: IInvoiceLayout[];

  @Input()
  nextDepositNumber: number;

  @Input()
  nextDepositNumberLoading: number;

  @Input()
  paymentMethods: IPaymentMethod[];

  @Input()
  defaultInvoiceLayoutId: number;

  @Input() isMobile = false;

  @Output()
  estimate = new EventEmitter<Partial<EstimateRequest>>();

  @Output()
  proportionalDayPrice = new EventEmitter<ProportionalDayPrice>();

  @Output()
  loadDepositNextNumber = new EventEmitter<number>();

  @Output()
  discountChanged = new EventEmitter();

  form = this.formBuilder.group({
    guests: [null],
    rateplan: [null],
    status: [{ status: ReservationStatus.Confirmed }],
    checkin_hour: [null],
    checkout_hour: [null],
    price: [null],
    deposit: [null],
    payment_method: [null],
  });

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.registerToControlValueChangesAndEmitEstimate('guests');
    this.registerToControlValueChangesAndEmitEstimate('rateplan');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getForms() {
    return [
      this.form,
      ...flatten(this.formGetters.map((formGetter) => formGetter.getForms())),
    ];
  }

  getEstimateRequest(formValue = this.form.value) {
    const { guests, rateplan } = formValue;

    return {
      children: guests?.total_children,
      num_adults: guests?.adults_number,
      rateplan_id: rateplan?.rateplan_id,
      treatment_id: rateplan?.treatment_id,
    };
  }

  get priceControl() {
    return this.form.controls.price;
  }

  get value() {
    const { deposit, payment_method, checkin_hour, checkout_hour } =
      this.form.value;

    return {
      ...this.form.value,
      checkin_hour: checkin_hour && moment(checkin_hour).format('HH:mm'),
      checkout_hour: checkout_hour && moment(checkout_hour).format('HH:mm'),
      payment_method: !deposit
        ? payment_method
        : mapKeys(payment_method, (_, key) => 'deposit_' + key),
    };
  }

  private registerToControlValueChangesAndEmitEstimate(controlName: string) {
    this.subs.add(
      this.form.get(controlName).valueChanges.subscribe((controlValue) => {
        this.estimate.emit(
          this.getEstimateRequest({
            ...this.form.value,
            [controlName]: controlValue,
          }),
        );
      }),
    );
  }

  get formLayout(): 'horizontal' | 'vertical' {
    return this.isMobile ? 'vertical' : 'horizontal';
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
