import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpBackend, HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Bowser from 'bowser';
import { defaultTo, upperFirst } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { PagesLoadedEvent } from 'ngx-extended-pdf-viewer';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, map, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { GRID_BREAKPOINT } from '../../config';
import { getBase64 } from '../../core/helpers/get-base-64';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { FileSaverService } from '../../services';
import { AttachmentModalData } from '../../models/objects/attachment-viewer-modal-data';
import mime from 'mime';
@Component({
  selector: 'by-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss'],
})
export class AttachmentViewerComponent implements OnInit, OnDestroy {
  @Input() printData: {
    src: string;
    fileName: string;
  };

  @Input()
  height = '80vh';

  @Input()
  loading = false;

  @Input('src') set _src(src: string) {
    if (!src) {
      return;
    }
    this.src = src;
    this.loading = true;
    this.subs.add(
      this.getBlob(src)
        .pipe(
          catchError((error) => {
            this.errorHandler.handle(error);
            this.error = error;
            this.loading = false;
            return throwError(error);
          }),
        )
        .subscribe(this.setBlob),
    );
  }

  @Input()
  title: string;

  src: string;

  type: string;

  blob: Blob;

  base64: string;

  isMobile$ = this.breakPointObserver
    .observe(GRID_BREAKPOINT.beforeMedium)
    .pipe(map(({ matches }) => matches));

  findState$ = new BehaviorSubject(null);

  isMobile = false;

  protected _error: any;

  findState: 'success' | 'error' | 'validating';

  totalPages = 0;

  page = 1;

  zoom: string | number = 100;

  pageScale = 1.0;

  private http: HttpClient;

  private subs = new SubSink();

  readonly nzModalData: Pick<
    AttachmentModalData,
    '_src' | 'title' | 'loading' | 'height' | 'printData'
  > = inject(NZ_MODAL_DATA);

  constructor(
    httpBackend: HttpBackend,
    @Optional() private modal: NzModalRef,
    private translate: TranslateService,
    private cookieService: CookieService,
    private errorHandler: ErrorHandlerService,
    private fileSaverService: FileSaverService,
    private breakPointObserver: BreakpointObserver,
  ) {
    this.http = new HttpClient(httpBackend);
  }

  ngOnInit(): void {
    const { _src, title, loading, height, printData } = this.nzModalData;
    this.printData = printData;
    this._src = _src;
    this.loading = loading;
    this.title = title;
    this.height = height;

    this.subs.add(
      this.findState$
        .pipe(
          tap(() => (this.findState = 'validating')),
          debounceTime(300),
        )
        .subscribe((findState) => {
          this.findState = findState;
        }),
    );

    this.subs.add(
      this.isMobile$.subscribe((isMobile) => (this.isMobile = isMobile)),
    );
  }

  get isImage() {
    return this.blob?.type?.split('/')?.includes('image');
  }

  setBlob = (blob: Blob) => {
    this.blob = blob;

    this.type = mime.getExtension(this.blob.type);

    if (this.isImage) {
      this.modal?.updateConfig({
        nzTitle: this.title,
        nzClosable: true,
        nzBodyStyle: null,
        nzFooter: [
          {
            type: 'primary',
            label: upperFirst(this.translate.instant('download')),
            onClick: () => this.onDownload(),
          },
        ],
      });
    } else if (this.type !== 'pdf') {
      this.onDownload();

      this.modal?.close();
    }

    getBase64(this.blob, (base64) => {
      this.base64 = base64;
      this.checkPlatformCompatibility();
      this.loading = false;
    });
  };

  getBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  get lang() {
    return this.translate.store.currentLang;
  }

  onClose() {
    this.modal?.destroy();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.modal?.destroy();
  }

  private checkPlatformCompatibility() {
    const parser = Bowser.getParser(window.navigator.userAgent);

    const isMobileApp = !!this.cookieService.get('from_mobile_app');

    const isExtendedPdfViewerSupported =
      defaultTo(parser.satisfies({ safari: '>=15' }), true) && !isMobileApp;

    const isPdf = this.type === 'pdf';

    if (!isExtendedPdfViewerSupported && isPdf) {
      this.fileSaverService.saveAs(
        this.blob,
        this.setExtension(this.title, 'pdf'),
      );
      this.modal?.close();
    }
  }

  setFindState(state: number) {
    switch (state) {
      case 2:
      case 0: {
        this.findState$.next('success');
        break;
      }
      case 1: {
        this.findState$.next('error');
        break;
      }

      case 3: {
        this.findState$.next('validating');
        break;
      }
    }
  }

  onSetPageLoaded(pagesEvent: PagesLoadedEvent) {
    this.totalPages = pagesEvent.pagesCount;
  }

  onPageChange(page: number) {
    this.page = page;
  }

  onSetPageScale(pageScale: number) {
    this.pageScale = pageScale;
  }

  onSetZoom(zoom: number | string) {
    this.zoom = zoom;
  }

  onDownload() {
    this.fileSaverService.saveAs(
      this.blob,
      this.setExtension(this.title, this.type),
    );
  }

  set error(error: any) {
    this._error = error;

    this.onClose();
  }

  get error() {
    return this._error;
  }

  private setExtension(path: string, extension: string) {
    return path.includes('.' + extension) ? path : `${path}.${extension}`;
  }
}
