<div class="alert-box__list">
  <div class="alert-box__item" *ngIf="estimateResponse?.closed">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{ 'message_warning_closed' | translate | upperfirst }}</span>
  </div>

  <div class="alert-box__item" *ngIf="estimateResponse?.close_to_arrival">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{
      'message_warning_close_to_arrival' | translate | upperfirst
    }}</span>
  </div>

  <div class="alert-box__item" *ngIf="estimateResponse?.close_to_departure">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{
      'message_warning_close_to_departure' | translate | upperfirst
    }}</span>
  </div>

  <div class="alert-box__item" *ngIf="estimateResponse?.min_stay">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{ 'message_warning_min_stay' | translate | upperfirst }}</span>
  </div>

  <div class="alert-box__item" *ngIf="estimateResponse?.max_stay">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{ 'message_warning_max_stay' | translate | upperfirst }}</span>
  </div>

  <div class="alert-box__item" *ngIf="estimateResponse?.treatment_not_set">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{
      'message_warning_treatment_not_set' | translate | upperfirst
    }}</span>
  </div>
</div>
