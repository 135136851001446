import { Action, createReducer, on } from '@ngrx/store';

import { Property } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

const PROPERTY_NOTIFICATION_KEYS = [
  { key: 'first_notice_date', icon: 'fas fa-circle color--green' },
  { key: 'second_notice_date', icon: 'fas fa-circle color--orange' },
  { key: 'deactivate_users_date', icon: 'fas fa-circle color--red' },
];

const propertiesMapper = (property: Property): Property => {
  const notices = PROPERTY_NOTIFICATION_KEYS.filter(
    ({ key }) => property[key],
  ).map(({ key, icon }) => ({ key, value: property[key], icon }));

  return { ...property, notices };
};

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items, pagination }) =>
    fromState.featureAdapter.setAll(items.map(propertiesMapper), {
      ...state,
      isLoading: false,
      error: null,
      pagination,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadLookupRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadLookupSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadLookupFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadGroupPropertiesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadGroupPropertiesSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadGroupPropertiesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadDetailsSuccess, (state, { item }) =>
    fromState.featureAdapter.upsertOne(item, {
      ...state,
      isLoading: false,
      error: null,
      details: item,
    }),
  ),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.enableRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.enableSuccess, (state, { itemId }) => {
    const item = {
      ...state.entities[itemId],
      status: 1,
    };
    return fromState.featureAdapter.updateOne(
      { id: item.id, changes: item },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    );
  }),
  on(fromActions.enableFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { itemId }) => {
    const item = {
      ...state.entities[itemId],
      status: 0,
    };
    return fromState.featureAdapter.updateOne(
      { id: item.id, changes: item },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    );
  }),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { item }) =>
    fromState.featureAdapter.addOne(item, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state, { item }) =>
    fromState.featureAdapter.updateOne(
      { id: item.id, changes: item },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateAttributesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.updateAttributesSuccess,
    (state, { request: { property_id, ...attributes } }) =>
      fromState.featureAdapter.updateOne(
        {
          id: property_id,
          changes: {
            attributes: {
              ...state.entities[property_id].attributes,
              ...attributes,
            },
          },
        },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      ),
  ),
  on(fromActions.updateAttributesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
  on(fromActions.cloneRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.cloneSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.cloneFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export function propertiesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
