import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mapValues } from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { environment } from '../../environments/environment';
import { ICurrency } from '../core/models/api/generics/currencies/currency.model';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { EstimateInterfaceComponent } from '../features/commons/estimate/estimate-interface/estimate-interface.component';
import { Days } from '../features/commons/estimate/models/days';
import { SplitInformations } from '../features/commons/estimate/models/split-informations';
import { EstimateModalService } from '../features/commons/estimate/services/estimate-modal.service';
import { TableauEstimateModalComponent } from '../features/commons/tableau-2/tableau-estimate-modal/tableau-estimate-modal.component';
import { removeNullishValues } from '../helpers';
import {
  ISplitReservation,
  SplitDailyRates,
  SplitReservationAccommodationResponse,
  TableauReservation,
  TableauRoom,
  TableauRow,
} from '../models';

import { TableauService } from './tableau.service';
import { TreatmentsService } from './treatments.service';

interface Params {
  reservation: TableauReservation;
  sourceRow: TableauRow<TableauRoom>;
  destinationRow: TableauRow<TableauRoom>;
  warnings: string[];
  splitData: SplitReservationAccommodationResponse;
  splitRequest: ISplitReservation;
  currencies: ICurrency[];
}

@Injectable({ providedIn: 'root' })
export class TableauSplitEstimateService {
  private subs = new SubSink();

  beddyChannelId = environment.beddyChannelId;

  constructor(
    private translate: TranslateService,
    private tableauService: TableauService,
    private treatmentsService: TreatmentsService,
    private estimateModalService: EstimateModalService,
  ) {}

  estimate(params: Params): Observable<Partial<ISplitReservation>> {
    const modal = this.openModal(params);

    this.subs.add(
      modal.afterOpen.subscribe(() => {
        const component = modal.componentInstance.getInterface();
        this.onModalComponentInit(component, params);
      }),
    );

    return modal.afterClose.asObservable().pipe(
      map((component) => {
        this.subs.unsubscribe();

        if (!component) {
          return null;
        }

        const {
          modelPrice,
          splitOptionsForm: {
            value: { rateplan_id, treatment_id },
          },
        } = component;

        return removeNullishValues({
          force_operation: true,
          model_price: modelPrice,

          rateplan_id_to: rateplan_id,
          treatment_id_to: treatment_id,
        });
      }),
    );
  }

  private openModal(params: Params) {
    const { splitData, currencies, reservation, warnings } = params;

    return this.estimateModalService.interface({
      componentClass: TableauEstimateModalComponent,
      componentInputs: {
        showWarnings: true,
        currencySymbol: this.getCurrencySymbol(
          splitData.currency_id,
          currencies,
        ),
        guestFullName: reservation.label,
        dates: this.getDates(splitData),
        range: [reservation.arrival_date, reservation.departure_date],
        newTotal: splitData.new_price,
        originalTotal: splitData.old_price,
        isClosed: splitData.is_closed,
        hasDoorCode: splitData.has_door_key,
        withoutAvail: !splitData.is_available,
        splitInformations: this.getSplitInformations(params),
        warnings,
        newDays: this.getDays(splitData.daily_rates?.new_rates),
        originalDays: this.getDays(splitData.daily_rates?.old_rates),
        rateplanRequired: splitData.rateplan_id_required,
        treatmentRequired: splitData.treatment_id_required,
        oldTreatmentId: splitData?.old_treatment_id,
        saveButtonLabel: this.translate.instant('move_reservation'),
      },
    });
  }

  private getCurrencySymbol(currencyId: number, currencies: ICurrency[]) {
    return currencies?.find(({ id }) => currencyId === id)?.symbol;
  }

  private getDates(splitData: SplitReservationAccommodationResponse): string[] {
    const { daily_rates } = splitData;
    return Object.keys(daily_rates?.new_rates || {}).sort();
  }

  private getSplitInformations(params: Params): SplitInformations {
    const { sourceRow, destinationRow } = params;

    const getAccommodationInfo = (row: TableauRow<TableauRoom>) => ({
      accommodation: row.data.accommodation_details.name,
      tableau_label: row.data.label,
    });

    return {
      new: getAccommodationInfo(destinationRow),
      original: getAccommodationInfo(sourceRow),
    };
  }

  private getDays(dailyRates: SplitDailyRates): Days {
    return mapValues(dailyRates, ({ price }) => ({ total: price }));
  }

  private onModalComponentInit(
    component: EstimateInterfaceComponent,
    params: Params,
  ) {
    const { splitData } = params;

    this.registerToRateplanTreatmentChanges(component, params);

    if (splitData.rateplan_id_required || splitData.treatment_id_required) {
      this.loadRateplansTreatments(
        splitData.accommodation_id,
        splitData.rateplan_id,
        component,
        params?.reservation?.channel_id === this.beddyChannelId,
        splitData.old_treatment_id,
      );
    }
  }

  private loadRateplansTreatments(
    accommodationId: number,
    rateplanId: number,
    component: EstimateInterfaceComponent,
    withoutOnlyChannel: boolean,
    oldTreatmentId?: number,
  ) {
    if (!accommodationId) {
      return;
    }
    component.loading = true;
    this.subs.add(
      this.treatmentsService
        .loadWithRateplans(accommodationId, withoutOnlyChannel)
        .subscribe(({ data }: IResponseSuccess) => {
          const rateplan = data.find(({ id }) => id === rateplanId) || data[0];

          component.rateplans = data;
          component.treatments = rateplan.treatments;

          component.loading = false;

          const treatmentFind = rateplan.treatments.find(
            ({ id }) => +id === oldTreatmentId,
          );

          component.splitOptionsForm.patchValue({
            rateplan_id: rateplan.id,
            treatment_id: treatmentFind?.id || rateplan.treatments[0].id,
          });
        }),
    );
  }

  private registerToRateplanTreatmentChanges(
    component: EstimateInterfaceComponent,
    params: Params,
  ) {
    const { splitRequest, currencies } = params;

    this.subs.add(
      component.splitOptionsForm.valueChanges
        .pipe(
          switchMap(({ rateplan_id, treatment_id }) => {
            component.loading = true;

            return this.tableauService.moveReservation({
              ...splitRequest,
              rateplan_id_to: rateplan_id,
              treatment_id_to: treatment_id,
            });
          }),
        )
        .subscribe(
          ({
            data,
            meta,
          }: IResponseSuccess<SplitReservationAccommodationResponse[]>) => {
            const splitData = data[0];

            component.newTotal = splitData?.new_price;
            component.originalTotal = splitData?.old_price;
            component.dates = this.getDates(splitData);
            component.newDays = this.getDays(splitData?.daily_rates?.new_rates);
            component.originalDays = this.getDays(
              splitData?.daily_rates?.old_rates,
            );
            component.currencySymbol = this.getCurrencySymbol(
              splitData?.currency_id,
              currencies,
            );

            component.isClosed = splitData?.is_closed;

            component.withoutAvail = !splitData?.is_available;

            component.warnings = meta?.warnings;

            component.newDays = this.getDays(splitData?.daily_rates?.new_rates);

            component.originalDays = this.getDays(
              splitData?.daily_rates?.old_rates,
            );

            component.rateplanRequired = splitData?.rateplan_id_required;

            component.treatmentRequired = splitData?.treatment_id_required;

            component.oldTreatmentId =
              splitData?.old_treatment_id || component.oldTreatmentId;

            component.loading = false;
          },
        ),
    );
  }
}
