import { UntypedFormGroup } from '@angular/forms';
import { camelCase, snakeCase } from 'lodash';
import * as moment from 'moment';

type TransformerFn = (key: string, value: any) => [string, any];

export class FormAdapter {
  private form: UntypedFormGroup = null;

  private submitTransformerFn = this.defaultSubmitTransformer;
  private patchValueTransformerFn = this.defaultPatchValueTransformer;

  constructor(options: {
    form: UntypedFormGroup;
    submitTransformerFn?: TransformerFn;
    patchValueTransformerFn: TransformerFn;
  }) {
    const {
      form,
      submitTransformerFn = this.defaultSubmitTransformer,
      patchValueTransformerFn = this.defaultPatchValueTransformer,
    } = options;

    this.form = form;

    this.submitTransformerFn = submitTransformerFn;
    this.patchValueTransformerFn = patchValueTransformerFn;
  }

  patchValue(value: Object) {
    Object.keys(value).forEach((key) => {
      this.form.patchValue(this.patchValueTransformerFn(key, value[key]));
    });
  }

  submit(handler: (value: any) => void, forceSubmit?: boolean) {
    if (forceSubmit) {
      handler(this.value());

      return;
    }

    if (this.form.invalid) {
      this.invalidateForm();

      return;
    }

    handler(this.value());
  }

  private invalidateForm() {
    Object.keys(this.form.controls).forEach((control) => {
      this.form.get(control).markAsDirty();
      this.form.get(control).updateValueAndValidity();
    });
  }

  private value() {
    return Object.keys(this.form.controls).reduce((values, control) => {
      const [key, value] = this.submitTransformerFn(
        control,
        this.form.get(control).value,
      );

      return {
        ...values,
        [key]: value,
      };
    }, {});
  }

  private defaultSubmitTransformer(key: string, value: any): [string, any] {
    if (value instanceof Date) {
      value = moment(value).format('YYYY-MM-DD');
    }

    return [snakeCase(key), value];
  }

  private defaultPatchValueTransformer(key: string, value: any): [string, any] {
    if (value instanceof String && value.match(/([0-9]+-[0-9]+-[0-9]+){1}/g)) {
      value = new Date();
    }

    return [camelCase(key), value];
  }
}
