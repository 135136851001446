<div class="alert-box__list">
  <div class="alert-box__item" *ngIf="estimateResponse?.price_to_zero">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{ 'price_to_zero_warning' | translate | upperfirst }}</span>
  </div>

  <div *ngFor="let warning of warnings" class="alert-box__item">
    <i class="alert-box__icon fas fa-exclamation-triangle"></i>
    <span>{{ warning.message | translate: { param: warning.param } }}</span>
  </div>
</div>
