import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { get, last, mapValues, sortBy } from 'lodash';
import * as moment from 'moment';
import { SubSink } from 'subsink';

import { EstimateRequest } from '../../../../models/requests/estimate-request';
import { EstimateResponse } from '../../../../models/responses/estimate.response';
import {
  EstimateActions,
  EstimateSelectors,
} from '../../../../root-store/estimate-store';
import { RootState } from '../../../../root-store/root-state';
import { EstimateInterfaceComponent } from '../estimate-interface/estimate-interface.component';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

type Days = Record<string, { total: number; discountValue?: number }>;

interface SplitInformations {
  original: { accommodation: string; tableau_label?: string };
  new: { accommodation: string; tableau_label?: string };
}

@Component({
  selector: 'by-estimate-modal',
  templateUrl: './estimate-modal.component.html',
  styleUrls: ['./estimate-modal.component.scss'],
})
export class EstimateModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  readonly nzDataModal: Partial<EstimateModalComponent> = inject(NZ_MODAL_DATA);

  @ViewChild(EstimateInterfaceComponent)
  interface: EstimateInterfaceComponent;

  @Input()
  estimateRequests: EstimateRequest[] = this.nzDataModal.estimateRequests || [];

  @Input()
  originalDays: Days = this.nzDataModal.originalDays || null;

  @Input()
  guestFullName: string = this.nzDataModal.guestFullName || null;

  @Input()
  currencySymbol: string = this.nzDataModal.currencySymbol || null;

  @Input()
  splitInformations: SplitInformations =
    this.nzDataModal.splitInformations || null;

  loading$ = this.store.pipe(select(EstimateSelectors.selectLoading));

  rateplans: Array<{
    id: number;
    name: string;
    treatments: Array<{ id: number; name: string }>;
  }>;

  dates: string[];

  newDays: Days;

  originalTotal: number;

  newTotal: number;

  estimateResponse: EstimateResponse;

  rateplanRequired: boolean;

  private subs = new SubSink();

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.estimate(this.estimateRequests);

    this.originalTotal = this.getTotalFromDays(this.originalDays);

    this.setDates();
  }

  ngAfterViewInit() {
    this.subs.add(
      this.store
        .pipe(select(EstimateSelectors.selectEstimate))
        .subscribe((estimateResponse) => {
          if (!estimateResponse) {
            return;
          }

          this.estimateResponse = estimateResponse;

          const { rateplan_id_required, treatment_id_required } =
            estimateResponse;

          if (
            rateplan_id_required &&
            typeof rateplan_id_required === 'boolean'
          ) {
            this.rateplanRequired = this.rateplanRequired || true;
          }

          if (
            (rateplan_id_required || treatment_id_required) &&
            !this.rateplans
          ) {
            this.loadRateplans();
          }

          this.setNewDays();

          this.newTotal = this.getTotalFromDays(this.newDays);
        }),
    );

    this.subs.add(
      this.splitOptionsForm.valueChanges.subscribe(
        ({ rateplan_id, treatment_id }) => {
          if (!rateplan_id || !treatment_id) {
            return;
          }

          this.estimate(
            this.estimateRequests.map((estimateRequest) => {
              return { ...estimateRequest, rateplan_id, treatment_id };
            }),
          );
        },
      ),
    );

    this.subs.add(
      this.store
        .pipe(select(EstimateSelectors.selectRateplans))
        .subscribe((rateplans) => {
          if (!rateplans) {
            return;
          }

          const { rateplan_id_required } = this.estimateResponse;

          const rateplan =
            rateplans.find(({ id }) => id === rateplan_id_required) ||
            rateplans[0];

          this.rateplans = rateplans;
          this.interface.rateplans = rateplans; //Prevent CD delay

          this.splitOptionsForm.patchValue({
            rateplan_id: rateplan.id,
            treatment_id: rateplan.treatments[0].id,
          });
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(EstimateActions.resetState());
  }

  get splitOptionsForm() {
    return this.interface?.splitOptionsForm;
  }

  get estimateRequest() {
    return this.estimateRequests && this.estimateRequests[0];
  }

  get dailyPrices(): Record<string, number> {
    const days =
      this.modelPrice === 'original' ? this.originalDays : this.newDays;

    return mapValues(days, ({ total }) => +total);
  }

  get modelPrice() {
    return this.interface?.modelPrice;
  }

  get showRateplanAndTreatmentSelect() {
    return (
      this.estimateResponse?.rateplan_id_required ||
      this.estimateResponse?.treatment_id_required ||
      this.splitOptionsForm.dirty
    );
  }

  private estimate(requests: EstimateRequest[]) {
    this.store.dispatch(EstimateActions.estimateRequest({ requests }));
  }

  private setNewDays() {
    this.newDays = {
      ...this.originalDays,
      ...mapValues(this.estimateResponse.days, (total, date) => ({
        total,
        discountValue: get(this.originalDays, [date, 'discountValue']) || 0,
      })),
    };
  }

  private setDates() {
    if (!this.originalDays) {
      return;
    }

    this.dates = this.addCheckoutDay(sortBy(Object.keys(this.originalDays)));
  }

  private getTotalFromDays(days: Days): number {
    return Object.values(days || {}).reduce(
      (daysTotal, { total, discountValue }) => {
        return (daysTotal += +total - (+discountValue || 0));
      },
      0,
    );
  }

  private addCheckoutDay(dates: string[]): string[] {
    const lastDate = moment(new Date(last(dates)))
      .add(1, 'day')
      .format('YYYY-MM-DD');

    return [...dates, lastDate];
  }

  private loadRateplans() {
    this.store.dispatch(
      EstimateActions.loadRateplansRequest({
        accommodationId: this.estimateResponse.accommodation_id,
      }),
    );
  }
}
