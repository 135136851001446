import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';

import {
  TableauMappingAccommodationProperty,
  TableauRow,
} from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauExportRowBuilder } from '../tableau-export-builder';
import {
  DEFAULT_BORDER,
  getDefaultCellStyle,
  ROW_HEIGHT,
} from '../tableau-export.config';

@Injectable()
export class PropertyRowBuilderService implements TableauExportRowBuilder {
  build(
    tableuRow: TableauRow<TableauMappingAccommodationProperty>,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const {
      tableau_2: { days },
      core: { allProperties },
    } = state;

    const property = allProperties.find(
      ({ id }) => id === tableuRow.data.property_id,
    );

    const row = worksheet.addRow([property.name, ...days.map(() => null)]);

    row.height = ROW_HEIGHT;

    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.style = getDefaultCellStyle({
        border: {
          bottom: DEFAULT_BORDER,
          top: DEFAULT_BORDER,
          right: { style: 'thin', color: { argb: 'f3f3f5' } },
          left: { style: 'thin', color: { argb: 'f3f3f5' } },
        },
        alignment: { vertical: 'middle', indent: 1 },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f3f3f5' },
        },
        font: {
          bold: true,
        },
      });
    });
  }
}
