<div nz-row class="by-mb-2">
  <span *ngIf="!reservationData?.reservationAccommodationId">
    {{
      (reservationData?.status !== 'Cancelled'
        ? 'actions.cancel'
        : 'actions.delete'
      )
        | translate
          : {
              param: 'reservation' | translate
            }
    }}
  </span>
  <span *ngIf="reservationData?.reservationAccommodationId">
    {{
      'actions.delete'
        | translate
          : {
              param: 'accommodation' | translate
            }
    }}
  </span>
</div>

<div nz-row style="width: 100%" nzJustify="end" nzAlign="middle">
  <button class="by-mr-2" nz-button nzType="default" (click)="closeModal()">
    <span>
      {{ 'cancel' | translate | upperfirst }}
    </span>
  </button>

  <ng-container *ngIf="reservationData?.reservationAccommodationId">
    <div nz-col>
      <button
        nz-button
        nzDanger
        nzType="primary"
        (click)="onDelete(false)"
        [nzLoading]="loading"
      >
        <i class="fas fa-trash-alt by-mr-5"></i>
        <span>
          {{ 'delete_accommodation' | translate | upperfirst }}
        </span>
      </button>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      !reservationData?.reservationAccommodationId &&
      reservationData?.status === 'Cancelled'
    "
  >
    <div nz-col>
      <button
        nz-button
        nzDanger
        nzType="primary"
        (click)="onDelete(false)"
        [nzLoading]="loading"
      >
        <i class="fas fa-trash-alt by-mr-5"></i>
        <span>
          {{ 'delete_reservation' | translate | upperfirst }}
        </span>
      </button>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      !reservationData?.reservationAccommodationId &&
      reservationData?.status !== 'Cancelled'
    "
  >
    <button
      nz-button
      nzDanger
      nzType="primary"
      class="by-mr-2"
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzLoading]="loading"
    >
      <span
        >{{ 'cancel_reservation' | translate | upperfirst }}
        <i class="far fa-chevron-down"></i>
      </span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li (click)="onDelete(false)" nz-menu-item>
          <i class="fas fa-trash-alt by-mr-5 color--red"></i>
          <span>
            {{
              'cancel_reservation_warning_without_restore'
                | translate
                | upperfirst
            }}</span
          >
        </li>
        <li
          *ngIf="!hideDeleteAndRestoreAvailabilityButton"
          (click)="reservationData?.status !== 'Expired' && onDelete(true)"
          nz-menu-item
          [ngClass]="{ disabled: reservationData?.status === 'Expired' }"
        >
          <i class="fas fa-trash-undo-alt by-mr-5 color--orange"></i>
          <span>
            {{
              'cancel_reservation_warning_with_restore' | translate | upperfirst
            }}
          </span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
