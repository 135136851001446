<td
  *ngIf="firstColumnRowspan"
  nzLeft="0px"
  [attr.rowspan]="firstColumnRowspan"
  class="by-tableau-cell--first"
>
  {{ 'events' | translate | uppercase }}
</td>

<td
  *ngFor="let day of days"
  class="by-tableau-cell"
  [ngClass]="{ 'by-tableau-cell--we': day | isWeekend }"
>
  <by-tableau-box-wrapper
    *ngIf="items | get: [day | formatDate: 'YYYY-MM-DD', 'data'] as event"
    class="by-tableau-event"
    [box]="{ data: event }"
    [boxColor]="event.color"
    [zoom]="zoom"
    [viewOptions]="viewOptions"
    nz-tooltip
    nzTooltipTitle="[{{ event.property_name }}] - ({{
      event.date_from | formatDate
    }} - {{ event.date_to | formatDate }}) {{ event.title }}"
  >
    <span>{{ event.title }}</span>
  </by-tableau-box-wrapper>
</td>
