<div class="by-tableau-toolbar">
  <div
    *ngIf="(propertiesNames | keys)?.length > 1"
    class="by-tableau-toolbar__section"
  >
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-home-alt by-mr-5"></i>
        {{ 'properties' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <ng-container *ngTemplateOutlet="propertiesSelectTemplate"></ng-container>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-bed by-mr-5"></i>
        {{ 'accommodation_types' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <by-multi-select
        [formControl]="visibleAccommodationsControl"
        [options]="accommodations"
        [groupsNames]="propertiesNames"
        label="accommodation_details.name"
        value="accommodation_details.id"
        groupByKey="property_details.property_id"
      ></by-multi-select>
    </div>
  </div>

  <div *ngIf="tags?.length" class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-tags by-mr-5"></i>
        {{ 'reservation_tag' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-select
        [formControl]="viewOptionsForm.controls.tag_id"
        [nzCustomTemplate]="customTagTemplate"
        [nzDisabled]="!viewOptionsForm.value.tags"
        class="by-w-100"
      >
        <nz-option
          [nzLabel]="'NS' | translate | upperfirst"
          [nzValue]="0"
        ></nz-option>

        <nz-option
          *ngFor="let tag of tags"
          [nzLabel]="tag.label + '@beddy' + tag.color"
          [nzValue]="tag.id"
          nzCustomContent
        >
          <i class="fas fa-tag by-mr-5" [style.color]="tag.color"></i>
          {{ tag.label }}
        </nz-option>
      </nz-select>
      <ng-template #customTagTemplate let-selected>
        <i
          *ngIf="selected.nzValue"
          class="fas fa-tag by-mr-5"
          [style.color]="selected.nzLabel | split : '@beddy' | last"
        ></i>
        {{ selected.nzLabel | split : '@beddy' | head }}
      </ng-template>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-desktop by-mr-5"></i>
        {{ 'visualization' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-radio-group
        [(ngModel)]="viewMode"
        (ngModelChange)="onChangeViewMode($event)"
      >
        <label nz-radio nzValue="default">
          {{ 'by_accommodation_type' | translate | upperfirst }}
        </label>
        <label nz-radio nzValue="unified">
          {{ 'unified' | translate | upperfirst }}
        </label>
        <label nz-radio nzValue="floors">
          {{ 'by_floor' | translate | upperfirst }}
        </label>
      </nz-radio-group>
    </div>
  </div>

  <div *ngIf="activeChannels?.length" class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-palette by-mr-5"></i>
        {{ 'reservations_colors' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-radio-group
        [formControl]="viewOptionsForm.controls.reservations_palette"
      >
        <label nz-radio nzValue="status">
          {{ 'by_status' | translate | upperfirst }}
        </label>
        <label nz-radio nzValue="channel">
          {{ 'by_channel' | translate | upperfirst }}
        </label>
      </nz-radio-group>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-cut by-mr-5"></i>
        {{ 'enable_reservations_split' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-radio-group
        [ngModel]="splitMode"
        (ngModelChange)="onSplitModeChange($event)"
      >
        <label nz-radio [nzValue]="true">
          {{ 'yes' | translate | upperfirst }}
        </label>
        <label nz-radio [nzValue]="false">
          {{ 'no' | translate | upperfirst }}
        </label>
      </nz-radio-group>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-expand-wide by-mr-5"></i>
        {{ 'fullscreen' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-radio-group
        [ngModel]="fullscreen"
        (ngModelChange)="fullscreenChange.emit($event)"
      >
        <label nz-radio [nzValue]="true">
          {{ 'yes' | translate | upperfirst }}
        </label>
        <label nz-radio [nzValue]="false">
          {{ 'no' | translate | upperfirst }}
        </label>
      </nz-radio-group>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-rectangle-wide oblique-icon by-mr-5"></i>
        {{ 'oblique_elements' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-radio-group [formControl]="viewOptionsForm.controls.oblique">
        <label nz-radio [nzValue]="true">
          {{ 'yes' | translate | upperfirst }}
        </label>
        <label nz-radio [nzValue]="false">
          {{ 'no' | translate | upperfirst }}
        </label>
      </nz-radio-group>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-users by-mr-5"></i>
        {{ 'group_reservation_visualization' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-radio-group
        [formControl]="viewOptionsForm.controls.reservation_details_group_label"
      >
        <label nz-radio nzValue="room">
          {{ 'tableau_number' | translate | upperfirst }}
        </label>
        <label nz-radio nzValue="guest">
          {{ 'guest_initials' | translate | upperfirst }}
        </label>
      </nz-radio-group>
    </div>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-eye by-mr-5"></i> {{ 'view' | translate | upperfirst }}
      </strong>
    </div>

    <form nzForm [formGroup]="viewOptionsForm">
      <div>
        <label nz-checkbox formControlName="events">
          <span>{{ 'events' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="notes">
          <span>{{ 'notes' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="quotes">
          <span>{{ 'quotes' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="stats">
          <span>{{ 'statistics' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="tags">
          <span>{{ 'reservation_tag' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="virtual_room">
          <span>{{ 'virtual_room' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="not_room">
          <span>{{ 'not_room' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="ota_source">
          <span>{{ 'source' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="bed_type">
          <span>{{ 'preparation' | translate | upperfirst }}</span>
        </label>
      </div>

      <div>
        <label nz-checkbox formControlName="housekeeper_notes">
          <span>{{ 'housekeeper_notes' | translate | upperfirst }}</span>
        </label>
      </div>
    </form>
  </div>

  <div class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-search by-mr-5"></i>
        {{ 'zoom' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <ng-template #prefixIconButton> </ng-template>

      <nz-input-group nzCompact class="by-flex">
        <button
          nz-button
          nzType="primary"
          class="by-tableau-toolbar__zoom-button ant-btn-icon-only"
          [disabled]="zoomOutDisabled"
          (click)="onZoomOut()"
        >
          <i class="fal fa-search-minus"></i>
        </button>

        <input
          [ngModel]="zoom + 'x'"
          type="text"
          nz-input
          readonly
          class="text-align--center"
        />

        <button
          nz-button
          nzType="primary"
          class="by-tableau-toolbar__zoom-button ant-btn-icon-only"
          [disabled]="zoomInDisabled"
          (click)="onZoomIn()"
        >
          <i class="fal fa-search-plus"></i>
        </button>
      </nz-input-group>

      <ng-template #suffixIconButton> </ng-template>
    </div>
  </div>

  <div *ngIf="viewMode === 'default'" class="by-tableau-toolbar__section">
    <div class="by-tableau-toolbar__section-label">
      <strong>
        <i class="far fa-arrows-v by-mr-5"></i>
        {{ 'expand_accommodations' | translate | upperfirst }}
      </strong>
    </div>

    <div>
      <nz-button-group class="by-w-100">
        <button
          nz-button
          nzType="primary"
          class="by-w-50"
          (click)="onExpandChange(true)"
        >
          <i class="fas fa-plus"></i>
          {{ 'expand' | translate | upperfirst }}
        </button>
        <button nz-button class="by-w-50" (click)="onExpandChange(false)">
          <i class="fas fa-minus"></i>
          {{ 'collapse' | translate | upperfirst }}
        </button>
      </nz-button-group>
    </div>
  </div>

  <button
    class="by-excel-export-button"
    nz-button
    nzType="primary"
    [nzLoading]="exportLoading"
    (click)="onExport()"
  >
    <i class="fal fa-file-spreadsheet by-mr-5"></i>
    {{ 'xlsx_export' | translate | upperfirst }}
  </button>
</div>
