import * as moment from 'moment';

import { ReservationGuestFactory } from '../../../models';

export function totalGuestRoomFactory(
  room,
  arrival_date: string,
  departure_date: string,
  guestType: 'adults' | 'children',
): ReservationGuestFactory {
  if (!room || !Object.keys(room).length) {
    return;
  }
  let numAdults = 0;
  let numChildren = 0;
  let totalChildren = [];
  const arrival = moment(arrival_date, 'YYYY-MM-DD');
  const departure = moment(departure_date, 'YYYY-MM-DD');
  const toDay = moment();
  const sortDays = (_room): any => {
    return {
      ..._room,
      days: _room.days
        .slice()
        .sort((a, b) =>
          moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD'), 'day'),
        ),
    };
  };
  const roomWithDaysSorted = sortDays(room);
  if (toDay.isBefore(arrival, 'day')) {
    numAdults = roomWithDaysSorted.days[0].adults_number;
    numChildren = roomWithDaysSorted.days[0].children_number;
    totalChildren = roomWithDaysSorted.days[0]?.total_children;
  } else if (toDay.isSameOrAfter(departure, 'day')) {
    numAdults =
      roomWithDaysSorted.days[roomWithDaysSorted.days.length - 1].adults_number;
    numChildren =
      roomWithDaysSorted.days[roomWithDaysSorted.days.length - 1]
        .children_number;
    totalChildren =
      roomWithDaysSorted.days[roomWithDaysSorted.days.length - 1]
        ?.total_children;
  } else {
    numAdults = roomWithDaysSorted.days.find(
      (day) => day.date === toDay.format('YYYY-MM-DD'),
    ).adults_number;
    numChildren = roomWithDaysSorted.days.find(
      (day) => day.date === toDay.format('YYYY-MM-DD'),
    ).children_number;
    totalChildren = roomWithDaysSorted.days.find(
      (day) => day.date === toDay.format('YYYY-MM-DD'),
    )?.total_children;
  }
  if (guestType === 'adults') {
    return { numAdults };
  } else {
    return { numChildren, totalChildren: totalChildren || [] };
  }
}
