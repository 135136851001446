<form [formGroup]="form" nzLayout="vertical">
  <nz-card class="card-with-shadow">
    <div nz-row [nzGutter]="20">
      <div nz-col [nzXs]="24" [nzSm]="24">
        <nz-form-item class="vertical-layout">
          <nz-form-label nzRequired>{{
            'date' | translate | capitalize
          }}</nz-form-label>
          <nz-form-control>
            <ng-container
              *ngIf="
                showPeriodConditionMapConstant &&
                  showPeriodConditionMapConstant[form.get('type').value];
                else singleDate
              "
            >
              <nz-range-picker
                byRangePicker
                byDisabledDateFixer
                style="width: 100%"
                [ngStyle]="{ width: '100%' }"
                nzFormat="dd/MM/yyyy"
                formControlName="date_range"
                [nzDisabledDate]="accommodationRangeCheck"
                [nzSeparator]="'~'"
              ></nz-range-picker>
            </ng-container>

            <ng-template #singleDate>
              <nz-date-picker
                byDisabledMobileKeyboard
                byDisabledDateFixer
                style="width: 100%"
                [nzAllowClear]="false"
                [ngStyle]="{ width: '100%' }"
                formControlName="single_date"
                nzFormat="dd/MM/yyyy"
                [nzDisabledDate]="accommodationRangeCheck"
              >
              </nz-date-picker>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <ng-container
      *ngIf="showQuantityAndRateConditionMapConstant[form.get('type').value]"
    >
      <div nz-row [nzGutter]="20">
        <div nz-col [nzXs]="12" [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzRequired>{{
              'price' | translate | capitalize
            }}</nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="price"
                byDecimalOnly
                [nzMin]="0"
                [nzFormatter]="currencyFormatter"
                [nzParser]="currencyParser"
                [nzStep]="1"
                class="by-w-100"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="12" [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzRequired>{{
              'quantity' | translate | capitalize
            }}</nz-form-label>
            <nz-form-control>
              <nz-input-number
                formControlName="quantity"
                [nzMin]="1"
                [nzStep]="1"
                class="by-w-100"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="showChildrenRangesConditionMapConstant[form.get('type').value]"
    >
      <div nz-row [nzGutter]="20">
        <div nz-col [nzXs]="12" [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzRequired
              >{{ 'adults_number' | translate | capitalize }} ({{
                'max' | translate
              }}
              {{ accommodationData?.max_adults }})</nz-form-label
            >
            <nz-form-control>
              <nz-input-number
                formControlName="adults_number"
                [nzStep]="1"
                [nzMin]="1"
                byInputNumberParser
                class="by-w-100"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="12"
          [nzSm]="12"
          *ngIf="childrenRange && childrenRange.length"
        >
          <nz-form-item>
            <nz-form-label nzRequired
              >{{ 'children_number' | translate | capitalize }} ({{
                'max' | translate
              }}
              {{ accommodationData?.max_children }})</nz-form-label
            >
            <nz-form-control>
              <nz-input-number
                formControlName="children_number"
                [nzMin]="0"
                [nzStep]="1"
                class="by-w-100"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <ng-container *ngIf="form.get('total_children')['controls']">
        <ng-container
          formArrayName="total_children"
          *ngFor="
            let formRange of form.get('total_children')['controls'];
            let childIndex = index
          "
        >
          <div [formGroupName]="childIndex">
            <div nz-row [nzGutter]="20" style="margin: 15px 0">
              <div nz-col [nzXs]="24" [nzSm]="24">
                {{
                  ('age' | translate | upperfirst) +
                    ' ' +
                    ('child' | translate) +
                    ' ' +
                    (childIndex + 1)
                }}
                <nz-select
                  class="by-w-100"
                  [nzDropdownMatchSelectWidth]="false"
                  nzPlaceHolder="{{
                    'placeholder.select'
                      | translate : { param: 'child' | translate }
                  }}"
                  formControlName="id"
                >
                  <ng-container>
                    <nz-option
                      *ngFor="let range of childrenRange"
                      [nzLabel]="range.age"
                      [nzValue]="range.id"
                    ></nz-option>
                  </ng-container>
                </nz-select>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div nz-row [nzGutter]="20">
      <div nz-col [nzXs]="24" [nzSm]="24">
        <nz-form-item class="vertical-layout">
          <nz-form-label nzRequired>{{
            'aliquot' | translate | capitalize
          }}</nz-form-label>
          <nz-form-control>
            <nz-select
              class="by-mb-2 by-w-100"
              formControlName="vat_quote_id"
              [nzPlaceHolder]="'aliquot' | translate | capitalize"
            >
              <ng-container *ngFor="let aliquot of vatQuotes">
                <nz-option
                  [nzLabel]="'[' + aliquot.value + '%] ' + aliquot.description"
                  [nzValue]="aliquot.id"
                ></nz-option>
              </ng-container>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="20">
      <div nz-col [nzXs]="12" [nzSm]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{
            'discount' | translate | capitalize
          }}</nz-form-label>
          <nz-form-control>
            <nz-input-number
              formControlName="discount_value"
              byDecimalOnly
              [nzStep]="1"
              class="by-w-100"
              [nzMin]="0"
              [nzMax]="100"
              [nzFormatter]="percentageFormatter"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{
            'total' | translate | capitalize
          }}</nz-form-label>
          <nz-form-control>
            <by-spin nzSize="small" [nzSpinning]="priceLoading">
              <nz-input-number
                formControlName="total"
                byDecimalOnly
                [nzStep]="1"
                class="by-w-100"
                [nzMin]="0"
                [nzFormatter]="currencyFormatter"
                [nzParser]="currencyParser"
              ></nz-input-number>
            </by-spin>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="20">
      <div nz-col [nzXs]="24" [nzSm]="24">
        <button
          class="by-w-100"
          nz-button
          type="button"
          nzType="primary"
          nzSize="small"
          (click)="addToCart.emit()"
        >
          <i class="far fa-plus"></i>
          {{ 'add_to_cart' | translate | upperfirst }}
        </button>
      </div>
    </div>
  </nz-card>
</form>
