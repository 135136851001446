import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mapValues } from 'lodash';

@Pipe({
  name: 'translateParams',
})
export class TranslateParamsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(str: string, params?: Record<string, string>): string {
    if (!str) {
      return str;
    }
    const translatedParams = mapValues(params || {}, (value) =>
      this.translate.instant(`${value}`),
    );
    return this.translate.instant(str, translatedParams);
  }
}
