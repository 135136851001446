import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime, map } from 'rxjs/operators';

import { DateFormatterService } from '../../core/services/date-formatter.service';
import { CustomersLookupMethods, CustomersLookupOption } from '../../models';
import { AppStore } from '../../models/types/app-store';
import { CompaniesService, CustomersService } from '../../services';
import { CustomersLookupComponent } from '../customers-lookup/customers-lookup.component';
import {
  hasLeadingWhitespaces,
  removeLeadingWhitespaces,
} from '@app/helpers/custom-regex';

@Component({
  selector: 'by-customers-autocomplete',
  templateUrl: './customers-autocomplete.component.html',
  styleUrls: ['./customers-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomersAutocompleteComponent,
      multi: true,
    },
  ],
})
export class CustomersAutocompleteComponent extends CustomersLookupComponent {
  @Input()
  autocompleteFor: 'name' | 'surname' | 'full_name' = 'full_name';

  @Input()
  searchBy: CustomersLookupMethods = 'value';

  @Input()
  byClass = null;

  @Input()
  enabled = true;

  @Input()
  width: number;

  constructor(
    protected store: Store<AppStore>,
    protected customersService: CustomersService,
    protected companiesService: CompaniesService,
    protected dateFormatter: DateFormatterService,
    protected formBuilder: UntypedFormBuilder,
    protected modalService: NzModalService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(
      store,
      customersService,
      companiesService,
      dateFormatter,
      formBuilder,
      modalService,
      translate,
      cdr,
    );
  }

  /**
   * @override
   */
  registerOnChange(fn: any) {
    this.subs.add(
      this.control.valueChanges
        .pipe(
          map((value) => {
            if (typeof value === 'string' && hasLeadingWhitespaces(value)) {
              const noWhitespacesValue = removeLeadingWhitespaces(value);

              this.control.patchValue(noWhitespacesValue, { emitEvent: false });

              return noWhitespacesValue;
            }

            return value;
          }),
          debounceTime(300),
        )
        .subscribe((value: string | CustomersLookupOption) => {
          // Typing on keybooard
          if (typeof value === 'string') {
            this.customerSelected.emit(null);
            this.onSearchCustomers(value, this.searchBy);
            fn(value);
          }

          // Selected options
          if (typeof value !== 'string') {
            this.emitSelectedCustomer(value.id);
            fn(value?.customer && value.customer[this.autocompleteFor]);
          }
        }),
    );
  }

  /**
   * @override
   */
  writeValue(value: string | number) {
    this.control.setValue(value, { emitEvent: false });
  }
}
