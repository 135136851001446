import { ChangeDetectorRef, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[byButtonBomileIcon]',
})
export class ButtonMobileIconDirective {
  private _isMobile = false;

  constructor(private cdr: ChangeDetectorRef) {}

  @HostBinding('class') classes = 'icon-only';

  @HostBinding('class.icon-only') get isHidden() {
    return !this._isMobile;
  }

  applyMobile() {
    this._isMobile = true;
    this.cdr.detectChanges();
  }

  removeMobile() {
    this._isMobile = false;
    this.cdr.detectChanges();
  }
}
