import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponse } from '../core/models/response.model';
import { IUserDetails } from '../features/commons/user-details/models/user-detail.model';

@Injectable({ providedIn: 'root' })
export class UserDetailsService {
  constructor(private _http: HttpClient) {}

  public setRoleUser(
    property_id: number,
    property_role_id: number,
    userId: number,
  ): Observable<IResponse> {
    return this._http.post(
      `property/${property_id}/acl/user/${userId}/set_role`,
      { property_role_id },
    ) as Observable<IResponse>;
  }

  public loadUserDetails(userId: number): Observable<IResponse> {
    return this._http.get(`user/${userId}`) as Observable<IResponse>;
  }

  public loadAclUserDetails(
    userId: number,
    propertyId: number,
  ): Observable<IResponse> {
    return this._http.get(
      `property/${propertyId}/acl/user/${userId}`,
    ) as Observable<IResponse>;
  }

  public create(newUserData: IUserDetails): Observable<IResponse> {
    return this._http.post(`user`, newUserData) as Observable<IResponse>;
  }

  public delete(userId: number): Observable<IResponse> {
    return this._http.delete(`user/${userId}`) as Observable<IResponse>;
  }

  public attachUserProperty(
    propertyId: number[],
    userId: number,
    property_role_id: number,
  ): Observable<IResponse> {
    return this._http.post(`property/${propertyId}/acl/user/${userId}/attach`, {
      property_id: propertyId,
      user_id: userId,
      property_role_id,
    }) as Observable<IResponse>;
  }

  public detachUserProperty(data: {
    property_id: number[];
    user_id: number;
  }): Observable<IResponse> {
    return this._http.post(
      `user/detach_property
    `,
      { ...data },
    ) as Observable<IResponse>;
  }

  public updateUserDetails(
    user_id: number,
    property_id: number,
    newUserData: IUserDetails,
  ): Observable<IResponse> {
    return this._http.put(
      `property/${property_id}/acl/user/${user_id}`,
      newUserData,
    ) as Observable<IResponse>;
  }
}
