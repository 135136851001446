import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flatten, pick, uniqBy, values } from 'lodash';

import { IFilter } from '../models/filter';

import { FiltersActions, FiltersActionTypes } from './filters.actions';

export interface IFiltersState {
  accommodations?: { [propertyId: number]: IFilter[] };
  rateplans?: IFilter[];
  full_rateplans?: { [accommodationId: number]: IFilter[] };
  channels?: IFilter[];
}

export const initialState: IFiltersState = {};

export function reducer(
  state = initialState,
  action: FiltersActions,
): IFiltersState {
  switch (action.type) {
    case FiltersActionTypes.LoadAccommodationsSuccess: {
      const { accommodations } = action.payload;
      return {
        ...state,
        accommodations,
      };
    }
    case FiltersActionTypes.LoadRateplansSuccess: {
      const { accommodationsIds, rateplans } = action.payload;
      return {
        ...state,
        rateplans: flattenFiltersData(rateplans, accommodationsIds),
        full_rateplans: rateplans,
      };
    }

    case FiltersActionTypes.LoadChannelsSuccess:
      return {
        ...state,
        channels: flattenFiltersData(
          action.payload.channels,
          Object.keys(action.payload.channels).map((propertyId) => +propertyId),
        ),
      };

    default:
      return state;
  }
}

function flattenFiltersData(
  object: {
    [id: number]: IFilter[];
  },
  ids: number[],
): IFilter[] {
  const onlyWidthSelectedIds = pick(object, ids);
  const objectValues = values(onlyWidthSelectedIds);
  return uniqBy(flatten(objectValues), 'id');
}

export const selectFiltersFeature = createFeatureSelector<IFiltersState>(
  'filters',
);

export const selectFilterAccommodations = createSelector(
  selectFiltersFeature,
  (state: IFiltersState) => {
    if (state.accommodations && Object.keys(state.accommodations)) {
      const properties_ids = Object.keys(state.accommodations);
      let newAccommodations: any;
      properties_ids.forEach((property_id) => {
        const accommodationsObject = state.accommodations[property_id].reduce(
          (acc, accommodations) => {
            acc = { ...acc, [accommodations.id]: accommodations.name };
            return acc;
          },
          {},
        );
        newAccommodations = {
          ...newAccommodations,
          [property_id]: accommodationsObject,
        };
      });
      return newAccommodations;
    }
    return {};
  },
);

export const selectFilterChannels = createSelector(
  selectFiltersFeature,
  (state: IFiltersState) => {
    if (state.channels && Object.keys(state.channels)) {
      return state.channels.reduce((acc, channels) => {
        acc = { ...acc, [channels.id]: channels.name };
        return acc;
      }, {});
    }
    return {};
  },
);

export const selectFilterRateplans = createSelector(
  selectFiltersFeature,
  (state: IFiltersState) => {
    if (state.rateplans && Object.keys(state.rateplans)) {
      return state.rateplans.reduce((acc, rateplans) => {
        acc = { ...acc, [rateplans.id]: rateplans.name };
        return acc;
      }, {});
    }
    return {};
  },
);
