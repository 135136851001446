import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { environment } from '../../../../../environments/environment';
import { TableauModalHeader } from '../../../../models';
import { ModalDragService } from '../../../../services/modal-drag.service';

@Component({
  selector: 'by-tableau-modal-header',
  templateUrl: './tableau-modal-header.component.html',
  styleUrls: ['./tableau-modal-header.component.scss'],
  providers: [ModalDragService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauModalHeaderComponent {
  @Input() data: TableauModalHeader;

  @Input() reservationsColors: Record<string, string>;

  @Input() loading = false;

  CDN_URL = environment.cdnUrl;
  STORAGE_URL = environment.storageUrl;

  constructor(
    private modalDragService: ModalDragService,
    @Optional() private modalRef: NzModalRef,
  ) {
    if (this.modalRef) {
      this.modalDragService.handle(this.modalRef);
    }
  }
}
