import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { fromPairs } from 'lodash';

import { EstimateResponse } from '../../../../models/responses/estimate.response';
import {
  Price,
  ReservationFormPriceComponent,
} from '../reservation-form-price/reservation-form-price.component';

type OnChange = (price: Price) => void;

@Component({
  selector: 'by-reservation-form-summary-price-editor',
  templateUrl: './reservation-form-summary-price-editor.component.html',
  styleUrls: ['./reservation-form-summary-price-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormSummaryPriceEditorComponent),
      multi: true,
    },
  ],
})
export class ReservationFormSummaryPriceEditorComponent
  extends ReservationFormPriceComponent
  implements OnChanges
{
  @Input()
  addonsTotalPrice: number;

  @Input()
  depositValue: number;

  showEditor = false;

  private originalPriceConfig: Price;

  private priceTotalMinValidator: ValidatorFn;

  constructor(protected formBuilder: UntypedFormBuilder) {
    super(formBuilder);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const { depositValue } = changes;

    if (depositValue) {
      this.setDepositValue();
    }
  }

  /**
   * @override
   */
  registerOnChange(onChange: OnChange) {
    this.onChange = onChange;

    this.onChange(this.lastValueBeforeRegisterOnChange);
  }

  /**
   * @override
   */
  writeValue(priceConfig: Price) {
    this.originalPriceConfig = priceConfig;
    super.writeValue(priceConfig);
  }

  /**
   * @override
   */
  onResetDiscount() {}

  /**
   * @override
   */
  registerTotalPriceValueChange() {}

  /**
   * @override
   */
  setProportionalDayPriceResponse() {
    super.setProportionalDayPriceResponse();

    this.originalPriceConfig = {
      ...this.getPriceConfig(),
      price_without_discount: null,
      discount_value: null,
      discount_type_id: 4,
    };

    this.onChange(this.originalPriceConfig);
  }

  /**
   * @override
   */
  setEstimateResponse(previousEstimate: EstimateResponse) {
    this.estimateResponse = {
      ...this.estimateResponse,
      total: this.estimateResponse.total + (this.addonsTotalPrice || 0),
    };

    super.setEstimateResponse(previousEstimate);
  }

  onConfirm() {
    this.showEditor = false;

    if (this.totalPriceControl.value === this.originalPriceConfig.price_total) {
      return;
    }

    this.proportionalDayPrice.emit({
      old_price: this.originalPriceConfig.price_total,
      new_price: this.totalPriceControl.value,
      days: fromPairs(this.dailyRatesControl.value),
      type: 'equal',
    });
  }

  onCancel() {
    this.showEditor = false;
    this.writeValue(this.originalPriceConfig);
  }

  private setDepositValue() {
    const control = this.form.get('price_total');

    if (this.priceTotalMinValidator) {
      control.removeValidators([this.priceTotalMinValidator]);
    }

    this.priceTotalMinValidator = Validators.min(this.depositValue || 0);

    control.addValidators([this.priceTotalMinValidator]);

    control.updateValueAndValidity({ emitEvent: false });
  }
}
