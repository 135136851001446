import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AddonEstimateModalModule } from '../../../components/addon-estimate-modal/addon-estimate-modal.module';
import { CompaniesLookupModule } from '../../../components/companies-lookup/companies-lookup.module';
import { CreditCardFormModule } from '../../../components/credit-card-form/credit-card-form.module';
import { CustomersAutocompleteModule } from '../../../components/customers-autocomplete/customers-autocomplete.module';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { UiModule } from '../../../ui/ui.module';

import { ReservationFormCustomerComponent } from './reservation-form-customer/reservation-form-customer.component';
import { ReservationFormDatesComponent } from './reservation-form-dates/reservation-form-dates.component';
import { ReservationFormDepositComponent } from './reservation-form-deposit/reservation-form-deposit.component';
import { ReservationFormEstimateWarningsComponent } from './reservation-form-estimate-warnings/reservation-form-estimate-warnings.component';
import { ReservationFormFirstStepComponent } from './reservation-form-first-step/reservation-form-first-step.component';
import { ReservationFormGuestsComponent } from './reservation-form-guests/reservation-form-guests.component';
import { ReservationFormNotificationComponent } from './reservation-form-notification/reservation-form-notification.component';
import { ReservationFormPaymentMethodComponent } from './reservation-form-payment-method/reservation-form-payment-method.component';
import { ReservationFormPriceWarningsComponent } from './reservation-form-price-warnings/reservation-form-price-warnings.component';
import { ReservationFormPriceComponent } from './reservation-form-price/reservation-form-price.component';
import { ReservationFormRateplanComponent } from './reservation-form-rateplan/reservation-form-rateplan.component';
import { ReservationFormSecondStepComponent } from './reservation-form-second-step/reservation-form-second-step.component';
import { ReservationFormStatusComponent } from './reservation-form-status/reservation-form-status.component';
import { ReservationFormSummaryDiscountInputComponent } from './reservation-form-summary-discount-input/reservation-form-summary-discount-input.component';
import { ReservationFormSummaryPriceEditorComponent } from './reservation-form-summary-price-editor/reservation-form-summary-price-editor.component';
import { ReservationFormSummaryComponent } from './reservation-form-summary/reservation-form-summary.component';
import { ReservationFormComponent } from './reservation-form/reservation-form.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AutomaticPaymentSummaryComponent } from '../../../components/automatic-payment-summary/automatic-payment-summary.component';
import { ReservationFormAutomaticPaymentComponent } from './reservation-form-automatic-payment/reservation-form-automatic-payment.component';

@NgModule({
  declarations: [
    ReservationFormComponent,
    ReservationFormDatesComponent,
    ReservationFormFirstStepComponent,
    ReservationFormSecondStepComponent,
    ReservationFormGuestsComponent,
    ReservationFormRateplanComponent,
    ReservationFormStatusComponent,
    ReservationFormPriceComponent,
    ReservationFormDepositComponent,
    ReservationFormPaymentMethodComponent,
    ReservationFormCustomerComponent,
    ReservationFormSummaryComponent,
    ReservationFormNotificationComponent,
    ReservationFormEstimateWarningsComponent,
    ReservationFormPriceWarningsComponent,
    ReservationFormSummaryDiscountInputComponent,
    ReservationFormSummaryPriceEditorComponent,
    ReservationFormAutomaticPaymentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    TranslateModule,
    NgPipesModule,
    CreditCardFormModule,
    CompaniesLookupModule,
    CustomersAutocompleteModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AddonEstimateModalModule,
    AutomaticPaymentSummaryComponent,
  ],
  exports: [
    ReservationFormComponent,
    ReservationFormDatesComponent,
    ReservationFormPriceWarningsComponent,
  ],
  providers: [provideNgxMask()],
})
export class ReservationFormModule {}
