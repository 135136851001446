import * as moment from 'moment';

/**
 * @param value Data nel formato 'YYYY-MM-DD' o oggetto Date
 * @param format Formato in cui formattare la date, di default è il formato del locale attuale
 * @returns Data formattata in: formato specificato o formato locale
 */
export function formatDate(value: string | Date, format = 'L'): string {
  if (!value) {
    return null;
  }

  const date = moment(value).toDate();

  if (!moment(date).isValid()) {
    return value as any;
  }

  return moment(date).format(format);
}
