import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import {
  DeleteReservationData,
  DeleteReservationModalData,
} from '../../models';
import { ReservationsStoreActions } from '../../root-store/reservations-store';
import { RootState } from '../../root-store/root-state';
import { ModalDragService } from '../../services/modal-drag.service';

@Component({
  selector: 'by-delete-reservation-modal',
  templateUrl: './delete-reservation-modal.component.html',
  styleUrls: ['./delete-reservation-modal.component.scss'],
  providers: [ModalDragService],
})
export class DeleteReservationModalComponent implements OnInit, OnDestroy {
  @Input()
  reservationData: DeleteReservationData;

  @Input()
  onSuccess: () => void;

  readonly nzModalData: DeleteReservationModalData = inject(NZ_MODAL_DATA);

  constructor(
    private store: Store<RootState>,
    private modal: NzModalRef,
    private modalDragService: ModalDragService,
  ) {
    this.modalDragService.handle(this.modal);
  }

  loading = false;

  ngOnInit() {
    const { reservationData, onSuccess } = this.nzModalData;
    this.reservationData = reservationData;
    this.onSuccess = onSuccess;
  }

  onDelete(restoreAvailability?: boolean) {
    this.loading = true;

    const action = this.getActionToDispatch(restoreAvailability);

    this.store.dispatch(action);
  }

  private getActionToDispatch(restoreAvailability: boolean): Action {
    const { reservationId, status, reservationAccommodationId, tabIndex } =
      this.reservationData;

    const onSuccess = () => {
      this.loading = false;
      this.closeModal('delete');
      this.onSuccess();
    };

    if (reservationAccommodationId) {
      return ReservationsStoreActions.deleteAccommodationRequest({
        reservationAccommodationIds: [reservationAccommodationId],
        reservationId,
        onSuccess,
      });
    }

    /**
     * @description se passiamo force_operation = 1 intendiamo cancellare definitivamente la prenotazione
     * se invece passiamo force_operation = 0 la prenotazione verrà messa in stato "Cancelled"
     *
     * se remove è a true rimuove la prenotazione dallo store.
     *
     */
    return ReservationsStoreActions.deleteRequest({
      reservationId,
      restoreAvailability,
      force_operation: +(status === 'Cancelled'),
      remove: tabIndex > 0,
      onSuccess,
    });
  }

  closeModal(result?: 'delete') {
    this.modal.destroy(result);
  }

  get hideDeleteAndRestoreAvailabilityButton() {
    const { availabilityOption, keepAvailability, status } =
      this.reservationData;

    return (status === 'Optionable' && !availabilityOption) || keepAvailability;
  }

  ngOnDestroy(): void {
    this.closeModal('delete');
  }
}
