import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler,
} from 'ngx-translate-messageformat-compiler';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LinkCustomDirective } from './core/directives/link-custom.directive';
import { BeddyDirective } from './core/easter-eggs/beddy.directive';
import { CachingInterceptor } from './interceptors/caching-interceptors';
import { RootStoreModule } from './root-store/root-store.module';
import { UiModule } from './ui/ui.module';

import itDateFns from 'date-fns/locale/it';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { JwtModule } from '@auth0/angular-jwt';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Sentry.init({
//   ...environment.sentry,
// });

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (
      error &&
      error.message &&
      (error.message.match('ExpressionChangedAfterItHasBeenCheckedError') ||
        error.message.match('_keydownEventSubscriptions') ||
        error.message.match('overlays[i]') ||
        error.message.match('Attempt to use a destroyed view: detectChanges'))
    ) {
      return null;
    }
    const eventId = Sentry.captureException(error.originalError || error);
    Sentry.showReportDialog({ eventId });
    console.error(error);
  }
}

@NgModule({
  declarations: [AppComponent, BeddyDirective, LinkCustomDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RootStoreModule,
    CoreModule,
    UiModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),

    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  // providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        biDiSupport: false,
      },
    },
    { provide: NZ_DATE_LOCALE, useValue: itDateFns },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
