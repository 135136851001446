import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { fromPairs, intersection, sortBy } from 'lodash';
import { Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { isEmptyValue } from '../../../../helpers';
import { BillFormHelpers } from '../../../../helpers/bill-form-helpers';
import { FormGetter, Nullable, ProportionalDayPrice } from '../../../../models';
import { EstimateResponse } from '../../../../models/responses/estimate.response';

export interface Price {
  price_total: number;
  discount_type_id: number;
  discount_value: number;
  daily_rates: Array<{ date: string; price: number }>;
  price_without_discount: number;
}

type OnChange = (price: Price) => void;

@Component({
  selector: 'by-reservation-form-price',
  templateUrl: './reservation-form-price.component.html',
  styleUrls: ['./reservation-form-price.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormPriceComponent),
      multi: true,
    },
  ],
})
export class ReservationFormPriceComponent
  implements OnInit, OnChanges, OnDestroy, FormGetter
{
  @Input()
  estimateResponse: EstimateResponse;

  @Input()
  proportionalDayPriceResponse: ProportionalDayPrice;

  @Input()
  currency: ICurrency;

  @Input()
  priceLoading: boolean;

  @Input() isMobile = false;

  @Output()
  proportionalDayPrice = new EventEmitter<ProportionalDayPrice>();

  @Output()
  discountChanged = new EventEmitter();

  form = this.formBuilder.group({
    price_total: [null, [Validators.required]],
    discount_type_id: [4],
    discount_value: [null],
    daily_rates: [[]],
    price_without_discount: [null],
  });

  showDiscount = false;

  lastValueBeforeRegisterOnChange: Nullable<Price> = null;

  onChange: OnChange = (value: Price) => {
    this.lastValueBeforeRegisterOnChange = value;
  };

  onTouched: () => void;

  protected subs = new SubSink();

  protected priceSubscription: Subscription;

  constructor(protected formBuilder: UntypedFormBuilder) {}

  currencySymbolFormatter = (value: string) =>
    value && `${this.currency?.symbol || ''} ${value}`;

  currencyParser = (value: string) =>
    value.replace(`${this.currency?.symbol} `, '') || '0';

  ngOnInit() {
    this.subs.add(
      this.discontValueControl.valueChanges.subscribe((discountValue) => {
        const { price_total, discount_type_id } = this.form.value;
        let { price_without_discount } = this.form.value;

        if (!price_without_discount) {
          price_without_discount = price_total;
        }

        const newPriceTotal = BillFormHelpers.getTotalPrice(
          price_without_discount,
          1,
          discount_type_id,
          discountValue,
        );

        this.silentPatchValue({
          price_total: newPriceTotal,
          price_without_discount,
        });

        this.discountChanged.emit();
      }),
    );

    this.subs.add(
      this.discontTypeIdControl.valueChanges.subscribe(() => {
        const { price_total, price_without_discount } = this.form.value;

        this.silentPatchValue({
          price_total: price_without_discount || price_total,
          discount_value: null,
          price_without_discount: null,
        });
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { estimateResponse, proportionalDayPriceResponse } = changes;

    if (estimateResponse && this.estimateResponse) {
      this.setEstimateResponse(estimateResponse.previousValue);
    }

    if (proportionalDayPriceResponse && this.proportionalDayPriceResponse) {
      this.setProportionalDayPriceResponse();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.priceSubscription?.unsubscribe();
  }

  writeValue(priceConfig: Price) {
    this.form.patchValue(
      {
        ...priceConfig,
        daily_rates: priceConfig?.daily_rates?.map(({ date, price }) => [
          date,
          price,
        ]),
      },
      { emitEvent: false },
    );
  }

  registerOnChange(onChange: OnChange) {
    this.onChange = onChange;

    this.onChange(this.lastValueBeforeRegisterOnChange);

    this.subs.add(
      this.form.valueChanges.subscribe(() => {
        onChange(this.getPriceConfig());
      }),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  getForms() {
    return [this.form];
  }

  onResetDiscount(extras?: Partial<Price>, discountChanged: boolean = false) {
    this.silentPatchValue({
      discount_value: null,
      discount_type_id: 4,
      price_without_discount: null,
      ...extras,
    });

    this.showDiscount = false;

    if (discountChanged) {
      this.discountChanged.emit();
    }
  }

  get totalPriceControl() {
    return this.form.get('price_total');
  }

  get dailyRatesControl() {
    return this.form.get('daily_rates');
  }

  get discontValueControl() {
    return this.form.get('discount_value');
  }

  get discontTypeIdControl() {
    return this.form.get('discount_type_id');
  }

  get priceWithoutDiscount() {
    const { price_without_discount, price_total } = this.form.value;
    return price_without_discount || price_total;
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }

  protected setEstimateResponse(previousEstimate: EstimateResponse) {
    const { total, days } = this.estimateResponse;

    if (this.skipEstimateSetting(previousEstimate, this.estimateResponse)) {
      return;
    }

    this.silentPatchValue({
      price_total: total,
      daily_rates: this.getDailyRatesPairs(days),
    });

    this.onResetDiscount();

    this.registerTotalPriceValueChange();
  }

  protected setProportionalDayPriceResponse() {
    this.form.patchValue({
      daily_rates: this.getDailyRatesPairs(
        this.proportionalDayPriceResponse.days,
      ),
    });
  }

  protected getPriceConfig(): Price {
    const { daily_rates, total_children, ...formValue } = this.form.value;
    return {
      ...formValue,
      daily_rates: daily_rates?.map(([date, price]) => ({ date, price })),
    };
  }

  protected getDailyRatesPairs(
    dailyRates: Record<string, number>,
  ): Array<[string, number]> {
    return sortBy(Object.entries(dailyRates || {}), ([date]) => date);
  }

  protected registerTotalPriceValueChange() {
    this.priceSubscription?.unsubscribe();
    this.priceSubscription = this.form
      .get('price_total')
      .valueChanges.pipe(startWith(this.estimateResponse.total), pairwise())
      .subscribe(([old_price, new_price]) => {
        this.onResetDiscount();

        if (isEmptyValue(old_price) || isEmptyValue(new_price)) {
          return;
        }

        this.proportionalDayPrice.emit({
          old_price,
          new_price,
          days: fromPairs(this.dailyRatesControl.value),
          type: 'equal',
        });
      });
  }

  protected silentPatchValue(formValue = this.form.value) {
    this.form.patchValue(formValue, { emitEvent: false });

    this.onChange(this.getPriceConfig());
  }

  protected skipEstimateSetting(
    previousEstimate: EstimateResponse,
    currentEstimate: EstimateResponse,
  ) {
    if (!previousEstimate) {
      return false;
    }

    const previousDays = Object.keys(previousEstimate.days);
    const currentDays = Object.keys(currentEstimate.days);
    const daysIntersection = intersection(previousDays, currentDays);

    // Dates have been changed
    if (
      daysIntersection.length !== previousDays.length ||
      daysIntersection.length !== currentDays.length
    ) {
      return false;
    }

    return previousEstimate.total === currentEstimate.total;
  }
}
