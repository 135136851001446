import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { TableauHoveredCell, TableauReservation } from '../../../../models';
import { TableauRowIndexService } from '../../../../services';

@Injectable()
export class TableauHoverService {
  private hoveredCell$ = new Subject<TableauHoveredCell>();

  private hoveredReservation$ = new Subject<TableauReservation>();

  constructor(private tableauRowIndexService: TableauRowIndexService) {}

  setHoveredCell(cell: { rowId: string; date: Date }) {
    if (!cell) {
      this.hoveredCell$.next(null);
      return;
    }

    const { date, rowId } = cell;

    const row = this.tableauRowIndexService.decode(rowId);

    this.hoveredCell$.next({ date, row });
  }

  getHoveredCell() {
    return this.hoveredCell$.asObservable();
  }

  setHoveredReservation(reservation: TableauReservation) {
    this.hoveredReservation$.next(reservation);
  }

  getHoveredReservation() {
    return this.hoveredReservation$.asObservable();
  }
}
