import { Rateplan } from '@app/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_LOOKUP_REQUEST = '[Rateplan] Load Lookup Request',
  LOAD_LOOKUP_FAILURE = '[Rateplan] Load Lookup Failure',
  LOAD_LOOKUP_SUCCESS = '[Rateplan] Load Lookup Success',

  LOAD_REQUEST = '[Rateplan] Load Request',
  LOAD_FAILURE = '[Rateplan] Load Failure',
  LOAD_SUCCESS = '[Rateplan] Load Success',

  LOAD_RATEPLANS_ACCOMMODATION_REQUEST = '[Rateplan] Load Rateplans Accommodation Request',
  LOAD_RATEPLANS_ACCOMMODATION_FAILURE = '[Rateplan] Load Rateplans Accommodation Failure',
  LOAD_RATEPLANS_ACCOMMODATION_SUCCESS = '[Rateplan] Load Rateplans Accommodation Success',

  LOAD_ALL_ACCOMMODATIONS_RATEPLANS_REQUEST = '[Rateplan] Load All Accommodations Rateplans Request',
  LOAD_ALL_ACCOMMODATIONS_RATEPLANS_SUCCESS = '[Rateplan] Load All Accommodations Rateplans Success',
  LOAD_ALL_ACCOMMODATIONS_RATEPLANS_FAILURE = '[Rateplan] Load All Accommodations Rateplans Failure',

  DELETE_REQUEST = '[Rateplan] Delete Request',
  DELETE_FAILURE = '[Rateplan] Delete Failure',
  DELETE_SUCCESS = '[Rateplan] Delete Success',

  RESET_STATE = '[Rateplan] Reset State',
}

export class LoadLookupRequestAction implements Action {
  readonly type = ActionTypes.LOAD_LOOKUP_REQUEST;
  constructor(public payload: { propertyIds: number[] }) {}
}

export class LoadLookupFailureAction implements Action {
  readonly type = ActionTypes.LOAD_LOOKUP_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadLookupSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_LOOKUP_SUCCESS;
  constructor(public payload: { items: Rateplan[] }) {}
}
export class LoadRateplansAccommodationRequestAction implements Action {
  readonly type = ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_REQUEST;
  constructor(
    public payload: {
      accommodation_id: number;
      reservation_accommodation_room_id: number;
    },
  ) {}
}

export class LoadRateplansAccommodationFailureAction implements Action {
  readonly type = ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadRateplansAccommodationSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_SUCCESS;
  constructor(public payload: { items: Rateplan[] }) {}
}

export class LoadAllAccommodationsRateplansRequest implements Action {
  readonly type = ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_REQUEST;
  constructor(public payload: { properties: number[] }) {}
}

export class LoadAllAccommodationsRateplansSuccess implements Action {
  readonly type = ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_SUCCESS;
  constructor(public payload: { rateplans: any }) {}
}

export class LoadAllAccommodationsRateplansFailure implements Action {
  readonly type = ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;

  constructor(public payload: { properties: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public payload: { items: Rateplan[] }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;

  constructor(public payload: { propertyId: number; rateplanId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;

  constructor(public payload: { rateplanId: number }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadLookupRequestAction
  | LoadLookupFailureAction
  | LoadLookupSuccessAction
  | LoadRateplansAccommodationRequestAction
  | LoadRateplansAccommodationFailureAction
  | LoadRateplansAccommodationSuccessAction
  | LoadAllAccommodationsRateplansRequest
  | LoadAllAccommodationsRateplansSuccess
  | LoadAllAccommodationsRateplansFailure
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | ResetSuccessAction;
