import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Property, SavePropertyRequest } from '../../models';
import { PropertySetAttributes } from '../../models/objects/property-set-attributes';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Properties] Load Request',
  props<{
    page: number;
    searchParams: SearchParams;
  }>(),
);

export const loadSuccess = createAction(
  '[Properties] Load Success',
  props<{ items: Property[]; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Properties] Load Failure',
  props<{ error: any }>(),
);

export const loadLookupRequest = createAction(
  '[Properties] Load Lookup Request',
  props<{ value: string; channelId?: number; active?: number }>(),
);

export const loadLookupSuccess = createAction(
  '[Properties] Load Lookup Success',
  props<{ items: Property[] }>(),
);

export const loadLookupFailure = createAction(
  '[Properties] Load Lookup Failure',
  props<{ error: any }>(),
);

export const loadGroupPropertiesRequest = createAction(
  '[Properties] Load Group Properties Request',
  props<{ property_id: number }>(),
);

export const loadGroupPropertiesSuccess = createAction(
  '[Properties] Load Group Properties Success',
  props<{ items: Property[] }>(),
);

export const loadGroupPropertiesFailure = createAction(
  '[Properties] Load Group Properties Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Properties] Load Details Request',
  props<{ itemId: number }>(),
);

export const loadDetailsSuccess = createAction(
  '[Properties] Load Details Success',
  props<{ item: Property }>(),
);

export const loadDetailsFailure = createAction(
  '[Properties] Load Details Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Properties] Disable Request',
  props<{ itemId: number }>(),
);

export const deleteSuccess = createAction(
  '[Properties] Disable Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Properties] Disable Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Properties] Create Request',
  props<{ request: SavePropertyRequest }>(),
);

export const createSuccess = createAction(
  '[Properties] Create Success',
  props<{ item: Property }>(),
);

export const createFailure = createAction(
  '[Properties] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Properties] Update Request',
  props<{ request: SavePropertyRequest }>(),
);

export const updateSuccess = createAction(
  '[Properties] Update Success',
  props<{ item: Property }>(),
);

export const updateFailure = createAction(
  '[Properties] Update Failure',
  props<{ error: any }>(),
);

export const updateAttributesRequest = createAction(
  '[Properties] Update Attributes Request',
  props<{ request: PropertySetAttributes }>(),
);

export const updateAttributesSuccess = createAction(
  '[Properties] Update Attributes Success',
  props<{ request: PropertySetAttributes }>(),
);

export const updateAttributesFailure = createAction(
  '[Properties] Update Attributes Failure',
  props<{ error: any }>(),
);

export const enableRequest = createAction(
  '[Properties] Enable Request',
  props<{ itemId: number }>(),
);

export const enableSuccess = createAction(
  '[Properties] Enable Success',
  props<{ itemId: number }>(),
);

export const enableFailure = createAction(
  '[Properties] Enable Failure',
  props<{ error: any }>(),
);

export const cloneRequest = createAction(
  '[Properties] Clone Request',
  props<{
    optionsToClone: string[];
    sourcePropertyId: number;
    destinationPropertyId: number;
  }>(),
);

export const cloneSuccess = createAction('[Properties] Clone Success');

export const cloneFailure = createAction(
  '[Properties] Clone Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties] Reset State');
