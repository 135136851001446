import { UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export const LimitDaysValidator = (limit: number): ValidatorFn => (
  formArray: UntypedFormArray,
): ValidationErrors | null => {
  if (!formArray.value || !formArray.value.length) {
    return null;
  }

  const exludedDays = formArray.controls.reduce((acc, control) => {
    if (!control.value) {
      return null;
    }
    const startDate = moment(control.value[0]);
    const endDate = moment(control.value[1]);
    acc = acc + endDate.diff(startDate, 'days') + 1;
    return acc;
  }, 0);

  if (exludedDays > limit) {
    return {
      limit: true,
    };
  } else {
    return null;
  }
};
