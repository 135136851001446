import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { UiModule } from '../../ui/ui.module';

import { CustomersAutocompleteComponent } from './customers-autocomplete.component';

@NgModule({
  declarations: [CustomersAutocompleteComponent],
  imports: [CommonModule, UiModule, TranslateModule, ReactiveFormsModule],
  exports: [CustomersAutocompleteComponent],
})
export class CustomersAutocompleteModule {}
