export const DEFAULT_ACCOMMODATIONS_LEGEND_TYPE_COLORS = [
  {
    title: 'parent_virtual_room',
    color: 'transparent',
    icon: 'by-icon by-master-room-icon color--orange',
    iconColor: '#f28c3c',
  },
  {
    title: 'parent_virtual_sum',
    color: 'transparent',
    iconColor: '#02be0d',
    icon: 'by-icon by-sum-room-icon color--green',
  },
  {
    title: 'room_children',
    color: 'transparent',
    iconColor: '#047bf8',
    icon: 'by-icon by-child-room-icon color--azure',
  },
  {
    title: 'room_children_sum',
    color: 'transparent',
    iconColor: '#047bf8',
    icon: 'by-icon by-sum-room-child-icon color--azure',
  },
  {
    title: 'virtual_room',
    color: 'transparent',
    iconColor: '#fd4848',
    icon: 'by-icon by-virtual-room-icon color--red',
  },
  {
    title: 'not_room',
    color: 'transparent',
    iconColor: '#0222be',
    icon: 'by-icon by-no-room-icon color--ligth-blue',
  },
];
