<ng-container [ngSwitch]="item?.component">
  <by-tableau-reservation-box
    *ngSwitchCase="Components.Reservation"
    [data]="item.data"
    [userCanWrite]="userCanWrite"
    [reservationsColors]="reservationsColors"
    [channelsColors]="channelsColors"
    [viewOptions]="viewOptions"
    [splitMode]="splitMode"
    [zoom]="zoom"
    [period]="period"
    [row]="row"
    [draggingItem]="draggingItem"
    [hoveredReservation]="hoveredReservation"
    [boxWrapperComponent]="boxWrapperComponent"
    [isMobile]="isMobile"
  ></by-tableau-reservation-box>

  <by-tableau-quote-box
    *ngSwitchCase="Components.Quote"
    [data]="item.data"
    [row]="row"
    [userCanWrite]="userCanWrite"
    [draggingItem]="draggingItem"
    [reservationsColors]="reservationsColors"
    [boxWrapperComponent]="boxWrapperComponent"
    [isMobile]="isMobile"
    [viewOptions]="viewOptions"
  ></by-tableau-quote-box>

  <by-tableau-note-box
    *ngSwitchCase="Components.Note"
    [data]="item.data"
    [draggingItem]="draggingItem"
    [row]="row"
    [userCanWrite]="userCanWrite"
    [boxWrapperComponent]="boxWrapperComponent"
  ></by-tableau-note-box>
</ng-container>
