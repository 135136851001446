import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';

import { DateFormatterService } from '../../../../../core/services/date-formatter.service';
import { isWeekend } from '../../../../../helpers';
import {
  TableauNote,
  TableauQuote,
  TableauReservation,
  TableauRoom,
  TableauRow,
  TableauRowItem,
} from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauRowIndexService } from '../../../../../services';
import { TableauBoxComponents } from '../../tableau-components-map';
import { TableauExportRowBuilder } from '../tableau-export-builder';
import {
  FIRST_DAYS_COLUMN,
  getDefaultCellStyle,
  ROW_HEIGHT,
} from '../tableau-export.config';

@Injectable()
export class RoomRowBuilderService implements TableauExportRowBuilder {
  boxPainters: { [component: string]: Function } = {
    [TableauBoxComponents.Reservation]: this.paintReservation,
    [TableauBoxComponents.Quote]: this.paintQuote,
    [TableauBoxComponents.Note]: this.paintNote,
  };

  constructor(
    private dateFormatter: DateFormatterService,
    private tableauRowIndexService: TableauRowIndexService,
  ) {}

  build(
    tableauRow: TableauRow<TableauRoom>,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const {
      tableau_2: { days },
    } = state;

    const { items, data, firstColumnRowspan, spanIndex } = tableauRow;

    const daysCells = new Map<number, Date>();

    const boxesCells = new Map<number, TableauRowItem>();

    const firstColumnText = data.label || 'OB';

    // Building row
    const row = worksheet.addRow([
      firstColumnText,
      ...days.map((day, index) => {
        daysCells.set(FIRST_DAYS_COLUMN + index, day);

        const boxRowItem: TableauRowItem =
          items[this.dateFormatter.toServerFormat(day)];

        if (boxRowItem) {
          boxesCells.set(FIRST_DAYS_COLUMN + index, boxRowItem);
        }

        return null;
      }),
    ]);

    // Cell formatting
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const date = daysCells.get(colNumber);
      const box = boxesCells.get(colNumber);

      let cellColor = 'ffffff';

      if (date && isWeekend(date)) {
        cellColor = 'fdecf6';
      }

      if (data.closures?.[this.dateFormatter.toServerFormat(date)]) {
        cellColor = 'fab588';
      }

      cell.style = getDefaultCellStyle({
        alignment: {
          vertical: 'middle',
          horizontal: colNumber === 1 ? 'right' : 'center',
          indent: colNumber === 1 ? 1 : null,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor },
        },
      });

      if (box) {
        const painter = this.getPainter(box);
        painter.call(this, box, cell, row, state);
      }
    });

    // Merging first column
    if (!firstColumnRowspan) {
      const { spanLength } = this.tableauRowIndexService.decode(tableauRow.id);
      if (spanLength === spanIndex + 1) {
        worksheet.mergeCells(row.number - spanIndex, 1, row.number, 1);
      }
    }

    row.height = ROW_HEIGHT;
  }

  private getPainter(box: TableauRowItem) {
    return this.boxPainters[box.component];
  }

  private paintReservation(
    box: TableauRowItem<TableauReservation>,
    cell: ExcelJS.Cell,
    row: ExcelJS.Row,
    state: RootState,
  ) {
    const { data } = box;
    const {
      tableau_2: { reservationsColors, viewOptions },
      activeChannels: { entities: channels },
    } = state;

    if (data.child_reservation) {
      cell.fill = {
        type: 'pattern',
        pattern: 'lightDown',
        bgColor: { argb: 'ffffff' },
        fgColor: { argb: 'dddddd' },
      };
    } else {
      cell.value = data.label;
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: (viewOptions?.reservations_palette === 'status'
            ? reservationsColors[data?.background_key]
            : channels[data?.channel_id].color
          ).replace('#', ''),
        },
      };
    }

    this.applyCommonBoxConfigurations(box, cell, row);

    if (data.tag) {
      cell.border = {
        ...cell.border,
        left: {
          style: 'thick',
          color: { argb: data.tag.color.replace('#', '') },
        },
      };
    }
  }

  private paintQuote(
    box: TableauRowItem<TableauQuote>,
    cell: ExcelJS.Cell,
    row: ExcelJS.Row,
    state: RootState,
  ) {
    const { data } = box;

    const {
      tableau_2: { reservationsColors },
    } = state;

    cell.value = data.label;

    cell.fill = {
      type: 'pattern',
      pattern: 'lightDown',
      bgColor: { argb: 'ffffff' },
      fgColor: { argb: reservationsColors.quotes.replace('#', '') },
    };

    this.applyCommonBoxConfigurations(box, cell, row);
  }

  private paintNote(
    box: TableauRowItem<TableauNote>,
    cell: ExcelJS.Cell,
    row: ExcelJS.Row,
  ) {
    const { data } = box;

    cell.value = data.title;

    cell.fill = {
      type: 'pattern',
      pattern: 'lightDown',
      bgColor: { argb: 'ffffff' },
      fgColor: { argb: 'ffa234' },
    };

    this.applyCommonBoxConfigurations(box, cell, row);
  }

  private applyCommonBoxConfigurations(
    box: TableauRowItem,
    cell: ExcelJS.Cell,
    row: ExcelJS.Row,
  ) {
    row.worksheet.mergeCellsWithoutStyle(
      row.number,
      cell.fullAddress.col,
      row.number,
      cell.fullAddress.col + box.data.length - 1,
    );

    cell.alignment.wrapText = true;
  }
}
