import { Injectable } from '@angular/core';
import { RateplansService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';

import * as featureActions from './actions';

@Injectable()
export class RateplansStoreEffects {
  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.properties).pipe(
          map(({ data }: any) => {
            return new featureActions.LoadSuccessAction({
              items: data,
            });
          }),
          catchError((error) =>
            of(new featureActions.LoadFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  loadRateplansAccommodationEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRateplansAccommodationRequestAction>(
        featureActions.ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_REQUEST,
      ),
      switchMap(
        (action: featureActions.LoadRateplansAccommodationRequestAction) =>
          this.dataService.loadRateplansAccommodation(action.payload).pipe(
            map(({ data }: any) => {
              return new featureActions.LoadRateplansAccommodationSuccessAction(
                {
                  items: data,
                },
              );
            }),
            catchError((error) =>
              of(
                new featureActions.LoadRateplansAccommodationFailureAction({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );
  loadLookupRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadLookupRequestAction>(
        featureActions.ActionTypes.LOAD_LOOKUP_REQUEST,
      ),
      switchMap((action: featureActions.LoadLookupRequestAction) =>
        this.dataService.load(action.payload.propertyIds).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadLookupSuccessAction({
                items: data,
              }),
          ),
          catchError((error) =>
            of(new featureActions.LoadLookupFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({ payload: { rateplanId } }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(rateplanId).pipe(
            map(
              () =>
                new featureActions.DeleteSuccessAction({
                  rateplanId,
                }),
            ),
            catchError((error) =>
              of(new featureActions.DeleteFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  loadAllAccommodationsRateplans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        featureActions.ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_REQUEST,
      ),
      flatMap(
        ({
          payload: { properties },
        }: featureActions.LoadAllAccommodationsRateplansRequest) => {
          return this.dataService
            .loadAllAccommodationsRateplans(properties)
            .pipe(
              map(
                ({ data: rateplans }: IResponseSuccess) =>
                  new featureActions.LoadAllAccommodationsRateplansSuccess({
                    rateplans,
                  }),
              ),
              catchError((error: any) => {
                return of(
                  new featureActions.LoadAllAccommodationsRateplansFailure({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  constructor(
    private dataService: RateplansService,
    private actions$: Actions,
  ) {}
}
