<nz-form-item>
  <nz-form-label [nzSpan]="18">
    {{ 'activate_automatic_payment' | translate | upperfirst }}
  </nz-form-label>
  <nz-form-control class="by-full-width-control">
    <nz-select
      [nzDropdownMatchSelectWidth]="false"
      [nzSize]="inputSize"
      [formControl]="paymentPolicyControl"
      nzAllowClear
      [nzPlaceHolder]="'no' | translate | upperfirst"
    >
      @for (
        automaticPaymentConfiguration of automaticPaymentSummary;
        track automaticPaymentConfiguration.payment_policy_id
      ) {
        <nz-option
          [nzValue]="automaticPaymentConfiguration.payment_policy_id"
          [nzLabel]="automaticPaymentConfiguration.payment_policy_name"
          [nzCustomContent]="true"
        >
          {{ automaticPaymentConfiguration.payment_policy_name }}
          @if (!automaticPaymentConfiguration.suitable) {
            <i
              nz-tooltip
              [nzTooltipTitle]="
                'this_configuration_doesnt_match' | translate | upperfirst
              "
              class="fas fa-exclamation-triangle color--orange"
            ></i>
          }
        </nz-option>
      }
    </nz-select>
  </nz-form-control>
</nz-form-item>

@if (paymentPolicyControl.value) {
  <div class="by-mt-10 by-mb-10">
    <by-automatic-payment-summary
      [automaticPaymentSummary]="
        automaticPaymentSummary
          | firstOrDefault: ['payment_policy_id', paymentPolicyControl.value]
      "
      [currency]="currency.symbol"
    />
  </div>
}
