import { Component, Input } from '@angular/core';

import { EstimateResponse } from '../../../../models/responses/estimate.response';

@Component({
  selector: 'by-reservation-form-estimate-warnings',
  templateUrl: './reservation-form-estimate-warnings.component.html',
  styleUrls: ['./reservation-form-estimate-warnings.component.scss'],
})
export class ReservationFormEstimateWarningsComponent {
  @Input()
  estimateResponse: EstimateResponse;
}
