import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';

import { AttachmentViewerModule } from '../../../components/attachment-viewer/attachment-viewer.module';
import { UiModule } from '../../../ui/ui.module';

import { MaintenanceReportsContainerComponent } from './maintenance-reports-container/maintenance-reports-container.component';
import { MaintenanceReportsFormComponent } from './maintenance-reports-form/maintenance-reports-form.component';
import { MaintenanceReportsListComponent } from './maintenance-reports-list/maintenance-reports-list.component';

@NgModule({
  declarations: [
    MaintenanceReportsContainerComponent,
    MaintenanceReportsFormComponent,
    MaintenanceReportsListComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    ReactiveFormsModule,
    AttachmentViewerModule,
  ],
  exports: [MaintenanceReportsContainerComponent],
})
export class MaintenanceReportsModule {}
