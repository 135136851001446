import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MaintenanceReport, MaintenanceReportStatus } from '../../../../models';

@Component({
  selector: 'by-maintenance-reports-form',
  templateUrl: './maintenance-reports-form.component.html',
  styleUrls: ['./maintenance-reports-form.component.scss'],
})
export class MaintenanceReportsFormComponent implements OnChanges {
  @Input()
  report: MaintenanceReport;

  states: Array<MaintenanceReportStatus> = ['unsolved', 'solved'];

  form = this.formBuilder.group({
    id: [null],
    status: ['unsolved'],
    description: [null, [Validators.required]],
    title: [null, [Validators.required, Validators.maxLength(255)]],
  });

  medias: {
    upload: File;
    delete: number;
  };

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { report } = changes;

    if (report) {
      this.patchForm();
    }
  }

  onUploadMedia(file: File) {
    this.medias = { ...this.medias, upload: file };
  }

  onDeleteMedia(mediaId: number) {
    this.medias = { ...this.medias, delete: mediaId };
  }

  private patchForm() {
    this.form.reset({ status: 'unsolved' });

    if (this.report) {
      this.form.patchValue(this.report);
    }

    if (this.report?.media) {
      this.form.patchValue({
        medias: [this.report.media],
      });
    }
  }
}
