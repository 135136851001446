import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { ReservationFormAccommodation } from '../../../../models';
import { EstimateResponse } from '../../../../models/responses/estimate.response';

interface Guests {
  adults_number: number;
  children_number: number;
}

@Component({
  selector: 'by-reservation-form-price-warnings',
  templateUrl: './reservation-form-price-warnings.component.html',
  styleUrls: ['./reservation-form-price-warnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationFormPriceWarningsComponent implements OnChanges {
  @Input()
  accommodation: ReservationFormAccommodation;

  @Input()
  guests: Guests;

  @Input()
  estimateResponse: EstimateResponse;

  warnings: Array<{ message: string; param: any }>;

  ngOnChanges() {
    this.setPriceWarnings();
  }

  private setPriceWarnings() {
    if (!this.accommodation || !this.guests) {
      return;
    }

    this.warnings = [];

    const { max_children, max_adults, total_beds } = this.accommodation;
    const { adults_number, children_number } = this.guests;

    if (+adults_number > max_adults) {
      this.warnings = [
        ...this.warnings,
        {
          message: 'max_adults_limit_warning',
          param: max_adults,
        },
      ];
    }

    if (+children_number > max_children) {
      this.warnings = [
        ...this.warnings,
        {
          message: 'max_children_limit_warning',
          param: max_children,
        },
      ];
    }

    if (
      +adults_number + +children_number > total_beds &&
      this.warnings.length < 2
    ) {
      this.warnings = [
        ...this.warnings,
        {
          message: 'toal_beds_limit_warning',
          param: total_beds,
        },
      ];
    }
  }
}
