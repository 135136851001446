<div style="display: flex; flex-direction: column">
  <div
    class="headerRow"
    *ngIf="isShowHeaderRow"
    style="
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: #293144;
      height: 56px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    "
  >
    <div class="logo-menu">
      <div class="logo-beddy-menu">
        <img src="assets/images/general/logo/logo-beddy-1-white.png" />
      </div>
    </div>
    <div class="actions-container">
      <i
        class="fas fa-th-large menu-button"
        (click)="bottomSheet.open = true"
      ></i>
      <i
        class="fal fa-shopping-cart menu-button"
        (click)="openAddonsModal.emit()"
      ></i>

      <span class="pointer" (click)="onOpenNotificationsModal()">
        <nz-badge [nzCount]="notificationsCounter">
          <i class="fal fa-bell by-fs-25 color--white"></i>
        </nz-badge>
      </span>

      <div style="width: 25px; text-align: center">
        <i
          class="fal menu-button"
          (click)="isShowLinks = !isShowLinks"
          [ngClass]="{
            'fa-bars': !isShowLinks,
            'fa-times': isShowLinks
          }"
        ></i>
      </div>
    </div>
  </div>
  <by-bottom-sheet #bottomSheet>
    <by-mobile-bar-container
      [languages]="languages"
      (changeLanguage)="changeLanguage.emit($event)"
      (closeBottomSheet)="bottomSheet.onClose()"
    ></by-mobile-bar-container>
  </by-bottom-sheet>
  <div
    [class.expanded]="isShowLinks"
    [class.body-menu]="isShowLinks || !isShowLinks"
  >
    <div class="headerRow">
      <by-multi-property-menu
        [groupPropertyName]="groupPropertyName"
        [userFullName]="userFullName"
        [isGroupSelectDisabled]="isGroupSelectDisabled"
        [allGroups]="allGroups"
        [allProperties]="allProperties"
        [groupLogo]="groupLogo"
        [logoProperty]="logoProperty"
        [selectedProperty]="selectedProperty"
        [selectedGroup]="selectedGroup"
        (changeGroupEvent)="changeGroupEvent.emit($event)"
        (changePropertyEvent)="changePropertyEvent.emit($event)"
      ></by-multi-property-menu>
    </div>
    <ul
      nz-menu
      [nzInlineIndent]="15"
      (nzClick)="scrollTop(); isShowLinks = false"
      [nzTheme]="'dark'"
      [nzMode]="isCollapsed ? 'vertical' : 'inline'"
      [nzInlineCollapsed]="isCollapsed"
      style="padding-left: 15px; padding-right: 15px; padding-top: 60px"
    >
      <ng-container
        *ngFor="let link of showAdminRoutes ? adminRoutes : commonRoutes"
      >
        <ng-container *ngIf="link.isDropdown">
          <li
            nz-submenu
            routerLinkActive
            #rla="routerLinkActive"
            style="
              min-height: 60px;
              padding-top: 10px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            "
          >
            <span title>
              <i
                [style.color]="rla.isActive ? '#047bf8' : 'white'"
                class="fal {{ link.icon }}"
                style="margin-right: 10px"
              ></i>

              <span class="menu-text">{{ link.label | translate }}</span>
            </span>

            <ul>
              <ng-container *ngFor="let sublink of link?.children">
                <li
                  *ngIf="!sublink.children; else subChildrenMenu"
                  nz-menu-item
                >
                  <ng-container *ngIf="!sublink?.targetBlank; else linkHref">
                    <a [routerLink]="sublink.link"
                      ><span></span
                      ><span class="menu-text">
                        {{ sublink.label | translate }}
                      </span>
                    </a>
                  </ng-container>

                  <ng-template #linkHref>
                    <a
                      [href]="!sublink.disableRouter && sublink.link"
                      target="'_blank'"
                      ><span></span
                      ><span class="menu-text">
                        {{ sublink.label | translate }}
                      </span>
                    </a>
                  </ng-template>
                </li>
                <ng-template #subChildrenMenu>
                  <li nz-submenu>
                    <div title>
                      <span class="menu-text">{{
                        sublink.label | translate | upperfirst
                      }}</span>
                    </div>
                    <ul>
                      <li
                        nz-menu-item
                        *ngFor="let sideLink of sublink?.children"
                      >
                        <a
                          [routerLink]="
                            !sideLink.disableRouter && sideLink.link
                          "
                        >
                          <span class="menu-text">
                            {{ sideLink.label | translate | upperfirst }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </li>
        </ng-container>
        <ng-container *ngIf="!link.isDropdown">
          <li
            nz-menu-item
            routerLinkActive
            #rla="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
            style="
              min-height: 60px;
              padding-top: 10px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            "
          >
            <a [routerLink]="link.link"
              ><i
                style="margin-right: 10px"
                [style.color]="rla.isActive ? '#047bf8' : 'white'"
                class="fal {{ link.icon }}"
              ></i
              ><span class="menu-text">{{ link.label | translate }} </span>
            </a>
          </li>
        </ng-container>
      </ng-container>
      <li
        nz-menu-item
        routerLinkActive
        #rla="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        style="
          min-height: 60px;
          padding-top: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        "
      >
        <a [routerLink]="['/me']"
          ><i
            class="fas fa-user"
            style="margin-right: 10px"
            [style.color]="rla.isActive ? '#047bf8' : 'white'"
          ></i
          ><span class="menu-text">{{ 'profile' | translate }} </span>
        </a>
      </li>
      <li
        nz-menu-item
        routerLinkActive
        #rla="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        style="
          min-height: 60px;
          padding-top: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        "
      >
        <a [routerLink]="['/user-sessions']"
          ><i
            class="fas fa-shield-alt"
            style="margin-right: 10px"
            [style.color]="rla.isActive ? '#047bf8' : 'white'"
          ></i
          ><span class="menu-text"
            >{{ 'protection_and_access' | translate }}
          </span>
        </a>
      </li>
      <li
        nz-menu-item
        routerLinkActive
        *ngIf="!isAdmin"
        (click)="logOutEvent.emit()"
        style="margin-top: 0px; min-height: 60px; padding-top: 10px"
      >
        <span>
          <i
            class="far fa-sign-out by-mr-10"
            [style.color]="rla.isActive ? '#047bf8' : 'white'"
          ></i
          ><span class="menu-text">{{ 'logout' | translate }} </span>
        </span>
      </li>
      <li
        *ngIf="isAdmin"
        nz-menu-item
        routerLinkActive
        (click)="onRemoveImpersonateEvent.emit()"
        style="margin-top: 0px; min-height: 60px; padding-top: 10px"
      >
        <span>
          <i
            class="far fa-sign-out by-mr-10"
            [style.color]="rla.isActive ? '#047bf8' : 'white'"
          ></i
          ><span class="menu-text">{{ 'back_to_admin' | translate }} </span>
        </span>
      </li>
    </ul>
  </div>
  <div class="color-line" *ngIf="isShowColorLine"></div>
</div>
