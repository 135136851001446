import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { PlacesModule } from '../../shared/places/places.module';
import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '../../ui/ui.module';

import { SimpleCompanyFormComponent } from './simple-company-form/simple-company-form.component';
import { QuestionModule } from '../question/question.module';
import { SectionBoxComponent } from '../../ui/section-box/section-box.component';

@NgModule({
  declarations: [SimpleCompanyFormComponent],
  imports: [
    CommonModule,
    UiModule,
    ReactiveFormsModule,
    TranslateModule,
    PlacesModule,
    NgPipesModule,
    SharedModule,
    QuestionModule,
    SectionBoxComponent,
  ],
  exports: [SimpleCompanyFormComponent],
})
export class SimpleCompanyFormModule {}
