import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AutomaticPaymentType,
  AutomaticPaymentsConfiguration,
  PaymentMethod,
} from '../../../../models';

export default createActionGroup({
  source: 'Automatic Payments Configuration',
  events: {
    'Load Request': props<{
      propertiesIds: number[];
    }>(),
    'Load Success': props<{
      automaticPaymentsConfigurations: AutomaticPaymentsConfiguration[];
    }>(),
    'Load Failure': props<{ error: any }>(),

    'Load Details Request': props<{
      id: number;
    }>(),
    'Load Details Success': props<{
      automaticPaymentsConfiguration: AutomaticPaymentsConfiguration;
    }>(),
    'Load Details Failure': props<{ error: any }>(),

    'Delete Request': props<{
      id: number;
    }>(),
    'Delete Success': props<{
      id: number;
    }>(),
    'Delete Failure': props<{ error: any }>(),

    'Load Payment Methods Request': props<{
      payment_type: AutomaticPaymentType;
      property_id: number[];
    }>(),
    'Load Payment Methods Success': props<{
      automaticPaymentMethods: PaymentMethod[];
    }>(),
    'Load Payment Methods Failure': props<{ error: any }>(),

    'Create Request': props<{
      automaticPaymentsConfiguration: AutomaticPaymentsConfiguration;
      force_operation?: boolean;
    }>(),
    'Create Success': props<{
      automaticPaymentsConfiguration: AutomaticPaymentsConfiguration;
    }>(),
    'Create Failure': props<{ error: any }>(),

    'Edit Request': props<{
      automaticPaymentsConfiguration: AutomaticPaymentsConfiguration;
      id: number;
      force_operation?: boolean;
    }>(),
    'Edit Success': props<{
      automaticPaymentsConfiguration: AutomaticPaymentsConfiguration;
    }>(),
    'Edit Failure': props<{ error: any }>(),
    'Reset State': emptyProps(),
  },
});
