import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  inject,
} from '@angular/core';
import {
  FormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { IGeneralBedType } from '@app/models';
import { BedTypesStoreActions } from '@app/root-store/bed-types-store';
import {
  selectBedTypesGeneralBedTypes,
  selectById,
} from '@app/root-store/bed-types-store/selectors';
import { RootState } from '@app/root-store/root-state';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { merge } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { removeNullishValues } from '../../../helpers';
import { TableauMappingAccommodationTableauNumbers } from '../../../models/objects/tableau-mapping';

import {
  IBedsTypesCombination,
  IBedTypes,
} from './../../../models/objects/bed-types.model';
import { selectBedTypesIsLoading } from './../../../root-store/bed-types-store/selectors';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { QuestionModule } from '../../../components/question/question.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

interface Tab {
  label: string;
  combination_id: number;
  ideal_for: number;
  change_days_sheets: number;
  change_days_linen: number;
  clean_every_days: number;
  visibility_days_before_next_reservation: number;
  default: boolean;
}

@Component({
  standalone: true,
  selector: 'by-bed-types-form',
  templateUrl: './bed-types-form.component.html',
  styleUrls: ['./bed-types-form.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    NzFormModule,
    NzTabsModule,
    NzInputModule,
    NzButtonModule,
    QuestionModule,
    TranslateModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NzInputNumberModule,
    NgUpperFirstPipeModule,
  ],
})
export class BedTypesFormComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<BedTypesFormComponent> = inject(NZ_MODAL_DATA);

  @Input() accommodationId: number = this.nzDataModal.accommodationId;

  @Input() propertyId: number = this.nzDataModal.propertyId;

  @Input() set tableauRooms(
    tableauRooms: TableauMappingAccommodationTableauNumbers[],
  ) {
    if (!tableauRooms) {
      return;
    }
    this._tableauRooms = tableauRooms;
    if (this.generalBedTypes) {
      this.newTab();
    }
  }

  @Output()
  closeModal = new EventEmitter<boolean>();

  forms: { [combination_id: number]: UntypedFormGroup[] };
  _tableauRooms: TableauMappingAccommodationTableauNumbers[];
  loading = false;
  tabs: Tab[] = [];
  tabSelectedIndex = 0;

  generalBedTypes: IGeneralBedType[] = null;
  bedTypes: IBedTypes[] = null;
  keyFormGroup: number[];

  subs = new SubSink();

  iconMap = {
    1: 'single.jpg',
    2: 'double.jpg',
    3: 'bunked.jpg',
    4: 'sofa.jpg',
  };

  clickedOnSave = false;

  constructor(
    private store: Store<RootState>,
    private _formBuilder: UntypedFormBuilder,
    private _translate: TranslateService,
    private message: NzMessageService,
    @Optional() private modalRef: NzModalRef,
  ) {
    this.tableauRooms = this.nzDataModal.tableauRooms;
  }

  ngOnInit() {
    this._tableauRooms = this._tableauRooms ? this._tableauRooms : [];

    this.subs.add(
      this.store
        .pipe(select(selectBedTypesGeneralBedTypes))
        .subscribe((data: any) => {
          if (!data) {
            this.store.dispatch(
              new BedTypesStoreActions.LoadSelectRequestAction(),
            );
            return;
          }
          this.generalBedTypes = data;
          this.keyFormGroup = this.generalBedTypes.map(({ id }) => id);
          this.newTab();
          this.store.dispatch(
            new BedTypesStoreActions.LoadRequestAction({
              accommodationId: [this.accommodationId],
            }),
          );
        }),
    );

    this.subs.add(
      this.store
        .pipe(select(selectById(this.accommodationId)))
        .subscribe((data: any) => {
          if (!data || !Object.keys(data).length) {
            return;
          }
          this.bedTypes = data;
          if (!this.generalBedTypes) {
            return;
          }
          this.tabs = [];
          this.forms = null;
          this.bedTypes.forEach((bedTypes) => {
            const {
              label,
              combination_id,
              ideal_for,
              change_days_sheets,
              change_days_linen,
              clean_every_days,
              visibility_days_before_next_reservation,
            } = bedTypes;

            this.tabs.push({
              label,
              combination_id,
              ideal_for,
              change_days_sheets,
              change_days_linen,
              clean_every_days,
              visibility_days_before_next_reservation,
              default: bedTypes.default,
            });

            this.generateForm(combination_id);
          });
        }),
    );

    this.subs.add(
      this.store
        .pipe(select(selectBedTypesIsLoading))
        .subscribe((data: boolean) => {
          this.loading = data;
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.bedTypes = null;
    this.generalBedTypes = null;
    this.accommodationId = null;
    this.forms = null;
    this.store.dispatch(new BedTypesStoreActions.ResetAction());
  }

  closeTab(tab: number, index: number): void {
    if (tab > 0) {
      const request = {
        accommodation_id: this.accommodationId,
        acc_bed_type_combination_id: tab,
      };
      this.store.dispatch(
        new BedTypesStoreActions.DeleteRequestAction({
          request,
          propertyId: this.propertyId,
        }),
      );
    }
    this.tabs.splice(index, 1);
    if (!this.tabs.length) {
      this.newTab();
    }
    if (index > 0) {
      this.tabSelectedIndex = index - 1;
    } else {
      this.tabSelectedIndex = 0;
    }
  }

  newTab(next?: boolean): void {
    const number = this.tabs.length + 1;

    this.tabs.push({
      label: this._translate.instant('new_combination') + ' ' + number,
      combination_id: number * -1,
      ideal_for: null,
      change_days_linen: null,
      change_days_sheets: null,
      clean_every_days: null,
      default: false,
      visibility_days_before_next_reservation: null,
    });

    this.generateForm(number * -1);

    if (next) {
      this.tabSelectedIndex = number;
    }
  }

  generateForm(combinationId) {
    if (!this._tableauRooms) {
      return;
    }
    const newForms = [];
    this._tableauRooms.forEach((room) => {
      if (!this.keyFormGroup) {
        return;
      }
      let tableauNumbersData: any = null;
      if (this.bedTypes) {
        tableauNumbersData = this.bedTypes.find(
          ({ combination_id }) => combinationId === combination_id,
        );
      }
      const valueForm = this.keyFormGroup.reduce((acc, curr) => {
        let bedsTypes: any = null;
        if (tableauNumbersData) {
          bedsTypes = tableauNumbersData.accommodation.tableau_numbers.find(
            ({ id }) => id === room.id,
          );

          if (bedsTypes && bedsTypes.beds_types) {
            let quantity = null;
            const bedTypeData = bedsTypes.beds_types.find(
              ({ bed_type_id }) => bed_type_id === curr,
            );
            if (bedTypeData) {
              quantity = bedTypeData.quantity;
            }
            return (acc = {
              ...acc,
              [curr]: quantity,
            });
          } else {
            return (acc = { ...acc, [curr]: null });
          }
        } else {
        }
        return (acc = { ...acc, [curr]: null });
      }, {});
      newForms.push(this._formBuilder.group({ ...valueForm, room }));
    });
    this.forms = merge({}, this.forms, { [combinationId]: newForms });
  }

  cloneBedTypes(combinationId?: number, keyForm?: number, all?: boolean) {
    let value = this.forms[combinationId][0].get([keyForm]).value;

    if (all) {
      value = !value;
    }

    this.forms[combinationId].forEach((form) => {
      form.patchValue({ [keyForm]: value });
    });
  }

  onDefaultChange(tabIndex: number) {
    const tab = this.tabs[tabIndex];

    if (!tab.default) {
      return;
    }

    this.tabs.forEach((tab, index) => {
      if (tabIndex === index) {
        return;
      }

      tab.default = false;
    });
  }

  checkTabValidity(tab: Tab) {
    if (tab.default && !tab.visibility_days_before_next_reservation) {
      return false;
    }

    return true;
  }

  onSave() {
    this.clickedOnSave = true;

    const invalidTabIndex = this.tabs.findIndex(
      (tab) => !this.checkTabValidity(tab),
    );

    if (invalidTabIndex >= 0) {
      this.tabSelectedIndex = invalidTabIndex;
      return;
    }

    const newCombinations = [];
    const oldCombinations = [];

    this.tabs.forEach((combination) => {
      const {
        ideal_for,
        label,
        combination_id,
        change_days_sheets,
        change_days_linen,
        clean_every_days,
        visibility_days_before_next_reservation,
        default: isDefault,
      } = combination;

      const tableau_numbers = [];
      this.forms[combination_id].forEach((form) => {
        const { room, ...bedtype } = form.value;
        const roomBedTypes = Object.keys(bedtype).map(Number);
        // const roomBedTypes = Object.keys(
        //   omitBy(
        //     bedtype,
        //     (i) => i === null || i === undefined || i === false || i <= 0,
        //   ),
        // ).map(Number);
        //if (roomBedTypes.length) {
        tableau_numbers.push({
          tableau_number_id: room.id,
          beds_types: roomBedTypes.map((id) => ({
            bed_type_id: id,
            quantity: 1,
          })),
        });
        //}
        //Vecchio codice quantity: bedtype[id],
      });

      if (tableau_numbers.length) {
        if (combination_id > 0) {
          oldCombinations.push(
            removeNullishValues({
              ideal_for,
              change_days_sheets: change_days_sheets || null,
              change_days_linen: change_days_linen || null,
              clean_every_days: clean_every_days || null,
              label,
              acc_bed_type_combination_id: combination_id,
              tableau_numbers,
              default: +isDefault,
              visibility_days_before_next_reservation: isDefault
                ? visibility_days_before_next_reservation
                : null,
            }),
          );
        } else {
          newCombinations.push(
            removeNullishValues({
              ideal_for,
              label,
              change_days_sheets: change_days_sheets || null,
              change_days_linen: change_days_linen || null,
              clean_every_days: clean_every_days || null,
              tableau_numbers,
              default: +isDefault,
              visibility_days_before_next_reservation: isDefault
                ? visibility_days_before_next_reservation
                : null,
            }),
          );
        }
      }
    });

    if (!newCombinations.length && !oldCombinations.length) {
      const messageWarning = this._translate.instant(
        'warning_bed_type_configuration',
      );
      this.message.create('warning', messageWarning);
    }

    if (newCombinations.length) {
      const dataSendNew: IBedsTypesCombination = {
        accommodation_id: this.accommodationId,
        combinations: newCombinations,
      };
      this.store.dispatch(
        new BedTypesStoreActions.CreateRequestAction({
          request: dataSendNew,
          propertyId: this.propertyId,
        }),
      );
    }
    if (oldCombinations.length) {
      const dataSendNew: IBedsTypesCombination = {
        accommodation_id: this.accommodationId,
        combinations: oldCombinations,
      };
      this.store.dispatch(
        new BedTypesStoreActions.UpdateRequestAction({
          request: dataSendNew,
          propertyId: this.propertyId,
        }),
      );
    }

    this.closeModal.emit(true);

    this.modalRef?.close();
  }
}
