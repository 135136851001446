import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import fromActions from './actions';
import { ErrorHandlerService } from '../../../../core/services/error-handler.service';
import { AutomaticPaymentsConfigurationService } from '../../../../services';
import { NotificationService } from '../../../../ui/services/notification.service';
import { WarningConfirmModalService } from '../../../../services/warning-confirm-modal.service';

@Injectable()
export class AutomaticPaymentsConfigurationStoreEffects {
  constructor(
    private dataService: AutomaticPaymentsConfigurationService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private warningModalService: WarningConfirmModalService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds }) =>
        this.dataService.load(propertiesIds).pipe(
          map((response) =>
            fromActions.loadSuccess({
              automaticPaymentsConfigurations: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ id }) =>
        this.dataService.loadDetails(id).pipe(
          map((response) =>
            fromActions.loadDetailsSuccess({
              automaticPaymentsConfiguration: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ id }) =>
        this.dataService.delete(id).pipe(
          map(() => {
            this.notifications.updateSuccess();

            return fromActions.deleteSuccess({
              id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ automaticPaymentsConfiguration, force_operation }) =>
        this.dataService
          .create(automaticPaymentsConfiguration, force_operation)
          .pipe(
            map((response) => {
              if (!response.meta.confirm_required) {
                this.notifications.updateSuccess();

                this.router.navigate(['/configuration/automatic-payments']);

                return fromActions.createSuccess({
                  automaticPaymentsConfiguration: response.data[0],
                });
              }

              this.warningModalService.open({
                message: response.meta.warnings.toString(),
                action: fromActions.createRequest({
                  automaticPaymentsConfiguration,
                  force_operation: true,
                }),
              });

              return fromActions.createFailure({
                error: null,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.createFailure(error));
            }),
          ),
      ),
    ),
  );

  edit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editRequest),
      switchMap(({ automaticPaymentsConfiguration, id, force_operation }) =>
        this.dataService
          .edit(automaticPaymentsConfiguration, id, force_operation)
          .pipe(
            map((response) => {
              if (!response.meta.confirm_required) {
                this.notifications.updateSuccess();
                this.router.navigate(['/configuration/automatic-payments']);
                return fromActions.editSuccess({
                  automaticPaymentsConfiguration: response.data[0],
                });
              }

              this.warningModalService.open({
                message: response.meta.warnings.toString(),
                action: fromActions.editRequest({
                  automaticPaymentsConfiguration,
                  id,
                  force_operation: true,
                }),
              });

              return fromActions.editFailure({
                error: null,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.editFailure(error));
            }),
          ),
      ),
    ),
  );

  loadPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPaymentMethodsRequest),
      switchMap(({ payment_type, property_id }) =>
        this.dataService.loadPaymentMethods(payment_type, property_id).pipe(
          map((response) => {
            return fromActions.loadPaymentMethodsSuccess({
              automaticPaymentMethods: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadPaymentMethodsFailure(error));
          }),
        ),
      ),
    ),
  );
}
