import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isWeekend',
  pure: true,
})
export class IsWeekendPipe implements PipeTransform {
  transform(date: Date): boolean {
    const weekDayIndex = date?.getDay();
    return weekDayIndex === 0 || weekDayIndex === 6;
  }
}
