<div class="text-align--end">
  <div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="toggleFilter" class="by-mb-10">
    <span>
      <i class="fas fa-filter"></i>
      {{ 'filter_view' | translate | capitalize
      }}<nz-switch
        nzSize="small"
        class="by-ml-2"
        [ngModel]="filtersAreVisible"
        (ngModelChange)="onChangeFiltersVisibility($event)"
      ></nz-switch>
    </span>
    <!--

    <nz-form-item
  *ngIf="toggleFilter"
  [ngClass]="{
    selectAbsolute:
      filterSelectIsAbsolute && !selectForm.get('filterSelected').value?.length
  }"
  style="text-align: left; margin-right: 10px"
>
  <span style="display: block">
    <i class="fas fa-filter"></i>
    {{ 'selectfilter_type' | translate | capitalize }}<nz-switch [(ngModel)]="showFilter"></nz-switch>
    </span
  >

    <nz-select
    #multiSelectButton
    [nzDropdownRender]="render"
    nzMode="multiple"
    style="width:182px; margin-top: 4px;"
    nzAllowClear
    id="selectAllNoValue"
    nzPlaceHolder="{{ 'filters_selected' | translate | capitalize }} : {{
      selectForm.get('filterSelected').value?.length
    }}"
    [ngModel]="selectForm.get('filterSelected').value"
    (ngModelChange)="selectAllFilter($event)"
  >
    <nz-option nzCustomContent nzValue="all">
      <span class="filterSelectAllSpan">{{ 'select_all' | translate }}</span>
    </nz-option>
    <nz-option
      *ngFor="let filter of filterSelect"
      [nzValue]="filter"
      [nzLabel]="filter | translate | capitalize"
    ></nz-option>
  </nz-select>
  <ng-template #render>
    <nz-divider style="margin: 4px 0;"></nz-divider>
    <div class="container-button-multiselect">
      <button
        nz-button
        (click)="multiSelectButton.nzOpen = false"
        nzType="primary"
      >
        {{ 'button-multiselect-ok' | translate }}
      </button>
    </div>
  </ng-template>-->
  </div>
</div>

<!-- per utilizzare la select decommentare il codice sopra ed aggiungere nel div sotto *ngIf="selectForm.get('filterSelected').value?.length"-->

<div
  [@animateFiltersBox]="filtersAnimationState"
  (@animateFiltersBox.start)="animationHandler($event, 'start')"
  (@animateFiltersBox.done)="animationHandler($event, 'done')"
  [ngClass]="{ hidden: !filtersAreVisible }"
  #filterDivGeneral
  class="ant-advanced-search-form"
>
  <nz-card
    class="card-without-padding card-with-shadow"
    style="border-radius: 6px; overflow: hidden"
    [nzBordered]="false"
  >
    <div class="filters-box">
      <div class="form-box">
        <div>
          <form
            nz-form
            [nzLayout]="'vertical'"
            [formGroup]="form"
            class="custom-form"
          >
            <!-- per usare la select di selezione cambiare customFilters con customFiltersView-->
            <ng-container *ngFor="let customFilter of customFilters || []">
              <ng-container *ngIf="!customFilter.onlyAdmin || isAdmin">
                <div class="custom-filters-item">
                  <nz-form-item
                    [ngClass]="{
                      'flex-cloumn': customFilter.type === 'number'
                    }"
                  >
                    <nz-form-label class="by-mr-2"
                      >{{
                        customFilter.label_translate ||
                          customFilter.nameToDisplay ||
                          customFilter.label
                          | translate
                          | capitalize
                      }}
                      <i
                        *ngIf="customFilter.onlyAdmin"
                        nz-tooltip
                        [nzTooltipTitle]="
                          'admin_operation' | translate | upperfirst
                        "
                        class="by-ml-2 fas fa-user-shield color--azure"
                      ></i>
                    </nz-form-label>
                    <ng-container [ngSwitch]="customFilter.type">
                      <input
                        *ngSwitchCase="'text'"
                        [formControlName]="customFilter.label"
                        nz-input
                        type="text"
                        (keyup.enter)="onSearch()"
                      />

                      <nz-input-number
                        class="by-w-100"
                        *ngSwitchCase="'number'"
                        byNumberOnly
                        [formControlName]="customFilter.label"
                        [nzPlaceHolder]="
                          customFilter.label_translate || customFilter.label
                            | translate
                            | upperfirst
                        "
                        [nzMin]="1"
                        [nzStep]="1"
                      ></nz-input-number>

                      <nz-year-picker
                        style="width: 100%"
                        [ngStyle]="{ width: '100%' }"
                        [formControlName]="customFilter.label"
                        [nzPlaceHolder]="
                          customFilter.label_translate | translate | upperfirst
                        "
                        *ngSwitchCase="'date_year'"
                      ></nz-year-picker>
                      <nz-month-picker
                        nzFormat="MMMM"
                        style="width: 100%"
                        [ngStyle]="{ width: '100%' }"
                        [formControlName]="customFilter.label"
                        [nzPlaceHolder]="
                          customFilter.label_translate | translate | upperfirst
                        "
                        *ngSwitchCase="'date_month'"
                      ></nz-month-picker>

                      <nz-date-picker
                        byDisabledMobileKeyboard
                        style="width: 100%"
                        [ngStyle]="{ width: '100%' }"
                        nzFormat="dd/MM/yyyy"
                        [formControlName]="customFilter.label"
                        [nzDisabled]="
                          customFilter.disableWhenIsEqual &&
                          (form.get(customFilter.disableWhenIsEqual.controlName)
                            .value ===
                            customFilter.disableWhenIsEqual.controlValue ||
                            form.get(
                              customFilter.disableWhenIsEqual.controlName
                            ).value
                            | firstOrDefault
                              : customFilter.disableWhenIsEqual.controlValue)
                        "
                        *ngSwitchCase="'date'"
                      ></nz-date-picker>

                      <nz-range-picker
                        byRangePicker
                        style="width: 100%"
                        [ngStyle]="{ width: '100%' }"
                        nzFormat="dd/MM/yyyy"
                        [nzDisabledDate]="customFilter?.nzDisabledDate"
                        [formControlName]="customFilter.label"
                        [nzRanges]="customFilter?.ranges"
                        nzDropdownClassName="range-picker-preset"
                        [nzRenderExtraFooter]="
                          customFilter?.ranges ? footerRender : null
                        "
                        *ngSwitchCase="'range'"
                        [nzSeparator]="'~'"
                      ></nz-range-picker>
                      <ng-template #footerRender>
                        <div class="preset-ranges">
                          <div nz-row nzJustify="center" nzGutter="10">
                            <div
                              nz-col
                              [nzXs]="24"
                              [nzSm]="8"
                              *ngFor="let option of customFilter?.ranges | keys"
                              class="preset-column"
                            >
                              <button
                                class="preset-button"
                                (click)="onChangeRange(option, customFilter)"
                                nz-button
                                nzSize="small"
                                nzType="primary"
                              >
                                {{ option | translate | upperfirst }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-template>

                      <by-multi-select
                        *ngSwitchCase="'by-multiselect'"
                        [formControlName]="customFilter.label"
                        [options]="customFilter.options"
                        [groupByKey]="customFilter.groupByKey"
                        [groupsNames]="customFilter.groupsNames"
                        [groupIcon]="customFilter.groupIcon"
                        value="value"
                        label="name"
                      >
                      </by-multi-select>

                      <nz-select
                        nzAllowClear
                        [formControlName]="customFilter.label"
                        *ngSwitchCase="'select'"
                        [nzMode]="customFilter.selectMode || 'default'"
                        [nzShowArrow]="true"
                      >
                        <nz-option
                          *ngFor="let option of customFilter.options"
                          [nzValue]="option.value"
                          [nzLabel]="option.name | translate | capitalize"
                        ></nz-option>
                      </nz-select>
                    </ng-container>
                  </nz-form-item>
                </div>
              </ng-container>
            </ng-container>
            <!-- per usare la select di selezione cambiare filters con filtersView-->
            <ng-container
              *ngFor="let filter of filters || [] | without : 'place'"
            >
              <ng-container [ngSwitch]="filter">
                <ng-container *ngSwitchCase="'accommodations'">
                  <ng-container
                    *ngTemplateOutlet="
                      accommodationsFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'property_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      propertiesFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'booking_widget_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      bookingWidgetsFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'accommodation'">
                  <ng-container
                    *ngTemplateOutlet="
                      accommodationsFilter;
                      context: { multi: false }
                    "
                  ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'rateplans'">
                  <ng-container
                    *ngTemplateOutlet="
                      rateplansFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'rateplan'">
                  <ng-container
                    *ngTemplateOutlet="
                      rateplansFilter;
                      context: { multi: false }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'rateplansByProperty'">
                  <ng-container
                    *ngTemplateOutlet="rateplansByPropertyFilter"
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tag_id'">
                  <ng-container *ngTemplateOutlet="tagFilter"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'channel_id'">
                  <ng-container
                    *ngTemplateOutlet="channelsFilter; context: { multi: true }"
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'module_id'">
                  <ng-container
                    *ngTemplateOutlet="modulesFilter; context: { multi: true }"
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'property_type_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      propertyTypesFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dealers'">
                  <ng-container
                    *ngTemplateOutlet="
                      dealersFilter;
                      context: { controlName: 'dealers' }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dealerWithoutCustomer'">
                  <ng-container
                    *ngTemplateOutlet="
                      dealersFilter;
                      context: {
                        controlName: 'dealerWithoutCustomer',
                        data: dealerWithoutCustomer$ | async
                      }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'user_id'">
                  <ng-container
                    *ngTemplateOutlet="userIdFilter; context: { multi: true }"
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'events_id'">
                  <ng-container
                    *ngTemplateOutlet="eventIdFilter; context: { multi: true }"
                  ></ng-container>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="'document_type_code_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      documentTypeCodeIdFilter;
                      context: { multi: true }
                    "
                  ></ng-container> -->
                <ng-container *ngSwitchCase="'document_type_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      documentTypeIdFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'reseller_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      resellerIdFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'reservation_from'">
                  <ng-container
                    *ngTemplateOutlet="
                      reservationFromFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'metasearch_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      metasearchFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'payment_method_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      paymentMethodFilter;
                      context: { multi: false }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'payment_method_ids'">
                  <ng-container
                    *ngTemplateOutlet="
                      paymentMethodFilter;
                      context: { multi: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'property_pos_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      propertyPosFilter;
                      context: { multi: false }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'months'">
                  <ng-container *ngTemplateOutlet="monthsFilter"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dealer'">
                  <ng-container
                    *ngTemplateOutlet="
                      dealersFilter;
                      context: { controlName: 'dealer' }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'company_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      searchCustomerCompanyFilter;
                      context: { isCompany: true }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'customer_id'">
                  <ng-container
                    *ngTemplateOutlet="
                      searchCustomerCompanyFilter;
                      context: { isCompany: false }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'invoice_layout_id'">
                  <ng-container
                    *ngTemplateOutlet="invoiceLayoutFilter"
                  ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'expense_category_id'">
                  <ng-container
                    *ngTemplateOutlet="expensesCategoriesFilter"
                  ></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #propertiesFilter>
              <div
                *ngIf="properties && properties.length"
                class="custom-filters-item"
              >
                <nz-form-item>
                  <nz-form-label
                    >{{ 'properties' | translate | capitalize }}
                  </nz-form-label>
                  <by-multi-select
                    formControlName="property_id"
                    [placeholder]="
                      ('properties_selected' | translate | upperfirst) +
                      ': ' +
                      0
                    "
                    [options]="properties"
                    type="properties"
                    value="id"
                    label="name"
                  ></by-multi-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #bookingWidgetsFilter>
              <div *ngIf="bookingWidgets.length" class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label
                    >{{ 'booking_engine' | translate | capitalize }}
                  </nz-form-label>
                  <by-multi-select
                    formControlName="booking_widget_id"
                    [options]="bookingWidgets"
                    value="id"
                    label="dns"
                  ></by-multi-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #modulesFilter>
              <div
                *ngIf="(modules$ | async)?.length"
                class="custom-filters-item"
              >
                <nz-form-item>
                  <nz-form-label
                    >{{ 'property_modules' | translate | capitalize }}
                  </nz-form-label>
                  <by-multi-select
                    formControlName="module_id"
                    [options]="modules$ | async"
                    value="id"
                    label="name"
                  ></by-multi-select>
                </nz-form-item>
              </div>
            </ng-template>
            <ng-template #propertyTypesFilter>
              <div
                *ngIf="(propertyTypes$ | async)?.length"
                class="custom-filters-item"
              >
                <nz-form-item>
                  <nz-form-label
                    >{{ 'type' | translate | capitalize }}
                  </nz-form-label>
                  <by-multi-select
                    formControlName="property_type_id"
                    [options]="propertyTypes$ | async"
                    value="id"
                    label="name"
                  ></by-multi-select>
                </nz-form-item>
              </div>
            </ng-template>
            <ng-template #accommodationsFilter let-multi="multi">
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label
                    >{{
                      (multi ? 'accommodations' : 'accommodation')
                        | translate
                        | capitalize
                    }}
                  </nz-form-label>
                  <ng-container *ngIf="!multi; else multipleAccommodation">
                    <nz-select
                      nzAllowClear
                      [nzMode]="multi ? 'multiple' : 'default'"
                      [formControlName]="
                        multi ? 'accommodations' : 'accommodation'
                      "
                    >
                      <ng-container
                        *ngFor="
                          let propertyId of filtersData.get('accommodations')
                            | keys
                        "
                      >
                        <ng-container
                          *ngIf="
                            filtersData.get('accommodations')[
                              propertyId
                            ] as _accommodations
                          "
                        >
                          <ng-container
                            *ngIf="_accommodations.length; else onlyoption"
                          >
                            <nz-option-group [nzLabel]="nameProperty">
                              <ng-template #nameProperty>
                                <i class="by-mr-2 fal fa-home-lg-alt"></i>
                                {{ propertiesNames[propertyId] }}
                              </ng-template>
                              <nz-option
                                *ngFor="let accommodation of _accommodations"
                                [nzLabel]="accommodation.name"
                                [nzValue]="accommodation.id"
                              ></nz-option>
                            </nz-option-group>
                          </ng-container>
                          <ng-template #onlyoption>
                            <nz-option
                              *ngFor="let accommodation of _accommodations"
                              [nzLabel]="accommodation.name"
                              [nzValue]="accommodation.id"
                            ></nz-option>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </nz-select>
                  </ng-container>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #rateplansByPropertyFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'rateplans' | translate | capitalize
                  }}</nz-form-label>
                  <by-multi-select
                    [options]="filtersData.get('_allRateplan')"
                    label="name"
                    value="id"
                    formControlName="rateplansByProperty"
                    groupByKey="property_id"
                    [noEmpty]="false"
                    [placeholder]="
                      ('rateplan_selected' | translate | upperfirst) + ': ' + 0
                    "
                    [groupLabelTpl]="rateplansByProperty"
                    [groupsNames]="propertiesNames"
                  ></by-multi-select>
                  <ng-template #rateplansByProperty let-label="label">
                    <i class="by-pr-2 fal fa-home-lg-alt"></i>
                    {{ label }}
                  </ng-template>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #rateplansFilter let-multi="multi">
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    (multi ? 'rateplans' : 'rateplan') | translate | capitalize
                  }}</nz-form-label>

                  <nz-select
                    nzAllowClear
                    [nzMode]="multi ? 'multiple' : 'default'"
                    [formControlName]="multi ? 'rateplans' : 'rateplan'"
                  >
                    <nz-option-group
                      *ngIf="
                        (filtersData.get('_rateplans') &&
                          filtersData.get('_rateplans').length) ||
                        ((allRateplans$ | async | get : 'plan') || []).length
                      "
                      [nzLabel]="'rateplans' | translate | capitalize"
                    >
                      <ng-container
                        *ngFor="
                          let rateplan of filtersData.get('_rateplans') ||
                            (allRateplans$ | async | get : 'plan') ||
                            []
                        "
                      >
                        <nz-option
                          [nzValue]="rateplan.id"
                          [nzLabel]="rateplan.name"
                        ></nz-option>
                      </ng-container>
                    </nz-option-group>
                    <nz-option-group
                      *ngIf="
                        (filtersData.get('_offers') &&
                          filtersData.get('_offers').length) ||
                        ((allRateplans$ | async | get : 'offer') || []).length
                      "
                      [nzLabel]="'offers' | translate | capitalize"
                    >
                      <ng-container
                        *ngFor="
                          let offer of filtersData.get('_offers') ||
                            (allRateplans$ | async | get : 'offer') ||
                            []
                        "
                      >
                        <nz-option
                          [nzValue]="offer.id"
                          [nzLabel]="offer.name"
                        ></nz-option>
                      </ng-container>
                    </nz-option-group>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #tagFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'reservation_tag' | translate | capitalize
                  }}</nz-form-label>

                  <nz-select
                    nzAllowClear
                    nzMode="default"
                    formControlName="tag_id"
                  >
                    <nz-option
                      *ngFor="let tag of (reservationTags$ | async) || []"
                      [nzValue]="tag.id"
                      [nzLabel]="tag.label"
                    ></nz-option>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #channelsFilter let-multi="multi">
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    (multi ? 'channels' : 'channel') | translate | capitalize
                  }}</nz-form-label>
                  <nz-select
                    nzAllowClear
                    [nzMode]="'multiple'"
                    [nzMaxMultipleCount]="multi ? 100 : 1"
                    [formControlName]="multi ? 'channel_id' : 'channel_id'"
                    [nzShowArrow]="true"
                  >
                    <nz-option
                      *ngFor="let channel of filtersData.get('channels')"
                      [nzValue]="channel.id"
                      [nzLabel]="channel.name"
                    ></nz-option>
                    <nz-option
                      *ngIf="addWebsiteChannelFilter"
                      nzCustomContent
                      [nzValue]="'website'"
                      [nzLabel]="'website' | translate | capitalize"
                    >
                      <span>{{ 'website' | translate | capitalize }}</span>
                      <i
                        class="by-ml-5 fal fa-info-circle pointer color--orange"
                        nz-tooltip
                        [nzTooltipTitle]="
                          'website_channel_filter_warning'
                            | translate
                            | upperfirst
                        "
                      ></i>
                    </nz-option>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #paymentMethodFilter let-multi="multi">
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'payment_method' | translate | capitalize
                  }}</nz-form-label>
                  <nz-select
                    style="width: 100%"
                    nzAllowClear
                    [nzMode]="multi ? 'multiple' : 'default'"
                    [formControlName]="
                      multi ? 'payment_method_ids' : 'payment_method_id'
                    "
                    [nzShowArrow]="true"
                  >
                    <ng-container
                      *ngFor="let payment of paymentMethods$ | async"
                    >
                      <nz-option
                        [nzLabel]="payment.name"
                        [nzValue]="payment.id"
                      ></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #propertyPosFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'pos' | translate | capitalize
                  }}</nz-form-label>
                  <by-multi-select
                    style="display: block; width: 100%"
                    [options]="posStoreData$ | async"
                    label="name"
                    value="id"
                    [noEmpty]="false"
                    formControlName="property_pos_id"
                    groupByKey="property_id"
                    [groupsNames]="propertiesNames"
                    [groupLabelTpl]="groupLabelTpl"
                  ></by-multi-select>
                  <ng-template #groupLabelTpl let-label="label">
                    <i class="by-pr-2 fal fa-home-lg-alt"></i>
                    {{ label }}
                  </ng-template>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #invoiceLayoutFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'invoice_layout' | translate | capitalize
                  }}</nz-form-label>
                  <nz-select
                    style="width: 100%"
                    nzAllowClear
                    formControlName="invoice_layout_id"
                  >
                    <ng-container
                      *ngFor="let layout of invoiceLayouts$ | async"
                    >
                      <nz-option
                        [nzLabel]="layout.name"
                        [nzValue]="layout.id"
                      ></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #expensesCategoriesFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'expenses_category' | translate | capitalize
                  }}</nz-form-label>
                  <nz-select
                    style="width: 100%"
                    nzAllowClear
                    formControlName="expense_category_id"
                  >
                    <ng-container
                      *ngFor="let category of expensesCategories$ | async"
                    >
                      <nz-option
                        [nzLabel]="category.label"
                        [nzValue]="category.id"
                      ></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template
              #dealersFilter
              let-controlName="controlName"
              let-data="data"
            >
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'dealer' | translate | capitalize
                  }}</nz-form-label>
                  <nz-select
                    style="width: 100%"
                    nzAllowClear
                    [formControlName]="controlName"
                  >
                    <nz-option
                      *ngFor="let dealer of data || (dealers$ | async)"
                      [nzValue]="dealer.id"
                      [nzLabel]="dealer.title"
                    ></nz-option>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #userIdFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>
                    {{ 'user' | translate | capitalize }}
                  </nz-form-label>
                  <by-lookup
                    class="by-w-100"
                    formControlName="user_id"
                    [options]="users$ | async"
                    (search)="onUsersSearch($event)"
                  ></by-lookup>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #eventIdFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>
                    {{ 'event' | translate | capitalize }}
                  </nz-form-label>
                  <by-lookup
                    class="by-w-100"
                    nzModeSelect="multiple"
                    [nzMaxMultipleCount]="1"
                    formControlName="events_id"
                    labelPath="label"
                    [placeholder]="'event' | translate | capitalize"
                    [options]="events$ | async"
                    (search)="onEventSearch({ title: $event })"
                  ></by-lookup>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #resellerIdFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>
                    {{ 'reseller' | translate | capitalize }}
                  </nz-form-label>
                  <nz-select
                    style="width: 100%"
                    nzAllowClear
                    formControlName="reseller_id"
                  >
                    <nz-option
                      *ngFor="let reseller of resellers$ | async"
                      [nzValue]="reseller.id"
                      [nzLabel]="
                        reseller.company_name +
                        ' - (' +
                        reseller.name +
                        ' ' +
                        reseller.surname +
                        ')'
                      "
                    ></nz-option>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #reservationFromFilter>
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>
                    {{ 'reservation_from' | translate | capitalize }}
                  </nz-form-label>
                  <nz-select
                    style="width: 100%"
                    nzAllowClear
                    nzShowSearch
                    formControlName="reservation_from"
                  >
                    <nz-option
                      *ngFor="let reservationFrom of reservationFroms$ | async"
                      [nzValue]="reservationFrom.id"
                      [nzLabel]="reservationFrom.name"
                    ></nz-option>
                  </nz-select>
                </nz-form-item>
              </div>
            </ng-template>

            <ng-template #metasearchFilter>
              <ng-container *ngIf="metasearch$ | async as metasearchEngines">
                <div
                  *ngIf="metasearchEngines.length"
                  class="custom-filters-item"
                >
                  <nz-form-item>
                    <nz-form-label>
                      {{ 'metasearch' | translate | capitalize }}
                    </nz-form-label>
                    <nz-select
                      style="width: 100%"
                      nzAllowClear
                      formControlName="metasearch_id"
                      [nzMode]="'multiple'"
                    >
                      <nz-option
                        *ngFor="let metasearch of metasearchEngines"
                        [nzValue]="metasearch.id"
                        [nzLabel]="metasearch.name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-item>
                </div>
              </ng-container>
            </ng-template>

            <ng-template #documentTypeIdFilter>
              <ng-container *ngIf="invoiceTypes$ | async as invoiceTypes">
                <div class="custom-filters-item">
                  <nz-form-item>
                    <nz-form-label>
                      {{ 'document_type' | translate | capitalize }}<br />
                    </nz-form-label>
                    <nz-select
                      style="width: 100%"
                      nzAllowClear
                      formControlName="document_type_id"
                    >
                      <ng-container *ngFor="let invoiceType of invoiceTypes">
                        <nz-option
                          *ngIf="
                            invoiceType.id
                              | isInArray
                                : documentTypesToInclude || [1, 5, 6, 7]
                          "
                          [nzValue]="invoiceType.id"
                          [nzLabel]="invoiceType.name | translate | upperfirst"
                        ></nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-item>
                </div>
              </ng-container>
            </ng-template>

            <ng-template #documentTypeCodeIdFilter>
              <ng-container *ngIf="invoiceTypes$ | async as invoiceTypes">
                <div class="custom-filters-item">
                  <nz-form-item>
                    <nz-form-label>
                      {{ 'document_type' | translate | capitalize }}
                    </nz-form-label>
                    <nz-select
                      style="width: 100%"
                      nzAllowClear
                      formControlName="document_type_code_id"
                    >
                      <ng-container *ngFor="let invoiceType of invoiceTypes">
                        <nz-option-group
                          *ngIf="
                            invoiceType?.document_type_codes?.length &&
                            invoiceType.name !== 'credit_note'
                          "
                          [nzLabel]="invoiceType.name | translate | upperfirst"
                        >
                          <nz-option
                            *ngFor="
                              let code of invoiceType?.document_type_codes
                            "
                            [nzValue]="code.id"
                            [nzLabel]="'(' + code.code + ') - ' + code.name"
                          ></nz-option>
                        </nz-option-group>
                      </ng-container>
                    </nz-select>
                  </nz-form-item>
                </div>
              </ng-container>
            </ng-template>

            <ng-container *ngIf="isPlaceEnabled">
              <div class="custom-filters-item by-w-100" style="margin: 0">
                <by-places-form
                  [allowClear]="true"
                  [skip]="0"
                  (changePlace)="onPlaceChange($event)"
                  formDisplay="flex"
                  nzLayout="vertical"
                  customFormClass="from-filters"
                  [enabledInputs]="hidePlacesFn"
                  [place]="{ countryId: null }"
                ></by-places-form>
              </div>
            </ng-container>
            <ng-container *ngIf="isExtraActivated">
              <!-- <nz-divider></nz-divider>
                <p>
                  <strong> {{ 'details' | translate | capitalize }} </strong>
                </p> -->
              <div class="custom-filters-item">
                <nz-form-item>
                  <nz-form-label>{{
                    'advanced' | translate | upperfirst
                  }}</nz-form-label>
                  <nz-select
                    #multiSelectButton
                    class="beddy-multi-select"
                    [nzDropdownRender]="render"
                    nzAllowClear
                    nzMode="multiple"
                    formControlName="extra"
                    (ngModelChange)="showValueSelected($event)"
                    style="width: 100%"
                  >
                    <nz-option
                      *ngFor="let value of dataSelectValueChange"
                      [nzLabel]="value.label | translate | capitalize"
                      [nzValue]="value.id"
                    ></nz-option>
                  </nz-select>
                  <ng-template #render>
                    <nz-divider style="margin: 4px 0"></nz-divider>
                    <div class="container-button-multiselect">
                      <button
                        nz-button
                        (click)="multiSelectButton.nzOpen = false"
                        nzType="primary"
                      >
                        {{ 'button-multiselect-ok' | translate }}
                      </button>
                    </div>
                  </ng-template>
                </nz-form-item>
              </div>
            </ng-container>

            <!-- <div nz-row>
            <div nz-col [nzSpan]="24" style="text-align: right;">
              <button
                [disabled]="!form.valid"
                (click)="onSearch()"
                type="submit"
                nz-button
                nzType="primary"
                class="white-text"
              >
                <i class="fal fa-search"></i>
                {{ 'search' | translate | capitalize }}
              </button>
              <button nz-button (click)="clear()">
                {{ 'clear' | translate | capitalize }}
              </button>
            </div>
          </div> -->
          </form>
          <ng-container
            *ngIf="showValueInput && (showValueInput | keys).length > 0"
          >
            <nz-divider
              class="filter-divider"
              [nzText]="'advanced' | translate | upperfirst"
              nzOrientation="left"
              style="font-size: 13px"
            ></nz-divider>
            <form nz-form [nzLayout]="'vertical'" [formGroup]="form">
              <div class="advanced">
                <ng-container *ngFor="let value of dataSelectValueChange">
                  <ng-container *ngIf="showValueInput[value.id]">
                    <ng-container
                      *ngIf="value.type === 'number'; else selectValue"
                    >
                      <nz-form-item>
                        <nz-form-label>{{
                          value.label | translate | capitalize
                        }}</nz-form-label>
                        <nz-input-number
                          class="by-w-100"
                          byNumberOnly
                          [formControlName]="value.id"
                          [nzMin]="1"
                          [nzStep]="1"
                        ></nz-input-number>
                      </nz-form-item>
                    </ng-container>
                    <ng-template #selectValue>
                      <nz-form-item>
                        <nz-form-label>{{
                          value.label | translate | capitalize
                        }}</nz-form-label>
                        <nz-select
                          nzAllowClear
                          [formControlName]="value.id"
                          class="by-w-100"
                        >
                          <nz-option
                            *ngFor="let el of value.valueSelect"
                            [nzLabel]="el.label | translate | capitalize"
                            [nzValue]="el.value"
                          ></nz-option>
                        </nz-select>
                      </nz-form-item>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </form>
          </ng-container>

          <ng-template #searchCustomerCompanyFilter let-isCompany="isCompany">
            <div class="custom-filters-item">
              <nz-form-item>
                <nz-form-label>{{
                  (isCompany ? 'company' : 'customer') | translate | capitalize
                }}</nz-form-label>
                <ng-container
                  *ngIf="
                    propertiesIds && propertiesIds.length;
                    else addPropertyInput
                  "
                >
                  <nz-select
                    style="width: 100%"
                    nzShowSearch
                    nzAllowClear
                    [nzServerSearch]="true"
                    [nzPlaceHolder]="'search' | translate | capitalize"
                    [nzShowArrow]="false"
                    (ngModelChange)="
                      isCompany
                        ? onSelectCompnay($event)
                        : onSelectCustomer($event)
                    "
                    [(ngModel)]="
                      isCompany ? searchValueCompany : searchValueCustomer
                    "
                    (nzOnSearch)="searchCustomerCompany($event, isCompany)"
                  >
                    <nz-option
                      *ngFor="let item of isCompany ? companies : customers"
                      [nzLabel]="
                        !isCompany
                          ? item?.name + ' ' + (item?.surname || '')
                          : item.name
                      "
                      [nzValue]="item.id"
                    >
                    </nz-option>
                  </nz-select>
                </ng-container>
                <ng-template #addPropertyInput>
                  Inserisci input id property
                </ng-template>
              </nz-form-item>
            </div>
          </ng-template>

          <ng-template #monthsFilter>
            <div class="custom-filters-item">
              <nz-form-item>
                <nz-form-label
                  >{{ 'month' | translate | capitalize }}
                </nz-form-label>
                <nz-select
                  #multiSelectButtonMonth
                  class="beddy-multi-select"
                  [nzDropdownRender]="render"
                  id="selectAllNoValue"
                  [nzMaxTagCount]="0"
                  [nzMaxTagPlaceholder]="selectedCount"
                  nzAllowClear
                  style="width: 100%"
                  nzMode="multiple"
                  nzPlaceHolder="{{
                    'months_selected' | translate | capitalize
                  }} :
        {{
                    form.get('months').value?.length === 0 ||
                    form.get('months').value?.length === 12
                      ? ('all' | translate)
                      : form.get('months').value?.length
                  }}"
                  [ngModel]="form.get('months').value"
                  (ngModelChange)="selectAllMonth($event)"
                  [nzDropdownClassName]="'filterSelectAllSpan'"
                >
                  <nz-option nzCustomContent nzValue="all">
                    {{ 'select_all' | translate | capitalize }}
                  </nz-option>

                  <nz-option
                    *ngFor="let month of months"
                    [nzLabel]="month.label | upperfirst"
                    [nzValue]="month.value"
                  ></nz-option>
                  <ng-template #selectedCount>
                    <span class="ant-select-selection__placeholder">
                      {{ 'months_selected' | translate | capitalize }} :
                      {{
                        form.get('months').value?.length === 0 ||
                        form.get('months').value?.length === 12
                          ? ('all' | translate)
                          : form.get('months').value?.length
                      }}
                    </span>
                  </ng-template>
                </nz-select>
                <ng-template #render>
                  <nz-divider style="margin: 4px 0"></nz-divider>
                  <div class="container-button-multiselect">
                    <button
                      nz-button
                      (click)="multiSelectButtonMonth.nzOpen = false"
                      nzType="primary"
                    >
                      {{ 'button-multiselect-ok' | translate | capitalize }}
                    </button>
                  </div>
                </ng-template>
              </nz-form-item>
            </div>
          </ng-template>

          <ng-template #multipleAccommodation>
            <nz-select
              #multiSelectButton
              class="beddy-multi-select"
              [nzDropdownRender]="render"
              id="selectAllNoValue"
              nzAllowClear
              style="width: 100%"
              nzMode="multiple"
              nzPlaceHolder="{{
                'accommodations_selected' | translate | capitalize
              }}: {{ form.get('accommodations').value?.length || 0 }}"
              [nzMaxTagCount]="0"
              [nzMaxTagPlaceholder]="selectedCount"
              [ngModel]="form.get('accommodations').value"
              (ngModelChange)="selectAllAccommodations($event)"
              [nzDropdownClassName]="'filterSelectAllSpan'"
              [nzShowArrow]="true"
            >
              <nz-option nzCustomContent nzValue="all">
                <span class="color--azure">{{
                  'select_all' | translate | capitalize
                }}</span>
              </nz-option>

              <ng-container
                *ngFor="
                  let propertyId of filtersData.get('accommodations') | keys
                "
              >
                <ng-container
                  *ngIf="
                    filtersData.get('accommodations')[
                      propertyId
                    ] as _accommodations
                  "
                >
                  <ng-container *ngIf="_accommodations.length; else onlyoption">
                    <nz-option-group [nzLabel]="nameProperty">
                      <ng-template #nameProperty>
                        <i class="by-mr-2 fal fa-home-lg-alt"></i>
                        {{ propertiesNames[propertyId] }}
                      </ng-template>
                      <nz-option
                        *ngFor="let accommodation of _accommodations"
                        [nzLabel]="accommodation.name"
                        [nzValue]="accommodation.id"
                      ></nz-option>
                    </nz-option-group>
                  </ng-container>
                  <ng-template #onlyoption>
                    <nz-option
                      *ngFor="let accommodation of _accommodations"
                      [nzLabel]="accommodation.name"
                      [nzValue]="accommodation.id"
                    ></nz-option>
                  </ng-template>
                </ng-container>
              </ng-container>
              <ng-template #selectedCount>
                <span class="ant-select-selection__placeholder">
                  {{ 'accommodations_selected' | translate | capitalize }} :
                  {{ form.get('accommodations').value?.length }}
                </span>
              </ng-template>
            </nz-select>
            <ng-template #render>
              <nz-divider style="margin: 4px 0"></nz-divider>
              <div class="container-button-multiselect">
                <button
                  nz-button
                  (click)="multiSelectButton.nzOpen = false"
                  nzType="primary"
                >
                  {{ 'button-multiselect-ok' | translate | capitalize }}
                </button>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="buttons-box">
        <button
          [disabled]="!form.valid"
          (click)="onSearch()"
          type="submit"
          nz-button
          nzType="primary"
          class="btn-no-style ant-btn-icon-only"
        >
          <i style="font-size: 25px; color: #fff" class="far fa-search"></i>
        </button>
        <button
          class="btn-no-style ant-btn-icon-only"
          nz-button
          (click)="clear()"
        >
          <i style="font-size: 25px; color: #fff" class="far fa-redo"></i>
        </button>
      </div>
    </div>
  </nz-card>
</div>
