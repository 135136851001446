<div nz-row>
  <div nz-col>
    <span *ngIf="label" class="by-mr-3 bold nowrap-text">{{ label }}:</span>
    <div
      *ngIf="!isHtmlString"
      class="text by-mr-5"
      [style.display]="display"
      style="word-break: break-word"
      [@slideInOut]="collapsedStatus"
      [innerText]="
        (collapsedStatus === 'expand' ? originalText : clippedText) || ''
      "
    ></div>
    <by-external-template-viewer
      *ngIf="isHtmlString"
      class="text by-mr-5"
      [style.display]="display"
      [byStyle]="{
        display: display,
        'word-break': 'break-word'
      }"
      style="word-break: break-word"
      [@slideInOut]="collapsedStatus"
      [template]="
        (collapsedStatus === 'expand' ? originalText : clippedText) || ''
      "
      [bypassSanitizer]="bypassSanitizer"
    ></by-external-template-viewer>
    <span
      *ngIf="showReadMore && inline"
      (click)="toggleCollapse()"
      class="expand-label by-mt-5"
      style="white-space: nowrap"
      >{{
        (collapsedStatus === 'close' ? showMessage : hideMessage)
          | translate
          | upperfirst
      }}</span
    >
  </div>
</div>
<div nz-row *ngIf="showReadMore && !inline" nzJustify="end">
  <div nz-col>
    <div
      (click)="toggleCollapse()"
      class="expand-label by-mt-5 text-align--end"
    >
      {{
        (collapsedStatus === 'close' ? showMessage : hideMessage)
          | translate
          | upperfirst
      }}
    </div>
  </div>
</div>
