import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  Property,
  TableauDraggingItem,
  TableauHoveredCell,
  TableauPeriod,
  TableauReservation,
  TableauRow,
  TableauSelection,
  TableauViewMode,
  TableauViewOptions,
} from '../../../../models';
import { TableauRowComponents } from '../tableau-components-map';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[by-tableau-row-resolver]',
  templateUrl: './tableau-row-resolver.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauRowResolverComponent {
  @Input()
  row: TableauRow;

  @Input()
  days: Date[];

  @Input()
  property: Property;

  @Input()
  userCanWrite: boolean;

  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  channelsColors: Record<number, string>;

  @Input()
  viewOptions: TableauViewOptions;

  @Input()
  viewMode: TableauViewMode;

  @Input()
  zoom: number;

  @Input()
  hoveredCell: TableauHoveredCell;

  @Input()
  hoveredReservation: TableauReservation;

  @Input()
  selection: TableauSelection;

  @Input()
  collapsedAccommodations: Record<number, boolean>;

  @Input()
  searchValue: string;

  @Input()
  splitMode: boolean;

  @Input()
  period: TableauPeriod;

  @Input()
  draggingItem: TableauDraggingItem;

  @Input()
  isMobile: boolean;

  readonly Components = TableauRowComponents;

  constructor() {}
}
