<div cdkDropListGroup>
  <div #scrollParent class="by-tableau full-page-feature">
    <by-spin [nzSpinning]="loading$ | async">
      <nz-table
        #virtualTable
        [nzVirtualItemSize]="36"
        [nzVirtualMaxBufferPx]="1500"
        [nzVirtualMinBufferPx]="1500"
        [nzVirtualForTrackBy]="virtualTrackBy"
        [nzData]="rows"
        [nzFrontPagination]="false"
        [nzShowPagination]="false"
        [nzWidthConfig]="tableWidthConfig"
        [ngStyle]="{
          width: scrollParent.clientWidth + 'px',
          maxWidth: TableauConfig.MaxWidth + 'px'
        }"
        [nzScroll]="{
          y: tableHeight + 'px'
        }"
      >
        <by-tableau-header
          [days]="days"
          [zoom]="zoom"
          [hoveredCell]="hoveredCell"
          [isMobile]="isMobile"
          [period]="period"
          (startDateChange)="setHorizontalScroll(0); onStartDateChange($event)"
          (movePeriod)="onMovePeriod($event)"
          (reload)="onReload(); pushSuccessNotification()"
        ></by-tableau-header>
        <tbody *ngIf="showTableau">
          <ng-template nz-virtual-scroll let-index="index" let-data>
            <tr
              [id]="data.id"
              cdkDropList
              [cdkDropListData]="data"
              [cdkDropListSortingDisabled]="true"
              by-tableau-row-resolver
              [row]="data"
              [days]="days"
              [property]="properties[data.propertyId]"
              [userCanWrite]="userCanWrite[data.propertyId]"
              [reservationsColors]="reservationsColors"
              [channelsColors]="channelsColors"
              [viewOptions]="viewOptions"
              [zoom]="zoom"
              [hoveredCell]="hoveredCell"
              [hoveredReservation]="hoveredReservation"
              [selection]="selection"
              [viewMode]="viewMode"
              [collapsedAccommodations]="collapsedAccommodations"
              [searchValue]="searchValue"
              [splitMode]="splitMode"
              [period]="period"
              [draggingItem]="draggingItem"
              [isMobile]="isMobile"
            ></tr>
          </ng-template>
        </tbody>
      </nz-table>
    </by-spin>

    <div class="by-tableau__options-drawer-buttons">
      <button
        class="by-tableau__options-drawer-button"
        [style.transform]="
          'translateX(' + optionsButtonLabel?.clientWidth + 'px)'
        "
        nz-button
        nzSize="large"
        (click)="optionsDrawer.open()"
      >
        <i
          *ngIf="!(isTagFilterApplied$ | async)"
          class="fas fa-exclamation color--orange-light by-mr-5"
          nz-tooltip
          [nzTooltipTitle]="'filter_applied' | translate | upperfirst"
        ></i>
        <i class="fad fa-cog color--azure by-fs-20 by-mr-5"></i>
        <span
          #optionsButtonLabel
          class="by-tableau__options-drawer-button--label"
          >{{ 'options' | translate | upperfirst }}</span
        >
      </button>
    </div>
  </div>

  <div
    class="by-tableau__fixed-bottom-area"
    [class.by-tableau__fixed-bottom-area--mobile]="isMobile"
  >
    <by-tableau-swap-room
      [swappedReservations]="swappedReservations$ | async"
      [reservationsColors]="reservationsColors"
      [channelsColors]="channelsColors"
      [viewOptions]="viewOptions"
      [zoom]="zoom"
      [isMobile]="isMobile"
    ></by-tableau-swap-room>
  </div>

  <nz-drawer
    #optionsDrawer
    nzPlacement="right"
    [nzWidth]="280"
    (nzOnClose)="optionsDrawer.close()"
  >
    <ng-container *nzDrawerContent>
      <nz-tabset class="noselectText">
        <nz-tab [nzTitle]="'options' | translate | upperfirst">
          <by-tableau-toolbar
            [isMobile]="isMobile"
            [viewOptions]="viewOptions"
            [viewMode]="viewMode"
            [zoom]="zoom"
            [tags]="tags$ | async"
            [fullscreen]="fullscreen$ | async"
            [splitMode]="splitMode"
            [activeChannels]="activeChannels$ | async"
            [propertiesSelectTemplate]="propertiesSelectTemplate"
            [accommodations]="accommodations$ | async"
            [propertiesNames]="propertiesNames"
            [filteredAccommodations]="filteredAccommodations$ | async"
            [exportLoading]="(exportLoading$ | async) || (loading$ | async)"
            (startDateChange)="
              setHorizontalScroll(0); onStartDateChange($event)
            "
            (fullscreenChange)="onFullscreenChange($event)"
            (propertiesChange)="load($event)"
          >
          </by-tableau-toolbar>
        </nz-tab>
        <nz-tab [nzTitle]="'legend' | translate | upperfirst">
          <by-tableau-legend
            [paymentStatusColors]="userPaymentStatusColor$ | async"
            [reservationsColors]="reservationsColors"
            [tags]="tags$ | async"
            [activeChannels]="activeChannels$ | async"
          ></by-tableau-legend>
        </nz-tab>
      </nz-tabset>
    </ng-container>
  </nz-drawer>
</div>

<div [hidden]="true">
  <ng-container *ngTemplateOutlet="propertiesSelectTemplate"></ng-container>
</div>

<ng-template #propertiesSelectTemplate>
  <by-properties-select
    [remember]="true"
    (propertiesSelected)="load($event)"
    type="multiple"
    [minWidth]="null"
    [skipLocalStorageProperties]="false"
    [featurePermission]="{ permission: 'tableau' }"
  ></by-properties-select>
</ng-template>
