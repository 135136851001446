import { NgModule } from '@angular/core';

import { CoreModule } from '../../core/core.module';

import { EventLabelPipe } from './event-label.pipe';

@NgModule({
  declarations: [EventLabelPipe],
  imports: [CoreModule],
  exports: [EventLabelPipe],
})
export class EventLabelModule {}
