import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, upperFirst } from 'lodash';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { PinInputComponent } from '../../components/pin-input/pin-input.component';
import { LoadUserPropertiesRequest } from '../../core/+state/core.actions';
import { TokenService } from '../../core/services/token.service';
import { UserMeService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';
import { ActiveModulesStoreActions } from '../active-modules-store';
import { BookingWidgetsStoreActions } from '../booking-widgets-store';
import { RootState } from '../root-state';
import { UserMePermissionsStoreActions } from '../user-me-permissions-store';
import { UserPreferencesStoreActions } from '../user-preferences-store';

import * as fromActions from './actions';

@Injectable()
export class UserMeStoreEffects {
  constructor(
    private dataService: UserMeService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notification: NotificationService,
    private translate: TranslateService,
    private tokenService: TokenService,
    private router: Router,
    private modalService: NzModalService,
    private store: Store<RootState>,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ redirect, skipWorkspaceSelection, selectedPropertyId }) =>
        this.dataService.load().pipe(
          mergeMap((response: IResponseSuccess) => {
            const userMe = response.data[0];

            const { id, roles, lang_iso_code, preferences, status } = userMe;

            if (!status) {
              this.router.navigate(['/properties-not-found']);
              return [
                fromActions.loadSuccess({
                  item: response.data[0],
                }),
              ];
            }

            const isCommonUser = roles.find(({ slug }) => slug === 'user');

            if (redirect && !isCommonUser) {
              this.router.navigate(['admin/properties']);
              return [
                fromActions.loadSuccess({
                  item: response.data[0],
                }),
              ];
            }

            const changeTranslationHandler = (
              _skipWorkspaceSelection: boolean,
              _redirect: string,
            ) => {
              moment.locale(this.translate.currentLang);

              this.store.dispatch(
                UserMePermissionsStoreActions.loadUserMePermissionsRequest({
                  userId: response.data[0].id,
                }),
              );

              if (isCommonUser) {
                // this.store.dispatch(
                //   new LoadAllUserPropertiesRequest({ userId: id }),
                // );
                this.store.dispatch(
                  new LoadUserPropertiesRequest({
                    userId: id,
                    redirect: _redirect,
                    skipWorkspaceSelection: _skipWorkspaceSelection,
                    selectedPropertyId,
                  }),
                );
              }
            };

            this.translate
              .use(get(preferences, 'general.lang', lang_iso_code))
              .subscribe(() =>
                changeTranslationHandler(skipWorkspaceSelection, redirect),
              );

            this.translate.onLangChange.subscribe(() =>
              changeTranslationHandler(true, null),
            );

            return [
              fromActions.loadSuccess({
                item: response.data[0],
              }),
            ];
          }),
          catchError((error) => {
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  private updatePinModalRef: NzModalRef<PinInputComponent>;
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map((response: IResponseSuccess) => {
            this.updatePinModalRef?.close();

            this.notification.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('user'),
              }),
              type: 'success',
            });
            return fromActions.updateSuccess({
              item: response.data[0],
            });
          }),
          catchError((response: HttpErrorResponse) => {
            // Email verification required
            if (response.status === 409) {
              this.updatePinModalRef = this.openPinModal(
                (email_verification_code) => {
                  this.store.dispatch(
                    fromActions.updateRequest({
                      request: { ...request, email_verification_code },
                      noLoading: true,
                    }),
                  );
                },
              );

              return of(
                fromActions.updateFailure({ error: 'verification_required' }),
              );
            }

            // Wrong email verification code
            if (
              this.updatePinModalRef &&
              response.status === 422 &&
              response.error.meta.errors.email_verification_code
            ) {
              const component = this.updatePinModalRef.componentInstance;
              component.saving = false;
              component.error = upperFirst(
                this.translate.instant('pin_not_valid'),
              );

              return of(
                fromActions.updateFailure({ error: 'verification_required' }),
              );
            }

            this.errorHandler.handle(response);
            return of(fromActions.updateFailure(response));
          }),
        ),
      ),
    ),
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changePasswordRequest),
      switchMap(({ request }) =>
        this.dataService.changePassword(request).pipe(
          map(() => {
            this.notification.push({
              title: this.translate.instant('done'),
              content: this.translate.instant(
                'messagere_reset_password_complete_email',
              ),
              type: 'success',
            });

            return fromActions.changePasswordSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.changePasswordFailure(error));
          }),
        ),
      ),
    ),
  );

  cleanup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cleanup),
      mergeMap(() => [
        new UserPreferencesStoreActions.ResetSuccessAction(),
        UserMePermissionsStoreActions.resetUserMePermissionsState(),
        ActiveModulesStoreActions.resetActiveModulesState(),
        BookingWidgetsStoreActions.resetState(),
      ]),
    ),
  );

  private openPinModal(onSave: (pin: string) => void): NzModalRef {
    const modal: NzModalRef = this.modalService.create<
      PinInputComponent,
      Partial<PinInputComponent>
    >({
      nzTitle: upperFirst(this.translate.instant('verification_pin')),
      nzContent: PinInputComponent,
      nzData: {
        description: upperFirst(
          this.translate.instant('email_otp_description'),
        ),
      },
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          onClick: () => modal.close(),
        },
        {
          label: upperFirst(this.translate.instant('save')),
          type: 'primary',
          loading: ({ saving }) => saving,
          disabled: ({ pin }) => pin.invalid,
          onClick: (component) => {
            component.saving = true;
            component.error = null;
            onSave(component.pin.value);
          },
        },
      ],
    });

    return modal;
  }
}
