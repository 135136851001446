<div [formGroup]="form" class="by-reservation-form-dates">
  <div>
    <div>{{ dateFromLabel || ('arrival_date' | translate | upperfirst) }}</div>
    <nz-date-picker
      byDisabledMobileKeyboard
      nzBorderless
      [nzAllowClear]="false"
      [nzSuffixIcon]="null"
      nzFormat="dd/MM/yyyy"
      nzSize="large"
      formControlName="from"
      class="by-reservation-form-dates__picker color--white bold pointer"
    ></nz-date-picker>
  </div>

  <div>
    <i class="far fa-angle-double-right color--white"></i>
  </div>

  <div>
    <div>{{ dateToLabel || ('departure_date' | translate | upperfirst) }}</div>
    <nz-date-picker
      byDisabledMobileKeyboard
      nzBorderless
      [nzAllowClear]="false"
      [nzSuffixIcon]="null"
      [nzDisabledDate]="disableDateTo"
      nzFormat="dd/MM/yyyy"
      nzSize="large"
      formControlName="to"
      class="by-reservation-form-dates__picker color--white bold pointer"
    ></nz-date-picker>
  </div>

  <div *ngIf="showNights" class="text-align--center by-ml-10">
    <div>{{ 'nights' | translate | upperfirst }}</div>
    <div class="color--white bold by-fs-15">
      {{ nights }}
    </div>
  </div>
</div>
