<div
  class="by-tableau-note"
  nz-tooltip
  [nzTooltipTitle]="triggerTooltip ? data.title : null"
  nzTooltipPlacement="right"
  (contextmenu)="onOpenContextMenu($event, contextMenu)"
  (click)="onOpenNote()"
  (mousedown)="onMouseDownTooltip()"
>
  <span>{{ data.title | uppercase }}</span>
</div>

<nz-dropdown-menu #contextMenu="nzDropdownMenu">
  <ul nz-menu nzInDropDown (nzClick)="onCloseContextMenu()">
    <li nz-menu-item (click)="onOpenNote()">
      {{ 'open' | translate | capitalize }}
    </li>

    <li
      *ngIf="userCanWrite"
      nz-menu-item
      nz-popconfirm
      [nzPopconfirmTitle]="
        'actions.delete' | translate: { param: 'note' | translate }
      "
      (nzOnConfirm)="onDeleteNote()"
    >
      {{ 'delete' | translate | capitalize }}
    </li>
  </ul>
</nz-dropdown-menu>
