<by-tableau-modal-header
  [data]="header"
  [reservationsColors]="reservationsColors"
  [loading]="loading"
></by-tableau-modal-header>

<div class="notes">
  <div *ngIf="reservationRoom?.note_customer?.length > 0">
    <div
      [originalText]="reservationRoom?.note_customer"
      [inline]="true"
      [maxLength]="100"
      by-collapsable-text
      hideMessage="hide"
      showMessage="more"
      [label]="'notes_for_guest' | translate | upperfirst"
    ></div>
  </div>

  <div *ngIf="reservationRoom?.note_internal?.length > 0">
    <div
      [originalText]="reservationRoom?.note_internal"
      [inline]="true"
      [maxLength]="100"
      by-collapsable-text
      hideMessage="hide"
      showMessage="more"
      [label]="'note_internal' | translate | upperfirst"
    ></div>
  </div>

  <div class="by-flex" *ngIf="reservationRoom?.label_name">
    <div class="by-mr-2px bold">{{ 'group' | translate | upperfirst }}:</div>
    <div>{{ reservationRoom?.label_name }}</div>
  </div>
  <div class="by-flex" *ngIf="isOptionable">
    <div class="by-mr-2px bold">
      {{ 'expiration_quote_option' | translate | upperfirst }}:
    </div>
    <div>
      {{ reservationRoom?.expiration_date | toDate | date: 'dd/MM/yyyy' }}
    </div>
  </div>
</div>

<by-tableau-modal-prices
  [data]="reservationRoom"
  [loading]="loading"
></by-tableau-modal-prices>

<div class="status">
  <nz-select
    class="by-fs-12 status__select"
    [nzDropdownMatchSelectWidth]="false"
    [(ngModel)]="checkinCheckout"
    (ngModelChange)="checkinCheckoutUpdated($event)"
    [nzDisabled]="!userCanWrite"
    [nzLoading]="loading"
  >
    <nz-option
      nzLabel="{{ 'arriving' | translate | capitalize }}"
      nzValue="arriving"
    ></nz-option>
    <nz-option
      nzLabel="{{ 'checkin_success' | translate | capitalize }}"
      nzValue="checkin"
    ></nz-option>
    <nz-option
      nzLabel="{{ 'checkout_success' | translate | capitalize }}"
      nzValue="checkout"
    ></nz-option>
  </nz-select>

  <div class="status__info">
    <b>
      <span>{{ 'payment_status' | translate | titlecase }}:</span>
    </b>
    <br />
    <ng-container
      *ngTemplateOutlet="
        !loading ? paymentStatusTpl : skeleton;
        context: skeletonContext
      "
    ></ng-container>
    <ng-template #paymentStatusTpl>
      <span class="by-mr-2">{{
        reservationRoom?.payment_status + '-details' | translate | upperfirst
      }}</span>
    </ng-template>
  </div>
</div>
<div *nzModalFooter nz-row nzJustify="end" class="footer by-flex-middle">
  <ng-container
    *ngTemplateOutlet="
      !loading ? footerTpl : skeleton;
      context: skeletonContext
    "
  ></ng-container>

  <ng-template #footerTpl>
    <div class="footer__content">
      <by-tag-select
        [tags]="tags"
        [disabled]="!userCanWrite"
        [(ngModel)]="tagSelectedId"
        (ngModelChange)="attachTag($event)"
      ></by-tag-select>
      <div>
        <i
          nz-tooltip
          nzTooltipTitle="{{ 'linked_split' | translate | capitalize }}"
          *ngIf="reservationRoom?.split_references?.length"
          class="fas fa-link color--red"
        ></i>
      </div>
      <div class="footer__content__actions">
        <div
          nz-tooltip
          [nzTooltipTitle]="keepAccommodationTitle"
          (click)="onSetKeepAccommodation()"
          class="by-mr-2"
        >
          <i
            class="fas pointer"
            [class.color--grey-dark]="!reservationRoom.keep_accommodation"
            [class.color--red]="reservationRoom.keep_accommodation"
            [class.fa-lock]="reservationRoom.keep_accommodation"
            [class.fa-unlock]="!reservationRoom.keep_accommodation"
          ></i>
        </div>

        <ng-template #keepAccommodationTitle>
          <span
            [innerHtml]="
              (reservationRoom.keep_accommodation
                ? 'keep_accommodation_active'
                : 'keep_accommodation_not_active'
              )
                | translate
                | upperfirst
            "
          ></span>
        </ng-template>

        <div (click)="goToReservation()">
          <i
            nz-tooltip
            [nzTooltipTitle]="
              reservationDetailsTooltipMsg | translate | capitalize
            "
            class="by-mr-2 pointer color--azure"
            [ngClass]="reservationDetailsIcon"
          ></i>
        </div>
        <i
          nz-tooltip
          nzTooltipTitle="{{ 'bills' | translate | capitalize }}"
          (click)="openBillModal()"
          class="by-mr-2 fas fa-money-bill-wave-alt pointer by-fs-15 action"
          *ngIf="userCanWrite"
        ></i>
        <div (click)="goToReservationBills()">
          <i
            nz-tooltip
            nzTooltipTitle="{{ 'charges_2' | translate | capitalize }}"
            class="by-mr-2 pointer fas fa-file-invoice color--azure"
          ></i>
        </div>
        <div class="action" (click)="goToReservationPayments()">
          <i
            nz-tooltip
            nzTooltipTitle="{{ 'payments_documents' | translate | capitalize }}"
            class="by-mr-2 pointer fas fa-cash-register"
          ></i>
        </div>

        <i
          (click)="onSplit()"
          nz-tooltip
          nzTooltipTitle="{{ 'split' | translate | capitalize }}"
          class="by-mr-2 pointer fas fa-cut color--azure"
          *ngIf="userCanWrite"
        ></i>

        <div (click)="onExport()" class="action" class="print-button">
          <i
            nz-tooltip
            nzTooltipTitle="{{ 'print' | translate | capitalize }}"
            class="by-mr-2 pointer fas fa-print"
          ></i>
        </div>
        <div
          nz-dropdown
          nzTrigger="click"
          class="pointer"
          [nzDropdownMenu]="menu"
          style="padding: 0 5px"
          nzPlacement="bottomRight"
          *ngIf="userCanWrite"
        >
          <i class="fas fa-ellipsis-v"></i>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li
              nz-menu-item
              (click)="goToReservationMessages()"
              class="color--blue"
            >
              <i class="fas fa-comments by-mr-5 color--orange"></i>

              {{ 'go_to_reservation_messages' | translate | capitalize }}
            </li>
            <li
              nz-menu-item
              *ngIf="userCanWrite"
              (click)="onResendEmail()"
              class="color--blue"
            >
              <i class="fas fa-envelope by-mr-5 color--azure"></i>
              {{ 'resend_reservation_email' | translate | capitalize }}
            </li>
            <li
              nz-menu-item
              *ngIf="
                userCanWrite &&
                reservationRoom?.channel?.id === beddyChannelId &&
                showDeleteRoom
              "
              (click)="onDeleteRoom()"
              class="color--blue"
            >
              <i class="fas fa-trash-alt by-mr-5 color--red"></i>
              {{ 'delete_accommodation' | translate | upperfirst }}
            </li>
            <li
              nz-menu-item
              *ngIf="
                userCanWrite && reservationRoom?.channel?.id === beddyChannelId
              "
              (click)="onDeleteReservation()"
              class="color--blue"
            >
              <i class="fas fa-trash-alt by-mr-5 color--red"></i>
              {{ 'cancel_reservation' | translate | upperfirst }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #skeleton let-style="style" let-nzParagraph="nzParagraph">
  <nz-skeleton
    [nzActive]="true"
    [nzParagraph]="nzParagraph"
    [nzTitle]="false"
    [ngStyle]="style"
  ></nz-skeleton>
</ng-template>
