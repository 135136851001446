import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomaticPaymentSummary } from '../../models';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyFormatComponent } from '../../ui/currency-format/currency-format.component';
import { FormatDatePipe } from '../../pipes/format-date/format-date.pipe';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { IsTodayPipe } from '@app/pipes/is-today.pipe';

@Component({
  selector: 'by-automatic-payment-summary',
  standalone: true,
  imports: [
    IsTodayPipe,
    CommonModule,
    NzTableModule,
    NzAlertModule,
    FormatDatePipe,
    TranslateModule,
    NgUpperFirstPipeModule,
    CurrencyFormatComponent,
  ],
  templateUrl: './automatic-payment-summary.component.html',
  styleUrl: './automatic-payment-summary.component.scss',
})
export class AutomaticPaymentSummaryComponent {
  @Input({ required: true }) automaticPaymentSummary: AutomaticPaymentSummary;

  @Input({ required: true }) currency = '€';

  @Input() showWarning = true;
}
