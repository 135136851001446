import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { groupBy } from 'lodash';
import * as moment from 'moment';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { SubSink } from 'subsink';

import { TableauHoveredCell, TableauPeriod } from '../../../../models';
import { TableauConfig } from '../config';
import { getMonthsFromDays } from '../helpers/get-months-from-days';
import { Month } from '../models/month';
import { TableauSearchService } from '../services/tableau-search.service';

@Component({
  selector: 'by-tableau-header',
  templateUrl: './tableau-header.component.html',
  styleUrls: ['./tableau-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauHeaderComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild('mobileCalendarButton', { read: NzPopoverDirective })
  mobileCalendarPopover: NzPopoverDirective;

  @Input()
  days: Date[];

  @Input()
  zoom: number;

  @Input()
  hoveredCell: TableauHoveredCell;

  @Input()
  isMobile: boolean;

  @Input()
  period: TableauPeriod;

  @Output()
  startDateChange = new EventEmitter<Date>();

  @Output()
  movePeriod = new EventEmitter<number>();

  @Output()
  reload = new EventEmitter();

  months: Month[];

  columnWidth: string;

  today = new Date();

  yesterday = moment(this.today).subtract(1, 'day').toDate();

  startPeriodDate = this.formBuilder.control(null);

  searchControl = this.formBuilder.control(null);

  private subs = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tableauSearchService: TableauSearchService,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.startPeriodDate.valueChanges.subscribe((startDate) =>
        this.onStartDateChange(startDate),
      ),
    );

    this.subs.add(
      this.searchControl.valueChanges.subscribe((value) => {
        this.tableauSearchService.search(value);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { days, zoom, period } = changes;

    if (days && this.days) {
      this.setMonths();
    }

    if (zoom && this.zoom) {
      this.setColumnWidth();
    }

    if (period && this.period) {
      this.setPeriodStartDate();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onStartDateChange(date: Date) {
    this.mobileCalendarPopover?.hide();
    this.startDateChange.emit(date);
  }

  onSearchReset() {
    this.searchControl.reset();
  }

  private setColumnWidth() {
    const zoom = this.zoom || 1;
    this.columnWidth = `${TableauConfig.CellWidth * zoom}px`;
  }

  private setMonths() {
    this.months = getMonthsFromDays(this.days);
  }

  private setPeriodStartDate() {
    this.startPeriodDate.setValue(this.period.from, { emitEvent: false });
  }
}
