<ng-container [formGroup]="form">
  <nz-form-item class="by-reservation-form__item">
    <nz-form-label class="required-input-red">
      {{ 'adults_number' | translate | upperfirst }} ({{ 'max' | translate }}
      {{ accommodation?.max_adults }})
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.adults_number.errors | keys | head)
          | translate: { param: 'adults_number' | translate }
      "
    >
      <nz-input-number
        [nzSize]="inputSize"
        byNumberOnly
        formControlName="adults_number"
        [nzMin]="1"
        [nzStep]="1"
        [nzParser]="adultsParser"
        (nzBlur)="onTouched()"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    *ngIf="childrenRanges?.length"
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label>
      {{ 'children_number' | translate | upperfirst }} ({{ 'max' | translate }}
      {{ accommodation?.max_children }})
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
    >
      <nz-input-number
        [nzSize]="inputSize"
        byNumberOnly
        [formControl]="childrenNumberControl"
        [nzMin]="0"
        [nzStep]="1"
        [nzParser]="childrenParser"
        (nzBlur)="onTouched()"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <div class="by-reservation-form__children">
    <nz-form-item
      *ngFor="let childControl of childrenControl.controls; let index = index"
      class="by-reservation-form__item"
    >
      <nz-form-label>
        {{ 'child' | translate | upperfirst }} {{ index + 1 }}
      </nz-form-label>
      <nz-form-control
        class="by-reservation-form__control"
        [class.by-full-width-control]="isMobile"
        [formGroup]="childControl"
      >
        <nz-select
          [nzDropdownMatchSelectWidth]="false"
          [nzSize]="inputSize"
          class="input--select-medium"
          nzPlaceHolder="{{
            'placeholder.select' | translate: { param: 'children' | translate }
          }}"
          formControlName="age"
        >
          <nz-option
            *ngFor="let childrenRange of childrenRanges"
            [nzLabel]="
              childrenRange.combination.from +
              ' - ' +
              childrenRange.combination.to
            "
            [nzValue]="childrenRange.combination.to"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-container>
