import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TableauSearchService {
  private search$ = new Subject<string>();

  search(value: string) {
    this.search$.next(value);
  }

  getSearch() {
    return this.search$.asObservable();
  }
}
