import { ListRange } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, get, intersection, mapValues, uniq } from 'lodash';
import * as moment from 'moment';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, take, withLatestFrom } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { CoreActionTypes } from '../../../../core/+state/core.actions';
import { selectAllProperties } from '../../../../core/+state/core.reducer';
import { propertiesIsEqual } from '../../../../core/helpers';
import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import {
  hasHorizontalScroll,
  horizontalScrollFinished,
} from '../../../../helpers';
import {
  Property,
  SetReservationCheckinAction,
  TableauDraggingItem,
  TableauHoveredCell,
  TableauPeriod,
  TableauReservation,
  TableauRow,
  TableauSelection,
  TableauViewMode,
  TableauViewOptions,
} from '../../../../models';
import { BedTypesStoreActions } from '../../../../root-store/bed-types-store';
import { DealersStoreActions } from '../../../../root-store/dealers-store';
import { PlaceVatQuotesSettingsStoreActions } from '../../../../root-store/place-vat-quotes-store';
import { ReservationFromsStoreActions } from '../../../../root-store/reservation-froms-store';
import { ReservationTagStoreActions } from '../../../../root-store/reservation-tag-store';
import { ReservationsStoreActions } from '../../../../root-store/reservations-store';
import { RootState } from '../../../../root-store/root-state';
import { TableauReservationDetailsStoreActions } from '../../../../root-store/tableau-reservation-details-store';
import { TableauActions } from '../../../../root-store/tableau-store';
import {
  AppShellService,
  FullscreenService,
  GlobalTopUpButtonService,
} from '../../../../services';
import { clearLocalStorageProperties } from '../../../../shared/properties-select/properties-select.component';
import { IBreadcrumb } from '../../../../ui/breadcrumb/models/breadcrumb.model';
import { NotificationService } from '../../../../ui/services/notification.service';
import { TableauConfig } from '../config';
import { TableauDataLoaderService } from '../services/tableau-data-loader.service';
import { TableauDataSelectorService } from '../services/tableau-data-selector.service';
import { TableauDragService } from '../services/tableau-drag.service';
import { TableauHoverService } from '../services/tableau-hover.service';
import { TableauSearchService } from '../services/tableau-search.service';
import { TableauSelectionService } from '../services/tableau-selection.service';

const TABLEAU_HEADER_HEIGHT = 80;

@Component({
  selector: 'by-tableau-page',
  templateUrl: './tableau-page.component.html',
  styleUrls: ['./tableau-page.component.scss'],
})
export class TableauPageComponent implements OnInit, OnDestroy, AfterViewInit {
  breadCrumbs: IBreadcrumb[] = [{ routerLink: null, label: 'tableau' }];

  @ViewChild(NzTableComponent)
  tableComponent: NzTableComponent<TableauRow>;

  @ViewChild('scrollParent', { read: ElementRef })
  scrollParentElementRef: ElementRef<HTMLElement>;

  swappedReservations$ = this.selector.selectSwappedReservations();

  loading$ = this.selector.selectLoading();

  tags$ = this.selector.selectTags();

  activeChannels$ = this.selector.selectActiveChannels();

  userPaymentStatusColor$ = this.selector.selectPaymentStatusColor();

  accommodations$ = this.selector.selectMappingAccommodations();

  filteredAccommodations$ = this.selector.selectFilteredAccommodations();

  exportLoading$ = this.selector.selectExportLoading();

  fullscreen$ = this.fullscreen.changes;

  isTagFilterApplied$ = this.selector.selectIsTagFilterApplied();

  period: TableauPeriod;

  isMobile: boolean;

  rows: TableauRow[];

  propertiesIds: number[] = [];

  properties: Record<number, Property> = {};

  propertiesNames: Record<number, string> = {};

  userCanWrite: Record<number, boolean> = {};

  days: Date[];

  reservationsColors: Record<string, string>;

  channelsColors: Record<number, string>;

  viewOptions: TableauViewOptions;

  viewMode: TableauViewMode;

  splitMode: boolean;

  zoom: number;

  hoveredCell: TableauHoveredCell;

  hoveredReservation: TableauReservation;

  selection: TableauSelection;

  collapsedAccommodations: Record<number, boolean>;

  searchValue: string;

  draggingItem: TableauDraggingItem;

  showTableau = true;

  tableWidthConfig: string[];

  appFooterNativeElement: HTMLElement;

  readonly TableauConfig = TableauConfig;

  private subs = new SubSink();

  constructor(
    private actions$: Actions,
    private cdr: ChangeDetectorRef,
    private store: Store<RootState>,
    private fullscreen: FullscreenService,
    private activatedRoute: ActivatedRoute,
    private loader: TableauDataLoaderService,
    private appShellService: AppShellService,
    private notifications: NotificationService,
    private dateFormatter: DateFormatterService,
    private selector: TableauDataSelectorService,
    private tableauDragService: TableauDragService,
    private tableauHoverService: TableauHoverService,
    private tableauSearchService: TableauSearchService,
    private tableauSelectionService: TableauSelectionService,
    private globalTopUpButtonService: GlobalTopUpButtonService,
  ) {
    this.appShellService.footer.setShow(false);
    this.globalTopUpButtonService.hide();
    clearLocalStorageProperties();
  }

  forceRender() {
    this.showTableau = false;
    this.cdr.detectChanges();
    this.showTableau = true;
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.subs.add(
      this.selector.selectRows().subscribe((rows) => {
        this.rows = rows;
        this.checkVirtualScrollViewportSize();
      }),
    );

    this.subs.add(
      this.store
        .select(selectAllProperties)
        .pipe(
          filter((properties) => !!properties?.length),
          take(1),
        )
        .subscribe((properties) => {
          this.properties = properties.reduce(
            (acc, property) => (acc = { ...acc, [property.id]: property }),
            {},
          );

          this.propertiesNames = mapValues(
            this.properties,
            (property) => property.name,
          );

          const allPropertiesIds = properties.map(({ id }) => id);
          this.subscribeToPeriodChanges();
          this.subscribeToPeriodAndLoadedPropertiesChanges();

          this.store.dispatch(
            TableauActions.setPropertiesOrder({
              sortedProperties: allPropertiesIds,
            }),
          );

          this.store.dispatch(
            new PlaceVatQuotesSettingsStoreActions.LoadRequestAction({
              place_id: first(properties)?.country_id,
              pagination: false,
            }),
          );
        }),
    );

    this.subs.add(
      this.selector.selectUserCanWrite().subscribe((userCanWrite) => {
        this.userCanWrite = userCanWrite || {};
      }),
    );

    this.subs.add(
      this.selector.selectDays().subscribe((days) => {
        this.days = days;
        this.setTableWidthConfig();
      }),
    );

    this.subs.add(
      this.selector
        .selectReservationsColors()
        .subscribe((reservationsColors) => {
          this.reservationsColors = reservationsColors;
        }),
    );

    this.subs.add(
      this.selector.selectChannelsColors().subscribe((channelsColors) => {
        this.channelsColors = channelsColors;
      }),
    );

    this.subs.add(
      this.selector.selectZoom().subscribe((zoom) => {
        this.zoom = zoom;
        this.setTableWidthConfig();
      }),
    );

    this.subs.add(
      this.tableauHoverService.getHoveredCell().subscribe((hoveredCell) => {
        this.hoveredCell = hoveredCell;
      }),
    );

    this.subs.add(
      this.tableauHoverService
        .getHoveredReservation()
        .subscribe((hoveredReservation) => {
          this.hoveredReservation = hoveredReservation;
        }),
    );

    this.subs.add(
      this.tableauSelectionService.getSelection().subscribe((selection) => {
        this.selection = selection;
      }),
    );

    this.subs.add(
      this.tableauSearchService.getSearch().subscribe((searchValue) => {
        this.searchValue = searchValue;
      }),
    );

    this.subs.add(
      this.tableauDragService.getDraggingItem().subscribe((draggingItem) => {
        this.draggingItem = draggingItem;
      }),
    );

    this.subs.add(
      this.selector.selectIsMobile().subscribe((isMobile) => {
        this.isMobile = isMobile;

        if (isMobile) {
          document.documentElement.style.overflow = null;
          this.appShellService.header.setShow(false);
        } else {
          document.documentElement.style.overflow = 'hidden';
          this.appShellService.header.setShow(true);
        }

        this.setTableWidthConfig();
      }),
    );

    this.subs.add(
      this.selector.selectViewOptions().subscribe((viewOptions) => {
        this.viewOptions = viewOptions;
      }),
    );

    this.subs.add(
      this.selector.selectViewMode().subscribe((viewMode) => {
        this.viewMode = viewMode;
      }),
    );

    this.subs.add(
      this.selector.selectSplitMode().subscribe((splitMode) => {
        this.splitMode = splitMode;
      }),
    );

    this.subs.add(
      this.selector
        .selectCollapsedAccommodations()
        .subscribe((collapsedAccommodations) => {
          this.collapsedAccommodations = collapsedAccommodations;
        }),
    );

    this.subs.add(
      this.selector
        .selectWindowResize()
        .pipe(
          filter(
            () =>
              !hasHorizontalScroll(this.cdkVirtualScrollViewportNativeElement),
          ),
        )
        .subscribe(() => {
          this.store.dispatch(
            TableauActions.setPeriod({ start: this.period.from }),
          );
        }),
    );

    this.subs.add(
      this.selector.selectWindowMouseUp().subscribe(() => {
        this.tableauSelectionService.end();
      }),
    );

    this.subs.add(
      this.actions$
        .pipe(
          ofType(
            TableauActions.moveQuoteSuccess,
            TableauActions.moveReservationSuccess,

            ReservationsStoreActions.deleteSuccess,

            ReservationsStoreActions.deleteAccommodationSuccess,

            CoreActionTypes.CreateNewReservationSuccess,
          ),
          withLatestFrom(this.selector.selectLoadedProperties()),
        )
        .subscribe(([_, loadedProperties]) => {
          const { from, to } = this.stringifyPeriod;

          this.store.dispatch(
            TableauActions.resetLoadedResources({
              resources: ['availabilities', 'reservations', 'notes', 'quotes'],
            }),
          );

          this.loader.loadReservations(loadedProperties, from, to);
          this.loader.loadAvailabilities(loadedProperties, from, to);
          this.loader.loadNotes(loadedProperties, from, to);
          this.loader.loadQuotes(loadedProperties, from, to);
        }),
    );

    this.subs.add(
      this.actions$
        .pipe(
          ofType(TableauActions.setClosureSuccess),
          withLatestFrom(this.selector.selectLoadedProperties()),
        )
        .subscribe(([_, loadedProperties]) => {
          const { from, to } = this.stringifyPeriod;

          this.store.dispatch(
            TableauActions.resetLoadedResources({
              resources: ['availabilities', 'clousures'],
            }),
          );

          this.loader.loadAvailabilities(loadedProperties, from, to);
          this.loader.loadClousures(loadedProperties, from, to);
        }),
    );

    this.subs.add(
      this.actions$
        .pipe(ofType(TableauActions.forceRender))
        .subscribe(() => this.forceRender()),
    );

    this.subs.add(
      this.actions$
        .pipe(
          ofType(TableauReservationDetailsStoreActions.setCheckinStatusSuccess),
        )
        .subscribe(({ checkinStatus, reservation_id, roomreservation_id }) =>
          this.setReservationCheckinStatus({
            checkinStatus,
            reservation_id,
            roomreservation_id: reservation_id + roomreservation_id,
          }),
        ),
    );

    this.subs.add(
      this.actions$
        .pipe(
          ofType(
            BedTypesStoreActions.ActionTypes.CREATE_SUCCESS,
            BedTypesStoreActions.ActionTypes.UPDATE_SUCCESS,
            BedTypesStoreActions.ActionTypes.DELETE_SUCCESS,
          ),
          debounceTime(500),
        )
        .subscribe((action) => {
          const propertyId = get(action, 'payload.propertyId');

          if (!propertyId) {
            return;
          }

          this.loader.loadHousekeeperInformations(
            [propertyId],
            this.dateFormatter.toServerFormat(new Date()),
          );
        }),
    );

    this.subs.add(
      this.loading$.pipe(debounceTime(2000)).subscribe((loading) => {
        if (!loading) {
          this.cdr.detectChanges();
        }
      }),
    );

    this.store.dispatch(new ReservationFromsStoreActions.LoadRequestAction());

    this.store.dispatch(DealersStoreActions.loadRequest());

    this.store.dispatch(TableauActions.readPreferences());

    this.setInitialPeriod();
  }

  ngAfterViewInit() {
    this.subs.add(
      this.cdkVirtualScrollViewport.renderedRangeStream
        .pipe(debounceTime(500))
        .subscribe((range) => {
          const visiblePropertiesIds =
            this.getVisiblePropertiesFromListRange(range);

          this.store.dispatch(
            TableauActions.setLoadedProperties({
              loadedProperties: visiblePropertiesIds,
            }),
          );
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    this.store.dispatch(TableauActions.resetState());
    this.store.dispatch(new ReservationTagStoreActions.ResetSuccessAction());
    this.store.dispatch(
      new PlaceVatQuotesSettingsStoreActions.ResetSuccessAction(),
    );

    this.globalTopUpButtonService.show();
    document.documentElement.style.overflow = null;

    this.fullscreen.deactive();

    this.appShellService.header.setShow(true);
    this.appShellService.footer.setShow(true);
  }

  load(propertiesIds: number[]) {
    if (
      propertiesIsEqual(this.propertiesIds, propertiesIds) ||
      this.arePropertiesFromDifferentGroup(propertiesIds)
    ) {
      return;
    }

    this.propertiesIds = propertiesIds;

    this.loader.loadMapping(propertiesIds);
    this.loader.loadFloors(propertiesIds);
    this.loader.loadReservationTags(propertiesIds);

    this.loader.loadActiveChannels(propertiesIds);

    if (this.period) {
      this.loader.loadEvents(
        propertiesIds,
        this.stringifyPeriod.from,
        this.stringifyPeriod.to,
      );
    }

    this.store.dispatch(TableauActions.resetLoadedProperties());
  }

  virtualTrackBy(_: number, data: TableauRow) {
    return data.id;
  }

  subscribeToPeriodChanges() {
    this.subs.add(
      this.selector
        .selectPeriod()
        .pipe(debounceTime(100))
        .subscribe((period) => {
          this.period = period;

          this.loader.loadEvents(
            this.propertiesIds,
            this.stringifyPeriod.from,
            this.stringifyPeriod.to,
          );
        }),
    );
  }

  subscribeToPeriodAndLoadedPropertiesChanges() {
    this.subs.add(
      combineLatest([
        this.selector.selectPeriod(),
        this.selector.selectPropertiesToLoad(),
      ])
        .pipe(debounceTime(100))
        .subscribe(([period, propertiesToLoad]) => {
          const stringifyPeriod = this.dateFormatter.formatObjectDates(period);

          this.loader.loadReservations(
            propertiesToLoad,
            stringifyPeriod.from,
            stringifyPeriod.to,
          );

          this.loader.loadQuotes(
            propertiesToLoad,
            stringifyPeriod.from,
            stringifyPeriod.to,
          );

          this.loader.loadNotes(
            propertiesToLoad,
            stringifyPeriod.from,
            stringifyPeriod.to,
          );

          this.loader.loadAvailabilities(
            propertiesToLoad,
            stringifyPeriod.from,
            stringifyPeriod.to,
          );

          this.loader.loadClousures(
            propertiesToLoad,
            stringifyPeriod.from,
            stringifyPeriod.to,
          );

          this.loader.loadHousekeeperInformations(
            propertiesToLoad,
            this.dateFormatter.toServerFormat(new Date()),
          );
        }),
    );
  }

  onStartDateChange(start: Date) {
    this.store.dispatch(TableauActions.resetLoadedResources({}));
    this.reloadVisibleProperties();
    this.store.dispatch(TableauActions.setPeriod({ start }));
  }

  onMovePeriod(directionMultiplier: number) {
    const element = this.cdkVirtualScrollViewportNativeElement;
    this.setHorizontalScroll(
      element.scrollLeft + TableauConfig.CellWidth * directionMultiplier,
    );

    let startDate: Date;

    if (!element.scrollLeft) {
      startDate = moment(this.period.from)
        .subtract(TableauConfig.MovingPeriodDays, 'day')
        .toDate();
    }

    if (horizontalScrollFinished(element)) {
      startDate = moment(this.period.from)
        .add(TableauConfig.MovingPeriodDays, 'day')
        .toDate();
    }

    if (startDate) {
      this.onStartDateChange(startDate);
    }
  }

  onReload() {
    this.store.dispatch(TableauActions.resetLoadedResources({}));
    this.reloadVisibleProperties();
    this.loader.loadEvents(
      this.propertiesIds,
      this.stringifyPeriod.from,
      this.stringifyPeriod.to,
    );
  }

  onFullscreenChange(fullscreen: boolean) {
    fullscreen ? this.fullscreen.active() : this.fullscreen.deactive();
  }

  pushSuccessNotification() {
    this.notifications.success('notifications.generic_success');
  }

  setHorizontalScroll(left: number) {
    this.cdkVirtualScrollViewport
      .getElementRef()
      .nativeElement.scroll({ left, behavior: 'smooth' });
  }

  reloadVisibleProperties() {
    this.store.dispatch(TableauActions.resetLoadedProperties());

    const currentVisibleProperties = this.getVisiblePropertiesFromListRange(
      this.cdkVirtualScrollViewport.getRenderedRange(),
    );

    this.store.dispatch(
      TableauActions.setLoadedProperties({
        loadedProperties: currentVisibleProperties,
      }),
    );
  }

  setReservationCheckinStatus(data: SetReservationCheckinAction) {
    const { checkinStatus, reservation_id, roomreservation_id } = data;

    this.store.dispatch(
      TableauActions.setCheckinStatus({
        reservation_id,
        roomreservation_id,
        checkinStatus,
      }),
    );
  }

  arePropertiesFromDifferentGroup(propertiesIds: number[]): boolean {
    if (this.propertiesIds?.length === 0) {
      return false;
    }
    return (
      intersection(propertiesIds, Object.keys(this.properties).map(Number))
        ?.length === 0
    );
  }

  get windowHeight(): number {
    return window.innerHeight;
  }

  get cdkVirtualScrollViewport() {
    return this.tableComponent?.cdkVirtualScrollViewport;
  }

  get cdkVirtualScrollTotalContentHeight(): number {
    return +this.cdkVirtualScrollViewport?._totalContentHeight.replace(
      'px',
      '',
    );
  }

  get stringifyPeriod(): { from: string; to: string } {
    return this.dateFormatter.formatObjectDates(this.period);
  }

  get tableHeight(): number {
    return (
      this.windowHeight -
        this.scrollParentElementRef?.nativeElement?.getBoundingClientRect()?.y -
        TABLEAU_HEADER_HEIGHT || 0
    );
  }

  private get cdkVirtualScrollViewportNativeElement() {
    return this.cdkVirtualScrollViewport.getElementRef().nativeElement;
  }

  private checkVirtualScrollViewportSize() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => this.cdkVirtualScrollViewport.checkViewportSize(), 0);
      this.cdr.detectChanges();
    }
  }

  private getVisiblePropertiesFromListRange(range: ListRange): number[] {
    return uniq(
      this.rows
        .slice(range.start, range.end + 1)
        .map(({ propertyId }) => propertyId)
        .filter((propertyId) => !!propertyId),
    );
  }

  private setInitialPeriod() {
    const { start } = this.activatedRoute.snapshot.queryParams;

    this.store.dispatch(
      TableauActions.setPeriod({
        start: start ? new Date(start) : moment().subtract(1, 'day').toDate(),
      }),
    );
  }

  private setTableWidthConfig() {
    if (!this.days) {
      return;
    }

    this.tableWidthConfig = [
      this.isMobile ? '80px' : '235px',
      ...this.days.map(() => `${TableauConfig.CellWidth * (this.zoom || 1)}px`),
    ];
  }
}
