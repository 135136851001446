<input
  name="by-beddy-na"
  type="search"
  nz-input
  [nzAutocomplete]="customersTemplate"
  [placeholder]="placeHolder"
  [formControl]="control"
  [nzSize]="size"
  [class]="byClass"
/>
<nz-autocomplete #customersTemplate [nzWidth]="width">
  <ng-container *ngIf="enabled">
    <nz-auto-option
      *ngFor="let customer of customersOptions"
      [nzValue]="customer"
      [nzLabel]="customer | get: ['customer', autocompleteFor]"
    >
      {{ customer.name }}
    </nz-auto-option>
  </ng-container>
</nz-autocomplete>
