import * as moment from 'moment';

type DatePosition = 'between' | 'before' | 'after';

/**
 * @author Sebastiano Maesano
 *
 * @param referanceDate Data per la comparazione
 * @param dateFrom Data di inzio del periodo
 * @param dateTo Data di fine del periodo
 * @returns Posizione della data di riferimento rispetto al periodo
 *
 * @description La funzione ritorna la posizione di una data di riferimento rispetto ad un periodo (prima, in mezzo, dopo).
 * La funzione segue la logica dei days delle reservations:
 * - Se la data di riferimento coincide con l'estremo inferiore (dateFrom), la data viene considerata all'interno del periodo
 * - Se la data di riferimento coincide con l'estremo superiore (dateTo), la data ciene considerata successiva al periodo
 */
export function datePositionInRange(
  referanceDate: Date,
  dateFrom: Date,
  dateTo: Date,
): DatePosition {
  if (moment(referanceDate).isBefore(moment(dateFrom), 'days')) {
    return 'before';
  }

  if (moment(referanceDate).isSameOrAfter(moment(dateTo), 'days')) {
    return 'after';
  }

  return 'between';
}
