import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first, get, upperFirst } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

import { DeleteReservationModalService } from '../../../../components/delete-reservation-modal/delete-reservation-modal.service';
import { ResendReservationEmailModalService } from '../../../../components/resend-reservation-email-modal/resend-reservation-email-modal.service';
import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { openInNewTab } from '../../../../helpers';
import {
  CheckinStatus,
  DeleteReservationData,
  TableauModalHeader,
  TableauModalHeaderLogo,
  TableauReservationDetails,
  TableauReservationTag,
  UpdateReservationKeepAccommodationRequest,
} from '../../../../models';
import { UpdateReservationCheckinCheckoutRequestData } from '../../../../models/requests/update-reservation-checkin-checkout.request';
import { RootState } from '../../../../root-store/root-state';
import { TableauReservationDetailsStoreActions } from '../../../../root-store/tableau-reservation-details-store';
import { TableauActions } from '../../../../root-store/tableau-store';
import { ExportService } from '../../../../services/export.service';
import { AddBillModalService } from '../../add-bill-modal/services/add-bill-modal.service';

const { cdnUrl, storageUrl } = environment;

@Component({
  selector: 'by-tableau-reservation-details-room',
  templateUrl: './tableau-reservation-details-room.component.html',
  styleUrls: ['./tableau-reservation-details-room.component.scss'],
})
export class TableauReservationDetailsRoomComponent implements OnChanges {
  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  loading: boolean;

  @Input()
  reservationRoom: TableauReservationDetails;

  @Input()
  userCanWrite: boolean;

  @Input()
  tags: TableauReservationTag[];

  @Input()
  onSuccess: () => void;

  @Input()
  showDeleteRoom = false;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  setKeepAccommodation = new EventEmitter<UpdateReservationKeepAccommodationRequest>();

  header: TableauModalHeader;

  skeletonContext = {
    nzParagraph: { rows: 1, width: '100%' },
  };

  checkinCheckout: 'checkout' | 'checkin' | 'arriving';

  tagSelectedId: number;

  referenceDate = this.dateFormatter.toServerFormat(new Date());

  beddyChannelId = environment.beddyChannelId;

  constructor(
    private store: Store<RootState>,
    private translate: TranslateService,
    private exportService: ExportService,
    private modalService: NzModalService,
    private dateFormatter: DateFormatterService,
    private addBillModalService: AddBillModalService,
    private deleteReservationModalService: DeleteReservationModalService,
    private resendReservationEmailModalService: ResendReservationEmailModalService,
    @Optional()
    private modalRef: NzModalRef<TableauReservationDetailsRoomComponent>,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { reservationRoom } = changes;

    if (reservationRoom && this.reservationRoom) {
      this.header = this.getHeaderData();
      this.tagSelectedId = this.reservationRoom.tag?.id;
      this.checkinCheckout = this.reservationRoom.checkin_status;
    }
  }

  goToReservationPayments() {
    openInNewTab(
      `/reservation/${this.reservationId}?roomReservationId=${this.roomreservationId}&subTab=4`,
    );
  }

  goToReservationBills() {
    openInNewTab(
      `/reservation/${this.reservationId}?roomReservationId=${this.roomreservationId}&subTab=3`,
    );
  }

  goToReservationMessages() {
    openInNewTab(
      `/reservation/${this.reservationId}?roomReservationId=${this.roomreservationId}&subTab=5`,
    );
  }

  goToReservation() {
    openInNewTab(
      `/reservation/${this.reservationId}?roomReservationId=${this.roomreservationId}`,
    );
  }

  checkinCheckoutUpdated(checkinStatus: CheckinStatus) {
    this.store.dispatch(
      TableauReservationDetailsStoreActions.setCheckinStatusRequest({
        reservation_id: this.reservationRoom.reservation_id,
        roomreservation_id: this.reservationRoom.roomreservation_id,
        checkinStatus: this.setChekinStatus(checkinStatus),
      }),
    );

    if (checkinStatus !== 'arriving') {
      const notes_checkin = get(this.reservationRoom, [
        'notes_checkin',
        0,
        'note',
      ]);
      const notes_checkout = get(this.reservationRoom, [
        'notes_checkout',
        0,
        'note',
      ]);
      const title =
        checkinStatus === 'checkin' ? 'checkin_changed' : 'checkout_changed';
      const message =
        checkinStatus === 'checkin' ? notes_checkin : notes_checkout;
      if (message) {
        this.modalService.info({
          nzTitle: upperFirst(this.translate.instant(title)),
          nzContent: message,
        });
      }
    }
  }

  setChekinStatus(
    checkinStatus: CheckinStatus,
  ): UpdateReservationCheckinCheckoutRequestData {
    if (checkinStatus === 'arriving') {
      return {
        checkin: false,
        checkout: false,
      };
    }

    if (checkinStatus === 'checkin') {
      return {
        checkin: true,
        checkout: false,
      };
    }

    if (checkinStatus === 'checkout') {
      return {
        checkin: true,
        checkout: true,
      };
    }
  }

  attachTag(tagId: number) {
    this.store.dispatch(
      TableauActions.setReservationTagRequest({
        reservation_id: this.reservationId,
        tag: this.tags.find(({ id }) => id === tagId),
      }),
    );
  }

  openBillModal() {
    this.addBillModalService.openWithReservationAccommodationId(
      this.reservationId,
      this.reservationRoom?.reservation_accommodation_id,
      null,
    );
  }

  onExport() {
    const exportLink = `reservations/${this.reservationId}/export?reference_date=${this.referenceDate}`;
    this.exportService.directExport(exportLink);
  }

  onSplit() {
    this.store.dispatch(TableauActions.toggleSplitMode({ splitMode: true }));
    this.closeModal.emit();
  }

  onResendEmail() {
    this.resendReservationEmailModalService.open(
      this.reservationRoom.property_id,
      this.reservationId,
    );
  }

  onDeleteReservation() {
    const reservationData: DeleteReservationData = {
      reservationId: this.reservationId,
      status: this.reservationRoom.status,
      reservationAccommodationId: null,
      availabilityOption: this.reservationRoom.availability_option,
      keepAvailability: this.reservationRoom.keep_availability,
    };

    const onSuccess = () => {
      this.modalRef?.close();
    };

    const modal = this.deleteReservationModalService.openModal(
      reservationData,
      onSuccess,
    );

    modal.afterClose.subscribe((result) => {
      if (result === 'delete') {
        this.closeModal.emit();
      }
    });
  }

  onDeleteRoom() {
    const reservationData: DeleteReservationData = {
      reservationId: this.reservationId,
      status: this.reservationRoom.status,
      reservationAccommodationId:
        this.reservationRoom.reservation_accommodation_id,
      availabilityOption: this.reservationRoom.availability_option,
      keepAvailability: this.reservationRoom.keep_availability,
    };

    const onSuccess = () => {
      this.modalRef?.close();
    };

    const modal = this.deleteReservationModalService.openModal(
      reservationData,
      onSuccess,
    );

    modal.afterClose.subscribe((result) => {
      if (result === 'delete') {
        this.closeModal.emit();
      }
    });
  }

  onSetKeepAccommodation() {
    this.setKeepAccommodation.emit({
      reservation_id: this.reservationId,
      keep_accommodation: !this.reservationRoom.keep_accommodation,
      roomreservation_id: this.reservationRoom?.roomreservation_id,
    });
  }

  get reservationDetailsIcon(): string {
    return this.userCanWrite ? 'fas fa-pen' : 'fas fa-eye';
  }

  get reservationDetailsTooltipMsg(): string {
    return this.userCanWrite ? 'edit' : 'view';
  }

  get roomreservationId(): string {
    return this.reservationId + this.reservationRoom?.roomreservation_id;
  }

  get reservationId(): number {
    return this.reservationRoom?.reservation_id;
  }

  private getHeaderData(): TableauModalHeader {
    if (!this.reservationRoom) {
      return;
    }

    const { booker, guest, label } = this.reservationRoom;

    const user = {
      fullName: label,
      email: booker?.email,
      telephone: booker?.telephone,
      facebook: guest?.facebook || booker?.facebook,
    };

    return {
      ...this.reservationRoom,
      resource_id: this.reservationRoom?.reference_number,
      user,
      logo: this.getHeaderDataLogo(),
    };
  }

  private getHeaderDataLogo(): TableauModalHeaderLogo {
    const { company, channel, metasearch } = this.reservationRoom;

    if (metasearch) {
      return {
        label: metasearch.name,
        url: `${cdnUrl}images/metasearch/${metasearch.id}/svg/logo.svg`,
        radius: false,
      };
    }

    if (company) {
      return {
        label: company.name,
        url: company?.logo?.length
          ? `${storageUrl}${first(company.logo)?.path}`
          : null,
        radius: true,
      };
    }

    if (channel?.id > 1000) {
      return {
        label: channel.label,
        url: null,
        radius: true,
        is_virtual_channel: true,
      };
    }

    if (channel) {
      return {
        label: channel.label,
        url: `${cdnUrl}images/channels/${channel.id}/svg/logo.svg`,
        radius: false,
      };
    }

    return null;
  }

  get isOptionable() {
    return this.reservationRoom?.status === 'Optionable';
  }
}
