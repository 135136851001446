import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { selectPropertyCurrency } from '../../../../core/+state/core.reducer';
import {
  OptionsAccommodationItem,
  PriceQuotation,
  TableauModalHeader,
} from '../../../../models';
import {
  PriceQuotationStoreActions,
  PriceQuotationStoreSelectors,
} from '../../../../root-store/price-quotation-details-store';
import { RootState } from '../../../../root-store/root-state';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-tableau-quote-details',
  templateUrl: './tableau-quote-details.component.html',
  styleUrls: ['./tableau-quote-details.component.scss'],
})
export class TableauQuoteDetailsComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<TableauQuoteDetailsComponent> =
    inject(NZ_MODAL_DATA);

  @Input() reservationsColors: Record<string, string> =
    this.nzDataModal.reservationsColors;

  @Input() data: {
    reservation_quote_option_accommodation_id: number;
    reservation_quote_id: number;
    reservation_quote_option_id: number;
    tableau_room_id: number;
    tableau_room_label: string;
  } = this.nzDataModal.data;

  @Input() userCanWrite: boolean = this.nzDataModal.userCanWrite || false;

  quoteDetails$ = this.store.pipe(
    select(PriceQuotationStoreSelectors.selectPriceQuotationItem),
  );

  loading$ = this.store.pipe(
    select(PriceQuotationStoreSelectors.selectPriceQuotationIsLoading),
  );

  currencySymbol$ = this.store.pipe(
    select(selectPropertyCurrency),
    map((currency) => currency && currency.symbol),
  );

  currencySymbol: string;

  loading = false;

  quoteDetails: PriceQuotation;

  header: TableauModalHeader;

  accommodation: OptionsAccommodationItem;

  skeletonContex = {
    nzParagraph: { rows: 1, width: '100%' },
  };

  translationLabel = {
    reservation_quote_internal: 'internal_notes',
    reservation_quote_customer: 'notes_for_guest',
  };

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.quoteDetails$.subscribe((quoteDetails) => {
        if (!quoteDetails) {
          return;
        }
        this.quoteDetails = quoteDetails;
        this.accommodation = this.getAccommodation();
        this.header = this.getHeaderData();
        this.ref.detectChanges();
      }),
    );

    this.subs.add(
      this.loading$.subscribe((loading) => (this.loading = loading)),
    );

    this.subs.add(
      this.currencySymbol$.subscribe((currencySymbol) => {
        this.ref.detectChanges();
        this.currencySymbol = currencySymbol;
      }),
    );

    this.loadQuoteDetails();
  }

  ngOnDestroy() {
    this.store.dispatch(new PriceQuotationStoreActions.ResetSuccessAction());
    this.subs.unsubscribe();
  }

  loadQuoteDetails() {
    this.store.dispatch(
      new PriceQuotationStoreActions.LoadRequestAction({
        priceQuotationId: this.data?.reservation_quote_id,
      }),
    );
  }

  getAccommodation(): OptionsAccommodationItem {
    if (!this.quoteDetails || !this.data) {
      return null;
    }

    const {
      reservation_quote_option_accommodation_id,
      reservation_quote_option_id,
    } = this.data;

    return this.quoteDetails?.options
      .find(({ id }) => id === reservation_quote_option_id)
      .accommodations.find(
        ({ id: reservationQuoteOptionAccommodationId }) =>
          reservationQuoteOptionAccommodationId ===
          reservation_quote_option_accommodation_id,
      ).accommodation;
  }

  getHeaderData(): TableauModalHeader {
    if (!this.quoteDetails || !this.data) {
      return null;
    }

    const {
      adults_number: total_adults,
      children_number: total_children,
      policies,
      daily_rates,
      arrival_date,
      departure_date,
    } = this.accommodation;

    return {
      ...this.accommodation,
      arrival_date,
      departure_date,
      resource_id: this.data?.reservation_quote_id,
      user: this.getUser(),
      total_adults,
      total_children,
      tableau_number: this.getTableuNumber(),
      policy_description: policies?.name,
      background_key: 'quotes',
      number_nights: Object.keys(daily_rates)?.length,
      logo: null,
      treatments_description: this.getTreatmentsDescriptions(),
    };
  }

  getTableuNumber() {
    const { tableau_room_id, tableau_room_label } = this.data;
    return {
      id: tableau_room_id,
      label: tableau_room_label,
      order: null,
      common_id: null,
    };
  }

  getUser() {
    const {
      customer: {
        detail: { facebook, email, telephone },
      },
      label,
    } = this.quoteDetails;
    return {
      facebook,
      email,
      telephone,
      fullName: label,
    };
  }

  getTreatmentsDescriptions(): {
    title: string;
    description: string;
  } {
    const title = this.accommodation.treatment_name;

    const description = this.accommodation.daily_rates
      .map(({ date, price }) => {
        return `${date}: ${title}\n${this.currencySymbol} ${price}`;
      })
      .join('\n');

    return {
      title,
      description,
    };
  }

  get quoteAccommodationPrice() {
    return {
      price_total: this.accommodation?.price,
    };
  }
}
