import * as moment from 'moment';

/**
 * @param date Data da formattare
 * @param inputFormat (opzionale) Se la data da formattare è una stringa specifica il formato, di default è YYYY-MM-DD
 * @returns l'instanza Date se la data è valida, null se la data è invalida
 */
export function getNullableDate(
  date: Date | string,
  inputFormat = 'YYYY-MM-DD',
): Date {
  if (!date) {
    return null;
  }

  if (!(date instanceof Date)) {
    date = moment(date, inputFormat).toDate();
  }

  if (!moment(date).isValid()) {
    return null;
  }

  return date;
}
