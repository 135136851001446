import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IReservationTag } from '../../models/objects/reservation-tag';

@Component({
  selector: 'by-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagSelectComponent),
      multi: true,
    },
  ],
})
export class TagSelectComponent implements OnInit, ControlValueAccessor {
  @Input() tags: IReservationTag[] = [];

  @Input() disabled = false;

  @Output() tag = new EventEmitter<IReservationTag>();

  tagDropdownIsVisible = false;

  tooltipVisibleMap = {};

  tagId: number;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(tagId: number) {
    this.tagId = tagId;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  forceTooltipClose(tagId: number): void {
    if (tagId) {
      this.tooltipVisibleMap[tagId] = false;
    }
  }

  selectTag(tagId: number) {
    if (this.tagSelected?.id === tagId) {
      return;
    }
    this.tagId = tagId;
    this.onChange(this.tagSelected?.id);
  }

  get tagSelected() {
    return (this.tags || []).find(({ id }) => this.tagId === id);
  }
}
