<div class="by-tableau-reservation-details-popover">
  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'customer' | translate | upperfirst }}:</div>
    <div>
      {{
        quote?.company?.name || quote?.booker_surname + ' ' + quote?.booker_name
      }}
    </div>
  </div>
  <div class="by-flex">
    <div class="bolder by-mr-5">
      {{ 'expire_date' | translate | upperfirst }}:
    </div>
    <div>
      {{ quote?.reservation_quote?.expire_date | formatDate }}
    </div>
  </div>
  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'check_in' | translate | upperfirst }}:</div>
    <div>{{ quote?.date_from | formatDate }}</div>
    <div class="bolder by-mr-5 by-ml-10">
      {{ 'check_out' | translate | upperfirst }}:
    </div>
    <div>{{ quote?.date_to | formatDate }}</div>
  </div>
  <!-- <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'nights' | translate | upperfirst }}:</div>
    <div>{{ quote?.number_nights }}</div>
  </div> -->
  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'price' | translate | upperfirst }}:</div>
    <by-currency-format
      [currencyValue]="quote?.price_total"
      [currencySymbol]="quote?.currency_symbol"
    ></by-currency-format>
  </div>
  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'guests' | translate | upperfirst }}:</div>
    <div>
      <span>
        {{
          'adults_label'
            | translate: { adults: quote?.total_adults }
            | titlecase
        }}
      </span>
      <span *ngIf="quote?.total_childrens" class="by-ml-5">
        {{
          'children_label'
            | translate: { children: quote?.total_childrens }
            | titlecase
        }}
      </span>
    </div>
  </div>
</div>
