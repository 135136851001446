import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { removeNullishValues } from '../helpers';
import { ExportFileRequestType } from '../models/types/export-file-request';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(private http: HttpClient) {}

  load(userId: number) {
    return this.http.get(`user_preferences/?user_id=${userId}`);
  }

  update(preferences: any) {
    return this.http.post('user_preferences', preferences);
  }
}
