import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { first } from 'lodash';
import { SubSink } from 'subsink';

import { EstimateRateplan, FormGetter } from '../../../../models';

interface RateplanConfig {
  treatment_id: number;
  rateplan_id: number;
}

type OnChange = (config: RateplanConfig) => void;

@Component({
  selector: 'by-reservation-form-rateplan',
  templateUrl: './reservation-form-rateplan.component.html',
  styleUrls: ['./reservation-form-rateplan.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormRateplanComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReservationFormRateplanComponent),
      multi: true,
    },
  ],
})
export class ReservationFormRateplanComponent
  implements ControlValueAccessor, OnChanges, OnDestroy, FormGetter
{
  @Input()
  rateplans: EstimateRateplan[];

  @Input() isMobile = false;

  treatments: Array<{ id: number; name: string }>;

  form = this.formBuilder.group({
    rateplan_id: [null, [Validators.required]],
    treatment_id: [null, [Validators.required]],
  });

  onTouched: () => void;

  private subs = new SubSink();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.subs.add(
      this.form.get('rateplan_id').valueChanges.subscribe((rateplanId) => {
        if (!rateplanId) {
          return;
        }

        const rateplan = this.getRateplanById(rateplanId);

        this.treatments = rateplan?.treatments;

        this.form.patchValue({
          treatment_id: first(this.treatments)?.id,
        });
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { rateplans } = changes;

    if (rateplans && this.rateplans) {
      this.setRateplans();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(config: RateplanConfig) {
    this.form.patchValue(config, { emitEvent: false });
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(this.form.valueChanges.subscribe(onChange));
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  getForms() {
    return [this.form];
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return {
      'rateplan-error': true,
    };
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }

  private setRateplans() {
    const rateplan = first(this.rateplans);

    this.form.patchValue({
      rateplan_id: rateplan?.id,
    });
  }

  private getRateplanById(rateplanId: number) {
    return this.rateplans.find(({ id }) => id === rateplanId);
  }
}
