import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { mapKeys, mapValues, omit, upperFirst } from 'lodash';

import { RootState } from '../../../../../root-store/root-state';
import { TableauExportBuilder } from '../tableau-export-builder';
import { FIRST_DAYS_COLUMN } from '../tableau-export.config';

@Injectable()
export class LegendBuilderService implements TableauExportBuilder {
  constructor(private translate: TranslateService) {}

  build(state: RootState, worksheet: ExcelJS.Worksheet) {
    this.paintTitle(worksheet);

    const {
      tableau_2: { viewOptions },
    } = state;

    if (viewOptions.reservations_palette === 'status') {
      this.paintReservationStatusPalette(state, worksheet);
    } else {
      this.paintChannelsPalette(state, worksheet);
    }

    this.paintCommonPalette(state, worksheet);
  }

  private paintTitle(worksheet: ExcelJS.Worksheet) {
    // Spacing
    worksheet.addRows([[], []]);

    const row = worksheet.addRow([
      ...new Array(FIRST_DAYS_COLUMN - 1).fill(null),
      upperFirst(this.translate.instant('legend')),
    ]);

    worksheet.mergeCells(
      row.number,
      FIRST_DAYS_COLUMN,
      row.number,
      FIRST_DAYS_COLUMN + 3,
    );

    row.findCell(FIRST_DAYS_COLUMN).style = {
      font: { bold: true },
      alignment: { horizontal: 'center' },
    };
  }

  private paintReservationStatusPalette(
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const {
      tableau_2: { reservationsColors },
    } = state;

    const palette = mapValues(
      mapKeys(omit(reservationsColors, 'quotes'), (_, key) =>
        upperFirst(this.translate.instant(key)),
      ),
      (color) => {
        return {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color.replace('#', '') },
        } as ExcelJS.Fill;
      },
    );

    this.paintPalette(palette, worksheet);
  }

  private paintChannelsPalette(state: RootState, worksheet: ExcelJS.Worksheet) {
    const { activeChannels } = state;

    const palette = Object.values(activeChannels.entities).reduce(
      (paletteColors, channel) => {
        paletteColors = {
          ...paletteColors,
          [channel.name]: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: channel.color.replace('#', '') },
          },
        };
        return paletteColors;
      },
      {},
    );

    this.paintPalette(palette, worksheet);
  }

  private paintCommonPalette(state: RootState, worksheet: ExcelJS.Worksheet) {
    const {
      tableau_2: { reservationsColors },
    } = state;

    const palette: Record<string, ExcelJS.Fill> = {
      [upperFirst(this.translate.instant('quotes'))]: {
        type: 'pattern',
        pattern: 'lightDown',
        bgColor: { argb: 'ffffff' },
        fgColor: { argb: reservationsColors.quotes.replace('#', '') },
      },
      [upperFirst(this.translate.instant('notes'))]: {
        type: 'pattern',
        pattern: 'lightDown',
        bgColor: { argb: 'ffffff' },
        fgColor: { argb: 'ffa234' },
      },
    };

    this.paintPalette(palette, worksheet);
  }

  private paintPalette(
    palette: Record<string, ExcelJS.Fill>,
    worksheet: ExcelJS.Worksheet,
  ) {
    Object.entries(palette).forEach(([name, fill]) => {
      const row = worksheet.addRow([
        ...new Array(FIRST_DAYS_COLUMN - 1).fill(null),
        name,
        null,
      ]);

      const colorCell = row.getCell(FIRST_DAYS_COLUMN + 3);

      colorCell.fill = fill;

      worksheet.mergeCells(
        row.number,
        FIRST_DAYS_COLUMN,
        row.number,
        FIRST_DAYS_COLUMN + 2,
      );
    });
  }
}
