<div class="by-tableau-reservation-details-popover">
  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'customer' | translate | upperfirst }}:</div>
    <div>{{ reservation?.label | titlecase }}</div>
  </div>

  <div class="by-flex" *ngIf="reservation?.label_name">
    <div class="bolder by-mr-5">{{ 'group' | translate | upperfirst }}:</div>
    <div>{{ reservation?.label_name }}</div>
  </div>

  <div class="by-flex" *ngIf="isOptionable">
    <div class="bolder by-mr-5">
      {{ 'expiration_quote_option' | translate | upperfirst }}:
    </div>
    <div>
      {{ reservation?.expiration_date | formatDate }}
    </div>
  </div>

  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'check_in' | translate | upperfirst }}:</div>
    <div>{{ reservation?.arrival_date | formatDate }}</div>
    <div class="bolder by-mr-5 by-ml-10">
      {{ 'check_out' | translate | upperfirst }}:
    </div>
    <div>{{ reservation?.departure_date | formatDate }}</div>
  </div>

  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'nights' | translate | upperfirst }}:</div>
    <div>{{ reservation?.number_nights }}</div>
  </div>

  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'guests' | translate | upperfirst }}:</div>
    <div>
      <span>
        {{
          'adults_label'
            | translate: { adults: reservation?.total_adults }
            | titlecase
        }}
      </span>
      <span *ngIf="reservation?.total_children" class="by-ml-5">
        {{
          'children_label'
            | translate: { children: reservation?.total_children }
            | titlecase
        }}
      </span>
    </div>
  </div>

  <div class="by-flex">
    <div class="bolder by-mr-5">{{ 'channel' | translate | upperfirst }}:</div>
    <div>{{ reservation?.channel_name | upperfirst }}</div>
  </div>

  <ng-container
    *ngIf="reservation?.hasReservationAccommodationDetails; else skeleton"
  >
    <div class="by-flex">
      <div class="bolder by-mr-5">{{ 'price' | translate | upperfirst }}:</div>
      <div>
        {{
          +reservation?.total_price | toCurrency: reservation?.currency_symbol
        }}
      </div>
    </div>

    <div class="by-flex">
      <div class="bolder by-mr-5">
        {{ 'treatment' | translate | upperfirst }}:
      </div>
      <div>{{ reservation?.treatment_name | upperfirst }}</div>
    </div>

    <div>
      <span
        [originalText]="reservation?.note_customer"
        [inline]="true"
        [maxLength]="100"
        by-collapsable-text
        hideMessage="hide"
        showMessage="more"
        [label]="'note_customer' | translate | upperfirst"
      ></span>
    </div>

    <div>
      <span
        [originalText]="reservation?.note_internal"
        [inline]="true"
        [maxLength]="100"
        by-collapsable-text
        hideMessage="hide"
        showMessage="more"
        [label]="'note_internal' | translate | upperfirst"
      ></span>
    </div>
  </ng-container>
</div>

<ng-template #skeleton>
  <nz-skeleton
    [nzTitle]="false"
    [nzParagraph]="{ rows: 2 }"
    [nzActive]="true"
  ></nz-skeleton>
</ng-template>
