import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';

import { DateFormatterService } from '../../../../../core/services/date-formatter.service';
import { isWeekend } from '../../../../../helpers';
import {
  TableauMappingAccommodationDetails,
  TableauRow,
  TableauRowItem,
} from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauExportRowBuilder } from '../tableau-export-builder';
import {
  FIRST_DAYS_COLUMN,
  getDefaultCellStyle,
  ROW_HEIGHT,
} from '../tableau-export.config';

@Injectable()
export class AccommodationRowBuilderService implements TableauExportRowBuilder {
  constructor(private dateFormatter: DateFormatterService) {}

  build(
    tableauRow: TableauRow<TableauMappingAccommodationDetails>,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const { days } = state.tableau_2;
    const { items, data } = tableauRow;

    const daysCells = new Map<number, Date>();

    const firstColumnText = data.name;

    // Building row
    const row = worksheet.addRow([
      firstColumnText,
      ...days.map((day, index) => {
        daysCells.set(FIRST_DAYS_COLUMN + index, day);

        const rowItem: TableauRowItem<{ availability: string }> =
          items[this.dateFormatter.toServerFormat(day)];

        return rowItem?.data.availability || '-';
      }),
    ]);

    // Cell formatting
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const date = daysCells.get(colNumber);

      let cellColor = 'f3f3f5';

      if (date && isWeekend(date)) {
        cellColor = 'fdecf6';
      }

      if (colNumber > 1 && !+cell.value) {
        cellColor = 'fef8e3';
      }

      cell.style = getDefaultCellStyle({
        alignment: {
          vertical: 'middle',
          horizontal: colNumber === 1 ? 'right' : 'center',
          indent: colNumber === 1 ? 1 : null,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor },
        },
      });
    });

    row.height = ROW_HEIGHT;
  }
}
