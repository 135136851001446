import * as moment from 'moment';

const checkBirthday = (customerBirthDate): boolean => {
  if (customerBirthDate) {
    const today = moment(new Date()).format('DD-MM');
    const customerBirthday = moment(customerBirthDate).format('DD-MM');
    if (today === customerBirthday) {
      return true;
    }
  }
  return false;
};

const calcAge = (customerBirthDate): number => {
  if (customerBirthDate) {
    const today = moment(new Date());
    const customerBirthday = moment(customerBirthDate);

    return moment(today).diff(customerBirthday, 'years');
  }
  return -1;
};

export const getBookerBirthday = (customer) => {
  if (!customer) {
    return;
  }

  const { birthday_date } = customer;

  if (checkBirthday(birthday_date)) {
    return {
      age: calcAge(birthday_date),
    };
  }

  return null;
};
