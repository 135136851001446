import {
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  inject,
} from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

import { ModalDragService } from '../../../../services/modal-drag.service';
import { EstimateInterfaceComponent } from '../../estimate/estimate-interface/estimate-interface.component';
import { Days } from '../../estimate/models/days';
import { SplitInformations } from '../../estimate/models/split-informations';

@Component({
  selector: 'by-tableau-estimate-modal',
  templateUrl: './tableau-estimate-modal.component.html',
  styleUrls: ['./tableau-estimate-modal.component.scss'],
  providers: [ModalDragService],
})
export class TableauEstimateModalComponent {
  readonly nzModalData: Partial<TableauEstimateModalComponent> =
    inject(NZ_MODAL_DATA);

  @ViewChild(EstimateInterfaceComponent)
  _estimateInterface: EstimateInterfaceComponent;

  @Input()
  dates: string[] = this.nzModalData?.dates;

  @Input()
  range: [string, string] = this.nzModalData?.range;

  @Input()
  guestFullName: string = this.nzModalData?.guestFullName;

  @Input()
  splitInformations: SplitInformations = this.nzModalData?.splitInformations;

  @Input()
  loading: boolean = this.nzModalData?.loading;

  @Input()
  warnings: string[] = this.nzModalData?.warnings;

  @Input()
  isClosed: boolean = this.nzModalData?.isClosed;

  @Input()
  withoutAvail: boolean = this.nzModalData?.withoutAvail;

  @Input()
  newTotal: number = this.nzModalData?.newTotal;

  @Input()
  oldTreatmentId: number = this.nzModalData?.oldTreatmentId;

  @Input()
  originalTotal: number = this.nzModalData?.originalTotal;

  @Input()
  rateplans: Array<{
    id: number;
    name: string;
    treatments: Array<{ id: number; name: string }>;
  }> = this.nzModalData?.rateplans;

  @Input()
  showWarnings: boolean = this.nzModalData?.showWarnings;

  @Input()
  rateplanRequired: boolean = this.nzModalData?.rateplanRequired;

  @Input()
  treatmentRequired: boolean = this.nzModalData?.treatmentRequired;

  @Input()
  noPrice: boolean = this.nzModalData?.noPrice;

  @Input()
  hasDoorCode: boolean = this.nzModalData?.hasDoorCode;

  @Input()
  currencySymbol: string = this.nzModalData?.currencySymbol;

  @Input()
  originalDays: Days = this.nzModalData?.originalDays;

  @Input()
  newDays: Days = this.nzModalData?.newDays;

  @Input()
  saveButtonLabel: string = this.nzModalData?.saveButtonLabel;

  constructor(
    private modalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private modalDragService: ModalDragService,
  ) {
    this.modalDragService.handle(this.modalRef);
  }

  getInterface() {
    this.cdr.detectChanges();
    return this._estimateInterface;
  }

  onConfirm() {
    this.modalRef.close(this._estimateInterface);
  }

  onCancel() {
    this.modalRef.close();
  }
}
