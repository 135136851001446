import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { CollapsableBoxComponent } from '../../../../../components/collapsable-box/collapsable-box.component';
import { ByClass } from '../../../../../models';

@Component({
  selector: 'by-tableau-modal-header-details-row',
  templateUrl: './tableau-modal-header-details-row.component.html',
  styleUrls: ['./tableau-modal-header-details-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauModalHeaderDetailsRowComponent {
  @Input() iconClass: ByClass;

  @Input() label: string | TemplateRef<any>;

  @ContentChildren('byTableauModalHeadeDetailsRowContent')
  contentElements: QueryList<TemplateRef<any>>;

  @ViewChild(CollapsableBoxComponent) collapsableBox: CollapsableBoxComponent;

  constructor() {}

  onToggle() {
    this.collapsableBox?.onToggle();
  }

  get isExpanded() {
    return this.collapsableBox?.isExpanded;
  }
}
