import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import { isNil, upperFirst } from 'lodash';

import { DateFormatterService } from '../../../../../core/services/date-formatter.service';
import { TableauRow } from '../../../../../models';
import { RootState } from '../../../../../root-store/root-state';
import { TableauExportRowBuilder } from '../tableau-export-builder';
import { getDefaultCellStyle, ROW_HEIGHT } from '../tableau-export.config';

const COLORS_BY_KEY = {
  current_availability: 'd6fed1',
  percent_occupancy: 'd3f0fb',
  sold: 'fdebd1',
};

@Injectable()
export class PropertyStatsRowBuilderService implements TableauExportRowBuilder {
  constructor(
    private translate: TranslateService,
    private dateFormatter: DateFormatterService,
  ) {}

  build(
    tableauRow: TableauRow<{ key: string }>,
    state: RootState,
    worksheet: ExcelJS.Worksheet,
  ) {
    const {
      tableau_2: { days },
    } = state;
    const { items, data } = tableauRow;

    // Building row
    const row = worksheet.addRow([
      upperFirst(this.translate.instant(data.key)),
      ...days.map((day) => {
        const value =
          items[this.dateFormatter.toServerFormat(day)]?.data?.[data.key];

        return isNil(value) ? null : value;
      }),
    ]);

    // Cell formatting
    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.style = getDefaultCellStyle({
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: COLORS_BY_KEY[data.key] },
        },
      });
    });

    row.height = ROW_HEIGHT;
  }
}
