import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { TagSelectComponent } from './tag-select.component';

@NgModule({
  declarations: [TagSelectComponent],
  imports: [
    CommonModule,
    NzDropDownModule,
    NzToolTipModule,
    TranslateModule,
    NgPipesModule,
  ],
  exports: [TagSelectComponent],
})
export class TagSelectModule {}
