import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';

import {
  TableauMappingAccommodationDetails,
  TableauRow,
  TableauRowComponent,
  TableauRowItems,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import * as TableauActions from '../../../../root-store/tableau-store/actions';

type TableauAccommodationRow =
  TableauRowComponent<TableauMappingAccommodationDetails>;

@Component({
  selector: 'by-tableau-accommodation-row',
  templateUrl: './tableau-accommodation-row.component.html',
  styleUrls: ['./tableau-accommodation-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauAccommodationRowComponent
  implements TableauAccommodationRow, OnChanges
{
  @Input()
  row: TableauRow;

  @Input()
  data: TableauMappingAccommodationDetails;

  @Input()
  days: Date[];

  @Input()
  items: TableauRowItems<{ availability: string }>;

  @Input()
  userCanWrite: boolean;

  @Input()
  collapsedAccommodations: Record<number, boolean>;

  constructor(private store: Store<RootState>) {}

  ngOnChanges() {}

  onToggleCollapse() {
    this.store.dispatch(
      TableauActions.toggleAccommodationExpand({
        accommodationId: this.data?.id,
        expand: this.isCollapsed,
      }),
    );
  }

  onOpenBedTypesModal() {
    this.store.dispatch(
      TableauActions.openBedTypesModal({
        accommodationId: this.data.id,
        property_id: this.row.propertyId,
      }),
    );
  }

  get isCollapsed() {
    return (
      this.collapsedAccommodations &&
      this.collapsedAccommodations[this.data?.id]
    );
  }
}
