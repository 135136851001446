import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Directive({
  selector: '[byCdkVirtualScrollFixer]',
})
export class CdkVirtualScrollFixerDirective implements OnDestroy {
  private topBreakpoint = null;

  private tableNativeElement: HTMLElement;

  private subs = new SubSink();

  constructor(
    private nzTableComponent: NzTableComponent<any>,
    elementRef: ElementRef,
  ) {
    this.tableNativeElement = elementRef.nativeElement;
    this.tableNativeElement.classList.add('cdk-virtual-scroll-fixer-table');

    this.subs.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (!this.cdkVirtualScroll) {
            return;
          }

          this.reset();

          setTimeout(() => this.getTopBreakpoint(), 300);
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (!this.cdkVirtualScroll) {
      return;
    }

    const virtualScrollElement =
      this.cdkVirtualScroll.getElementRef().nativeElement;

    virtualScrollElement.style.overflowY = 'hidden';

    const cdkVirtualScrollTotalContentHeight =
      +this.cdkVirtualScroll._totalContentHeight.replace('px', '');

    if (cdkVirtualScrollTotalContentHeight <= window.innerHeight) {
      return;
    }

    if (!this.topBreakpoint) {
      this.getTopBreakpoint();
    }

    const bottomBreakpoint =
      cdkVirtualScrollTotalContentHeight + this.topBreakpoint;

    if (window.scrollY >= this.topBreakpoint) {
      this.scroll(window.scrollY - this.topBreakpoint);

      this.tableNativeElement.style.position = 'fixed';
      this.tableNativeElement.style.top = '0px';
    } else {
      this.scroll(0);
      this.tableNativeElement.style.position = 'relative';
    }

    const scroll = window.scrollY + window.innerHeight;

    if (
      scroll >= bottomBreakpoint &&
      this.tableNativeElement.style.position === 'fixed'
    ) {
      this.tableNativeElement.style.transform = `translateY(${
        (scroll - bottomBreakpoint) * -1
      }px)`;
    } else {
      this.tableNativeElement.style.transform = `translateY(0px)`;
    }
  }

  private get cdkVirtualScroll() {
    return this.nzTableComponent.nzTableInnerScrollComponent
      .cdkVirtualScrollViewport;
  }

  private scroll(top: number) {
    this.cdkVirtualScroll.scrollTo({
      top: top,
    });
  }

  private getTopBreakpoint() {
    const html = document.querySelector('html');
    this.tableNativeElement.style.position = 'relative';

    this.topBreakpoint =
      this.tableNativeElement.getBoundingClientRect().top + html.scrollTop;
  }

  private reset() {
    this.scroll(0);

    this.tableNativeElement.style.position = 'relative';

    this.topBreakpoint = null;

    window.scrollTo({ top: 0 });
  }
}
