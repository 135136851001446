import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  inject,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { hasHorizontalScroll, hasVerticalScroll } from '../../../../helpers';
import {
  TableauReservationDetails,
  TableauViewOptions,
  UpdateReservationKeepAccommodationRequest,
} from '../../../../models';
import { ReservationTagStoreSelectors } from '../../../../root-store/reservation-tag-store';
import { RootState } from '../../../../root-store/root-state';
import {
  TableauReservationDetailsStoreActions,
  TableauReservationDetailsStoreSelectors,
} from '../../../../root-store/tableau-reservation-details-store';
import { TableauDataSelectorService } from '../services/tableau-data-selector.service';

@Component({
  selector: 'by-tableau-reservation-details',
  templateUrl: './tableau-reservation-details.component.html',
  styleUrls: ['./tableau-reservation-details.component.scss'],
})
export class TableauReservationDetailsComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<TableauReservationDetailsComponent> =
    inject(NZ_MODAL_DATA);

  @ViewChild('bookmarks')
  bookmarksElement: ElementRef<HTMLElement>;

  @Input()
  reservation_id: number = this.nzDataModal.reservation_id;

  @Input()
  reservation_accommodation_room_id: number =
    this.nzDataModal.reservation_accommodation_room_id;

  @Input()
  roomreservation_id: string = this.nzDataModal.roomreservation_id;

  @Input()
  property_id: number = this.nzDataModal.property_id;

  @Input()
  userCanWrite: boolean = this.nzDataModal.userCanWrite;

  @Input()
  reservationsColors: Record<string, string> =
    this.nzDataModal.reservationsColors;

  @Input()
  viewOptions: TableauViewOptions = this.nzDataModal.viewOptions;

  currentReservationRoom$: Observable<TableauReservationDetails>;

  rooms$ = this.store.pipe(
    select(
      TableauReservationDetailsStoreSelectors.selectTableauReservationRooms,
    ),
  );

  loading$ = this.store.pipe(
    select(
      TableauReservationDetailsStoreSelectors.selectTableauReservationDetailsIsLoading,
    ),
  );

  tags$ = this.store.pipe(
    select(ReservationTagStoreSelectors.selectAllReservationTagItems),
  );

  isMobile: boolean;

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    @Optional() private modalRef: NzModalRef,
    private tableauDataSelectors: TableauDataSelectorService,
  ) {}

  ngOnInit() {
    this.store.dispatch(
      TableauReservationDetailsStoreActions.loadRequest({
        reservation_id: this.reservation_id,
      }),
    );

    this.subs.add(
      this.tableauDataSelectors.selectIsMobile().subscribe((isMobile) => {
        this.isMobile = isMobile;
      }),
    );

    this.loadCurrentReservationRoom();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(TableauReservationDetailsStoreActions.resetState());
  }

  onCloseModal() {
    this.modalRef?.close();
  }

  onSelectRoom(room: Partial<TableauReservationDetails>) {
    if (room.detailed) {
      this.setCurrentRoomReservation(room.roomreservation_id);
      return;
    }

    this.loadCurrentReservationRoom(room);
  }

  onVerticalScroll(direction: 1 | -1) {
    const { nativeElement } = this.bookmarksElement;

    nativeElement.scroll({
      top: nativeElement.scrollTop + 20 * direction,
      behavior: 'smooth',
    });
  }

  onHorizontalScroll(direction: 1 | -1) {
    const { nativeElement } = this.bookmarksElement;

    nativeElement.scroll({
      left: nativeElement.scrollLeft + 20 * direction,
      behavior: 'smooth',
    });
  }

  get bookmarksHaveScroll() {
    if (!this.bookmarksElement?.nativeElement) {
      return false;
    }

    return this.isMobile
      ? hasHorizontalScroll(this.bookmarksElement.nativeElement)
      : hasVerticalScroll(this.bookmarksElement.nativeElement);
  }

  trackByFn(_, room: Partial<TableauReservationDetails>) {
    return room.roomreservation_id;
  }

  private loadCurrentReservationRoom(
    room?: Partial<TableauReservationDetails>,
  ) {
    const {
      reservation_accommodation_room_id,
      roomreservation_id,
      reservation_id,
    } = room || this;

    this.setCurrentRoomReservation(roomreservation_id);

    this.store.dispatch(
      TableauReservationDetailsStoreActions.loadDetailsRequest({
        reservation_id,
        reservation_accommodation_room_id,
      }),
    );
  }

  onSetKeepAccommodation(request: UpdateReservationKeepAccommodationRequest) {
    this.store.dispatch(
      TableauReservationDetailsStoreActions.setKeepAccommodationRequest(
        request,
      ),
    );
  }

  private setCurrentRoomReservation(roomreservation_id: string) {
    this.currentReservationRoom$ = this.store.pipe(
      select(
        TableauReservationDetailsStoreSelectors.selectTableuReservationRoom(
          roomreservation_id,
        ),
      ),
    );
  }
}
