import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { UiModule } from '../../../ui/ui.module';

import { EstimateInterfaceComponent } from './estimate-interface/estimate-interface.component';
import { EstimateModalComponent } from './estimate-modal/estimate-modal.component';
import { EstimateModalService } from './services/estimate-modal.service';

@NgModule({
  declarations: [EstimateModalComponent, EstimateInterfaceComponent],
  imports: [
    CommonModule,
    TranslateModule,
    UiModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [EstimateModalService],
  exports: [EstimateInterfaceComponent],
})
export class EstimateModule {}
