import { Injectable } from '@angular/core';
import { createReducer, on } from '@ngrx/store';
import { uniq, without } from 'lodash';
import * as moment from 'moment';

import { removeNullishValues } from '../../helpers';
import { CheckinStatus, TableauReservationDetails } from '../../models';
import { TableauRowsService } from '../../services/tableau-rows.service';

import * as fromActions from './actions';
import * as fromState from './state';

enum Loadings {
  List = 'list',
  Details = 'details',
}

@Injectable()
export class TableauReservationDetailsStoreReducer {
  constructor(private tableauRowsService: TableauRowsService) {}

  createReducer() {
    return createReducer(
      fromState.initialState,
      on(fromActions.loadRequest, (state) => ({
        ...state,
        entities: fromState.initialState.entities,
        ids: fromState.initialState.ids,
        loadings: state.loadings.concat(Loadings.List),
        error: null,
      })),
      on(fromActions.loadSuccess, (state, { accommodations }) => {
        return fromState.featureAdapter.upsertMany(
          accommodations.map((accommodation) =>
            this.accommodationParser(
              accommodation as TableauReservationDetails,
            ),
          ),
          {
            ...state,
            loadings: without(state.loadings, Loadings.List),
            error: null,
          },
        );
      }),
      on(fromActions.loadFailure, (state, { error }) => ({
        ...state,
        loadings: without(state.loadings, Loadings.List),
        error,
      })),

      on(fromActions.loadDetailsRequest, (state) => ({
        ...state,
        loadings: state.loadings.concat(Loadings.Details),
        error: null,
      })),
      on(fromActions.loadDetailsSuccess, (state, { accommodation }) => {
        return fromState.featureAdapter.upsertOne(
          this.accommodationParser({ ...accommodation, detailed: true }),
          {
            ...state,
            loadings: without(state.loadings, Loadings.Details),
            error: null,
          },
        );
      }),
      on(fromActions.loadDetailsFailure, (state, { error }) => ({
        ...state,
        loadings: without(state.loadings, Loadings.Details),
        error,
      })),

      on(fromActions.setCheckinStatusRequest, (state) => ({
        ...state,
        loadings: state.loadings.concat(Loadings.Details),
      })),

      on(
        fromActions.setCheckinStatusSuccess,
        (
          state,
          { checkinStatus: { checkin, checkout }, roomreservation_id },
        ) => {
          const accommodationRoom = state.entities[roomreservation_id];

          if (accommodationRoom) {
            return fromState.featureAdapter.updateOne(
              {
                id: accommodationRoom.roomreservation_id,
                changes: this.accommodationParser({
                  ...accommodationRoom,
                  checkin,
                  checkout,
                }),
              },
              {
                ...state,
                loadings: without(state.loadings, Loadings.Details),
              },
            );
          }

          return {
            ...state,
            loadings: without(state.loadings, Loadings.Details),
          };
        },
      ),
      on(fromActions.setCheckinStatusFailure, (state, { error }) => ({
        ...state,
        loadings: without(state.loadings, Loadings.Details),
        error,
      })),

      on(fromActions.setKeepAccommodationRequest, (state) => ({
        ...state,
        loadings: state.loadings.concat(Loadings.Details),
      })),

      on(
        fromActions.setKeepAccommodationSuccess,
        (state, { keep_accommodation, roomreservation_id }) => {
          const accommodationRoom = state.entities[roomreservation_id];

          if (accommodationRoom) {
            return fromState.featureAdapter.updateOne(
              {
                id: accommodationRoom.roomreservation_id,
                changes: this.accommodationParser({
                  ...accommodationRoom,
                  keep_accommodation,
                }),
              },
              {
                ...state,
                loadings: without(state.loadings, Loadings.Details),
              },
            );
          }

          return {
            ...state,
            loadings: without(state.loadings, Loadings.Details),
          };
        },
      ),
      on(fromActions.setKeepAccommodationFailure, (state, { error }) => ({
        ...state,
        loadings: without(state.loadings, Loadings.Details),
        error,
      })),

      on(fromActions.resetState, () => fromState.initialState),
    );
  }

  private accommodationParser(accommodation: TableauReservationDetails) {
    const { reservation_id } = accommodation;
    let { roomreservation_id } = accommodation;

    const reservationId = reservation_id.toString();

    if (
      roomreservation_id.substring(0, reservationId.length) === reservationId
    ) {
      roomreservation_id = roomreservation_id.replace(roomreservation_id, '');
    }

    const newAccommodationFields = removeNullishValues({
      roomreservation_id,
      checkin_status: this.getCheckinStatus(accommodation),
      treatments_description: this.getTreatmentsDescriptions(accommodation),
      label: this.tableauRowsService.getReservationLabel(accommodation),
      background_key:
        this.tableauRowsService.getReservationBackgroudKey(accommodation),
    });

    return {
      ...accommodation,
      ...newAccommodationFields,
    };
  }

  private getCheckinStatus(
    reservation: TableauReservationDetails,
  ): CheckinStatus {
    const { checkin, checkout } = reservation;
    if (checkout) {
      return 'checkout';
    }
    if (checkin) {
      return 'checkin';
    }
    return 'arriving';
  }

  private getTreatmentsDescriptions(reservation: TableauReservationDetails): {
    title: string;
    description: string;
  } {
    const { days } = reservation;

    if (!days) {
      return null;
    }

    const title = uniq(
      Object.keys(days).map((day) => days[day].treatment),
    ).join('\n');

    const description = Object.keys(days)
      .map((day) => {
        const dayFormatted = moment(new Date(day)).format('DD/MM/YYYY');
        return `${dayFormatted}: ${days[day].treatment}\n ${reservation.currency_symbol} ${days[day].price}`;
      })
      .join('\n');

    return {
      title,
      description,
    };
  }
}

export function tableauReservationDetailsReducerFactory(
  reducer: TableauReservationDetailsStoreReducer,
) {
  return reducer.createReducer();
}
