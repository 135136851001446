import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { CollapsableTextComponent } from './collapsable-text.component';
import { ExternalTemplateViewerModule } from '../external-template-viewer/external-template-viewer.module';

@NgModule({
  declarations: [CollapsableTextComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgPipesModule,
    ExternalTemplateViewerModule,
  ],
  exports: [CollapsableTextComponent],
})
export class CollapsableTextModule {}
