import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { DEFAULT_COLORS } from '../../core/helpers/default-colors';
import {
  Event,
  Floors,
  TableauAvailabilities,
  TableauClousures,
  TableauHousekeeperInformations,
  TableauMapping,
  TableauNotes,
  TableauPeriod,
  TableauQuotes,
  TableauReservations,
  TableauRow,
  TableauSwappedReservation,
  TableauViewMode,
  TableauViewOptions,
} from '../../models';

export const featureAdapter = createEntityAdapter<TableauRow>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<TableauRow> {
  mapping: TableauMapping;
  floors: Floors;
  sortedProperties: number[];
  loadedProperties: number[];
  period: TableauPeriod;
  days: Date[];
  zoom: number;
  resources: StateResources;
  reservationsColors: Record<string, string>;
  viewOptions: TableauViewOptions;
  viewMode: TableauViewMode;
  splitMode: boolean;
  collapsedAccommodations: Record<number, boolean>;
  filteredAccommodations: Record<number, boolean>;
  swappedReservations: TableauSwappedReservation[];
  loadings: string[]; // Contains slugs of loading resources, if empty loading is off
  exportLoading: boolean;
  error: any;
}

export interface StateResources {
  events: Event[];
  reservations: TableauReservations;
  notes: TableauNotes;
  availabilities: TableauAvailabilities;
  clousures: TableauClousures;
  quotes: TableauQuotes;
  housekeeper: TableauHousekeeperInformations;
}

const DEFAULT_RESOURCES: StateResources = {
  events: null,
  reservations: null,
  notes: null,
  availabilities: null,
  clousures: null,
  quotes: null,
  housekeeper: null,
};

const DEFAULT_VIEW_OPTIONS: TableauViewOptions = {
  events: false,
  oblique: false,
  not_room: true,
  notes: true,
  ota_source: true,
  quotes: true,
  stats: true,
  tags: true,
  virtual_room: true,
  bed_type: false,
  housekeeper_notes: true,
  tag_id: null,
  reservations_palette: 'status',
  reservation_details_group_label: 'room',
};

export const initialState: State = featureAdapter.getInitialState({
  mapping: null,
  floors: null,
  sortedProperties: null,
  loadedProperties: [],
  period: null,
  days: null,
  reservationsColors: DEFAULT_COLORS,
  resources: DEFAULT_RESOURCES,
  viewOptions: DEFAULT_VIEW_OPTIONS,
  viewMode: 'default',
  splitMode: false,
  zoom: 1,
  collapsedAccommodations: {},
  filteredAccommodations: {},
  swappedReservations: [],
  loadings: [],
  exportLoading: false,
  error: null,
});
