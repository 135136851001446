import { PlacesFormEnableInput } from '../models';

export const placesInputsToShow = (data: {
  show: boolean;
  fields?: (keyof PlacesFormEnableInput)[];
}): PlacesFormEnableInput => {
  const { show } = data;
  let { fields } = data;
  if (!fields) {
    fields = ['city', 'county', 'state'];
  }
  let placeInputs: PlacesFormEnableInput = <PlacesFormEnableInput>{};
  fields.forEach((field) => {
    placeInputs = {
      country: true,
      ...placeInputs,
      [field]: show,
    };
  });
  return placeInputs;
};
