<div
  *ngFor="
    let day of [] | range: reservation.length:0;
    let last = last;
    let first = first;
    let index = index
  "
  [style.width.px]="last || first ? externalSegmentWidth : segmentWidth"
  class="by-tableau-reservation-split__segment"
  [ngClass]="{
    'by-tableau-reservation-split__segment--selected':
      selectionRange &&
      index >= selectionRange.from &&
      index <= selectionRange.to
  }"
  (mouseenter)="onSegmentMouseEnter(index)"
  (click)="onSegmentClick(index); $event.stopPropagation()"
>
  <i
    *ngIf="selectionRange && selectionRange.from === index"
    class="fas fa-cut"
  ></i>
</div>
