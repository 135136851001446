import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  AutomaticPaymentsConfiguration,
  PaymentMethod,
} from '../../../../models';

export const featureAdapter: EntityAdapter<AutomaticPaymentsConfiguration> =
  createEntityAdapter<AutomaticPaymentsConfiguration>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<AutomaticPaymentsConfiguration> {
  isLoading?: boolean;
  error?: any;
  automaticPaymentMethods: PaymentMethod[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  automaticPaymentMethods: [],
});
