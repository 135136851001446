import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InvoiceTypeLabelPipe } from './invoice-type-label.pipe';

@NgModule({
  declarations: [InvoiceTypeLabelPipe],
  imports: [CommonModule],
  exports: [InvoiceTypeLabelPipe],
})
export class InvoiceTypeLabelPipeModule {}
