import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  TableauDraggingItem,
  TableauPeriod,
  TableauReservation,
  TableauRow,
  TableauRowItem,
  TableauViewOptions,
} from '../../../../models';
import { TableauBoxWrapperComponent } from '../tableau-box-wrapper/tableau-box-wrapper.component';
import { TableauBoxComponents } from '../tableau-components-map';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[by-tableau-box-resolver]',
  templateUrl: './tableau-box-resolver.component.html',
  styles: [
    `
      :host {
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauBoxResolverComponent {
  @Input()
  row: TableauRow;

  @Input()
  item: TableauRowItem;

  @Input()
  draggingItem: TableauDraggingItem;

  @Input()
  userCanWrite: boolean;

  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  channelsColors: Record<number, string>;

  @Input()
  viewOptions: TableauViewOptions;

  @Input()
  splitMode: boolean;

  @Input()
  zoom: number;

  @Input()
  period: TableauPeriod;

  @Input()
  hoveredReservation: TableauReservation;

  @Input()
  isMobile: boolean;

  readonly Components = TableauBoxComponents;

  constructor(public boxWrapperComponent: TableauBoxWrapperComponent) {}
}
