<div class="alert-box">
  <nz-alert
    nzBanner
    *ngIf="!(online$ | async)"
    nzType="error"
    [nzMessage]="'you_are_offline' | translate | capitalize"
  ></nz-alert>

  <by-app-shell-user-2fa-warning
    [userMe]="userMe"
  ></by-app-shell-user-2fa-warning>

  <by-app-shell-properties-account-expired
    [propertiesSubscriptionInfoWarnings]="
      propertiesSubscriptionInfoWarnings$ | async
    "
  ></by-app-shell-properties-account-expired>
</div>
<ng-container *ngIf="!fromMobileApp; else noMenuTemplate">
  <div
    class="my-container app-shell"
    byAppShellMenuResponsive
    (appShellMenuResponsiveEvent)="showResponsiveMenu($event)"
  >
    <by-app-shell-menu
      *ngIf="isShowResponsiveMenu"
      [isShowHeaderRow]="true"
      class="no-print"
      [isCollapsed]="false"
      style="width: 100%"
      [isShowColorLine]="false"
      [groupPropertyName]="
        (allGroups$ | async)?.length > 1 || (allProperties$ | async)?.length > 1
          ? selectedGroup?.name
          : selectedProperty?.name
      "
      [commonRoutes]="commonRoutes"
      [userFullName]="userFullName"
      [allGroups]="allGroups$ | async"
      [allProperties]="allProperties$ | async"
      [groupLogo]="grouplogo$ | async"
      [logoProperty]="logoProperty"
      [isAdmin]="isAdmin"
      [selectedProperty]="selectedProperty"
      [selectedGroup]="selectedGroup"
      [notificationsCounter]="notificationsCounter$ | async"
      (logOutEvent)="onLogout()"
      (onRemoveImpersonateEvent)="onRemoveImpersonate()"
      (changeGroupEvent)="onChangeGroupSelected($event)"
      (changePropertyEvent)="onChangePropertySelected($event)"
      (openAddonsModal)="createModal()"
      [languages]="languages$ | async"
      (changeLanguage)="onUpdateLanguage($event)"
    ></by-app-shell-menu>
    <nz-layout>
      <nz-sider
        class="no-print"
        nzCollapsible
        [nzCollapsed]="siderAnimationsState === 'reduced' ? true : false"
        [nzTrigger]="null"
        [nzWidth]="sidebarWidth"
        [nzCollapsedWidth]="50"
        [@sidebarAnimation]="siderAnimationsState"
        (@sidebarAnimation.start)="animationHandler($event, 'start')"
        (@sidebarAnimation.done)="animationHandler($event, 'done')"
        *ngIf="!isShowResponsiveMenu"
      >
        <div
          class="app-shell-header pointer"
          [ngClass]="{
            expanded: siderAnimationsState === 'expanded'
          }"
          (click)="
            siderResponsive(
              siderAnimationsState === 'reduced' ? 'expanded' : 'reduced'
            )
          "
        >
          <ng-container>
            <div class="logo-beddy" *ngIf="siderAnimationsState !== 'expanded'">
              <img src="assets/images/general/logo/logo-beddy-1-white.png" />
            </div>
            <div
              class="logo-beddy full-logo"
              *ngIf="siderAnimationsState === 'expanded'"
            >
              <img
                src="{{ CDN_URL }}images/beddy/svg/full-logo-white.svg"
                width="100"
              />
            </div>
          </ng-container>
        </div>

        <div
          class="app-shell-sider-scroll"
          [class.expanded]="siderAnimationsState === 'expanded'"
        >
          <ng-container *ngIf="siderAnimationsState === 'expanded'">
            <div class="logo-container">
              <div class="logo">
                <ng-container
                  *ngIf="
                    ((allGroups$ | async)?.length > 1 &&
                      (allProperties$ | async)?.length > 1) ||
                    (allProperties$ | async)?.length > 1
                      ? (grouplogo$ | async)?.media
                      : logoProperty as logoPrint;
                    else noLogo
                  "
                >
                  <div
                    class="image-logo"
                    [ngStyle]="{
                      'background-image': 'url(' + logoPrint + ')'
                    }"
                  ></div>
                </ng-container>
                <ng-template #noLogo>
                  <span class="logo-hotel">
                    <span class="logo-text">{{
                      (((allGroups$ | async)?.length > 1 &&
                        (allProperties$ | async)?.length > 1) ||
                      (allProperties$ | async)?.length > 1
                        ? selectedGroup?.name
                        : selectedProperty?.name
                      )
                        | truncate: 1
                        | upperfirst
                    }}</span>
                  </span></ng-template
                >
              </div>
            </div>
            <div class="info">
              <span class="property-name">
                {{
                  ((allGroups$ | async)?.length > 1 &&
                    (allProperties$ | async)?.length > 1) ||
                  (allProperties$ | async)?.length > 1
                    ? selectedGroup?.name
                    : selectedProperty?.name
                }}
              </span>
              <span class="user-name by-mb-2">
                {{ userFullName }}
              </span>

              <ng-container *ngIf="(allGroups$ | async)?.length > 1">
                <span class="user-name">{{ 'select_group' | translate }}</span>
                <nz-select
                  [nzDisabled]="isGroupSelectDisabled"
                  (ngModelChange)="onChangeGroupSelected($event)"
                  [nzDropdownMatchSelectWidth]="false"
                  nzSize="small"
                  style="width: 160px"
                  [ngModel]="selectedGroup?.id"
                >
                  <nz-option
                    *ngFor="let group of allGroups$ | async"
                    [nzValue]="group.id"
                    [nzLabel]="group.name"
                  >
                  </nz-option>
                </nz-select>
              </ng-container>
            </div>
            <by-arrivals-departures-progress
              [checkInOut]="checkinOut$ | async"
            ></by-arrivals-departures-progress>
          </ng-container>
          <ng-container *ngIf="activeModules; else skeletonTemplate">
            <ul
              [ngClass]="{ collpased: siderAnimationsState === 'reduced' }"
              class="main-navigation"
              nz-menu
              [nzInlineIndent]="15"
              [nzTheme]="'dark'"
              [nzMode]="'inline'"
            >
              <ng-container
                *ngIf="
                  siderAnimationsState === 'reduced' &&
                  (allGroups$ | async)?.length > 1
                "
              >
                <li
                  class="single-menu-li text-align--center"
                  nz-menu-item
                  nz-popover
                  [(nzPopoverVisible)]="groupsSelectPopoverVisible"
                  nzPopoverTrigger="click"
                  [nzPopoverContent]="switchGroupTemplate"
                  nzTooltipClassName="select-property-groups-popover"
                >
                  <i class="fal fa-layer-group"></i>
                </li>
              </ng-container>
              <ng-container *ngFor="let link of commonRoutes">
                <ng-container *ngIf="link.isDropdown && !link.withSiderPopup">
                  <li
                    class="single-menu-li single-menu-li-sub"
                    [ngClass]="{
                      'translateXeffect--submenu':
                        siderAnimationsState !== 'reduced'
                    }"
                    nz-submenu
                    [(nzOpen)]="link.dropdownIsOpen"
                    (nzOpenChange)="commonOpenHandler(link)"
                    routerLinkActive
                    #rla="routerLinkActive"
                  >
                    <div title>
                      <div class="menu-icon-box">
                        <i
                          class="menu-icon fal {{ link.icon }}"
                          [ngClass]="{
                            'active-icon': rla.isActive || link.isActive
                          }"
                        ></i>
                      </div>
                      <span
                        class="nav-text nav-text__submenu"
                        *ngIf="siderAnimationsState !== 'reduced'"
                        >{{ link.label | translate | upperfirst }}</span
                      >
                    </div>

                    <ul>
                      <ng-container *ngFor="let sublink of link?.children">
                        <li
                          *ngIf="!sublink.children; else subChildrenMenu"
                          nz-menu-item
                          [ngClass]="{
                            'active-sub-li': sublink.isActive,
                            'sub-menu-li': siderAnimationsState !== 'reduced',
                            'vertical-sub-menu-li':
                              siderAnimationsState === 'reduced'
                          }"
                        >
                          <ng-container
                            *ngIf="!sublink?.targetBlank; else linkHref"
                          >
                            <a
                              [routerLink]="
                                !sublink.disableRouter && sublink.link
                              "
                              (click)="scrollOnTop()"
                            >
                              <span class="point-list"></span>
                              <span class="sublink-text">
                                <label style="padding-right: 9px"></label>
                                {{ sublink.label | translate | upperfirst }}
                              </span>
                            </a>
                          </ng-container>
                          <ng-template #linkHref>
                            <a
                              [href]="
                                (!sublink.disableRouter && sublink.link) +
                                (sublink?.withToken
                                  ? '?token=' + token.get()
                                  : '')
                              "
                              target="'_blank'"
                              (click)="scrollOnTop()"
                              ><span class="point-list"></span
                              ><span class="sublink-text">
                                <label style="padding-right: 9px"></label>
                                {{ sublink.label | translate | upperfirst }}
                              </span>
                            </a>
                          </ng-template>
                        </li>
                        <ng-template #subChildrenMenu>
                          <li
                            nz-submenu
                            [ngClass]="{
                              'translateXeffect--submenu':
                                siderAnimationsState !== 'reduced',
                              'vertical-sub-menu-li':
                                siderAnimationsState === 'reduced'
                            }"
                            [(nzOpen)]="sublink.dropdownIsOpen"
                            routerLinkActive
                            #rla="routerLinkActive"
                          >
                            <div title class="by-pl-10">
                              <span
                                class="sublink-text"
                                [ngClass]="{
                                  'color--white': sublink.isActive
                                }"
                              >
                                {{ sublink.label | translate | upperfirst }}
                              </span>
                            </div>
                            <ul class="subsub-list">
                              <li
                                *ngFor="let sideLink of sublink?.children"
                                nz-menu-item
                                class="by-pl-5 sub-menu-li"
                                [ngClass]="{
                                  'active-sub-li': sideLink.isActive,
                                  'sub-menu-li':
                                    siderAnimationsState !== 'reduced',
                                  'vertical-sub-menu-li':
                                    siderAnimationsState === 'reduced'
                                }"
                              >
                                <a
                                  [routerLink]="
                                    !sideLink.disableRouter && sideLink.link
                                  "
                                  (click)="scrollOnTop()"
                                >
                                  <span class="point-list"></span>
                                  <span class="sublink-text">
                                    <label style="padding-right: 9px"></label>
                                    {{
                                      sideLink.label | translate | upperfirst
                                    }}
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ng-template>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
                <ng-container *ngIf="link.isDropdown && link.withSiderPopup">
                  <li
                    class="single-menu-li single-menu-li-sub menu-custom-popup-li"
                    [ngClass]="{
                      'menu-custom-popup-li--collapsed':
                        siderAnimationsState === 'reduced'
                    }"
                    routerLinkActive
                    #rla="routerLinkActive"
                    (click)="
                      link.dropdownIsOpen = !link.dropdownIsOpen;
                      submenuCustomPopupOverlayIsOpen =
                        !submenuCustomPopupOverlayIsOpen;
                      currentCustomPopup = link;
                      commonOpenHandler(link);
                      link.dropdownIsOpen ? scrollToCustomBox() : null
                    "
                  >
                    <i
                      *ngIf="siderAnimationsState !== 'reduced'"
                      class="far"
                      [ngClass]="{
                        'fa-chevron-right': !link.dropdownIsOpen,
                        'fa-chevron-left': link.dropdownIsOpen
                      }"
                    ></i>
                    <div class="nav-label">
                      <div class="menu-icon-box">
                        <i
                          class="menu-icon fal {{ link.icon }}"
                          [ngClass]="{
                            'active-icon': rla.isActive || link.isActive
                          }"
                          style="padding-left: 2px"
                        ></i>
                      </div>
                      <span
                        *ngIf="siderAnimationsState !== 'reduced'"
                        class="nav-text nav-text__submenu"
                        >{{ link.label | translate | upperfirst }}</span
                      >
                    </div>
                    <div
                      class="submenu-custom-popup"
                      [ngClass]="{
                        'submenu-custom-popup--open': link.dropdownIsOpen
                      }"
                    >
                      <div
                        class="submenu-custom-popup__header"
                        id="submenu-custom-popup__header"
                      >
                        {{ link.label | translate | upperfirst }}
                      </div>
                      <div class="submenu-custom-popup__body">
                        <ul>
                          <li
                            class="sub-menu-li"
                            *ngFor="let sublink of link?.children"
                            [ngClass]="{
                              'active-li': sublink.isActive
                            }"
                          >
                            <a
                              [routerLink]="sublink.link"
                              (click)="scrollOnTop()"
                              ><span class="point-list"></span>
                              <div class="sublink-text">
                                {{ sublink.label | translate | upperfirst }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="!link.isDropdown">
                  <li
                    class="single-menu-li"
                    nz-menu-item
                    routerLinkActive
                    #rla="routerLinkActive"
                    [ngClass]="{ 'active-li': rla.isActive }"
                  >
                    <a
                      [routerLink]="!link.disableRouter && link.link"
                      (click)="scrollOnTop(); isFoodAndBeverage(link.link)"
                      [ngClass]="{
                        translateXeffect: siderAnimationsState !== 'reduced'
                      }"
                    >
                      <div class="menu-icon-box">
                        <i class="menu-icon fal {{ link.icon }}"></i>
                      </div>
                      <span
                        class="nav-text"
                        *ngIf="siderAnimationsState !== 'reduced'"
                      >
                        {{ link.label | translate | upperfirst }}
                      </span>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
              <!-- <li class="single-menu-li" nz-menu-item>
            <a
              target="_blank˛"
              href="https://support.beddy.io/index.php?/Tickets/Submit"
              [ngClass]="{
                translateXeffect: siderAnimationsState !== 'reduced'
              }"
            >
              <div class="menu-icon-box">
                <i class="menu-icon fal fa-question-circle"></i>
              </div>
              <span class="nav-text" *ngIf="siderAnimationsState !== 'reduced'">
                {{ 'support' | translate | upperfirst }}
              </span>
            </a>
          </li> -->
            </ul>
          </ng-container>
        </div>
      </nz-sider>
      <nz-layout>
        <div
          class="color-line no-print"
          [class.mobile]="isShowResponsiveMenu"
        ></div>
        <nz-header
          *ngIf="!isFullscreen && showHeader$ | async"
          class="no-print"
          [class.mobile]="isShowResponsiveMenu"
        >
          <div class="header-border-right by-w-100" style="margin-right: -1px">
            <i
              *ngIf="!isShowResponsiveMenu"
              class="far app-shell-color--grey header-button"
              [ngClass]="{
                'fa-outdent': siderAnimationsState !== 'closed',
                'fa-indent': siderAnimationsState === 'closed'
              }"
              (click)="sidebarStateController()"
            ></i>
            <by-global-search
              [responsive]="isShowResponsiveMenu"
              [ngStyle]="{
                'min-width': isShowResponsiveMenu ? 'unset' : '170px'
              }"
            ></by-global-search>
          </div>

          <div style="justify-content: flex-end" *ngIf="!isShowResponsiveMenu">
            <div
              class="example-list hide-under-1100"
              cdkDropList
              cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="dropCustomLink($event)"
            >
              <div cdkDrag *ngFor="let link of linkCustom" class="example-box">
                <i
                  nz-tooltip
                  [nzTooltipTitle]="
                    (link?.label | upperfirst) +
                    ' [B+' +
                    (link?.keyCombination | upperfirst) +
                    ']'
                  "
                  [ngStyle]="{ color: link?.color }"
                  nzTooltipPlacement="bottom"
                  style="outline: none !important"
                  class="{{ link?.icon }}"
                  [routerLink]="[link?.link]"
                  (click)="scrollOnTop()"
                ></i>
              </div>
            </div>

            <div
              class="header-border-right add-custom-link-box pointer hide-under-1100"
              *ngIf="!isShowResponsiveMenu"
              [ngClass]="{ 'header-border-left': linkCustom.length > 0 }"
              (click)="createCustomMenuFormService()"
              nz-tooltip
              [nzTooltipTitle]="
                (linkCustom.length === 0
                  ? 'add_custom_menu_link'
                  : linkCustom.length >= 5
                  ? 'edit_custom_menu_link'
                  : 'add_edit_custom_menu_link'
                )
                  | translate
                  | upperfirst
              "
              nzTooltipPlacement="bottom"
            >
              <i
                class="fal app-shell-color--azure by-mr-10"
                [ngClass]="{
                  'fa-plus': linkCustom.length < 5,
                  'fa-pencil-alt': linkCustom.length >= 5
                }"
              ></i>
            </div>

            <div style="align-items: center; padding: 0 20px">
              <!-- START responsive custom link -->
              <a
                nz-dropdown
                class="custom-link-responsive custom-link-responsive__dropdown-button hide-over-1100"
                *ngIf="linkCustom && linkCustom.length"
                [nzTrigger]="'click'"
                nzTooltipClassName="custom-link-responsive__dropdown"
                [nzDropdownMenu]="menu"
                style="margin: 7px 30px 0 0"
                nzPlacement="bottomLeft"
              >
                <i class="far fa-link app-shell-color--azure"></i>
              </a>
              <nz-dropdown-menu class="reservation-tag" #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li *ngFor="let link of linkCustom">
                    <i
                      nz-tooltip
                      [nzTooltipTitle]="
                        (link?.label | upperfirst) +
                        ' [B+' +
                        (link?.keyCombination | upperfirst) +
                        ']'
                      "
                      [ngStyle]="{ color: link?.color }"
                      nzTooltipPlacement="bottom"
                      style="outline: none !important"
                      class="custom-link-responsive__item {{ link?.icon }}"
                      [routerLink]="[link?.link]"
                      (click)="scrollOnTop()"
                    ></i>
                  </li>
                  <li
                    class="header-border-left"
                    (click)="createCustomMenuFormService()"
                    nz-tooltip
                    [nzTooltipTitle]="
                      (linkCustom.length === 0
                        ? 'add_custom_menu_link'
                        : linkCustom.length >= 5
                        ? 'edit_custom_menu_link'
                        : 'add_edit_custom_menu_link'
                      )
                        | translate
                        | upperfirst
                    "
                    nzTooltipPlacement="bottom"
                  >
                    <i
                      class="custom-link-responsive__item fal app-shell-color--azure"
                      [ngClass]="{
                        'fa-plus': linkCustom.length < 5,
                        'fa-pencil-alt': linkCustom.length >= 5
                      }"
                    ></i>
                  </li>
                </ul>
              </nz-dropdown-menu>

              <!-- END responsive custom link -->
              <i
                class="far fa-shopping-cart app-shell-color--azure pointer by-mr-10"
                (click)="createModal()"
                *ngIf="
                  featuresThaCanBeSeen$
                    | async
                    | firstOrDefault: 'reservations' : false
                "
                nz-tooltip
                [nzTooltipTitle]="'add_bill' | translate | upperfirst"
                nzTooltipPlacement="bottom"
              ></i>

              <ng-container *ngIf="bookingWidgets$ | async as bookingWidgets">
                <ng-container *ngIf="bookingWidgets.length === 1">
                  <a
                    class="by-flex"
                    [href]="
                      (bookingWidgets | head | get: 'complete_url') +
                      '?lang=' +
                      selectedLanguageIsoCode
                    "
                    target="_blank"
                    nz-tooltip
                    [nzTooltipTitle]="
                      'go_to_booking_engine' | translate | upperfirst
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="bolShortcutIcon"
                    ></ng-container
                  ></a>
                </ng-container>

                <ng-container *ngIf="bookingWidgets.length > 1">
                  <a
                    class="by-flex"
                    nz-dropdown
                    [nzDropdownMenu]="menu"
                    nzPlacement="bottom"
                    nzTrigger="click"
                  >
                    <ng-container
                      *ngTemplateOutlet="bolShortcutIcon"
                    ></ng-container>
                  </a>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li
                        nz-menu-item
                        *ngFor="let bookingWidget of bookingWidgets"
                      >
                        <a
                          [href]="
                            (bookingWidget | get: 'complete_url') +
                            '?lang=' +
                            selectedLanguageIsoCode
                          "
                          target="_blank"
                        >
                          <i class="far fa-external-link color--azure"></i>
                          {{ bookingWidget.dns }}
                        </a>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </ng-container>

                <ng-template #bolShortcutIcon>
                  <i class="far fa-store pointer by-ml-2 by-mr-10"></i>
                </ng-template>
              </ng-container>

              <nz-select
                id="language-select"
                class="by-ml-10"
                nzDropdownClassName="nz-options-without-padding"
                [ngModel]="selectedLanguageIsoCode"
                (ngModelChange)="onUpdateLanguage($event)"
                [nzSuffixIcon]="selectedIcon"
              >
                <ng-container *ngFor="let language of languages$ | async">
                  <nz-option
                    nzCustomContent
                    [nzValue]="language.iso_code"
                    *ngIf="['it', 'en'] | firstOrDefault: language.iso_code"
                  >
                    <span
                      class="flag-icon flag-icon-{{
                        language.iso_3361_country_code
                      }}"
                    ></span>
                  </nz-option>
                </ng-container>
                <ng-template #selectedIcon>
                  <ng-container *ngFor="let language of languages$ | async">
                    <span
                      *ngIf="selectedLanguageIsoCode === language.iso_code"
                      class="flag-icon flag-icon-{{
                        language.iso_3361_country_code
                      }}"
                    ></span>
                  </ng-container>
                </ng-template>
              </nz-select>
              <by-help class="by-ml-2"></by-help>

              <div
                #notificationsPopover
                nz-popover
                byPopoverScrollBlocker
                [nzPopoverContent]="notifications"
                [nzPopoverTrigger]="'click'"
                [nzPopoverPlacement]="'bottomRight'"
                [nzPopoverOverlayClassName]="'notifications-popover'"
                [nzPopoverBackdrop]="true"
                class="pointer"
              >
                <nz-badge
                  [nzCount]="notificationsCounter$ | async"
                  class="by-ml-2"
                >
                  <i class="fas fa-bell by-fs-20"></i>
                  <ng-template #notifications>
                    <by-notifications-list></by-notifications-list>
                  </ng-template>
                </nz-badge>
              </div>

              <div
                [(nzPopoverVisible)]="showUserPopover"
                class="pointer"
                style="margin-left: 25px"
                nz-popover
                nzPopoverTrigger="click"
                [nzPopoverContent]="contentTemplate"
                nzSize="small"
                nzPopoverPlacement="bottomRight"
              >
                <nz-avatar
                  *ngIf="(userMeImage$ | async)?.media; else userAvatar"
                  [nzSrc]="(userMeImage$ | async)?.formats_size?.small"
                  id="profile-avatar"
                ></nz-avatar>
              </div>
            </div>
          </div>
        </nz-header>
        <nz-content
          *ngIf="isLanguageReady && (currency$ | async)"
          id="app-content"
        >
          <ng-container *ngIf="selectedProperty?.id">
            <router-outlet></router-outlet>
          </ng-container>
        </nz-content>

        <nz-footer
          *ngIf="showFooter$ | async"
          #appFooter
          id="by-app-footer"
          class="no-print"
        >
          {{ 'copyright' | translate }}
        </nz-footer>
      </nz-layout>
    </nz-layout>
    <ng-template #trigger>
      <i class="fal fa-chevron-up"></i>
    </ng-template>
  </div>
  <ng-template #contentTemplate>
    <ul nz-menu style="border: 0px !important">
      <li
        nz-menu-item
        (click)="showUserPopover = !showUserPopover; scrollOnTop()"
      >
        <a [routerLink]="['/me']">
          <i class="fal fa-user by-mr-10"></i
          >{{ 'profile' | translate | upperfirst }}
        </a>
      </li>
      <li
        *ngIf="userCanWrite$ | async"
        nz-menu-item
        (click)="showUserPopover = !showUserPopover"
      >
        <a routerLink="/properties">
          <i class="fal fa-home-lg-alt by-mr-10" style="margin-left: -1px"></i
          >{{ 'my_properties' | translate | upperfirst }}
        </a>
      </li>
      <li
        (click)="showUserPopover = !showUserPopover; scrollOnTop()"
        nz-menu-item
      >
        <a [routerLink]="['/user-sessions']">
          <i class="fas fa-shield-alt by-mr-10"></i>
          {{ 'protection_and_access' | translate | upperfirst }}
        </a>
      </li>
      <li
        *ngIf="isAdmin"
        nz-menu-item
        (click)="onRemoveImpersonate(); showUserPopover = !showUserPopover"
      >
        <i class="fal fa-sign-out-alt by-mr-10"></i>
        {{ 'back_to_admin' | translate | upperfirst }}
      </li>

      <li
        *ngIf="!isAdmin"
        nz-menu-item
        (click)="onLogout(); showUserPopover = !showUserPopover"
      >
        <i class="fal fa-sign-out-alt by-mr-10"></i>
        {{ 'logout' | translate | upperfirst }}
      </li>
    </ul>
  </ng-template>
  <div
    class="submenu-custom-popup__overlay"
    (click)="closeCustomSubmenu()"
    *ngIf="submenuCustomPopupOverlayIsOpen"
  >
    &nbsp;
  </div>

  <ng-template #userAvatar>
    <div class="user-avatar-template">
      <i class="fal fa-user"></i>
    </div>
  </ng-template>

  <ng-template #switchGroupTemplate>
    <span style="display: block" class="user-name">{{
      'select_group' | translate | upperfirst
    }}</span>
    <nz-select
      [nzDisabled]="isGroupSelectDisabled"
      (ngModelChange)="
        onChangeGroupSelected($event); groupsSelectPopoverVisible = false
      "
      nzSize="small"
      style="width: 160px"
      [ngModel]="selectedGroup?.id"
    >
      <nz-option
        *ngFor="let group of allGroups$ | async"
        [nzValue]="group.id"
        [nzLabel]="group.name"
      >
      </nz-option>
    </nz-select>
  </ng-template>

  <nz-modal
    [nzVisible]="isVisibleFoodAndBeverageModal"
    [nzTitle]="null"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    [nzWidth]="1000"
  >
    <ng-container *nzModalContent>
      <img width="100%" src="assets/images/food_and_beverage_fake.jpg" />
    </ng-container>
  </nz-modal>
</ng-container>

<ng-template #noMenuTemplate>
  <div
    class="my-container app-shell"
    byAppShellMenuResponsive
    (appShellMenuResponsiveEvent)="showResponsiveMenu($event)"
  >
    <nz-layout
      ><nz-layout>
        <nz-content
          *ngIf="isLanguageReady && (currency$ | async)"
          id="app-content"
        >
          <ng-container *ngIf="selectedProperty?.id">
            <router-outlet></router-outlet>
          </ng-container>
        </nz-content>
      </nz-layout>
    </nz-layout>
    <ng-template #trigger>
      <i class="fal fa-chevron-up"></i>
    </ng-template>
  </div>
</ng-template>
<div
  *ngIf="!fromMobileApp"
  [hidden]="topUpButtonHidden$ | async"
  class="top-up-global-button"
  [ngClass]="{
    'scroll-finished': verticalScrollFinished,
    'scroll-not-started': verticalScroll >= 0 && verticalScroll < 50
  }"
  (click)="scrollOnTop()"
  (window:scroll)="onWindowScroll()"
>
  <i class="far fa-chevron-up"></i>
</div>

<by-notifications-container></by-notifications-container>

<ng-template #skeletonTemplate>
  <nz-skeleton
    class="by-p-5 by-mt-5"
    [nzActive]="true"
    [nzParagraph]="{ rows: 4 }"
    [nzTitle]="false"
  ></nz-skeleton>
</ng-template>
