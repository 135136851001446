import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CapitalizePipe } from '@z-trippete/angular-pipes';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import {
  DeleteReservationData,
  DeleteReservationModalData,
} from '../../models';

import { DeleteReservationModalComponent } from './delete-reservation-modal.component';

@Injectable()
export class DeleteReservationModalService {
  private capitalize = new CapitalizePipe();

  modal: NzModalRef<DeleteReservationModalComponent, 'delete'>;

  constructor(
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  openModal(
    reservationData: DeleteReservationData,
    onSuccess?: () => void,
  ): NzModalRef<DeleteReservationModalComponent, 'delete'> {
    const { status, reservationAccommodationId, tabIndex } = reservationData;
    let title = this.capitalize.transform(
      this.translate.instant(
        status === 'Cancelled' ? 'delete_reservation' : 'cancel_reservation',
      ),
    );

    if (reservationAccommodationId) {
      title = this.capitalize.transform(
        this.translate.instant('delete_accommodation'),
      );
    }
    this.modal = this.modalService.create<
      DeleteReservationModalComponent,
      DeleteReservationModalData
    >({
      nzTitle: title,
      nzFooter: null,
      nzClassName: 'delete-reservation-modal',
      nzContent: DeleteReservationModalComponent,
      nzData: {
        reservationData: { ...reservationData, tabIndex },
        onSuccess,
      },
    });

    return this.modal;
  }

  closeModal() {
    if (this.modal) {
      this.modal.close();
    }
  }
}
