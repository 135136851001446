import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  name: 'isToday',
  standalone: true,
})
export class IsTodayPipe implements PipeTransform {
  transform(value: Date | string): boolean {
    let date = new Date(value);
    let today = new Date();

    return isSameDay(date, today);
  }
}
