import { Injectable, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

import { EstimateRequest } from '../../../../models/requests/estimate-request';
import { EstimateInterfaceComponent } from '../estimate-interface/estimate-interface.component';
import { EstimateModalComponent } from '../estimate-modal/estimate-modal.component';

interface InterfaceParams<T> {
  componentClass?: Type<T>;
  componentInputs: Partial<T>;
  modalOptions?: ModalOptions;
}

@Injectable()
export class EstimateModalService {
  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
  ) {}

  estimate(
    estimateRequests: EstimateRequest[],
    componentInputs?: Partial<EstimateModalComponent>,
  ): Observable<EstimateModalComponent> {
    const modal = this.modalService.create<
      EstimateModalComponent,
      Partial<EstimateModalComponent>
    >({
      nzContent: EstimateModalComponent,
      nzData: { ...componentInputs, estimateRequests },
      nzWidth: '350px',
      nzBodyStyle: { padding: '0px' },
      nzClassName: 'multi-estimate-modal',
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          type: 'default',
          onClick: () => modal.close(),
        },
        {
          type: 'primary',
          label: upperFirst(this.translate.instant('ok')),
          onClick: (component) => modal.close(component),
        },
      ],
    });

    return modal.afterClose.asObservable();
  }

  interface<T = EstimateInterfaceComponent>(params: InterfaceParams<T>) {
    const { componentClass, componentInputs, modalOptions } = params;

    const modal: NzModalRef<T, EstimateInterfaceComponent> =
      this.modalService.create({
        nzContent: (componentClass || EstimateInterfaceComponent) as Type<any>,
        nzData: componentInputs,
        nzWidth: '350px',
        nzBodyStyle: { padding: '0px' },
        nzClassName: 'multi-estimate-modal',
        nzFooter: [
          {
            label: upperFirst(this.translate.instant('cancel')),
            type: 'default',
            onClick: () => modal.close(),
          },
          {
            type: 'primary',
            label: upperFirst(this.translate.instant('ok')),
            onClick: (component) => modal.close(component),
          },
        ],
        ...modalOptions,
      });

    return modal;
  }
}
