<ng-container [formGroup]="form">
  <nz-form-item class="by-reservation-form__item">
    <nz-form-label class="required-input-red">
      {{ 'rateplans' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.rateplan_id.errors | keys | head)
          | translate
      "
    >
      <nz-select
        [nzDropdownMatchSelectWidth]="false"
        [nzSize]="inputSize"
        [nzPlaceHolder]="
          'placeholder.select' | translate: { param: 'rateplans' | translate }
        "
        formControlName="rateplan_id"
        (nzBlur)="onTouched()"
      >
        <nz-option
          *ngFor="let rateplan of rateplans"
          [nzLabel]="rateplan.name | upperfirst"
          [nzValue]="rateplan.id"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label class="required-input-red">
      {{ 'treatments' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.treatment_id.errors | keys | head)
          | translate
      "
    >
      <nz-select
        [nzDropdownMatchSelectWidth]="false"
        [nzSize]="inputSize"
        [nzPlaceHolder]="
          'placeholder.select' | translate: { param: 'treatments' | translate }
        "
        formControlName="treatment_id"
        (nzBlur)="onTouched()"
      >
        <nz-option
          *ngFor="let treatment of treatments"
          [nzLabel]="treatment.name | upperfirst"
          [nzValue]="treatment.id"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</ng-container>
