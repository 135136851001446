<td
  *ngIf="row.spanIndex === 0"
  nzLeft="0px"
  class="by-tableau-cell by-tableau-cell--first"
  [class.by-tableau-cell--mobile]="isMobile"
  rowspan="2"
>
  <div>
    {{ 'copyright' | translate }}
  </div>
</td>

<td colspan="100%" class="by-tableau-cell"></td>
