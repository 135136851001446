<nz-table
  nzHideOnSinglePage
  [nzData]="reports"
  [nzShowPagination]="false"
  [nzFrontPagination]="false"
  class="table-without-padding-top"
>
  <tbody>
    <tr *ngFor="let report of reports">
      <td>
        <i
          class="fas"
          [class.fa-clock]="report.status === 'unsolved'"
          [class.color--orange]="report.status === 'unsolved'"
          [class.fa-check-circle]="report.status === 'solved'"
          [class.color--green]="report.status === 'solved'"
          nz-tooltip
          [nzTooltipTitle]="report.status | translate | upperfirst"
        ></i>
      </td>
      <td>
        <div>{{ report.title }}</div>
        <div class="by-clamp-text-2 color--grey-dark by-fs-11">
          {{ report.description }}
        </div>
      </td>
      <td>
        <div class="color--grey-dark by-fs-11 nowrap-text">
          <i class="fas fa-calendar-exclamation color--azure"></i>
          {{ report.created_at | formatDate }}
          {{ report.created_at | formatDate: 'LT' }}
        </div>
        <div class="color--grey-dark by-fs-11 nowrap-text">
          <i class="fas fa-user-edit color--green"></i> {{ report.owner.name }}
        </div>
      </td>
      <td>
        <i
          *ngIf="report.media"
          class="fas fa-image pointer color--azure"
          (click)="showMedia.emit(report)"
          nz-tooltip
          [nzTooltipTitle]="report.media.label"
        ></i>
      </td>
      <td nzRight="0" style="width: 40px">
        <a
          *ngIf="canWrite"
          nz-dropdown
          [nzPlacement]="'bottomRight'"
          [nzDropdownMenu]="menu"
          [nzTrigger]="'click'"
        >
          <i class="fas fa-ellipsis-v"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="edit.emit(report)">
              <a>
                <i class="fas fa-pencil-alt color--azure"></i>
                {{ 'edit' | translate | upperfirst }}
              </a>
            </li>
            <li
              nz-menu-item
              nz-popconfirm
              [nzPopconfirmTitle]="'are_you_sure' | translate | upperfirst"
              (nzOnConfirm)="remove.emit(report)"
            >
              <a>
                <i class="fas fa-trash-alt color--red"></i>
                {{ 'delete' | translate | upperfirst }}
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>
</nz-table>
