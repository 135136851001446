import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupByPipe } from '@z-trippete/angular-pipes';
import {
  capitalize,
  get,
  intersection,
  mapValues,
  uniq,
  uniqBy,
  upperFirst,
} from 'lodash';
import * as moment from 'moment';

import { IPropertyTreatment } from '../core/models/api/properties-treatments/property-treatment.model';
import { DATE_FORMAT_MAP } from '../features/commons/stats-revenue/constants/date-format-map';
import {
  AccommodationLookup,
  AddonsByPropertyCategories,
  BuildChart,
  Channel,
  CollectedType,
  Rateplan,
  ReservationReason,
  Stats,
  StatsAccommodationFilterOption,
  StatsCollected,
  StatsDictionary,
  StatsFlat,
  StatsPartitioning,
  StatsPreset,
  StatsRateplanFilterOption,
  StatsResponse,
  StatsTypesValue,
} from '../models';
import { ChartLine, ChartLineItem } from '../models/objects/chart-line';
import { StatsProductionStoreState } from '../root-store/stats-production-store';
import { StatsStoreState } from '../root-store/stats-store';
import { isBefore } from 'date-fns';
import { removeTime } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class StatsHelperService {
  constructor(
    private groupBy: GroupByPipe,
    private translateService: TranslateService,
  ) {}

  dateFormatMap: { [partitioning: string]: string } = DATE_FORMAT_MAP;

  onBuildName = (data: {
    key_type: 'date' | 'string';
    key: string;
    dictionary: StatsDictionary;
    partitioning: StatsPartitioning;
  }): string => {
    const { key_type, key, dictionary, partitioning } = data;

    return key_type === 'date'
      ? upperFirst(
          moment(new Date(key)).format(
            this.dateFormatMap[partitioning || 'monthly'].toUpperCase(),
          ),
        )
      : get(dictionary, [key, 'label'], key);
  };

  onbuildCartesianChartLine(data: BuildChart) {
    const {
      chartTypeSelected,
      statsResponse: { dictionary, values: stats },
      translations: { currentYear, lastYear },
      partitioning,
      compare,
    } = data;
    if (!stats) {
      return;
    }
    const witDateValue = partitioning === 'daily';
    const lines: ChartLine[] = stats.reduce((acc, stat) => {
      const {
        children,
        key,
        offsets: { previous: previusDate },
      } = stat;

      const current = get(stat, ['collected', chartTypeSelected, 'current'], 0);
      const previous = get(
        stat,
        ['collected', chartTypeSelected, 'previous'],
        0,
      );

      const indexString = key.toString();

      if ((!children || !children?.length) && !compare) {
        const currentYearLine = {
          name: upperFirst(currentYear),
          series: [],
        };

        const previousYearLine = {
          name: upperFirst(lastYear),
          series: [],
        };

        if (!acc.length) {
          acc = [previousYearLine, currentYearLine];
        }

        acc = [
          {
            ...acc[0],
            series: [
              ...acc[0].series,
              {
                name: witDateValue ? new Date(indexString) : indexString,
                value: previous || 0,
                date: new Date(previusDate),
                label: witDateValue
                  ? new Date(previusDate?.toString())
                  : previusDate?.toString(),
              },
            ],
          },
          {
            ...acc[1],
            series: [
              ...acc[1].series,
              {
                name: witDateValue ? new Date(indexString) : indexString,
                value: current || 0,
                date: new Date(indexString),
                label: witDateValue ? new Date(indexString) : indexString,
              },
            ],
          },
        ];
        return acc;
      } else {
        if (!acc.length) {
          acc = Object.keys(dictionary).map((keyDictionary) => {
            return {
              name: get(dictionary, [keyDictionary, 'label']),
              series: [],
              key: keyDictionary,
            };
          });
        }
        (children || []).forEach((bundle) => {
          const bundleCurr = get(
            bundle,
            ['collected', chartTypeSelected, 'current'],
            0,
          );
          const bundleKey = get(bundle, ['key']);
          const findLineIndex = acc.findIndex((line) => line.key === bundleKey);
          acc[findLineIndex] = {
            ...acc[findLineIndex],
            series: [
              ...acc[findLineIndex].series,
              {
                name: witDateValue ? new Date(indexString) : indexString,
                value: bundleCurr || 0,
                date: new Date(indexString),
                label: witDateValue ? new Date(indexString) : indexString,
                dictionaryLabel: acc[findLineIndex].name,
              },
            ],
          };
        });
        return acc;
      }
    }, []);
    return lines;
  }

  onbuildHistogramMultiChartLine(data: BuildChart): ChartLine[] {
    const {
      chartTypeSelected,
      statsResponse: { dictionary, values: stats },
      partitioning,
    } = data;
    if (!stats) {
      return;
    }

    return stats
      .map((stat) => {
        const { key, key_type, children } = stat;

        const buildSeries = (dataSerie: Stats): ChartLineItem => {
          const {
            key: serieIndex,
            key_type: seriekey_type,
            collected: serieCollected,
          } = dataSerie;

          const name = this.onBuildName({
            key_type: seriekey_type,
            key: serieIndex,
            dictionary,
            partitioning,
          });

          const value = (get(serieCollected, [chartTypeSelected, 'current']) ||
            0) as number;
          return {
            name,
            value,
          };
        };

        const nameLine = this.onBuildName({
          key_type,
          key,
          dictionary,
          partitioning,
        });

        return {
          name: children?.length ? nameLine : '',
          series: (get(stat, 'children', [stat]) as Stats[])
            .map((serie) => buildSeries(serie))
            .filter((s) => s.value),
        };
      })
      .filter((serie) => serie.series.length);
  }

  uniqName(
    values: { name: string; value: number; id: number | string }[],
  ): { name: string; value: number; id: number | string }[] {
    return values.map((v, index, oldValue) => {
      const { name: currentName, value, id } = v;
      const itemsToCompare = oldValue.slice();
      itemsToCompare.splice(index, 1);
      const existsName = itemsToCompare.some(
        ({ name: otherName }) =>
          currentName.normalize() === otherName.normalize(),
      );
      return {
        name: !existsName
          ? currentName
          : `${currentName} (${this.translateService.instant('id')} ${id})`,
        value,
        id,
      };
    });
  }

  onBuildHistogramSingleChartLiene(
    data: BuildChart,
  ): { name: string; value: number; id: number | string }[] {
    const {
      statsResponse: { dictionary, values },
      chartTypeSelected,
    } = data;

    const chartData = values
      .map((statValue) => {
        const { collected, key } = statValue;
        const name = get(dictionary, [key, 'label'], key);
        const id = get(dictionary, [key, 'id']);
        return {
          name,
          value: collected[chartTypeSelected].current as number,
          id,
        };
      })
      .filter(({ value }) => value);

    return this.uniqName(chartData);
  }

  onSelectChannelsOptionsBySelectedProperties(
    channelsByProperties: { [propertyId: number]: Channel[] },
    propertiesSelected: number[],
  ): Channel[] {
    return Object.keys(channelsByProperties).reduce((acc, propertyId) => {
      acc = [...acc];
      if (propertiesSelected?.includes(+propertyId)) {
        acc = uniqBy([...acc, ...channelsByProperties[+propertyId]], 'id');
      }
      return acc;
    }, []);
  }

  onSelectReservationReasonsOptionsBySelectedProperties(
    reasonsByProperties: ReservationReason[],
    propertiesSelected: number[],
  ): ReservationReason[] {
    return reasonsByProperties?.filter((acc) => {
      return !!intersection(propertiesSelected, acc?.property_id)?.length;
    });
  }

  onSelectAccommodationsOptionsBySelectedProperties(
    accommodationsByProperties: AccommodationLookup,
    propertiesSelected: number[],
    initialValues: StatsAccommodationFilterOption[] = [],
  ): StatsAccommodationFilterOption[] {
    const values = Object.keys(accommodationsByProperties).reduce(
      (acc, propertyId) => {
        acc = [...acc];
        if (propertiesSelected?.includes(+propertyId)) {
          acc = uniqBy(
            [...acc, ...accommodationsByProperties[+propertyId]],
            'id',
          );
        }
        return acc;
      },
      [],
    );
    return [...initialValues, ...values];
  }

  buildcomparePeriodOptions(min_date: Date): string[] {
    let comparePeriodOptions: string[] = [];

    const minDateYear = min_date.getFullYear();

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= minDateYear; i--) {
      comparePeriodOptions = uniq([...comparePeriodOptions, i.toString()]);
    }
    return comparePeriodOptions;
  }

  filtersOptionsIntersection(newOptions: any[], oldOptions: any[]): any[] {
    const commonValues = intersection(oldOptions, newOptions);
    return commonValues.length ? commonValues : newOptions;
  }

  setBeginningPreset(
    defaultPreset: StatsPreset[],
    minPropertyActivationDate: Date,
  ): StatsPreset[] {
    return defaultPreset.map((preset) => {
      const { key, value } = preset;
      return {
        ...preset,
        value:
          key === 'beginning' ? [minPropertyActivationDate, value[1]] : value,
      };
    });
  }

  addLastYearPreset(
    defaultPreset: StatsPreset[],
    minPropertyActivationDate: Date,
  ): StatsPreset[] {
    const startOfLastYear = moment()
      .startOf('year')
      .subtract(1, 'years')
      .toDate();
    const endOfLastYear = moment(startOfLastYear).endOf('years').toDate();
    if (moment(endOfLastYear).isBefore(minPropertyActivationDate, 'days')) {
      return defaultPreset;
    }
    const startOfLastYearIsBefore = moment(startOfLastYear).isBefore(
      minPropertyActivationDate,
      'days',
    );
    const lastYearPreset: StatsPreset = {
      key: 'last_year',
      value: [
        startOfLastYearIsBefore ? minPropertyActivationDate : startOfLastYear,
        endOfLastYear,
      ],
      order: 9,
    };

    const newPresets = [
      ...defaultPreset.filter(({ key }) => key !== 'last_year'),
      lastYearPreset,
    ];

    return newPresets;
  }

  onBuildPreset(
    minPropertyActivationDate: Date,
    defaultPreset: StatsPreset[],
    pickupDates: Date[],
  ): StatsPreset[] {
    let preset: StatsPreset[] = [];
    if (pickupDates) {
      preset = defaultPreset.filter((_preset) => {
        const { value } = _preset;
        return !(
          moment(value[0]).isBefore(minPropertyActivationDate, 'days') ||
          moment(value[1]).isBefore(pickupDates[1], 'days')
        );
      });
    } else {
      preset = defaultPreset.filter((__preset) => {
        const { value } = __preset;
        return !isBefore(
          removeTime(value[1]),
          removeTime(minPropertyActivationDate),
        );
      });
    }
    return preset;
  }

  onBuildPresetPickup(
    minPropertyActivationDate: Date,
    periodTo: Date,
    defaultPreset: StatsPreset[],
  ): StatsPreset[] {
    return defaultPreset.filter((preset) => {
      const { value } = preset;

      return (
        moment(value[1]).isBefore(periodTo, 'days') &&
        moment(value[0]).isAfter(minPropertyActivationDate, 'days')
      );
    });
  }

  flatDataArray(
    stats: Partial<StatsResponse>,
    totalTranslation: string,
    reservation_date_from: string,
    reservation_date_to: string,
  ): StatsFlat[] {
    const label = (
      stat: Partial<Stats>,
      _dictionary: StatsDictionary,
    ): string => {
      const { key_type, key } = stat;
      return key_type === 'date' ? key : _dictionary[key].label;
    };

    const { dictionary, values, totals } = stats;

    const newItems: StatsFlat[] = values.reduce((allItems, item) => {
      const { children, offsets, ...itemToAdd } = item;

      allItems.push({
        ...itemToAdd,
        reservation_date_from,
        reservation_date_to,
        offsets,
        index: itemToAdd.key,
        id: get(dictionary, [itemToAdd.key, 'id']),
        hasChildren: !!children,
        label: label(itemToAdd, dictionary),
        status: get(dictionary, [itemToAdd.key, 'status']),
      });

      if (children?.length) {
        children.forEach((child) => {
          const { children: tableauNumbersObj } = dictionary[child.key] || {};
          const tableauNumbers: Partial<StatsFlat>[] = Object.keys(
            tableauNumbersObj || {},
          ).map((tableauNumberKey) => {
            return {
              key: tableauNumberKey,
            };
          });
          allItems.push({
            ...child,
            offsets,
            reservation_date_from,
            reservation_date_to,
            isChild: true,
            rootParentIndex: itemToAdd.key,
            parentKey: itemToAdd.key,
            index: itemToAdd.key + ':' + child.key,
            id: get(dictionary, [child.key, 'id']),
            hasChildren: tableauNumbersObj,
            label: label(child, dictionary),
            status: get(dictionary, [child.key, 'status']),
          });
          if (tableauNumbers?.length) {
            tableauNumbers.forEach((tableauNumber) => {
              allItems.push({
                key: tableauNumber.key,
                index:
                  itemToAdd.key + ':' + child.key + ':' + tableauNumber.key,
                id: get(dictionary, [
                  child.key,
                  'children',
                  tableauNumber.key,
                  'id',
                ]),
                parentKey: child.key,
                isChild: true,
                rootParentIndex: itemToAdd.key,
                isTableauNumber: true,
                label: label(tableauNumber, dictionary[child.key].children),
                status: get(dictionary, [
                  child.key,
                  'children',
                  tableauNumber.key,
                  'status',
                ]),
              });
            });
          }
        });
      }
      return allItems;
    }, []);

    let items = [...newItems];

    if (totals) {
      const totalItem: StatsFlat = this.getTotalsRow(
        stats,
        totalTranslation,
        reservation_date_from,
        reservation_date_to,
      );

      items = [...items, totalItem];
    }

    return items;
  }

  setAbsoluteGuestTotals(
    store: StatsProductionStoreState.State | StatsStoreState.State,
    stats: Partial<StatsResponse>,
  ): Partial<StatsResponse> {
    const absolute_guests = {
      ...stats?.totals?.collected?.absolute_guests,
      current:
        stats.values.reduce((acc, curr) => {
          const { collected } = curr;
          acc = Object.values(
            collected?.absolute_guests_array?.current || {},
          ).reduce((_acc: number, _curr: number) => {
            _acc = _acc + _curr;
            return _acc;
          }, 0) as number;
          return acc;
        }, 0) + store?.stats?.totals?.collected?.absolute_guests?.current || 0,
    };

    return {
      ...stats,
      totals: {
        ...stats.totals,
        collected: {
          ...stats.totals.collected,
          absolute_guests,
        },
      },
    };
  }

  getTotalsRow(
    stats: Partial<StatsResponse>,
    totalTranslation: string,
    reservation_date_from: string,
    reservation_date_to: string,
  ): StatsFlat {
    const { totals } = stats;

    let { collected } = totals;

    let offsets = totals.offsets;

    if (reservation_date_from && reservation_date_to) {
      offsets = {
        min: reservation_date_from,
        max: reservation_date_to,
        previous: moment(reservation_date_from)
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      };
    }

    return {
      key: 'total',
      key_type: 'string',
      reservation_date_from,
      reservation_date_to,
      collected,
      offsets,
      isChild: false,
      hasChildren: false,
      index: 'total',
      label: capitalize(totalTranslation),
      status: 'stored',
    };
  }

  onSelectTreatmentOptionsBySelectedProperties(
    treatmentsByProperties: { [propertyId: number]: IPropertyTreatment },
    propertiesSelected: number[],
  ): IPropertyTreatment[] {
    return Object.keys(treatmentsByProperties).reduce((acc, propertyId) => {
      acc = [...acc];
      if (propertiesSelected?.includes(+propertyId)) {
        acc = uniqBy([...acc, ...treatmentsByProperties[propertyId]], 'id');
      }
      return acc;
    }, []);
  }

  getAddonCategories(addons: AddonsByPropertyCategories[]): {
    [categoryId: number]: string;
  } {
    return addons.reduce((acc, property) => {
      const { addon_categories } = property;
      const categories = addon_categories.reduce((_acc, category) => {
        const { id: categoryId, name: categoryName } = category;
        _acc = {
          ..._acc,
          [categoryId]: categoryName,
        };
        return _acc;
      }, {});

      acc = {
        ...acc,
        ...categories,
      };
      return acc;
    }, {});
  }

  onSelectAddonsOptionsBySelectedProperties(
    addonsByProperty: AddonsByPropertyCategories[],
    propertiesSelected: any[],
  ): any[] {
    return addonsByProperty
      .filter(({ id }) => propertiesSelected?.includes(id))
      .reduce((acc, curr) => {
        const { addon_categories } = curr;
        const addonsWithCategory = addon_categories.reduce(
          (_acc, addon_category) => {
            const {
              id: categoryId,
              name: categoryName,
              addons,
            } = addon_category;

            const defaultCategoryName =
              categoryId === 1
                ? categoryName
                : this.translateService.instant('various');

            const newAddons = [
              ...addons.map((addon) => ({
                ...addon,
                categoryId,
                categoryName,
              })),
            ];

            _acc = [..._acc, ...newAddons];
            return _acc;
          },
          [],
        );
        acc = [...acc, ...addonsWithCategory];
        return uniqBy(acc, 'id');
      }, []);
  }

  onSelectRateplansOptionsBySelectedProperties(
    ratePlansByProperties: Rateplan[],
    propertiesSelected: any[],
    initialValues: StatsRateplanFilterOption[] = [],
  ): StatsRateplanFilterOption[] {
    const values = ratePlansByProperties?.filter((acc) =>
      propertiesSelected?.includes(acc?.property_id),
    );
    return [...initialValues, ...values];
  }

  setSharePercent(rows: StatsFlat[], totalsRow: StatsFlat): StatsFlat[] {
    return rows.map((row) => {
      if (row.isTableauNumber) {
        return row;
      }

      const totalCurrentOfPeriod = row.collected['total_price'].current;

      const totalsCurrent = totalsRow.collected['total_price'].current;

      const totalPreviousOfPeriod = row.collected['total_price'].previous;

      const totalsPreviousCurrent = totalsRow.collected['total_price'].previous;

      const share_percent = get(row.collected, ['share_percent']);

      let collected = row.collected;

      if (share_percent) {
        const current = (100 * totalCurrentOfPeriod) / totalsCurrent;

        const previous = (100 * totalPreviousOfPeriod) / totalsPreviousCurrent;

        collected = {
          ...collected,
          share_percent: {
            ...share_percent,
            current,
            previous,
            diff_percent: ((current - previous) / previous) * 100,
          },
        };
      }

      return {
        ...row,
        collected,
      };
    });
  }

  resetSharePercent(stats: Partial<StatsResponse>): Partial<StatsResponse> {
    return {
      ...stats,
      values: stats.values.map((value) => {
        const { collected, children } = value;

        let newValue = {
          ...value,
          collected: this.resetCollected('share_percent', collected),
        };

        if (children) {
          newValue = {
            ...newValue,
            children: children.map((child) => {
              const { collected } = child;

              return {
                ...child,
                collected: this.resetCollected('share_percent', collected),
              };
            }),
          };
        }

        return newValue;
      }),
    };
  }

  resetCollected(
    name: CollectedType,
    collected: Partial<StatsCollected>,
  ): Partial<StatsCollected> {
    return mapValues(
      collected,
      (value, key: CollectedType): StatsTypesValue => {
        if (key === name) {
          return {
            current: 0,
            diff_percent: 0,
            previous: 0,
          };
        }

        return value;
      },
    );
  }
}
