import { NgModule } from '@angular/core';

import { CoreModule } from '../../core/core.module';

import { TranslateParamsPipe } from './translate-params.pipe';

@NgModule({
  declarations: [TranslateParamsPipe],
  imports: [CoreModule],
  exports: [TranslateParamsPipe],
})
export class TranslateParamsModule {}
