<div [formGroup]="form">
  <nz-form-item
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label class="required-input-red">
      {{ 'price_total' | translate | upperfirst }}
      <i
        class="fas fa-info-circle pointer by-ml-5 by-mr-5"
        nz-popover
        [nzPopoverContent]="dayPriceTemplate"
        [nzPopoverTitle]="dayPriceTitle"
        nzPopoverPlacement="topRight"
      ></i>
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.price_total.errors | keys | head)
          | translate
      "
    >
      <by-spin
        class="text-align--end"
        [nzSize]="inputSize"
        [nzSpinning]="priceLoading"
      >
        <div class="by-flex by-flex-middle">
          <i
            *ngIf="totalPriceControl.value > 0"
            class="fas fa-percent color--azure by-mr-10 pointer"
            nz-tooltip
            [nzTooltipTitle]="'apply_discount' | translate | upperfirst"
            (click)="showDiscount = true"
          ></i>

          <del
            *ngIf="priceWithoutDiscount > totalPriceControl.value"
            class="color--grey-dark nowrap by-mr-10"
          >
            {{ priceWithoutDiscount | toCurrency: currency?.symbol }}</del
          >

          <nz-input-number
            byDecimalOnly
            [nzSize]="inputSize"
            formControlName="price_total"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="currencySymbolFormatter"
            [nzParser]="currencyParser"
            (nzBlur)="onTouched()"
          ></nz-input-number>
        </div>
      </by-spin>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    *ngIf="showDiscount"
    class="by-reservation-form__item by-reservation-form__item--gray by-reservation-form__item--no-border"
  >
    <nz-form-control>
      <div class="by-flex by-flex-middle by-mt-5">
        <nz-input-group nzCompact class="text-align--end">
          <div>
            <nz-select
              [nzSize]="inputSize"
              formControlName="discount_type_id"
              [nzShowArrow]="false"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option [nzValue]="4" [nzLabel]="currency?.symbol"></nz-option>
              <nz-option [nzValue]="5" nzLabel="%"></nz-option>
            </nz-select>
          </div>
          <nz-input-number
            byDecimalOnly
            [nzSize]="inputSize"
            formControlName="discount_value"
            [nzMin]="0"
            [nzMax]="
              form.value.discount_type_id === 5 ? 100 : priceWithoutDiscount
            "
            [nzStep]="1"
          ></nz-input-number>
        </nz-input-group>

        <div class="by-flex by-flex-middle">
          <button
            (click)="
              onResetDiscount({ price_total: priceWithoutDiscount }, true)
            "
            nz-button
            nzDanger
            nzType="primary"
            nzShape="circle"
            [nzSize]="inputSize"
            nz-tooltip
            [nzTooltipTitle]="'cancel' | translate | upperfirst"
            class="by-ml-5 ant-btn-icon-only"
          >
            <i class="far fa-times"></i>
          </button>

          <button
            (click)="showDiscount = false"
            nz-button
            nzType="primary"
            nzShape="circle"
            [nzSize]="inputSize"
            nz-tooltip
            [nzTooltipTitle]="'confirm' | translate | upperfirst"
            class="by-ml-5 ant-btn-icon-only"
          >
            <i class="far fa-check"></i>
          </button>
        </div>
      </div>
    </nz-form-control>
  </nz-form-item>

  <ng-content></ng-content>
</div>

<ng-template #dayPriceTitle>
  <div class="day-price-title">
    <span>{{ 'price_day' | translate | upperfirst }}</span>
  </div>
</ng-template>

<ng-template #dayPriceTemplate>
  <div class="day-price-content">
    <ng-container *ngFor="let dailyPrice of dailyRatesControl.value">
      <p>
        {{ dailyPrice | head | formatDate }}:
        {{ dailyPrice | last | toCurrency: currency?.symbol }}
      </p>
    </ng-container>
  </div>
</ng-template>
