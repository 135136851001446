import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { filter, take } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';

import {
  TableauBoxComponent,
  TableauDraggingItem,
  TableauPeriod,
  TableauReservation,
  TableauReservationDetailsModalData,
  TableauRoom,
  TableauRow,
  TableauViewOptions,
} from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import * as TableauActions from '../../../../root-store/tableau-store/actions';
import { TableauConfig } from '../config';
import { TableauDestinationRowSelectionService } from '../services/tableau-destination-row-selection.service';
import { TableauDragService } from '../services/tableau-drag.service';
import { TableauBoxWrapperComponent } from '../tableau-box-wrapper/tableau-box-wrapper.component';

const CHILD_RESERVATION_OBLIQUE_BG = '#d9d9d9';

const CHILD_RESERVATION_BG = `
  repeating-linear-gradient(
    -45deg,
    transparent 0 5px,
    #ccc 5px 10px
  )`;

type TableauReservationBox = TableauBoxComponent<TableauReservation>;

function needToSetBackground(changes: SimpleChanges) {
  const fieldsToWatch: Array<keyof TableauReservationBoxComponent> = [
    'reservationsColors',
    'data',
    'boxWrapperComponent',
    'viewOptions',
    'channelsColors',
  ];

  return fieldsToWatch.some((field) => !!changes[field]);
}

@Component({
  selector: 'by-tableau-reservation-box',
  templateUrl: './tableau-reservation-box.component.html',
  styleUrls: ['./tableau-reservation-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableauReservationBoxComponent
  implements TableauReservationBox, OnChanges
{
  @ViewChild(CdkDrag)
  cdkDrag: CdkDrag;

  @ViewChildren(NzPopoverDirective)
  popoverDirective: NzPopoverDirective[];

  @ViewChildren(NzTooltipDirective)
  tooltipDirective: NzTooltipDirective[];

  @Input()
  draggingItem: TableauDraggingItem;

  @Input()
  row: TableauRow<TableauRoom>;

  @Input()
  data: TableauReservation;

  @Input()
  zoom: number;

  @Input()
  userCanWrite: boolean;

  @Input()
  reservationsColors: Record<string, string>;

  @Input()
  channelsColors: Record<number, string>;

  @Input()
  viewOptions: TableauViewOptions;

  @Input()
  splitMode: boolean;

  @Input()
  period: TableauPeriod;

  @Input()
  hoveredReservation: TableauReservation;

  @Input()
  boxWrapperComponent: TableauBoxWrapperComponent;

  @Input()
  isMobile: boolean;

  readonly CDN_URL = environment.cdnUrl;
  readonly STORAGE_URL = environment.storageUrl;
  readonly TableauConfig = TableauConfig;

  constructor(
    private store: Store<RootState>,
    private tableauDragService: TableauDragService,
    private nzContextMenuService: NzContextMenuService,
    private tableauDestinationRowSelectionService: TableauDestinationRowSelectionService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { hoveredReservation, data, boxWrapperComponent } = changes;

    if (hoveredReservation) {
      this.setHoveredReservation();
    }

    if (data || boxWrapperComponent) {
      this.setBoxSearchValue();
      this.setReservationTag();
    }

    if (needToSetBackground(changes)) {
      this.setBoxBackground();
    }
  }

  onOpenContextMenu(
    $event: MouseEvent,
    dropdownComponent: NzDropdownMenuComponent,
  ) {
    if (!this.userCanWrite) {
      return;
    }

    this.nzContextMenuService.create($event, dropdownComponent);
  }

  onCloseContextMenu() {
    this.nzContextMenuService.close();
  }

  onDragStarted() {
    this.boxWrapperComponent.hide();
    this.tableauDragService.startDrag(this.data);
  }

  onDragDropped(event: CdkDragDrop<TableauRow>) {
    this.tableauDragService.startDrag(null);

    if (this.tableauDragService.isInvalidDragEvent(event)) {
      this.boxWrapperComponent.show();
      return;
    }

    if (event.container.id === TableauConfig.SwapRoomId) {
      this.onSwap();
      return;
    }

    this.store.dispatch(
      TableauActions.moveReservationRequest({
        reservation: this.data,
        sourceRow: event.previousContainer.data,
        destinationRow: event.container.data,
      }),
    );
  }

  onSwap() {
    if (this.data.keep_accommodation) {
      this.boxWrapperComponent.show();
      return;
    }

    this.store.dispatch(
      TableauActions.addReservationToSwap({
        swappedReservation: {
          reservation: this.data,
          sourceRow: this.row,
        },
      }),
    );
  }

  onSplit() {
    this.store.dispatch(TableauActions.toggleSplitMode({ splitMode: true }));
  }

  onMove() {
    this.tableauDestinationRowSelectionService
      .openSelectionForReservation(this.row, this.data)
      .pipe(
        take(1),
        filter((destinationRow) => !!destinationRow),
      )
      .subscribe((destinationRow) => {
        this.store.dispatch(
          TableauActions.moveReservationRequest({
            reservation: this.data,
            sourceRow: this.row,
            destinationRow,
          }),
        );
      });
  }

  onDragScrollError() {
    this.store.dispatch(TableauActions.forceRender());
    this.tableauDragService.startDrag(null);
    this.onMove();
  }

  onOpenReservationDetailsModal() {
    this.store.dispatch(
      TableauActions.openReservationDetailsModal(
        this.tableauReservationDetails,
      ),
    );
  }

  setHoveredReservation() {
    this.boxWrapperComponent.setHighlighted(
      this.hoveredReservation?.reservation_id === this.data.reservation_id &&
        (!this.isSumParentRow || !this.data.child_reservation),
    );
  }

  private setBoxSearchValue() {
    this.boxWrapperComponent?.setSearchLabel(this.data?.search_label);
  }

  private setReservationTag() {
    this.boxWrapperComponent?.setReservationTag(this.data?.tag);
  }

  private setBoxBackground() {
    if (this.data?.child_reservation && !this.viewOptions?.oblique) {
      this.boxWrapperComponent?.setBoxBackground(CHILD_RESERVATION_BG);
      return;
    }

    if (this.data?.child_reservation && this.viewOptions?.oblique) {
      this.boxWrapperComponent?.setBoxBackground(CHILD_RESERVATION_OBLIQUE_BG);
      return;
    }

    this.boxWrapperComponent?.setBoxBackground(
      !this.data?.child_reservation && this.backgroundColor,
    );
  }

  get tableauReservationDetails(): TableauReservationDetailsModalData {
    return {
      property_id: this.row.propertyId,
      reservation_accommodation_room_id:
        this.data.reservation_accommodation_room_id,
      reservation_id: this.data.reservation_id,
      roomreservation_id: this.data.roomreservation_id,
      userCanWrite: this.userCanWrite,
      reservationsColors: this.reservationsColors,
    };
  }

  get showReservationSplit() {
    return this.splitMode && this.data?.length > 1;
  }

  get backgroundColor() {
    const channelColor = get(this.channelsColors, this.data?.channel_id);

    if (this.viewOptions?.reservations_palette === 'status' || !channelColor) {
      return get(this.reservationsColors, this.data?.background_key);
    }

    if (this.viewOptions?.reservations_palette === 'channel') {
      return channelColor;
    }

    return null;
  }

  get triggerPopover(): 'hover' | null {
    return !this.showReservationSplit && !this.isMobile && !this.draggingItem
      ? 'hover'
      : null;
  }

  get isSumParentRow() {
    return this.row?.data?.accommodation_details?.is_sum_parent;
  }

  get triggerTooltip(): 'hover' | null {
    return this.draggingItem ? null : 'hover';
  }

  onMouseDownTooltip() {
    this.tooltipDirective.forEach((tooltip) => tooltip?.hide());
  }

  onMouseDownPopover() {
    this.popoverDirective.forEach((tooltip) => tooltip?.hide());
  }
}
