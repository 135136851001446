@if (showWarning) {
  <nz-alert
    nzType="warning"
    nzCloseable
    [nzMessage]="
      'warning_automatic_payments_ignore_deposit_configuration'
        | translate
        | upperfirst
    "
    nzShowIcon
    class="by-mb-20"
  />
}

<nz-table
  #basicTable
  [nzData]="automaticPaymentSummary?.expected_payments"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  class="recap-table"
>
  <thead>
    <tr>
      <th>{{ 'date' | translate }}</th>
      <th>{{ 'amount' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let payment of automaticPaymentSummary?.expected_payments">
      <td>
        @if (!(payment.date | isToday)) {
          {{ payment.date | formatDate: 'DD MMM YYYY' }}
        } @else {
          {{ 'today' | translate | upperfirst }}
        }
      </td>
      <td>
        <by-currency-format
          [currencyValue]="payment.amount"
          [currencySymbol]="currency"
        />
      </td>
    </tr>
  </tbody>
</nz-table>
