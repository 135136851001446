import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContinuousClickDirective } from './continuous-click.directive';

@NgModule({
  declarations: [ContinuousClickDirective],
  imports: [CommonModule],
  exports: [ContinuousClickDirective],
})
export class ContinuousClickModule {}
