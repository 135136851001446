import { Action, createReducer, on } from '@ngrx/store';

import fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { automaticPaymentsConfigurations }) =>
    fromState.featureAdapter.setAll(automaticPaymentsConfigurations, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadDetailsSuccess,
    (state, { automaticPaymentsConfiguration }) =>
      fromState.featureAdapter.upsertOne(automaticPaymentsConfiguration, {
        ...state,
        isLoading: false,
        error: null,
      }),
  ),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { id }) =>
    fromState.featureAdapter.removeOne(id, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { automaticPaymentsConfiguration }) =>
    fromState.featureAdapter.addOne(automaticPaymentsConfiguration, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.editRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.editSuccess, (state, { automaticPaymentsConfiguration }) =>
    fromState.featureAdapter.upsertOne(automaticPaymentsConfiguration, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.editFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadPaymentMethodsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadPaymentMethodsSuccess,
    (state, { automaticPaymentMethods }) => ({
      ...state,
      automaticPaymentMethods,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadPaymentMethodsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function automaticPaymentsConfigurationReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
